<template>
	<div class="row m-0 align-items-center">
		<div class="col-8 text-primary">
			<span class="w-100">
				<div class="row align-items-center">
					<i v-if="!showDevicePath" class="col-1 fa fa-caret-right"></i>
					<i v-else class="col-1 fa fa-caret-down"></i>
					<b class="col-10" style="cursor:pointer" @click="toggleSectionInfo">
						{{ device.name }}
					</b>
				</div>
				<small v-if="showDevicePath" class="w-100">
					{{ device.installation }} / {{ device.zone }}
				</small>
			</span>
		</div>

		<div class="col-4 text-right">
			<button
				v-if="device.metrics.length > 0"
				class="btn btn-xs btn-light border"
				type="button"
				@click="toggleModal(device.id)"
			>
				<span class="fa-stack fa-1x">
					<i class="fas fa-circle fa-stack-2x"></i>
					<i class="fas fa-info fa-stack-1x fa-inverse"></i>
				</span>
			</button>
			<device-metrics-modal
				:id="`modalMetricsTable${device.id}`"
				:device="device"
				:show_modal="showModal[device.id]"
			/>
			<span v-if="device.time_device" class="mr-1">{{ device.time_device }}</span>
			<span><i
				:class="`fa fa-circle ${
					device.is_online ? 'text-success' : 'text-danger'
				}`"
			></i></span>
		</div>
	</div>
</template>
<script>
import DeviceMetricsModal from './DeviceMetricsModal.vue';

export default {
	name: 'DeviceHeader',
	components: {
		DeviceMetricsModal
	},
	props: ['device'],
	data() {
		return {
			showModal: {},
			showDevicePath: false
		};
	},
	methods: {
		toggleModal(deviceId) {
			this.$set(this.showModal, deviceId, !this.showModal[deviceId]);
		},
		toggleSectionInfo() {
			this.showDevicePath = !this.showDevicePath;
		}
	}

};
</script>


