<template>
    <li class="list-group-item bg-gray-200">
        <zone-item  :zone="zone" :edit_fn="edit_fn"/>
    </li>
</template>
<script>
import ZoneItem from './ZoneItem'
export default {
    name: 'ZoneFinal',
    props: {
        zone: {required: true},
        edit_fn:{},
    },
    components: {
        ZoneItem
    },
    data() {
        return {

        }
    }
}
</script>
