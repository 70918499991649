<template>
    <input type='text' :id='container_search_id' class='form-control'>
</template>
<script>
export default {
    name: 'SearchBox',
    props: {
        container_search_id:{
            required: true,
            type: String
        },
        map: {
            required: true,
        },
        google: {
            required: true,
        },
    },
    watch: {
        map: function() {
            this.initComponent()
        },
    },
    data() {
        return {}
    },
    methods: {
        initComponent() {
            // Create the search box and link it to the UI element.
            const input = document.getElementById(this.container_search_id)
            const searchBox = new this.google.maps.places.Autocomplete(input)

            searchBox.addListener('place_changed', () => {
                const place = searchBox.getPlace()
                if(place && place.geometry){
                    const location = place.geometry.location
                    const latLng = {lat: location.lat(), lng: location.lng()}
                    this.map.panTo(latLng)
                }
            })
        },
    },
}
</script>
