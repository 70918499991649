<template>
    <div>
        <div class="row" v-if="searchable">
            <div class='col-12 col-md-4 mb-2'>
                <SearchBox :container_search_id='`${container_map_id}_box`' :google='google' :map='map'/>
            </div>
        </div>
        <div v-if='isEditable' class='row'>
            <div class='col-12 col-md-4 mb-2'>
                <SearchBox :container_search_id='`${container_map_id}_box`' :google='google' :map='map'/>
            </div>
            <div class='col-12 col-md-4'>

            </div>
            <div class='col-12 col-md-4 text-right mb-2'>
                <button @click='deleteCurrentShape' class='mb-3 btn btn-danger btn-sm'><i class='fa fa-trash'></i>
                    {{ this.locale ? this.locale.delete_shape : 'Delete active polygon' }}
                </button>
<!--                <button @click='removeMarkFromArray' class='mb-3 btn btn-danger btn-sm'><i class='fa fa-trash'></i>-->
<!--                    {{ this.locale ? this.locale.delete_checkpoint : 'Delete active mark' }}-->
<!--                </button>-->
            </div>
        </div>
       

        <div :style="{height: map_height}" class='gmap-container' :id='container_map_id'>
            <GmapDrawler
                v-if='isEditable'
                ref='mapDrawler'
                @click='getPolyData'
                @currentShape='setCurrentShape'
                :addShape_fn='addShape'
                :changeColorShapes_fn='changeColorShapes'
                :shapes='shapes'
                :google='google'
                :map='map'
                :set-current-mark_fn='setCurrentMark'
                :push-new-mark_fn='addMarkToArray'
                :zone_id='zone_id'
                :emitMarkData_fn='emitMarkData'
            />

            <div v-for='(zone,zone_idx) in zones' :key='zone_idx'>
                <PolygonMap
                    v-for='(poly_paths,poly_idx) in zone.paths'
                    @click='getPolyData'
                    @currentShape='setCurrentShape'
                    :key='poly_idx'
                    :container_polygon_id='`zone_${zone.zone_id}_poly_${poly_idx}`'
                    :polygon_data='parsePolyData(zone,poly_paths)'
                    :map='map'
                    :google='google'
                    :editable='isEditable'
                    :draggable='isEditable'
                    :addShape_fn='addShape'
                    :changeColorShapes_fn='changeColorShapes'
                    :set-current-mark_fn='setCurrentMark'
                    :push-new-mark_fn='addMarkToArray'
                    :emitMarkData_fn='emitMarkData'
                />

                <MarkerMap
                    :google='google'
                    :map='map'
										:edit_btn_url="edit_btn_url"
                    :set-current-mark_fn='setCurrentMark'
                    :push-new-mark_fn='addMarkToArray'
                    :marks='zone.checkpoints'
                    :info-window='infoWindow'
                    :editable='mark_editable'
                    :zone_id='zone.zone_id'
                    :zone_name='zone.zone_name'
                    :emit-mark-data_fn='emitMarkData'
                    @markerChanged='positionMarkerChanged'
                    :enableInfoWindow='enableInfoWindow'

                />
            </div>
            <slot></slot>
        </div>
        <div class="p-1">            
            <span class='mr-4'>Lat: {{ click_pos.lat }}</span>
            <span>Lng: {{ click_pos.lng }}</span>
            <a  v-if='showZoneSelector' class="btn btn-primary float float-right" data-toggle="collapse" href="#filtersMap" role="button" aria-expanded="false" aria-controls="filtersMap">
                <i class="fa fa-filter fa-lg"></i> Show Filters
              </a>
        </div>
        <div class='row collapse' id="filtersMap">
            <slot   name="CustomSelector"></slot>
            <div v-if='showZoneSelector' class='mb-3 col-12 col-sm-4'>
                <label for=''>Zones</label>
                <v-select @input='showZone' :options='zonesCombo' label='zone_name' code='zone_id'/>
            </div>
            <div v-if='showGeoPosBtn' class='mb-3 col-2 '>
                <button style='margin-top:32px' class='btn btn-sm btn-primary' @click='locateGeoLocation'>
                    <i class='fa fa-bullseye'></i></button>
            </div>
						
        </div>
    </div>

</template>
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import MarkerMap from './MarkerMap'
import {Loader} from '@googlemaps/js-api-loader'
import GmapDrawler from './DrawManager'
import SearchBox from './SearchBox'
import PolygonMap from './PolygonMap'

export default {
    name: 'Gmap',
    components: {
        vSelect,
        GmapDrawler,
        SearchBox,
        PolygonMap,
        MarkerMap,
    },
    props: {
				edit_btn_url:{
					type: String,
					default: null
				},
				map_height: {
					type: String,
					default: '500px'
				},
				container_map_id: {
						type: String,
						required: true,
				},
        showGeoPosBtn: {
            type: Boolean,
            default: true,
        },
        enableInfoWindow: {
            type: Boolean,
            default: true,
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        showZoneSelector: {
            type: Boolean,
            default: false,
        },

        editable: {
            type: Boolean,
            default: false,
        },
        mark_editable: {
            type: Boolean,
            default: false,
        },
        zone_id: {
            type: Number,
            required: false,
        },
        zone_name: {
            type: String,
            required: false,
        },
        lat: {
            type: Number,
        },
        lng: {
            type: Number,
        },
        calculateCurrentGeo: {
            type: Boolean,
            default: false,
        },
        zoom: {
            type: Number,
            default: 14,
        },
        zones: {
            type: Array,
            required: false,
            default: () => [],
        },
        positionBy: {
            type: String,
            default: 'polygons'  //polygons|latLng|checkpoints|auto
        }
    },
    created() {

    },
    computed: {
        isEditable: function () {
            return this.editable === true
        },
        locale: function () {
            if (this.$map_locale) {
                return this.$map_locale
            } else {
                return null
            }
        },
        zonesCombo: function () {
            const result = this.zones.map(zone => zone)
            return result
        },

    },
    mounted() {
        this.setMap()
    },
    watch: {
				zones: function(){
						if(this.map){
								this.setPosByProp()
								this.mapClickListener()
						}
				},
				lat: function(){
						this.setPosByProp()
				},
        center: function () {
            this.syncPosition()
            this.$emit('panPos', this.center)
        },
    },
    data() {
        return {
            click_pos: {
                lat: null,
                lng: null,
            },
            infoWindow: null,
            current_shape: null,
            current_mark: null,
            shapes: [],
            marks: [],
            map: null,
            google: null,
            center: {
                lat: 41.53881183339412,
                lng: 2.445926909799745,
            },
            options: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: true,
            },
        }
    },
    methods: {
        positionMarkerChanged(position) {
            this.$emit('markerChanged', position.path)
        },
        parsePolyData(zone, paths) {
            return {
                zone_id: zone.zone_id,
                zone_name: zone.zone_name,
                checkpoints: zone.checkpoints,
                paths,
            }
        },
        syncPosition() {
            this.map.panTo(this.center)
        },

        async setMap() {
            const loader = new Loader({
                apiKey: 'AIzaSyBJcQBziVj3_6swKwonhbDnYUaW29tMfOE',
                libraries: ['places', 'drawing'],
            })

            const mapOptions = {
                center: this.center,
                zoom: this.zoom,
                ...this.options,
            }
            this.google = await loader.load()
            const map = new this.google.maps.Map(document.getElementById(this.container_map_id), mapOptions)
            this.map = map
            this.infoWindow = new this.google.maps.InfoWindow()
            this.setPosByProp()
            this.mapClickListener()
        },

        setPosByProp() {
            const type = this.positionBy
						 // console.log(type,this.zones)
						//polygons|latLng|checkpoints
            switch (type) {
                case 'polygons':
                    this.setPosByPolygons(this.zones)
                    break
                case 'checkpoints':
                    this.setPosByMark(this.zones)
                    break
                case 'latLng':
                    this.setCustomLocations()
                    break
                default:
                    this.setPosByPolygons(this.zones)
            }
        },

        mapClickListener() {
            this.google.maps.event.addListener(this.map, 'click', (e) => {
                const position = e.latLng
                const coords = {
                    lat: position.lat(),
                    lng: position.lng(),
                }
                this.click_pos = coords
            })
        },

        async setCustomLocations() {
            if (this.lat && this.lng) {
								this.center = {
										lat: this.lat,
										lng: this.lng
								}
            } else if (this.calculateCurrentGeo) {
                await this.locateGeoLocation()
            }
        },

        setPosByMark(zones) {
            if (zones && zones.length > 0) {
                const bounds = new this.google.maps.LatLngBounds()
                let i = 0
                var polygonCoords = []
                zones.map(zone => {
                    zone.checkpoints.map(checkpoint => {
                        polygonCoords = polygonCoords.concat(checkpoint.position)
                    })
                })
                for (i = 0; i < polygonCoords.length; i++) {
                    bounds.extend(polygonCoords[i])
                }
                // console.log(bounds.getCenter());
                this.center = bounds.getCenter()
                //fit the map zoom on all objects of map
                this.map.fitBounds(bounds);
            }
        },

        setPosByPolygons(zones) {
						// console.log(zones,[...zones])
            if (zones && zones.length > 0) {
                const bounds = new this.google.maps.LatLngBounds()
                let i = 0
                var polygonCoords = []
                zones.map(zone => {
                    zone.paths.map(coords => {
                        polygonCoords = polygonCoords.concat(coords)
                    })

                })

                for (i = 0; i < polygonCoords.length; i++) {
                    bounds.extend(polygonCoords[i])
                }
                // console.log(bounds.getCenter());
                this.center = bounds.getCenter()
                //fit the map zoom on all objects of map
                this.map.fitBounds(bounds);
            }
        },
        async locateGeoLocation() {
            return axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=1eb96189ddc34c259e5e36c1985dc0cf').then(response => {
                this.center = {
                    lat: response.latitude,
                    lng: response.longitude,
                }

            })
        },
        setCurrentShape(payload) {
            this.current_shape = payload
            this.$emit('clickPoly', payload)
        },
        addShape(payload) {
            if (!payload.info) {
                payload.info = {
                    zone_id: this.zone_id,
                    zone_name: this.zone_name,
                }
            }
            this.shapes.push(payload)
            this.$emit('polygonsChanged')
        },

        deleteCurrentShape() {
            if (this.current_shape) {
                this.current_shape.status = null
                this.current_shape.setEditable(false)
                this.current_shape.setMap(null)
                this.current_shape = null
                this.shapes = this.shapes.filter(shape => shape.status === 'active')
            }
            this.$emit('polygonsChanged')

        },


        getPolyData(poly_data) {
            const {info, paths} = poly_data
            const infoJson = JSON.parse(JSON.stringify(info))
            const pathsJson = JSON.parse(JSON.stringify(paths))
            this.click_pos = pathsJson[0]
            this.$emit('clickPolygon', {info: infoJson, paths: pathsJson})
        },

        changeColorShapes(color) {
            this.shapes.forEach(shape => {
                shape.set('fillColor', color)
            })
        },
        setCurrentMark(payload) {
            this.current_mark = payload
        },
        emitMarkData(payload) {
            const data = {
                zone_id: payload.zone_id,
                title: payload.getTitle(),
                info: JSON.parse(JSON.stringify(payload.info)),
                path: JSON.parse(JSON.stringify(payload.path)),
            }
            this.click_pos = payload.path
            this.$emit('clickCheckpoint', data)
        },
        addMarkToArray(mark) {
            this.marks.push(mark)
        },

        removeMarkFromArray() {
            if (this.current_mark) {
                this.current_mark.status = null
                this.current_mark.setMap(null)
                this.current_mark = null
                this.marks = this.marks.filter(item => item.status === 'active')
            }
        },

        showZone(payload) {
						this.changeColorShapes('rgba(116,116,116,0.5)')
						if(payload && payload.zone_id){
								const selectedShapes = this.shapes.filter(shape => shape.info.zone_id === payload.zone_id)

								selectedShapes.map(item => {
										item.set('fillColor', 'rgba(196, 247, 195,.5)')
								})
								this.setPosByPolygons(selectedShapes)
						}else{
								this.setPosByPolygons(this.zones)
						}


        },

        /**
         * ===============================================================
         * METHODS CALLED FROM PARENT
         * ===============================================================
         */

        /**
         * Called from parent component
         * @returns {*[]}
         */
        getElements() {
            const shapes = this.getShapes()
            const checkpoints = this.getMarks()
            const shapes_keys = Object.keys(shapes)
            const result = []
            shapes_keys.map((zone_id) => {
                shapes[zone_id].checkpoints = checkpoints[zone_id] ? checkpoints[zone_id].checkpoints : []
                result.push(shapes[zone_id])
            })

            return result
        },

        /**
         * Called from parent component
         * @returns {*[]}
         */
        getShapes() {
            const shapes = []
            this.shapes.map(shape => {
                const shapeData = {
                    zone_id: shape.info.zone_id,
                    zone_name: shape.info.zone_name,
                    paths: JSON.parse(JSON.stringify(shape.paths)),
                }
                shapes.push(shapeData)
            })

            const result = {}
            shapes.map(item => {
                if (result[item.zone_id]) {
                    let itemPath = item.paths
                    result[item.zone_id].paths.push(itemPath)
                } else {
                    item.paths = [item.paths]
                    result[item.zone_id] = item
                }
            })

            return result
        },

        /**
         * Called from parent component
         * @returns {*[]}
         */
        getMarks() {
            const marks = []
            this.marks.map(mark => {
                const data = {
                    zone_id: mark.zone_id,
                    info: JSON.parse(JSON.stringify(mark.info)),
                    path: JSON.parse(JSON.stringify(mark.path)),
                    title: mark.getTitle(),
                }
                marks.push(data)
            })
            const result = {}
            marks.map(item => {
                if (result[item.zone_id]) {
                    result[item.zone_id].checkpoints.push(item)
                } else {
                    const data = {
                        zone_id: item.zone_id,
                        checkpoints: [item],
                    }

                    result[item.zone_id] = data
                }
            })

            return result
        },

        /**
         * Called from parent component
         * @returns {*[]}
         */
        setMarkInfo(id, content) {
            const mark = this.marks.find(mark => mark.info.id === id)
            if (mark && this.infoWindow) {
                const html = content
                this.infoWindow.close()
                this.infoWindow.setContent(html)
                this.infoWindow.open(mark.getMap(), mark)
            }
        },

				/**
				 * Get center parameters
				 * @returns {{lng: number, lat: number}}
				 */
				getCenter(){
						const center = this.map.getCenter()
						return {lat: center.lat(),lng:center.lng()}
				},

				/**
				 * Remove all objects from map
				 */
				resetMapObjects(){
						if(this.map){
								this.shapes.map(shape => {
										shape.setMap(null)
								})
								this.shapes = []

								this.marks.map(mark => {
										mark.setMap(null)
								})
								this.marks = []
						}
				}

    },


}
</script>
<style>
.gmap-container {
    width: 100%;
    height: 500px;
}
</style>
