<template>
	<div class="w-100">
		<card-template id="deviceGrid" :show_header="false">
			<DeviceGrid

				:devices="getInnoboxListData"
				:last_load_time="last_load_time"
				:countdown="countdown"
			></DeviceGrid>
		</card-template>
	</div>
</template>

<script>
import DeviceGrid from './DeviceGrid';
import moment from 'moment';
import {mapGetters} from 'vuex';

export default {
	name: 'DeviceDashboard',
	components: { DeviceGrid },
	props:{
		'user':{default:null},'from_auth':{required:false,default:false}
	},
	created() {
		this.$store.commit('receivers/setDefaultFiltersLoaded',this.from_auth);
		if (this.from_auth){
			this.$store.commit('receivers/setDefaultUserFilters',JSON.parse(this.user.default_filters));
		}
		this.getItems();
		this.updateReloadTime();
		setInterval(() => {
			this.getItems();
			this.updateReloadTime();
		}, 60000);
	},
	computed:{
		...mapGetters('receivers',['getInnoboxListData'])
	},
	data() {
		return {
			last_load_time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
			countdown: 60
		};
	},
	methods: {
		getItems() {
			this.getDataDashboard();
		},
		getDataDashboard() {
			this.$store.dispatch('receivers/getDataDashboard');
		},
		updateReloadTime() {
			this.last_load_time = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
			this.countdown = 60; // Reset countdown
			const countdownInterval = setInterval(() => {
				this.countdown--;
				if (this.countdown <= 0) {
					clearInterval(countdownInterval);
					this.countdown = 60; // Reset countdown
				}
			}, 1000);
		}
	}
};
</script>
