<template>
	<div>
		<li
			:href="`#item-zone-${zone.id}`"
			:class="!isOpen ? 'd-block pl-0 py-3 collapsed list-group-item collapse' : 'list-group-item pl-0 d-block  py-3 collapse'"
			data-toggle="collapse"
			role="button"
			:aria-expanded="isOpen ? 'true' : 'false' "
			:aria-controls="`#item-zone-${zone.id}`"
			@click="toggle()"
		>
			<zone-item :zone="zone" :is-open="openId"></zone-item>
		</li>

		<div :id="`item-zone-${zone.id}`" :class="isOpen ? 'p-2  collapse show' : 'p-2  collapse' ">
			<div class=" ">
				<div class=" p-0">
					<slot></slot>
				</div>
				<div>
					<device-tree-component :devices="zone.devices"></device-tree-component>
				</div>
			</div>
		</div>
	</div>
</template>
<script>


import ZoneItem from './ZoneItem.vue';
import DeviceTreeComponent from './DeviceTreeComponent.vue';
export default {
	name: 'ZonesItemAccordion',
	components: {DeviceTreeComponent, ZoneItem},
	props: ['zone'],
	computed:{
		isOpen(){
			if (this.zone.open){
				this.openId = this.zone.id;
			} else {
				this.openId = null;
			}
			return this.zone.open;
		}
	},
	data: function () {
		return {
			openId: null
		};
	},
	methods:{
		toggle(){

			if (this.openId == null){
				this.openId = this.zone.id;
			} else {
				this.openId = null;
			}
		}
	}

};
</script>



<style scoped>

</style>
