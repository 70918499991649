<template>
	<div>
		<v-client-table
			:ref="idTable"
			:data="items"
			:columns="columns"
			:options="options"
		>
			<div v-show="enableCreateBtn" slot="beforeTable" class="text-right">
				<button class="btn btn-primary btn-sm mb-2" @click="newItem">
					<i class="fa fa-plus"></i> {{ this.$t('buttons.new') }}
				</button>
			</div>

			<div slot="options" slot-scope="{row}">
				<button v-show="enableEditBtn" class="btn btn-xs btn-warning" @click="editUser(row)">
					<i class="fa fa-pencil"></i>
				</button>
				<button v-show="enableDeleteBtn" class="btn btn-xs btn-danger" @click="deleteUser(row)">
					<i class="fa fa-trash"></i>
				</button>
				<button
					v-show="enableEditBtn"
					v-if="btnConfig"
					class="btn btn-xs btn-primary"
					@click="config(row)"
				>
					<i class="fa fa-cog"></i>
				</button>
				<button
					v-show="$helpers.hasPermission('licence.edit')"
					v-if="btnConfigLicence"
					:class="{
						'btn btn-xs btn-success': row.devicelicence,
						'btn btn-xs btn-info': !row.devicelicence
					}"
					title="Licencia"
					@click="showUser(row)"
				>
					<i class="fa fa-key"></i>
				</button>
			</div>
			<template v-for="(item) in customFields">
				<div :slot="item.field">
					{{ item.content }}
				</div>
			</template>
		</v-client-table>
	</div>
</template>
<script>

export default {
	name: 'InnoCoreDatatable',
	props: {
		btnConfig:{default:false},
		btnConfigLicence:{default:false},
		columnsData: {required: true},
		idTable: {required: true},
		rowItems: {required: true},
		customFields:{required: false},
		enableCreateBtn: {default: true},
		enableEditBtn: {default: true},
		enableDeleteBtn: {default: true}
	},
	created() {
		this.setColumns();
		this.items = [...this.rowItems];
		// this.getData()
	},
	watch: {
		rowItems: function (newItems) {
			this.items = [...newItems];
		}
	},
	data() {
		return {
			items: [],
			columns: [],

			options: {
				resizableColumns: true,
				perPage: 10,
				perPageValues: [5, 10, 50, 100],
				pagination: {chunk: 5},
				datepickerOptions: {
					showDropdowns: true,
					autoUpdateInput: true
				},
				sortIcon: {
					base: 'fa float-right',
					is: 'fa-sort',
					up: 'fa-sort-up',
					down: 'fa-sort-down'
				},
				texts: {
					count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
					first: `${this.$t('First')}`,
					last: `${this.$t('Last')}`,
					filter: `${this.$t('Filter')}:`,
					filterPlaceholder: `${this.$t('Search query')}`,
					limit: `${this.$t('Records')}:`,
					page: `${this.$t('Page')}:`,
					noResults: `${this.$t('No matching records')}`,
					filterBy: `${this.$t('Filter by {column}')}`,
					loading: `${this.$t('Loading')}...`,
					defaultOption: `${this.$t('Select {column}')}`,
					columns: `${this.$t('Columns')}`
				},
				columnsDisplay:{}
				// columnsDropdown: true,
				// columnsDisplay: {
				//     email: 'min_tabletP',
				//     id: 'min_tabletP'
				// },
				// selectable:{
				//     mode: 'multiple', // or 'multiple'
				//     only: function(row) {
				//         return true // any condition
				//     },
				//     selectAllMode: 'all', // or 'page',
				//     programmatic: false
				// }
			}
		};
	},
	methods: {
		setColumns() {
			const headings = {};
			this.columnsData.map(item => {
				this.columns.push(item.field);
				headings[item.field] = item.label;
				if (item.display){
					this.options.columnsDisplay[item.field]= item.display;
				}
			});
			this.options.headings = headings;

		},
		getData() {
			this.$refs.usersTable.setLoadingState(true);
			axios.get('/api-admin/admin/users').then(response => {
				const rows = response.data.data;

				this.items = rows;
				this.$refs.usersTable.setLoadingState(false);
			});
		},

		editUser(item) {
			this.$emit('edit', item);
		},
		deleteUser(item) {
			this.$emit('delete', item);
		},
		showUser(item) {
			this.$emit('show', item);
		},
		newItem(){
			this.$emit('new');
		},
		config(item){
			this.$emit('config',item);
		}
	}

};
</script>
<style lang="scss">
.VueTables {
    label {
        margin-right: 10px;
    }
}

.VueTables__wrapper {
    max-height: 500px;
    overflow-y: scroll;
}

.VueTables__search-field {
    display: flex;
}

.VueTables__limit-field {
    display: flex;
}

.VueTables__columns-dropdown-wrapper {
    margin-right: 10px;
}

.VueTables__row {
    td {
        padding: 3px 5px;
        vertical-align: middle;
    }
}

.VueTables__columns-dropdown {
    input {
        margin-right: 3px;
    }
}
</style>
