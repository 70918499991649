<template>
		<card-template :show_header="false">
				<div class="table-responsive">
						<inno-core-datatable
								:enableCreateBtn="this.$helpers.hasPermission('zones.zoneType.create')"
								:enableEditBtn="this.$helpers.hasPermission('zones.zoneType.update')"
								:enableDeleteBtn="this.$helpers.hasPermission('zones.zoneType.delete')"
								:enableShowBtn="false"
								:idTable="idTable"
								:columnsData="columns"
								:rowItems="items"
								@edit="editItem"
								@delete="deleteItem"
								@new="newItem"
						/>
				</div>
				<modal-confirm-delete-fn />
				<zone-type-modal
						:id="idModal"
						:current="current"
						:refresh_data_fn="getItems"
				/>
		</card-template>
</template>
<script>
import ZoneTypeModal from './ZoneTypeModal'
export default{
		name:'ZoneTypes',
		components:{
				ZoneTypeModal
		},
		created() {
				this.getItems()
		},
		computed:{
			columns	: function (){
					return [
							{
									field: 'name',
									label: trans_devices_bundle.zones.name,
							},
							{
									field: 'options',
									label: trans_devices_bundle.zones.options,
							}
					]
			}
		},
		data() {
				return {
						current: null,
						idModal: 'ZoneTypeModal',
						idTable: 'ZoneTypesTable',
						items: []
				}
		},
		methods: {
				getItems() {
						axios.get('/api-admin/zones/types').then(response => {
								const rows = response.data
								this.items = rows
						})
				},
				editItem(item) {
						this.current = item
						$(`#${this.idModal}`).modal('show')
				},
				deleteItem(item) {
						this.$bus.$emit('fireModalConfirmDeleteFn', {
								text: `${this.$zones_locale.modals.delete_zone_type} ${item.name} `,
								parameters: item,
								fn: this.removeItem
						})
				},
				removeItem(item) {
						axios.delete(`/api-admin/zones/types/${item.id}`).then(response => {
								this.items = this.items.filter(i => i.id !== item.id)
								this.$toast.success(this.$t('toast.delete_success'))
						})
				},
				newItem() {
						this.current = null
						$(`#${this.idModal}`).modal('show')
				}
		}
}
</script>
