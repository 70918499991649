<template>
	<div class="row">
		<div class="col-12">
			<h5 class="badge badge-primary badge-xl p-1 mt-2" style="cursor: pointer; font-size: 90%">
				<span class="" @click="setValue">
					{{ this.title }}

				</span>
				<i v-if="setted" class="fa fa-times-circle ml-2 mr-1" @click="removeValue"></i>
			</h5>
		</div>
		<div class="col-12">
			<i class="fa fa-info-circle ml-2"></i> Se comparará el valor recibido de lifebit contra el
			current_timestamp-10min. Este valor se puede ajustar en el config.js
			<hr>
			<ul>
				<li>
					Se puede configurar una alarma "Mayor que" que se activará si el lifebit recibido es anterior a
					10min. Significaría "más de 10min desconectado"
				</li>
				<li>
					Se puede configurar una alarma "Menor o igual a" que se activará si el lifebit recibido está más de
					10min adelantado en el tiempo, que significaría que el dispositivo está adelantado en hora.
				</li>
			</ul>

			<br>-
		</div>
	</div>
</template>

<script>
export default {
	name: "CurrentTimestampEvaluate",
	props: ['current_value'],
	data() {
		return {
			title: 'current_timestamp',
			value: 'current_timestamp',
			setted: false,
			info: ''
		}
	},
	methods: {
		setValue() {
			this.$emit('setValue', this.value)
			this.setted = true
		},
		removeValue() {
			this.setted = false
			this.$emit('setValue', null)
		}
	}
}
</script>

<style scoped>

</style>
