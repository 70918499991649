<template>
  <card-template :padding="'p-1'" v-show="values !== null && values.length > 0">
    <template slot="titleCard">{{ title }}</template>
    <BarChartApex :items="values" :labels="labels" :label="label" />
  </card-template>
</template>

<script>
import BarChartApex from '../charts/BarChartApex'
export default {
  components: { BarChartApex },
  props: {
    title: { type: String },
    values: Array,
    labels: Array,
    label: String,
  },
};
</script>
