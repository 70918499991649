<template>
	<div class="table-receiver-section">
		<v-client-table
			:ref="idTable"
			:data="items"
			:columns="columns"
			:options="options"
		>
			<template v-for="(item) in customFields">
				<div :slot="item.field" v-html="item.content">
				</div>
			</template>
			<div slot="beforeSearch" class=" btn-filter-export-mobile mb-3">
				<button class="btn btn-warning btn-filters-mobile mr-2" @click="showFilters()">
					<i class="fa fa-filter fa-lg"></i>
				</button>
				<button class="btn btn-excel btn-excel-mobile " @click="exportCsv()">
					<i class="fa fa-file-excel fa-lg"></i>
				</button>
			</div>
			<div slot="afterFilter" class="after__filter d-block m-lg-1 m-0 col-12 col-lg-2 p-0">
				<label v-if="this.customFilters.includes('device_name')" for="">{{
					this.$receiver_locale.global.datatable.device
				}}:</label>
				<device-name-filter
					v-if="this.customFilters.includes('device_name')"
					:current="currentFilter"
					:refresh_fn="setFilter"
					:multiple="false"
					@setFilter="setFilter"
				></device-name-filter>
			</div>

			<div slot="afterFilter" class="after__filter d-block m-lg-1 m-0 col-12 col-lg-auto p-0">
				<recipe-start-date-filter
					v-if="includesDateStart"
					:refresh_fn="setFilter"
					@setFilter="setFilter"
				></recipe-start-date-filter>
			</div>
			<div slot="afterFilter" class="after__filter d-block m-lg-1 mt-2  m-0 col-12 col-lg-auto p-0">
				<div>
					<button class="btn btn-excel btn-excel-desktop ml-2 float float-right" @click="exportCsv()">
						<i class="fa fa-file-excel  "></i>
					</button>
				</div>
			</div>
			<template v-for="(item) in customFields">
				<div :slot="item.field" v-html="item.content"></div>
			</template>
		</v-client-table>
	</div>
</template>
<script>
import 'animate.css';
import {mapGetters} from 'vuex';

export default {
	name: 'ElementsDatatable',
	props: {
		btnConfig: {default: false},
		customFilters: {required: false},
		columnsData: {required: true},
		idTable: {required: true},
		rowItems: {required: true},
		customFields: {required: false},
		enableCreateBtn: {default: true}
	},
	created() {
		this.setColumns();
		this.items = [...this.rowItems];
		this.filterResults();
	},
	mounted() {

	},
	computed: {
		includesDateStart() {
			return this.customFilters.includes('date');
		},
		...mapGetters('receivers', ['getCurrentDevices'])
	},
	watch:{
		rowItems(){
			this.items = [...this.rowItems];
		},
		getCurrentDevices(){
			this.filterResults();
		}
	},
	data() {
		return {
			items: [],
			columns: [],
			currentFilter: [],
			options: {
				rowClassCallback: row => {
					return row.disabled ? 'disabled-item' : null;
				},
				resizableColumns: true,
				perPage: 10,
				perPageValues: [5, 10, 50, 100],
				pagination: {chunk: 5},
				datepickerOptions: {
					showDropdowns: true,
					autoUpdateInput: true
				},
				sortIcon: {
					base: 'fa float-right',
					is: 'fa-sort',
					up: 'fa-sort-up',
					down: 'fa-sort-down'
				},
				texts: {
					count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
					first: `${this.$t('First')}`,
					last: `${this.$t('Last')}`,
					filter: `${this.$t('Filter')}:`,
					filterPlaceholder: `${this.$t('Search query')}`,
					limit: `${this.$t('Records')}:`,
					page: `${this.$t('Page')}:`,
					noResults: `${this.$t('No matching records')}`,
					filterBy: `${this.$t('Filter by {column}')}`,
					loading: `${this.$t('Loading')}...`,
					defaultOption: `${this.$t('Select {column}')}`,
					columns: `${this.$t('Columns')}`
				},
				columnsDisplay: {}
			},
			selectChangeState: ''
		};
	},
	methods: {
		setColumns() {
			const headings = {};
			this.columnsData.map(item => {
				this.columns.push(item.field);
				headings[item.field] = item.label;
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display;
				}
			});
			this.options.headings = headings;
		},
		setFilter(filter) {
			let filterKey = Object.keys(filter)[0];
			this.currentFilter[filterKey] = filter[filterKey];
			this.filterResults();
		},
		filterResults() {
			let queryParams = [];
			queryParams.push({
				date: this.currentFilter['date_start']
					? this.currentFilter['date_start']
					: ''
			});
			queryParams.push({
				device_name: this.getCurrentDevices
			});
			if (this.getCurrentDevices.length > 0){
				this.$emit('setFilter',queryParams);
				axios.post('/api-admin/receiver/getElementsDef',queryParams).then(response => {
					const rows = response.data;
					this.items = rows;
				});
			}

		},

		exportCsv() {
			let data = this.$refs.elementsTable.data;
			axios.post('/api-admin/receiver/exportCsv', data).then((res) => {
				const blob = new Blob([res], {type: 'text/csv'});
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = `preventive_maintenance_elements_${this.formatDate(new Date())}.csv`;
				link.click();
				URL.revokeObjectURL(link.href);
			});
		},
		formatDate(date) {
			return [
				this.padTo2Digits(date.getDate()),
				this.padTo2Digits(date.getMonth() + 1),
				date.getFullYear()
			].join('/');
		},
		padTo2Digits(num) {
			return num.toString().padStart(2, '0');
		},

		showFilters(){
			window.showFilters();
		}

	}
};
</script>
<style lang="scss" scoped>
.btn-excel-mobile,
.btn-excel-desktop{
    background-color: #182e5e;color:white;
}
</style>
