<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
	name: 'AssignFilters',
	components: {
		vSelect
	},
	props: ['user_id','current_filters'],
	created() {
		this.getFilters();
		this.show = 'show';
		this.data = this.current_filters ?? {};
	},
	watch:{
		data(){
			this.$emit('setUserFilters',this.data);
		},
		current_filters(){
			this.data = this.current_filters;
		}
	},
	data() {
		return {
			filters: {},
			data:{},
			show: null
		};
	},
	methods: {
		async getFilters() {
			this.filters = this.$config.filters;
			Object.keys(this.filters).forEach((variableName) => {
				this.filters[variableName].length > 0 ? this.$set(this.data, this.filters[variableName], '') : '';
			});
			Object.keys(this.filters).map(async filter => {
				switch (filter) {
				case 'devices':
					this.filters[filter] = await this.getUserDevices();
					break;
				case 'customers':
					this.filters[filter] = await this.getUserCustomers();
					break;
				case 'installations':
					this.filters[filter] = await this.getUserInstallations();
					break;
				case 'sections':
					this.filters[filter] = await this.getUserSections();
					break;
				case 'deviceTypes':
					this.filters[filter] = await this.getDeviceTypes();
					break;
				}
			});
		},
		getUserDevices() {
			return this.$store.dispatch('receivers/getUserDevices', this.user_id).then(res => {
				return res;
			});
		},
		getUserInstallations() {
			return this.$store.dispatch('installations/getUserInstallations', this.user_id).then(res => {
				return res;
			});
		},
		getUserCustomers() {
			return this.$store.dispatch('customers/getUserCustomers', this.user_id).then(res => {
				return res;
			});
		},
		getUserSections() {
			return this.$store.dispatch('zones/getUserZones', this.user_id).then(res => {
				return res;
			});
		},
		getDeviceTypes() {
			return this.$store.dispatch('devices/getDevicesTypes').then(res => {
				return res;
			});
		}
	}
};
</script>

<template>
	<card-template padding="p-0">
		<template slot="titleCard">
			{{ this.$t('filters.title') }}
		</template>
		<div id="AssignFilters">
			<div v-for="(filter,name) in filters" :key="name" class="card">
				<a
					:href="`#group-${name}-filters`"
					class="d-block card-header py-3"

					role="button"
					aria-expanded="true"
					:aria-controls="`#group-${name}`"
				>
					<h6 class="m-0 font-weight-bold text-primary">{{ $t('filters')[name] }}</h6>
				</a>
				<div :id="`group-${name}-filters`">
					<v-select
						id="id"
						v-model="data[name]"

						:placeholder="'Seleccione ' + $t('filters')[name].toLowerCase()"
						label="name"
						:options="filter"
					>
					</v-select>
				</div>
			</div>
		</div>
	</card-template>
</template>

<style scoped>

</style>
