<template>
	<card-template class="w-100">
		<div class="row">
			<div class="col-lg-3 col-md-6 text-center col-12">
				<div class="rounded border border-primary p-2">
					<h3>{{ this.$devicelicence_locale.global.fixed }}</h3>
					<div class="mb-1">
						<label for="name" class="required mb-1">{{
							this.$devicelicence_locale.global.columns.name
						}}</label>
						<input
							v-model="formData.name"
							:readonly="true"
							type="text"
							class="form-control"
						>
					</div>
					<div class="mb-1">
						<label for="name" class="required mb-1">{{
							this.$devicelicence_locale.global.columns.asset_id
						}}</label>
						<input
							v-model="formData.asset_id"
							:readonly="true"
							type="text"
							class="form-control"
						>
					</div>


					<!-- <div class="mb-1">
						<label for="APIuser" class="required mb-1">{{
							this.$devicelicence_locale.global.columns.APIuser
						}}</label>
						<input
							v-model="formData.APIuser"
							:readonly="true"
							type="text"
							class="form-control"
						>
					</div>
					<div class="mb-1">
						<label for="APIpass" class="required mb-1">{{
							this.$devicelicence_locale.global.columns.APIpass
						}}</label>
						<input
							v-model="formData.APIpass"
							:readonly="true"
							type="text"
							class="form-control"
						>
					</div> -->
				</div>
			</div>
			<div class="col-lg-3 col-md-6 text-center col-12">
				<div class="rounded border border-primary p-2">
					<h3>{{ this.$devicelicence_locale.global.dynamic }}</h3>
					<div class="mb-1">
						<label for="serial_base" class="required mb-1">{{
							this.$devicelicence_locale.global.columns.serial_base
						}}</label>
						<input
							v-model="formData.serial_base"
							:readonly="this.show"
							type="text"
							class="form-control"
						>
					</div>
					<div class="mb-1">
						<label for="network_mac_addr" class="required mb-1">{{
							this.$devicelicence_locale.global.columns.network_mac_addr
						}}</label>
						<input
							v-model="formData.network_mac_addr"
							:readonly="this.show"
							type="text"
							class="form-control"
						>
					</div>
					<div class="mb-1">
						<label for="plc_code" class="required mb-1">{{
							this.$devicelicence_locale.global.columns.plc_code
						}}</label>
						<input
							v-model="formData.plc_code"
							:readonly="this.show"
							type="text"
							class="form-control"
						>
					</div>
				</div>
				<div v-if="!formData.devicelicence" class="rounded border border-primary p-2 mt-5">
					<h3>{{ this.$devicelicence_locale.global.licence }}</h3>

					<div class="mb-1">
						<label for="name" class="required mb-1">{{
							this.$devicelicence_locale.global.columns.code
						}}</label>
						<input
							v-model="formData.code"
							:readonly="true"
							type="text"
							class="form-control"
						>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6 text-center col-12">
				<div class="rounded border border-primary p-2">
					<h3>{{ this.$devicelicence_locale.global.final }}</h3>
					<div class="col-12">
						<div v-if="formData.devicelicence" class="row">
							<div class="bg-gray-200 p-3 col-12 rounded">
								<a
									class=" bg-dark white col-12 p-2 rounded"
									:href="'/subscriptions/' + subscriptionlicence.subscription.id + '/edit'"
								>
									<i class="fa fa-layer-group"> </i> Ver Suscripción asociada
								</a>
								<br /><br />
								<span>
									{{ this.$devicelicence_locale.global.columns.customer }}: <b> {{
										device.zone.installation.customer.name
									}} </b><br />
								</span>

								<span>{{ $devicelicence_locale.subscriptions.columns.type }} :
									<b>{{ subscriptionlicence.subscription.type.name }}</b><br /></span>

								<span>{{ $devicelicence_locale.subscriptions.columns.quantity }} :
									<b>{{ subscriptionlicence.subscription.quantity }}</b><br /></span>
								<span>{{ $devicelicence_locale.subscriptions.columns.quantity }} sin asignar :
									<b>{{
										subscriptionlicence.subscription.subscription_licences.filter(x =>
											x.devicelicence_id == null).length
									}}</b>

									<br /></span>


								<span>{{ $devicelicence_locale.subscriptions.columns.start_date }} :
									<b>{{ subscriptionlicence.subscription.start_date }}</b><br /></span>
								<span v-show="subscriptionlicence.subscription.end_date">{{
									$devicelicence_locale.subscriptions.columns.end_date
								}}
									:
									<b>{{ subscriptionlicence.subscription.end_date }}</b><br /></span>


								<p
									v-if="subscriptionlicence.subscription.subscription_licences.filter(x =>
										x.devicelicence_id == subscriptionlicence.devicelicence_id)[0].applied_date"
									class="bg-white rounded border border-primary p-2 mt-3"
								>
									{{ $devicelicence_locale.global.buttons.applied_at }}:
									<b>
										{{
											subscriptionlicence.subscription.subscription_licences.filter(x =>
												x.devicelicence_id == subscriptionlicence.devicelicence_id)[0].applied_date
										}}
									</b>
								</p>
							</div>
						</div>

						<div v-if="!formData.devicelicence" class="row">
							<span>
								{{ $devicelicence_locale.global.columns.customer }}: <b> {{
									device.zone.installation.customer.name
								}} </b><br />
							</span>
							<template v-for="(item) in device.zone.installation.customer.subscriptions">
								<div
									v-if="item.subscription_licences.filter(x => x.devicelicence_id == null).length > 0"
									class="bg-gray-200 col-12 p-3 mb-3 rounded border border-secondary"
								>
									<span>{{ $devicelicence_locale.subscriptions.columns.type }} :
										<b>{{ item.type.name }}</b><br /></span>

									<span>{{ $devicelicence_locale.subscriptions.columns.quantity }} :
										<b>{{ item.quantity }}</b><br /></span>



									<span>{{ $devicelicence_locale.subscriptions.columns.start_date }} :
										<b>{{ item.start_date }}</b><br /></span>
									<span v-show="item.end_date">{{
										$devicelicence_locale.subscriptions.columns.end_date
									}}
										:
										<b>{{ item.end_date }}</b><br /></span>
									<button
										v-if="item.subscription_licences.filter(x => x.devicelicence_id == null).length > 0"
										:id="item.id"
										:class="{ 'btn-success': item.id == clickedId, 'btn-secondary': item.id != clickedId }"
										class="btn  col-12"
										@click="assignSubscription(item)"
									>
										<i class="fa fa-hand mr-1"></i> {{
											item.id == clickedId ? "" : $devicelicence_locale.global.buttons.assign
										}}
										<i v-if="item.id == clickedId" class="fa fa-check mr-1"></i>
									</button>
									<button
										v-if="item.subscription_licences.filter(x => x.devicelicence_id == null).length == 0"
										class="btn btn-danger flat col-12"
									>
										<i class="fa fa-ban mr-1"></i> {{
											$devicelicence_locale.global.buttons.no_assign
										}}
									</button>
									<br />
									<span>{{ $devicelicence_locale.subscriptions.columns.quantity }} sin asignar :
										<b>{{
											item.subscription_licences.filter(x => x.devicelicence_id == null).length
										}}</b>

										<br /></span>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>

			<div v-if="formData.devicelicence" class="col-lg-3 col-md-6 text-center rounded">
				<div class="rounded border border-primary p-2">
					<h3>{{ this.$devicelicence_locale.global.licence_generated }}</h3>
					<div class="col-12">
						<p>
							{{ this.$devicelicence_locale.global.buttons.created_at }} : <b class="text-secondary">{{
								formData.devicelicence.created_at
							}}</b>
						</p>
						<p>
							{{ this.$devicelicence_locale.global.buttons.updated_at }} : <b class="text-secondary">{{
								formData.devicelicence.updated_at
							}}</b>
						</p>
					</div>
					<!-- <div class="col-12">
						<button v-if="formData.serial_base" class="btn btn-info mb-2" @click="downloadJson">
							<i
								class="fa fa-file-excel mr-1"
							></i> {{
								this.$devicelicence_locale.global.buttons.downloadJson
							}}
						</button>
					</div> -->
					<div class="mt-2 col-12">
						<button v-if="formData.serial_base" class="btn btn-success mb-2" @click="downloadEnc">
							<i
								class="fa fa-key mr-1"
							></i> {{
								this.$devicelicence_locale.global.buttons.downloadEnc
							}}
						</button>
					</div>
					<div class="rounded border border-primary p-2 mt-5">
						<h3>{{ this.$devicelicence_locale.global.licence }}</h3>

						<div class="mb-1">
							<label for="name" class="required mb-1">{{
								this.$devicelicence_locale.global.columns.code
							}}</label>
							<input
								v-model="formData.code"
								:readonly="true"
								type="text"
								class="form-control"
							>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="this.formData.devicelicence == '' || !subscriptionlicence.subscription.subscription_licences.filter(x =>
					x.devicelicence_id == subscriptionlicence.devicelicence_id)[0].applied_date"
				class=" col-12 mt-2 text-left"
			>
				<button class="btn btn-primary mb-2" @click="save">
					<i class="fa fa-key mr-1"></i> {{
						this.$devicelicence_locale.global.buttons.save
					}}
				</button>
			</div>
			<div v-else class=" col-12 mt-2 text-left">
				<button
					v-if="!subscriptionlicence.subscription.subscription_licences.filter(x =>
						x.devicelicence_id == subscriptionlicence.devicelicence_id)[0].applied_date"
					class="btn btn-primary mb-2"
					@click="save"
				>
					<i class="fa fa-key mr-1"></i> {{
						this.$devicelicence_locale.global.buttons.save
					}}
				</button>
			</div>
		</div>
	</card-template>
</template>

<script>
import moment from 'moment';

export default {
	name: 'DeviceLicenceForm',
	components: {},
	props: {
		device: { required: false },
		show: { default: false },
		subscriptionlicence: { required: false }
	},
	created() {
		if (this.device) {

			this.editPath = '/' + this.device.id;
			this.getItem();
		}
	},
	computed: {},
	data() {
		return {
			clicked: false,
			clickedId: null,
			editPath: '',
			formData: {
				id: null,
				name: '',
				code: ''
			}
		};
	},
	methods: {

		save() {
			let formData = new FormData();

			// Check if type is cloud or edge
			if (this.formData.type === 'cloud') {
				formData.append('APIuser', this.formData.APIuser || '');
				formData.append('APIpass', this.formData.APIpass || '');
			}

			const filteredFields = ['APIuser', 'APIpass'];

			Object.keys(this.formData).forEach((field) => {
				if (!filteredFields.includes(field)) {
					formData.append(field, this.formData[field] === null ? '' : this.formData[field]);
				}
			});

			// Convert to json
			let object = {};
			formData.forEach((value, key) => {
				if (!Reflect.has(object, key)) {
					object[key] = value;
					return;
				}
				if (!Array.isArray(object[key])) {
					object[key] = [object[key]];
				}
				object[key].push(value);
			});
			let json = JSON.stringify(object);

			formData.append('json', json);
			let password = '1V9B0M4LHXK8eKgmC'; //this.formData['APIpass'];
			let seed = btoa(this.formData['serial_base'] + this.formData['network_mac_addr'] + password);
			formData.append('seed', seed);
			formData.append('_method', 'put');

			axios.post(`/api-admin/devicelicence${this.editPath}`, formData, { headers: { 'content-type': 'multipart/form-data' } }).then(response => {
				this.$helpers.showLoading();
				this.$helpers.toastSuccess();
				setTimeout(() => {
					//window.location = `/devices`
					window.location = `/devicelicence/${this.device.id}/edit`;
				}, 1000);
			}).catch(error => {
				setTimeout(() => {
					// window.location = `/devicelicence/${this.device.id}/edit`
				}, 1000);
			});
		},
		// JSON to CSV Converter
		ConvertToCSV(objArray) {
			let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
			let str = '';


			let line = '';
			for (let index in array[0]) {
				if (line != '') line += ',';

				line += index;
			}

			str += line + '\r\n';

			for (let i = 0; i < array.length; i++) {
				let line = '';
				for (let index in array[i]) {
					if (line != '') line += ',';

					line += array[i][index];
				}

				str += line + '\r\n';
			}
			console.log(str);

			return str;
		},
		getItem() {

			this.formData.name = this.device.name;
			this.formData.code = this.device.devicelicence ? this.device.devicelicence.code : this.randomString(8);
			this.formData.asset_id = this.device.devicelicence ? this.device.devicelicence.asset_id : this.device.code;
			this.formData.APIuser = this.device.APIuser;
			this.formData.APIpass = this.device.APIpass;
			this.formData.plc_code = this.device.devicelicence ? this.device.devicelicence.plc_code : 'S7-1500';
			this.formData.device_id = this.device.id;
			this.formData.serial_base = this.device.devicelicence ? this.device.devicelicence.serial_base : '';
			this.formData.network_mac_addr = this.device.devicelicence ? this.device.devicelicence.network_mac_addr : '';
			this.formData.devicelicence = this.device.devicelicence ? this.device.devicelicence : '';
			if (this.formData.devicelicence != '') {
				this.formData.devicelicence.updated_at = moment(this.formData.devicelicence.updated_at).format('DD-MM-YYYY HH:mm:ss');
				this.formData.devicelicence.created_at = moment(this.formData.devicelicence.created_at).format('DD-MM-YYYY HH:mm:ss');
				this.formData.subscription_licences_id = this.subscriptionlicence.id;
			}
			if (this.subscriptionlicence != null) {
				this.formData.id = this.subscriptionlicence.devicelicence_id;
				this.formData.type = this.subscriptionlicence.subscription.type.name;
			}

		},
		randomString(len) {
			let p = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			return [...Array(len)].reduce(a => a + p[~~(Math.random() * p.length)], '');
		},
		setDevice(device) {
			this.formData.device = device.id ?? null;
		},
		downloadJson() {
			window.location = `/storage/${this.device.id}_devicelicence.json`;
		},
		downloadEnc() {
			window.location = `/storage/${this.device.id}_devicelicence.enc`;
		},
		assignSubscription(event) {

			this.clicked = true;
			this.clickedId = event.id;
			let subscription_licences_id;

			for (let i = 0; i < event.subscription_licences.length; i++) {
				if (event.subscription_licences[i].devicelicence_id == null) {
					subscription_licences_id = event.subscription_licences[i].id;
					break;
				}
			}

			this.formData.subscription_licences_id = subscription_licences_id;
			this.formData.type = event.type.name;
		}


	}
};
</script>

<style scoped></style>
