<template>
	<div class="row w-100 m-0">
		<div class="row align-items-center ml-1 m-0 w-100">
			<div class="col-2 ">
				<i v-if="(zone.devices.length > 0 || zone.zones.length > 0) && !isOpen" class="fa fa-caret-right"></i>
				<i v-if="(zone.devices.length > 0 ||zone.zones.length > 0) && isOpen" class="fa fa-caret-down"></i>
			</div>
			<div class="col-10 p-0">
				<h6 class="m-0 font-weight-bold text-primary ">
					<small class="font-bold font-italic">#{{ zone.id }}</small>
					{{ zone.name }}
				</h6>
			</div>
		</div>
	</div>
</template>
<script>

import {mapGetters} from 'vuex';
import moment from 'moment/moment';

export default {
	name: 'ZoneItem',
	props: ['zone', 'isOpen'],
	computed: {
		...mapGetters('receivers', ['getDevicesDashboardSelected','getDeviceSelectedAlarm'])
	},
	created() {
		// this.$store.commit('receivers/resetDeviceAlarms');

		//this.checkIfDeviceSelected();
	},
	data: function () {
		return {
			isChecked: false
		};
	},
	methods: {
		preventCollapseOpen(event) {
			event.stopPropagation(); // Evita que el evento se propague al colapso
		},
		handleDeviceSelected(event) {
			event.stopPropagation();
			let currentDevicesSelected = this.getDevicesDashboardSelected;
			if (this.isChecked) {
				currentDevicesSelected.push(this.device);

				this.device.open = true;
				this.$store.commit('receivers/selectDeviceDashboard', currentDevicesSelected);
				this.$store.dispatch('receivers/getDevicesAlarms');

			} else {
				currentDevicesSelected = currentDevicesSelected.filter(item => item.id !== this.device.id);
				this.device.open = false;
				this.$store.commit('receivers/selectDeviceDashboard', currentDevicesSelected);
				this.$store.dispatch('receivers/getDevicesAlarms');
			}
		},
		checkIfDeviceSelected() {
			const deviceSelected = this.getDevicesDashboardSelected.find(item => item.id == this.device.id);
			if (deviceSelected) {
				this.isChecked = true;
			}
		},
		formatLastLifebit(lastLifebit) {
			return moment(lastLifebit).format('YYYY-MM-DD HH:mm:ss');
		}
	}

};
</script>


<style scoped>
.md-checkbox {
    width: 25px !important;
    height: 25px !important;
}

</style>
