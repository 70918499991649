<template>
	<card-template>
		<div class="row">
			<div class="col-sm-4 mb-3">
				<img class="img-fluid avatar mb-3" :src="image_path">
				<div class="mb-3">
					<div class="custom-file">
						<input
							id="file"
							type="file"
							class="custom-file-input"
							@change="setFile"
						>
						<label class="custom-file-label" for="file">{{ fileName }}</label>
					</div>
				</div>
			</div>
			<div class="col-sm-8 mb-3">
				<div class="col-sm-12 mb-3 form-floating">
					<input
						id="customerCode"
						v-model="formData.customer_code"
						type="text"
						class="form-control"
						:placeholder="this.$customers_locale.global.code"
						:readonly="this.customer_id"
					>
					<label for="customerCode" class="required">{{ this.$customers_locale.global.code }}</label>
				</div>
				<div class="col-sm-12 mb-3 form-floating">
					<input
						id="name"
						v-model="formData.name"
						type="text"
						class="form-control"
						:placeholder="this.$customers_locale.global.name"
					>
					<label for="name" class="required">{{ this.$customers_locale.global.name }}</label>
				</div>
				<div class="col-sm-12 mb-3">
					<customers-combo
						:current="this.customer"
						:customer_id="customer_id"
						:multiple="false"
						:label=" this.$customers_locale.global.customer_parent"
						@add="syncCustomer"
					></customers-combo>
				</div>
			</div>

			<div class="col-sm-4 mb-3">
				<label for="cif">{{ this.$customers_locale.global.cif }}</label>
				<div class="input-group mb-3">
					<div class="input-group-prepend">
						<span id="cifIcon" class="input-group-text"><i class="fa fa-file-upload"></i></span>
					</div>
					<input
						id="cif"
						v-model="formData.cif"
						aria-describedby="cifIcon"
						type="text"
						name="cif"
						class="form-control"
					>
				</div>
			</div>

			<div class="col-sm-4 mb-3">
				<label for="phone">{{ this.$customers_locale.global.phone }}</label>
				<div class="input-group mb-3">
					<div class="input-group-prepend">
						<span id="phoneIcon" class="input-group-text"><i class="fa fa-phone"></i></span>
					</div>
					<input
						id="phone"
						v-model="formData.phone"
						aria-describedby="phoneIcon"
						type="phone"
						name="phone"
						class="form-control"
					>
				</div>
			</div>

			<div class="col-sm-4 mb-3">
				<label for="cif">{{ this.$customers_locale.global.contact }}</label>
				<div class="input-group mb-3">
					<div class="input-group-prepend">
						<span id="contactIcon" class="input-group-text"><i class="fa fa-user"></i></span>
					</div>
					<input
						id="contact"
						v-model="formData.contact"
						aria-describedby="contactIcon"
						type="text"
						name="contact"
						class="form-control"
					>
				</div>
			</div>

			<div class="col-md-6 mb-3">
				<label for="address">{{ this.$customers_locale.global.address }}</label>
				<div class="input-group mb-3">
					<div class="input-group-prepend">
						<span id="addressIcon" class="input-group-text"><i class="fa fa-building"></i></span>
					</div>
					<input
						id="address"
						v-model="formData.address"
						aria-describedby="addressIcon"
						type="text"
						name="address"
						class="form-control"
					>
				</div>
			</div>

			<div class="col-md-6 mb-3">
				<label for="email">{{ this.$customers_locale.global.email }}</label>
				<div class="input-group mb-3">
					<div class="input-group-prepend">
						<span id="emailIcon" class="input-group-text"><i class="fa fa-envelope"></i></span>
					</div>
					<input
						id="email"
						v-model="formData.email"
						aria-describedby="emailIcon"
						type="email"
						name="email"
						class="form-control"
					>
				</div>
			</div>
		</div>

		<hr>

		<button class="btn btn-primary" @click="save">
			{{ this.$customers_locale.global.buttons.save }}
		</button>
	</card-template>
</template>
<script>
import CustomersCombo from '/../Modules/Customers/Resources/assets/components/CustomerCombo';
export default {
	name: 'CustomerForm',
	components: {CustomersCombo},
	props: ['customer_id'],
	created() {
		if (this.customer_id) {
			this.getItem();
		}
	},
	data() {
		return {
			fileName: this.$customers_locale.global.choose_file,
			image_path: '/images/no-image.jpg',
			customer:null,
			formData: {
				id: '',
				name: '',
				phone: '',
				email: '',
				cif: '',
				address: '',
				customer_code: '',
				image: '',
				contact: '',
				//customer: null,
				customer_id:''
			}
		};
	},
	methods: {
		getItem() {
			axios.get(`/api-admin/customers/${this.customer_id}`).then(response => {
				const item = response.data;
				this.formData.name = item.name;
				this.formData.customer_code = item.customer_code;
				this.formData.id = item.id;
				this.formData.phone = item.phone;
				this.formData.address = item.address;
				this.formData.cif = item.cif;
				this.formData.email = item.email;
				this.image_path = item.avatar;
				this.formData.contact = item.contact;
				this.customer = item.parent?item.parent:null;
				this.formData.customer_id = item.parent?item.parent.id:'';
			});
		},

		setFile(e) {
			const file = e.target.files[0];
			this.fileName = file.name;
			this.formData.image = file;
		},

		save() {
			let formData = new FormData();
			Object.keys(this.formData).map(field => {
				formData.append(field, this.formData[field]);
			});


			axios.post('/api-admin/customers', formData, {headers: {'content-type': 'multipart/form-data'}}).then(response => {
				this.$helpers.showLoading();
				this.$helpers.toastSuccess();
				setTimeout(() => {
					window.location = `/customers/${response.data.id}`;
				}, 1000);
			});
		},
		syncCustomer(customer) {
			this.customer= customer ? customer : null;
			this.formData.customer_id = customer.id ? customer.id : '';
		}
	}
};
</script>
<style lang="css" scoped>
.custom-file-input:lang(en) ~ .custom-file-label:after {
    content: "\f093"; /* inspect an icon for this value */
    font-family: FontAwesome; /* intentionally no fallback */
    font-size: 16px;
}

.avatar {
    border-radius: 10px;
    border: solid 1px #ccc;
    padding: 10px;
}
</style>
