<template>
    <div class="col-12 col-lg-4 border-right">
        <div class="col-12">
            <h4>{{ this.$devices_locale.configuration.date_hour.title }}</h4>
            <div class="w-100 my-2 p-1 d-flex align-items-center">
                <span>{{ this.$devices_locale.configuration.date_hour.current_time }} <b>{{device_signal_time?device_signal_time:this.device_date}}</b></span>
            </div>
            <div class="w-100 my-2 p-1 d-flex align-items-center">
                <span class="mr-1">{{ this.$devices_locale.configuration.date_hour.update_date }}</span>
                <datetime v-model="current_date" @input='setCurrentDate' type="datetime"
                          format="dd/MM/yyyy HH:mm:ss" inputFormat="dd/MM/yyyy HH:mm:ss" altFormat="dd/MM/yyyy HH:mm:ss" value-zone="Europe/Madrid"></datetime>
            </div>
            <div class="row m-0 p-1">
                <label for="changeDateDevice" class="col-auto p-0">{{ this.$devices_locale.configuration.date_hour.update_date_checkbox }}</label>
                <input type="checkbox" id="changeDateDevice" @change='setUpdateDate' value="true" class="col-2 form-control form-control-sm"
                       v-model="change_date">

            </div>
        </div>
    </div>
</template>

<script>
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'

import moment from 'moment'
export default {
    name: "ChangeDateHourDevice",
    components: {
        Datetime
    },
    created() {
    },
    props: {
        device_date: { default:null },
        device_signal_time: {default:null}
    },
    data() {
        return {
            current_date: '',
            change_date: false,
        }
    },
    methods: {
        setCurrentDate(date) {

            this.$emit('dateChanged',date)
        },
        setUpdateDate(change_date){
            if($(change_date.target).prop('checked')){
                this.change_date = true;
            }else{
                this.change_date = false;
            }
            this.$emit('updateDate',this.change_date)

        }
    }
}
</script>

<style scoped>

</style>
