export const setCurrent = (state,payload)=> {
	state.current = payload
}

export const removeCurrent = (state) => {
	state.current = null
}

export const setTree= (state,payload) => {
	state.tree = payload
}