<template>
		<div class="modal fade" :id="id" tabindex="-1" role="dialog" :aria-labelledby="`${id}_Modal`"
				 aria-hidden="true">
				<div class="modal-dialog" role="document">
						<div class="modal-content">
								<div class="modal-header">
										<h5 class="modal-title" id="exampleModalLabel"></h5>
										<button @click="hideModal" class="close" type="button" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">×</span>
										</button>
								</div>
								<div class="modal-body">
										<ZoneTypeForm
												:current="current"
												:close_modal_fn="hideModal"
												:refresh_data_fn="refresh_data_fn"
										/>
								</div>
						</div>
				</div>
		</div>
</template>
<script>
import ZoneTypeForm from './ZoneTypeForm'
export default {
		name: 'ZoneTypeModal',
		props: ['id', 'current','refresh_data_fn'],
		components: {
				ZoneTypeForm
		},
		data() {
				return {}
		},
		methods: {
				hideModal() {
						$(`#${this.id}`).modal('hide')
				}
		}

}
</script>