<template>
    <div>
        <label v-if="label" :class="required ? 'required' : null">{{ this.$zones_locale.zone_types}}</label>
        <v-select
            :readonly="readonly"
            :options="combo_types"
            v-model="current_type"
            label="name"
            code="id"></v-select>
    </div>

</template>
<script>
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"
export default {
    name: 'ZoneTypeCombo',
    props: {
        required: {default: false},
        readonly: {default: false},
        current: {default: null},
        label: {default: true}
    },
    components: {
        vSelect
    },
    created() {
        this.getData()
		if(this.current){
			this.current_type = {...this.current}
		}
    },
    watch: {
        current_type: function () {
            this.emitSelected()
        },
        current: function (value) {
            this.current_type = value ? {...this.current} : null
        }
    },
    data() {
        return {
            combo_types: [],
            current_type: null,
        }
    },
    methods: {
        getData() {
            const url = '/api-admin/zones/types/combo'
            axios.get(url).then(response => {
                this.combo_types = response.data
            })
        },
        emitSelected() {
            this.$emit('selected', this.current_type)
        }
    }
}
</script>
