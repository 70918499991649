<template>
	<div>
		<card-template :padding="'p-1'">
			<template slot="titleCard">
				{{
					this.$receiver_locale.global.elements.title
				}}
			</template>
			<elements-list @setFilter="setFilter"></elements-list>
		</card-template>
		<elements-interactions v-show="false"></elements-interactions>
		<card-template :padding="'p-1'">
			<template slot="titleCard">
				{{
					this.$receiver_locale.global.elements_log.title
				}}
			</template>
			<elements-log-list :query-params="queryParams"></elements-log-list>
		</card-template>
	</div>
</template>

<script>
import ElementsList from './ElementsList.vue';
import ElementsLogList from './ElementsLogList.vue';
import ElementsInteractions from './ElementsInteractions.vue';

export default {
	name: 'PreventiveMaintenanceDash',
	components: {ElementsInteractions, ElementsLogList, ElementsList},
	mounted(){
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has('key') && searchParams.has('label')) {
			const key = searchParams.get('key');
			const label = searchParams.get('label');
			const date = searchParams.get('date_start');
			// Emitting an event with updated filter data
			this.$bus.$emit('filter-updated', [
				{
					key,
					label,
					date
				}
			]);
			//this.setDevice(data)
		}

	},
	data(){
		return {
			queryParams:null
		};
	},
	methods:{
		setFilter(queryParams){
			this.queryParams = queryParams;
		}
	}
};
</script>
