<template>
    <div class="col-12 p-3">
        <h5 class="w-100 text-primary">
            {{ this.$receiver_locale.global.predictive_production.distribution_production }}</h5>
        <apex-chart v-if="!resetChart" type="bar" height="350" :options="chartOptions" :series="series"></apex-chart>


    </div>

</template>

<script>
import ApexChart from 'vue-apexcharts'

export default {
    name: "ProductionDistributionChart",
    components: {ApexChart},
    props: {
        recipe_code: {required: true},
        device_id: {required: true},
        piezas_ok_nok: {required: true},
        real_simulation: {required: true},
    },
    data() {
        return {
            hoursResult:null,
            resetChart:true,
            selectedRecipeCode:null,
            selectedDeviceId:null,
            items: [],
            labels: [],
            titleChart: '',
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                }
            },
        }
    },
    created() {
        this.selectedRecipeCode = this.recipe_code
    },
    watch:{
        recipe_code(){
            this.selectedRecipeCode = this.recipe_code
        },
        piezas_ok_nok(){
            this.getProductionDistribution()
        },
        real_simulation(){
            this.getProductionDistribution()
        },
        device_id(){
            this.selectedDeviceId = this.device_id
        },
        selectedRecipeCode(){
            this.getProductionDistribution()
        },
        selectedDeviceId(){
            this.getProductionDistribution()
        }
    },
    methods: {
        async getProductionDistribution() {
            if(this.selectedRecipeCode&&this.selectedDeviceId){
                this.resetChart = true
                const options = {
                    piezas_ok_nok:this.piezas_ok_nok,
                    real_simulation:this.real_simulation
                }
                await axios.post(`/api-admin/receiver/getProductionDistribution/${this.selectedDeviceId}/${this.selectedRecipeCode}`, options).then(res=>{
                    // this.items = res.items
                    this.series = [{
                        name: this.$receiver_locale.global.predictive_production.qty_produced,
                        data: res.series
                    }]
                    this.hoursResult = res.result.reverse()
                    this.chartOptions.xaxis.categories = res.rangosString
                    this.resetChart = false
                    this.$emit('setHoursResult',this.hoursResult)
                    this.$emit('setTotalUnitsOk',res.currentRecipe? res.currentRecipe.ok_units : null)
                    this.$emit('setTotalUnits',res.currentRecipe? res.currentRecipe.order_units : null)
                })
            }

        }
    }
}
</script>

<style scoped>

</style>
