<template>
	<card-template :padding="'p-0'">
		<template slot="titleCard">
			{{
				this.$receiver_locale.global.predictive_production.title_recipes
			}}
		</template>
		<div class="row m-0 w-100 p-1">
			<div class="col-lg-6 col-12">
				<div class="row mt-2">
					<div class=" item-xs col-12">
						<predictive-options
							class=""
							@setPiezasOk="setPiezasOk"
							@setRealSimulation="setRealSimulation"
						></predictive-options>
					</div>
				</div>
				<div class="row mt-2">
					<div class="item col-12">
						<div class="row m-0">
							<div class="col-lg-6 col-12 p-3">
								<h5 class="text-primary">
									{{ this.$receiver_locale.global.predictive_production.select_device }}:
								</h5>
								<device-name-filter
									:multiple="false"
									:refresh_fn="setFilter"
									@setFilter="setDevice"
								></device-name-filter>
							</div>
							<!-- Select recipe from list     -->
							<div class="col-lg-6 col-12 p-3">
								<h5 class="text-primary">
									{{ this.$receiver_locale.global.predictive_production.select_recipe }}:
								</h5>
								<recipe-name-filter
									:multiple="false"
									:refresh_fn="setFilter"
									@setFilter="setFilter"
								></recipe-name-filter>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-2">
					<div class="item col-12">
						<!-- Select pieces qty            -->
						<select-pieces-to-do ref="selectPiecesComponent" @setPieces="setPieces"></select-pieces-to-do>
					</div>
				</div>
				<div class="row mt-2">
					<div class="item col-12 p-3">
						<!-- Total pieces an total pieces Ok-->
						<pieces-ok-no-o-k
							ref="piecesNoOk"
							:total_units_ok="totalUnitsOk"
							:total_units="totalUnits"
						></pieces-ok-no-o-k>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-12">
				<div class=" item row h-100">
					<div class="p-2">
						<h5 class="w-100 text-primary">
							{{ this.$receiver_locale.global.predictive_production.times }}
						</h5>
					</div>
					<div class="item">
						<div class="col-12 p-3">
							<h5 class="w-100 text-primary">
								{{ this.$receiver_locale.global.predictive_production.pessimistic_time }}
							</h5>
							<input
								v-model="pessimistic_time"
								type="text"
								class="form-control"
								readonly
							>
						</div>
					</div>

					<div class="item">
						<div class="col-12 p-3">
							<h5 class="w-100 text-primary">
								{{ this.$receiver_locale.global.predictive_production.expected_time }}
							</h5>
							<input
								v-model="expected_time"
								type="text"
								class="form-control"
								readonly
							>
						</div>
					</div>

					<div class="item">
						<div class="col-12 p-3">
							<h5 class="w-100 text-primary">
								{{ this.$receiver_locale.global.predictive_production.optimistic_time }}
							</h5>
							<input
								v-model="optimistic_time"
								type="text"
								class="form-control"
								readonly
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row m-0 p-1">
			<div class="item col-12">
				<production-distribution-chart
					ref="chartDistribution"
					:real_simulation="real_simulation"
					:piezas_ok_nok="piezas_ok_nok"
					:device_id="deviceId"
					:recipe_code="recipeCode"
					@setHoursResult="setHoursResult"
					@setTotalUnitsOk="setTotalUnitsOk"
					@setTotalUnits="setTotalUnits"
				>
				</production-distribution-chart>
			</div>
		</div>
	</card-template>
</template>

<script>
import PredictiveOptions from './PredictiveOptions.vue';
import SelectPiecesToDo from './SelectPiecesToDo.vue';
import PiecesOkNoOK from './PiecesOkNok.vue';
import ProductionDistributionChart from './ProductionDistributionChart.vue';
import moment from 'moment';
import {mapGetters} from 'vuex';

export default {
	name: 'PredictiveProductionDash',
	components: {ProductionDistributionChart, PiecesOkNoOK, SelectPiecesToDo, PredictiveOptions},
	mounted() {
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has('key') && searchParams.has('label')) {
			const key = searchParams.get('key');
			const label = searchParams.get('label');
			this.deviceId = key;
			// Emitting an event with updated filter data
			this.$bus.$emit('filter-updated', [
				{
					key,
					label
				}
			]);
		}
		this.setDevice();
		this.setFilter();
	},
	computed: {
		...mapGetters('receivers', ['getCurrentDevices', 'getCurrentRecipes'])
	},
	watch: {
		pieces_to_production() {
			if (this.pieces_to_production > 0) {
				this.calculateTimes();
			}
		},
		getCurrentRecipes() {
			this.setDevice();
			this.setFilter();
		}
	},
	data() {
		return {
			real_simulation: false,
			piezas_ok_nok: false,
			recipeCode: null,
			deviceId: null,
			totalUnits: null,
			totalUnitsOk: null,
			hoursResult: null,
			pieces_to_production: null,
			optimistic_time: null,
			pessimistic_time: null,
			expected_time: null
		};
	},

	methods: {
		setFilter() {
			this.resetInputs();
			this.recipeCode = this.getCurrentRecipes.code;
		},
		setRealSimulation(value) {
			this.resetInputs();
			this.real_simulation = value;
		},
		setPiezasOk(value) {
			this.resetInputs();
			this.piezas_ok_nok = value;
		},
		setTotalUnits(value) {
			this.totalUnits = value;
			this.$refs.selectPiecesComponent.piecesToDo = value;
			this.$refs.selectPiecesComponent.piecesToDo = value;

		},
		setTotalUnitsOk(value) {
			this.totalUnitsOk = value;
		},
		setHoursResult(hoursResult) {
			this.hoursResult = hoursResult;
		},
		setDevice() {
			this.resetInputs();
			this.deviceId = this.getCurrentDevices[0].key;
		},
		setPieces(value) {
			this.pieces_to_production = value;
		},
		resetInputs() {
			this.$refs.selectPiecesComponent.resetPieces();
			this.pieces_to_production = null;
			this.pessimistic_time = null;
			this.optimistic_time = null;
			this.expected_time = null;
		},
		async calculateTimes() {
			if (this.recipeCode) {
				this.pessimistic_time = await this.calculatePessimisticTime();
				this.optimistic_time = await this.calculateOptimisticTime();
				this.expected_time = await this.calculateExpectedTime();
			}
		},
		async calculateOptimisticTime() {
			let hoursResultAux = this.hoursResult;

			const countHoursResult = hoursResultAux.length;


			const countTenPercentHours = countHoursResult * 0.1;

			let totalPessimisticHours = 0;
			for (let i = 0; i < countTenPercentHours; i++) {
				totalPessimisticHours += hoursResultAux[i]['total_units'];
			}

			totalPessimisticHours = totalPessimisticHours / countTenPercentHours;

			const pessimisticTime = this.pieces_to_production / totalPessimisticHours;

			return this.convertirHoras(pessimisticTime);
		},
		async calculateExpectedTime() {
			let hoursResultAux = this.hoursResult;
			const countHoursResult = hoursResultAux.length;

			let totalPessimisticHours = 0;
			for (let i = 0; i < countHoursResult; i++) {
				totalPessimisticHours += hoursResultAux[i]['total_units'];
			}

			totalPessimisticHours = totalPessimisticHours / countHoursResult;

			const pessimisticTime = this.pieces_to_production / totalPessimisticHours;
			return this.convertirHoras(pessimisticTime);
		},
		async calculatePessimisticTime() {
			let hoursResultAux = this.hoursResult;
			const countHoursResult = hoursResultAux.length;
			const countTenPercentHours = countHoursResult * 0.1;
			let totalPessimisticHours = 0;
			hoursResultAux = hoursResultAux.reverse();

			for (let i = 0; i < countTenPercentHours; i++) {
				totalPessimisticHours += hoursResultAux[countHoursResult - 1 - i]['total_units'];
			}
			totalPessimisticHours = totalPessimisticHours / countTenPercentHours;
			const pessimisticTime = this.pieces_to_production / totalPessimisticHours;
			return pessimisticTime == 'Infinity' ? pessimisticTime : this.convertirHoras(pessimisticTime);
		},
		formatTime(time) {
			const duracion = moment.duration(time, 'hours');
			return moment.utc(duracion.asMilliseconds()).format('HH:mm:ss');
		},
		convertirHoras(time) {
			const days = Math.floor(time / 24);
			const hoursLeft = time % 24;
			const hours = Math.floor(hoursLeft);
			const minutesLeft = (hoursLeft - hours) * 60;
			const minutes = Math.floor(minutesLeft);
			const seconds = Math.floor((minutesLeft - minutes) * 60);

			const formatedHours = (days * 24 + hours).toString().padStart(2, '0');
			const formatedMinutes = minutes.toString().padStart(2, '0');
			const formatedSeconds = seconds.toString().padStart(2, '0');

			const result = `${formatedHours}:${formatedMinutes}:${formatedSeconds}`;
			return result;
		}
	}
};
</script>

<style scoped>
.container {
    padding: 2% 2% 0% 2%;

    gap: 10px;
    width: 100%;
    max-width: 100%;
}
@media only screen and (max-width: 767px) {
    .container {
        padding: 2% 2% 0% 2%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        width: 100%;
        max-width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .container {
        padding: 2% 2% 0% 2%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        width: 100%;
        max-width: 100%;
    }
}
.container-lg {
    display: grid;
    gap: 10px;
    width: 100%;
    max-width: 100%;
}

.card {
    background-color: #F6F6F6A5 !important;
}

.item {
    background-color: white;
    padding: 5px;
    text-align: left;
    width: 100%;
}

.item-xs {
    background-color: white;
    padding: 2px;
    text-align: left;
    width: 100%;
}
</style>
