<template>
	<div>
		<card-template>
			<template slot="titleCard"><i class="fa fa-filter fa-lg"></i> {{ this.$t('Filter') }}</template>
			<div class="row">
				<div class="col-12 col-md-3">
					<label for="">{{ this.$t('DateRange') }}</label>
					<Datepicker
						range
						circle
						show-clear-button
						:lang="this.lang"
						v-model="filterDateRange"
						:date-format="this.formatDate"
						:placeholder="this.$t('DateRange')"
						text-format="short"></Datepicker>
				</div>
				<div class="col-12 col-md-3">
					<label for="">{{ this.$t('EventType') }}</label>
					<v-select
						v-model="selectedType"
						:options="typeOptions"
						:placeholder="this.$t('EventType')"
						class="mb-2"
						multiple></v-select>
				</div>
				<div class="col-12 col-md-5">
					<download-csv
						:header="true"
						:data="filteredHistoryEvents"
						class="btn btn-primary btn-md mb-2 float-right">
						<i class="fa fa-file-excel"></i>
						{{ this.$t('buttons.save') }}
					</download-csv>
				</div>
			</div>
		</card-template>

		<card-template :show_header="false">
			<inno-core-datatable
				:columnsData="columns"
				:rowItems="filteredHistoryEvents"
				:idTable="idTable"
				:enableCreateBtn="false"></inno-core-datatable>
		</card-template>
	</div>
</template>

<script>
import JsonCSV from 'vue-json-csv'
import moment from 'moment'
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css'
import vSelect from 'vue-select'

export default {
	name: 'HistoryEventsList',
	components: {
		downloadCsv: JsonCSV,
		vSelect,
		Datepicker: VueDatepickerUi,
	},
	props: {
		lang: {
			required: true,
		},
	},
	data() {
		return {
			idTable: 'historyeventsTable',
			historyEvents: [],
			columns: [
				{
					field: 'timestamp',
					label: this.$t('Timestamp'),
				},
				{
					field: 'type',
					label: this.$t('EventType'),
				},
				{
					field: 'event_description',
					label: this.$t('EventDescription'),
				},
			],
			filterDateRange: [null, null],
			current: [null, null],
			formatDate: {
				month: '2-digit',
				day: '2-digit',
				year: 'numeric',
			},
			selectedType: null,
			typeOptions: [],
		}
	},
	mounted() {
		this.fetchHistoryEvents()
		this.fetchTypeOptions()
	},
	methods: {
		fetchHistoryEvents() {
			axios
				.get('/api-admin/history/history-events')
				.then((response) => {
					this.historyEvents = response.map((event) => {
						return {
							...event,
							timestamp: moment(event.timestamp).format(
								'YYYY-MM-DD HH:mm:ss'
							),
						}
					})
				})			
		},

		fetchTypeOptions() {
			axios
				.get('/api-admin/history/history-events/types')
				.then((response) => {
					this.typeOptions = response.map((option) => ({
						label: option.type,
						value: option.type,
					}))
				})				
		},
	},
	computed: {
		filteredHistoryEvents() {
			let filteredEvents = this.historyEvents

			// Filter by date range
			if (this.filterDateRange && this.filterDateRange.length === 2) {
				const startDate = this.filterDateRange[0]
				const endDate = this.filterDateRange[1]

				if (startDate && endDate) {
					filteredEvents = filteredEvents.filter((event) => {
						const eventDate = moment(event.timestamp)
						return eventDate >= startDate && eventDate <= endDate
					})
				}
			}

			// Filter by type
			if (this.selectedType && this.selectedType.length > 0) {
				filteredEvents = filteredEvents.filter((event) => {
					return this.selectedType.some(
						(selected) => selected.value === event.type
					)
				})
			}

			return filteredEvents
		},
	},
}
</script>
<style scoped>
.vs__search {
	height: 50px;
}
</style>
