<template>
	<div class="card shadow mb-4">
		<div class="card-body">
			<h6 v-if="user !== undefined" class="text-right">
				<i>#{{ user.id }}</i>
			</h6>
			<div class="row">
				<div class="col-xs-12 col-sm-6">
					<div class="mb-2 form-floating">
						<input
							id="name"
							v-model="formData.name"
							type="text"
							class="form-control"
							:placeholder="this.$trans.users.name"
						>
						<label for="id" class="required">{{ this.$trans.users.name }}</label>
					</div>
					<div class="mb-2 form-floating">
						<input
							id="email"
							v-model="formData.email"
							type="email"
							class="form-control"
							:placeholder="this.$trans.users.email"
						>
						<label for="email" class="required">{{ this.$trans.users.email }}</label>
					</div>
					<customers-combo
						:current="formData.customers"
						:multiple="true"
						:label="this.$trans.users.customers"
						@add="syncCustomer"
					></customers-combo>
					<installations-combo
						:current="formData.installations"
						:multiple="true"
						:label="this.$trans.users.assign_installations"
						@add="syncInstallation"
					/>
				</div>
				<div class="col-xs-12 col-sm-6">
					<div class="mb-2 form-floating">
						<input
							id="password"
							v-model="formData.password"
							type="password"
							class="form-control"
							:placeholder="this.$trans.users.password"
						>
						<label for="password" class="required">{{ this.$trans.users.password }}</label>
					</div>
					<div class="mb-2 form-floating">
						<input
							id="repeat_password"
							v-model="formData.password_confirmation"
							type="password"
							class="form-control"
							:placeholder="this.$trans.users.repeat_password"
						>
						<label for="repeat_password" class="required">{{ this.$trans.users.repeat_password }}</label>
					</div>
				</div>
			</div>
			<hr>
			<div class="row">
				<div v-if="this.$helpers.hasPermission('admin.perms.list')" class="col-sm-6">
					<assign-permissions
						ref="assignPermissions"
						:expanded="false"
						:current="formData.permissions"
						@toggled="togglePermission"
					/>
				</div>
				<div v-if="this.$helpers.hasPermission('admin.roles.list')" class="col-sm-6">
					<assign-roles
						:current="formData.roles"
						@toggled="toggleRole"
					/>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<button class="btn btn-primary" @click="save">
				{{ this.$t('buttons.save') }}
			</button>
		</div>
	</div>
</template>
<script>
import AssignPermissions from '../permissions/AssignPermissions';
import AssignRoles from '../roles/AssignRoles';
import CustomersCombo from '/../Modules/Customers/Resources/assets/components/CustomerCombo';
import InstallationsCombo from '/../Modules/Installations/Resources/assets/components/InstallationsCombo';

export default {
	name: 'UserForm',
	components: {
		AssignPermissions,
		AssignRoles,
		CustomersCombo,
		InstallationsCombo
	},
	props: {
		user: {required: false}
	},
	created() {
		if (this.user) {
			this.setUser();
		}
	},
	data() {
		return {
			installations: null,
			customers: null,
			formData: {
				id: null,
				name: null,
				email: null,
				customers: null,
				password: null,
				password_confirmation: null,
				permissions: [],
				roles: [],
				installations: null
			}
		};
	},
	methods: {
		setUser() {
			this.formData.id = this.user.id;
			this.formData.name = this.user.name;
			this.formData.email = this.user.email;
			this.formData.customers = this.user.customers?this.user.customers:null;
			this.formData.permissions = this.user.permissions;
			this.formData.roles = this.user.roles;
			this.formData.installations = this.user.installations?this.user.installations:null;
		},
		save() {
			const parameters = this.formData;
			axios.post('/api-admin/admin/users', parameters).then(response => {
				this.$toast.success(this.$t('toast.create_success'));

				if (!this.user) {
					setTimeout(() => {
						window.location = `/admin/users/${response.data.id}`;
					}, 1000);
				}
			});
		},
		togglePermission(currentSelected) {
			this.formData.permissions = currentSelected;
		},
		toggleRole(currentRolesSelected) {
			this.formData.roles = currentRolesSelected;
			this.manageRolesPermissions(currentRolesSelected);
		},
		manageRolesPermissions(currentRolesSelected) {
			let totalPermissions = this.$refs.assignPermissions.permissions;
			this.resetPermissions(totalPermissions);
			Object.keys(currentRolesSelected).forEach((index) => {
				let permissionsRole = currentRolesSelected[index]['permissions']; // permissions of the role selected
				if (permissionsRole){
					permissionsRole.forEach((permissionRole) => {
						let permissionGroup = totalPermissions[permissionRole.group];
						this.switchPermissionRole(permissionGroup, permissionRole);
					});
				}

			});
		},
		switchPermissionRole(permissionGroup, permissionRole) {
			let permissionResult = this.formData.permissions;
			const permission = permissionGroup.filter(item => item.name == permissionRole.name);
			if (permission) {
				permissionResult.push({id:permission[0].id,name:permission[0].name});
				permission[0].selected = true;
			}
			this.formData.permissions = permissionResult;
		},
		resetPermissions(totalPermissions) {
			let permissionResult = this.formData.permissions;
			Object.keys(totalPermissions).forEach(group => {
				totalPermissions[group].forEach(permission => {
					const index = permissionResult.find(item=>item.id == permission.id);
					permission.selected = false;
				});
			});
			const activePermissions = permissionResult.filter(activePermission => {
				const allPermission = Object.keys(totalPermissions).find(p => p.id === activePermission.id);
				return allPermission && allPermission.selected;
			});

			this.formData.permissions = activePermissions;

		},
		syncCustomer(customers) {
			this.formData.customers = customers ? customers : null;
		},
		syncInstallation(installations) {
			this.formData.installations = installations ? installations : null;
		},
		setUserFilters(filters){
			this.formData.filters = filters;
		}
	}
};
</script>
