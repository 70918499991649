<template>
	<div :padding="'p-2'">
		<div>
			<elements-log-datatable
				:btn-config="false"
				:enable-create-btn="false"
				:enable-edit-btn="false"
				:enable-delete-btn="false"
				:id-table="idTable"
				:columns-data="columns"
				:row-items="items"
				:custom-fields="customFields"
				:custom-filters="customFilters"
			>
			</elements-log-datatable>
		</div>
	</div>
</template>

<script>


import ElementsLogDatatable from './ElementsLogDatatable.vue';

export default {
	name: 'ElementsLogList',
	components: {ElementsLogDatatable},
	props: {
		queryParams: {default: null}
	},
	created() {
		//this.getItems()
	},
	watch:{
		queryParams(){
			this.getItems();
		}
	},
	data() {
		return {
			idTable: 'elementsLogTable',
			items: [],
			customFields: [],
			customFilters: [],
			columns: [

				{
					field: 'id',
					label: this.$receiver_locale.global.elements_log.id
				},
				{
					field: 'date',
					label: this.$receiver_locale.global.elements_log.date
				},
				{
					field: 'action',
					label: this.$receiver_locale.global.elements_log.action
				},
				{
					field: 'element_name',
					label: this.$receiver_locale.global.elements_log.element_name
				},
				{
					field: 'device.name',
					label: this.$receiver_locale.global.elements_log.device
				},
				{
					field: 'time_type',
					label: this.$receiver_locale.global.elements_log.time_type
				},
				{
					field: 'active',
					label: this.$receiver_locale.global.elements_log.active
				},
				{
					field: 'elapsed_time',
					label: this.$receiver_locale.global.elements_log.elapsed_time
				},

				{
					field: 'life_time',
					label: this.$receiver_locale.global.elements_log.life_time
				},
				{
					field: 'user',
					label: this.$receiver_locale.global.elements_log.user
				}
			]
		};
	},
	methods: {
		getItems() {
			axios.post('/api-admin/receiver/getElementsLog',this.queryParams).then((response) => {
				const rows = response.data;
				this.items = rows;
			});
		}
	}
};
</script>

<style scoped>

</style>
