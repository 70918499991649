<template>
	<card-template>
		<template slot="titleCard">
			{{
				this.$receiver_locale.global.current_production.control_quality
			}}
		</template>
		<div class="">
			<apex-chart
				v-if="countersData"
				ref="pie"
				type="donut"
				:options="chartOptions"
				:series="chartOptions.series"
			></apex-chart>
		</div>
	</card-template>
</template>

<script>
import ApexChart from 'vue-apexcharts';

export default {
	name: 'ControlQuality',
	components: {ApexChart},
	props: {
		currentRecipeData: {required: true}
	},
	created() {
		this.initData();
	},
	watch: {
		currentRecipeData() {
			this.initData();
		}
	},
	data(){
		return {
			productionState:null,
			countersData:null,
			deviceId:null,
			chartOptions: {
				series: [],
				times: [],
				chart: {
					width: '100%',
					type: 'pie'
				},
				noData: {
					text: 'No data',
					align: 'center',
					verticalAlign: 'middle'
				},
				dataLabels: {
					enabled: false
				},
				legend: {
					formatter: function(val, opts) {
						return val + ' - ' + '<b>' + opts.w.globals.series[opts.seriesIndex] + '</b>';
					}
				},
				labels: [],
				responsive: [{
					breakpoint: 480,
					options: {
						chart: {
							width: '100%'
						},
						legend: {
							position: 'bottom'
						}
					}
				}]
			},
			recipeData: null
		};
	},
	methods: {
		initData(){
			this.resetChartData();
			if (this.currentRecipeData){
				this.recipeData = this.currentRecipeData;
				this.deviceId = this.currentRecipeData.device_id;
				this.getCountersData();
			}
		},
		resetChartData(){
			this.recipeData = [];
			this.countersData = null;
			this.chartOptions['series'] = [];
			this.chartOptions['labels'] = [];
			this.chartOptions['times'] = [];
		},
		async buildChartData(){
			const columnsData = ['counter3','counter4','counter5','counter6','counter7','counter8'];
			await columnsData.forEach(item=>{
				const counterLabel = this.countersData[item] ? this.countersData[item] : item;
				this.chartOptions['labels'].push(counterLabel);
				this.chartOptions['series'].push(this.recipeData[item]);
				this.chartOptions['times'].push( this.recipeData[item]);
			});
		},
		async getCountersData(){
			await axios.post('/api-admin/receiver/getCounterByDevice/' + this.deviceId).then(res => {
				this.countersData = res;
				if (this.countersData){
					this.buildChartData();
				}
			});
		}
	}
};
</script>

<style scoped>

</style>
