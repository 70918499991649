<template>
	<div v-show="showWidgets" class="row">
		<div id="div_recipe_selected" class="col-12 col-md-6">
			<card-template>
				<template slot="titleCard">
					{{ this.$receiver_locale.global.dash.recipe_selected }}
				</template>
				<div class="row text-center">
					<div class="col-lg-4 col-12">
						<ValueBox :label="label_recipe_code" :value="recipe_code" />
					</div>
					<div class="col-lg-4 col-12">
						<ValueBox :label="label_name" :value="name" />
					</div>
					<!--div class="col-2">
						<ValueBox :label="label_original_id" :value="original_id" />
					</div-->
					<div class="col-lg-4 col-12">
						<ValueBox :label="label_inicio" :value="inicio" />
					</div>
				</div>
			</card-template>
		</div>

		<div id="div_metrics" class="col-12 col-md-6">
			<card-template>
				<template slot="titleCard">
					{{ this.$receiver_locale.global.dash.metrics_resume }}
				</template>
				<div class="row text-center">
					<div class="col-lg-4 col-12">
						<ValueBox :label="label_mttr" :value="mttr" :unit="unit_mttr" />
					</div>
					<div class="col-lg-4 col-12">
						<ValueBox :label="label_mtbf" :value="mtbf" :unit="unit_mtbf" />
					</div>
					<div class="col-lg-4 col-12">
						<ValueBox :label="label_mtff" :value="mtff" :unit="unit_mtff" />
					</div>
				</div>
			</card-template>
		</div>
		<div id="data_recipe_selected" class="col-12"></div>
		<div id="data_metrics" class="col-12"></div>

		<div id="div_duration" class="col-4">
			<card-template>
				<template slot="titleCard">
					{{ this.$receiver_locale.global.dash.duration }}
				</template>

				<!--BarChart ref="tiempo" :items="values1" :labels="labels1" :label="label1" /-->
				<BarChartApex
					ref="tiempo"
					:items="values1"
					:labels="labels1"
					:label="label1"
				/>
			</card-template>
		</div>
		<div id="div_times" class="col-4">
			<card-template>
				<template slot="titleCard">
					{{ this.$receiver_locale.global.dash.times }}
				</template>

				<!--BarChart ref="numero" :items="values2" :labels="labels2" :label="label2" /-->
				<BarChartApex
					ref="numero"
					:items="values2"
					:labels="labels2"
					:label="label2"
				/>
			</card-template>
		</div>
		<div id="div_stop_type" class="col-4">
			<card-template>
				<template slot="titleCard">
					{{ this.$receiver_locale.global.dash.stop_type }}
				</template>
				<!--Doughnut ref="stop_type" :items="values_stop_type" :labels="labels_stop_type"
					:label="label_stop_type" /-->
				<DoughnutApex
					ref="stop_type"
					:items="values_stop_type"
					:labels="labels_stop_type"
					:label="label_stop_type"
				/>
			</card-template>
		</div>
		<div id="data_graph_duration" class="col-4"></div>
		<div id="data_graph_times" class="col-4"></div>
		<div id="data_stop_type" class="col-4"></div>
		<div class="col-12">
			<faultlog-list></faultlog-list>
		</div>
	</div>
</template>
<script>

import BarChartApex from '../charts/BarChartApex';
import moment from 'moment';

import DoughnutApex from '../charts/DoughnutApex';
import ValueBox from '../ValueBox';

export default {
	name: 'RecipelogDash',
	components: { BarChartApex,DoughnutApex, ValueBox },

	created() {
		this.$bus.$on('data_graph_duration', (param) => {
			this.showJSONdata(param, '#data_graph_duration');
			this.drawBarChart_duration(param);
		});
		this.$bus.$on('data_graph_times', (param) => {
			this.showJSONdata(param, '#data_graph_times');
			this.drawBarChart_times(param);
		});
		this.$bus.$on('data_metrics', (param) => {
			this.showJSONdata(param, '#data_metrics');
			this.drawRecipeMetrics(param);
			this.showJSONdata(param, '#data_recipe_selected');
			this.drawRecipeSelected(param);
		});
		this.$bus.$on('data_graph_stoptype', (param) => {
			this.showJSONdata(param, '#data_stop_type');
			this.drawDoughnut(param);
		});
	},
	data() {
		return {
			showWidgets: false,
			values1: null,
			values2: null,
			labels1: null,
			labels2: null,
			label1: null,
			label2: null,
			values_stop_type: null,
			labels_stop_type: null,
			label_stop_type: null,
			label_mttr: this.$receiver_locale.global.dash.mttr,
			unit_mttr: 'min',
			mttr: null,
			label_mtbf: this.$receiver_locale.global.dash.mtbf,
			unit_mtbf: 'min',
			mtbf: null,
			label_mtff: this.$receiver_locale.global.dash.mtff,
			unit_mtff: 'min',
			mtff: null,
			label_name: this.$receiver_locale.global.dash.recipe_name,
			name: null,
			label_recipe_code: this.$receiver_locale.global.dash.recipe_code,
			recipe_code: null,
			//label_original_id: 'Original '+this.$receiver_locale.global.dash.recipe_id,
			id: null,
			//original_id:null,
			label_inicio: this.$receiver_locale.global.dash.recipe_start_date,
			inicio: null
		};
	},
	methods: {
		showJSONdata(param, id) {
			//$(id).text(JSON.stringify(param, null, 2))
			this.showWidgets = true;
		},
		drawBarChart_duration(param) {
			this.labels1 = [];
			param.forEach((element) => {
				this.labels1.push(element.fault_descriptionES);
			});

			this.values1 = [];
			param.forEach((element) => {
				this.values1.push(Number(element.timeToMin).toFixed(2));
			});
			this.label1 = this.$receiver_locale.global.dash.duration;
			window.dispatchEvent(new Event('resize'));
		},
		drawBarChart_times(param) {
			this.labels2 = [];
			param.forEach((element) => {
				this.labels2.push(element.fault_descriptionES);
			});
			this.values2 = [];
			param.forEach((element) => {
				this.values2.push(element.num);
			});
			this.label2 = this.$receiver_locale.global.dash.times;
			window.dispatchEvent(new Event('resize'));
		},
		drawDoughnut(param) {
			this.labels_stop_type = [];
			param.forEach((element) => {
				this.labels_stop_type.push(element.parada);
			});
			this.values_stop_type = [];
			param.forEach((element) => {
				this.values_stop_type.push(element.time);
			});
			this.label_stop_type = this.$receiver_locale.global.dash.stop_type;
			window.dispatchEvent(new Event('resize'));
		},
		drawRecipeMetrics(param) {
			this.mttr = Number(param.MTTR / 60).toFixed(2);
			this.mtbf = Number(param.MTBF / 60).toFixed(2);
			this.mtff = Number(param.MTFF / 60).toFixed(2);
		},
		drawRecipeSelected(param) {
			this.name = param.recipe_name;
			this.recipe_code = param.recipe_code;
			//this.original_id = param.original_id
			this.inicio = moment(param.date_start).format('DD-MM-YY hh:mm');
			window.dispatchEvent(new Event('resize'));
		}
	}
};
</script>
