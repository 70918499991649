// require('../../../../../resources/js/components/common_components')

Vue.prototype.$trans = window.trans;
Vue.prototype.$config = window.config;
require('../components');

// if ($('#adminApp').length) {
// 	const app = new Vue({
// 		el: '#adminApp',
// 	})
// }
