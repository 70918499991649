<template>
	<div>
		<card-template :padding="'p-1'">
			<template slot="titleCard">
				{{
					this.$receiver_locale.global.alarm_log_datatable
				}}
			</template>
			<alarms-log-list @setFilter="setFilter"></alarms-log-list>
		</card-template>
		<faultlog-list :current-filter="currentFilter"></faultlog-list>
	</div>
</template>

<script>
import AlarmsLogList from './AlarmsLogList.vue';

export default {
	name: 'AlarmFaultLog',
	components: {AlarmsLogList},
	data(){
		return {
			currentFilter:null
		};
	},
	methods:{
		setFilter(filter){
			this.currentFilter = filter;
		}
	}
};
</script>

<style scoped>

</style>
