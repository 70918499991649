<template>
	<div class="row align-items-end">
		<div class="col-12 col-lg-10 ">
			<label for="">{{
				this.$receiver_locale.global.datatable.date }}:</label>
			<Datepicker
				v-model="filterDateRange"
				range
				circle
				show-clear-button
				lang="es"
				:placeholder="this.$receiver_locale.global.datatable.datepick"
				:date-format="this.formatDate"
				text-format="short"
				height="37"
				@input="getData"
				@reset="restore"
			></Datepicker>
		</div>
		<div class="col-12 col-lg-2  p-lg-0">
			<label for="">{{
				this.$receiver_locale.global.datatable.period_label }}:</label>
			<div class="dropdown text-left">
				<button
					id="periodDropdown"
					class="btn btn-primary dropdown-toggle"
					type="button"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					<i class="fa fa-calendar-days"></i>
				</button>
				<div class="dropdown-menu" aria-labelledby="periodDropdown">
					<a
						v-for="option in filterDateOptions"
						class="dropdown-item"
						href="#"
						@click="setPeriodFilter(option.key)"
					>{{ option.label }}</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VueDatepickerUi from 'vue-datepicker-ui';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import vSelect from 'vue-select';

export default {

	name: 'RecipeStartDateFilter',

	components: {
		vSelect,
		Datepicker: VueDatepickerUi
	},
	props: {
		refresh_fn: { required: true },
		current_date_range:{ required:false}
	},
	mounted() {
		// Listening to the 'filter-updated' event

		// Set default date range to today - 1 months
		const today = new Date();
		this.$bus.$on('filter-updated', (value) => {
			this.filterDateRange = [value[0].date,today];
		});
		const threeMonthsAgo = new Date(today.getTime());
		threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 1);
		this.filterDateRange = [threeMonthsAgo, today];
		if (this.current_date_range){
			this.filterDateRange = this.current_date_range;
		}
		this.getData(true);
	},
	data() {
		return {
			filterDateRange: [null, null],
			current: [null, null],
			periodSelected:null,
			formatDate: {
				month: '2-digit',
				day: '2-digit',
				year: 'numeric'
			},
			filterDateOptions:[
				{
					key:'last_hours_24',
					label:this.$receiver_locale.global.datatable.last_hours_24
				},
				{
					key:'last_days_7',
					label:this.$receiver_locale.global.datatable.last_days_7
				},
				{
					key:'last_days_30',
					label:this.$receiver_locale.global.datatable.last_days_30
				},
				{
					key:'last_days_90',
					label:this.$receiver_locale.global.datatable.last_days_90
				}
			]
		};
	},
	methods: {
		getData(emitEvent) {
			if (!this.filterDateRange.includes(null)) {
				let fR = [];
				let date = new Date(this.filterDateRange[0]);
				let year = date.getFullYear();
				let month = ('0' + (date.getMonth() + 1)).slice(-2);
				let day = ('0' + date.getDate()).slice(-2);
				let dateString = `${year}-${month}-${day}`;
				fR[0] = dateString;

				// Same for the second date
				let nextDay = new Date(this.filterDateRange[1].getTime());
				let year2 = nextDay.getFullYear();
				let month2 = ('0' + (nextDay.getMonth() + 1)).slice(-2);
				let day2 = ('0' + nextDay.getDate()).slice(-2);
				let nextDayString = `${year2}-${month2}-${day2}`;
				fR[1] = nextDayString;

				//this.filterDateRange[0] = new Date(this.filterDateRange[0]).toISOString().slice(0, 10);
				//this.filterDateRange[1] = new Date(this.filterDateRange[1]).toISOString().slice(0, 10);
				if (emitEvent){
					this.$emit('setFilter', { 'date_start': fR });

				}
			}
		},
		restore() {
			this.$emit('setFilter', { 'date_start': [] });

		},
		setLastHours(hours) {
			const startDate = new Date();
			const endDate = new Date();
			startDate.setTime(endDate.getTime() - (hours * 60 * 60 * 1000));
			this.filterDateRange = [startDate, endDate];
			this.getData();
		},
		setLastDays(days) {
			const startDate = new Date();
			const endDate = new Date();
			startDate.setTime(endDate.getTime() - (days * 24 * 60 * 60 * 1000));
			this.filterDateRange = [startDate, endDate];
			this.getData();
		},
		convertOptionsSelect(data) {
			let result = [];
			Object.keys(data).forEach((value, key) => {
				result.push({ 'key': value, 'label': data[value] });
			});
			return result;
		},
		setPeriodFilter(key){
			this.periodSelected = key;
			switch (key){
			case 'last_hours_24':
				this.setLastHours(24);
				break;
			case 'last_days_7':
				this.setLastDays(7);
				break;
			case 'last_days_30':
				this.setLastDays(30);
				break;
			case 'last_days_90':
				this.setLastDays(90);
				break;
			default:
				this.setLastHours(24);
			}
		}
	}
};
</script>
<!--scoped not needed cause is using css for v-calendar -->
<style lang="scss">
.calendar.range {
    z-index: 9999;
}

.v-calendar{
    input{
        height: 37px !important;
    }
    svg{
        display: none;
    }
}
:root {
    --v-calendar-picker-color: #d3e8ff;
    --v-calendar-input-bg-color: #fff;
    --v-calendar-input-bg-disable-color: rgb(245, 245, 245);
    --v-calendar-input-text-disable-color: #b8b8b9;
    --v-calendar-select-bg-color: #d3e8ff;
    --v-calendar-border-color: black;
    --v-calendar-triangle-color: #eaeaeb;
    --v-calendar-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.2);
    --v-calendar-top-shadow: 3px -14px 30px 0px rgba(0, 0, 0, 0.2);
    --v-calendar-text-color: #7b8187;
    --v-calendar-action-color: #7b8187;
    --v-calendar-text-disabled-color: #b8b8b9;
    --v-calendar-view-button-color: #7b8187;
    --v-calendar-view-button-font-weight: 400;
    --v-calendar-view-button-font-size: 1rem;
    --v-calendar-datepicker-icon-color: #182e5e;
    --v-calendar-datepicker-icon-size: 1.1rem;
    --v-calendar-active-bg-color: #182e5e;
    --v-calendar-active-text-color: #fff;
    --v-calendar-range-bg-color: #f9dcf4;
    --v-calendar-range-text-color: #7b8187;
    --v-calendar-range-radius: 100%;
    --v-calendar-day-hover-bg-color: #eaeaeb;
    --v-calendar-day-width: 40px;
    --v-calendar-day-height: 40px;
    --v-calendar-day-font-size: 0.9rem;
    --v-calendar-day-font-weight: 400;
    --v-calendar-day-name-font-size: 0.9rem;
    --v-calendar-day-name-font-weight: 500;
    --v-calendar-day-name-color: #7b8187;
    --v-calendar-input-border: 1px solid #d1d3e2;
    --v-calendar-input-text-color: #7b8187;
    --v-calendar-input-font-size: 0.9rem;
    --v-calendar-input-font-weight: 400;
    --v-calendar-content-radius: 5px;
    --v-calendar-year-font-size: 1.1rem;
    --v-calendar-year-color: #7b8187;
    --v-calendar-year-font-weight: 400;
    --v-calendar-year-disabled-color: #b8b8b9;
    --v-calendar-year-disabled-bg-color: transparent;
    --v-calendar-year-padding: 10px;
    --v-calendar-year-border: none;
    --v-calendar-year-border-radius: none;
    --v-calendar-month-font-size: 1.1rem;
    --v-calendar-month-color: #7b8187;
    --v-calendar-month-font-weight: 400;
    --v-calendar-month-disabled-color: #b8b8b9;
    --v-calendar-month-disabled-bg-color: transparent;
    --v-calendar-month-padding: 8px;
    --v-calendar-month-border: none;
    --v-calendar-month-border-radius: none;
}
</style>
