
Vue.component('Analytics', require('./analytics/Analytics.vue').default);

/* Admin */
Vue.component('ApilogList', require('./ApiLogList.vue').default);

require('../sass/app.scss');
require('./filters');
require('./error_analytics');
require('./production_analytics');
require('./shift_analytics');
require('./dashboard');
require('./alarms_log');
require('./current_production');
require('./preventive_maintenance');
require('./predictive_production');
