<template>
	<card-template v-if="items" :padding="'p-1'">
		<template slot="titleCard">
			{{
				this.$receiver_locale.global.fault_log.fault_list_datatable
			}}
		</template>
		<button class="btn btn-excel float float-right position-absolute" style="right:10px;background-color: #182e5e;color:white;" @click="exportCsv(items[0].recipe_log_id)">
			<i class="fa fa-file-excel mr-1"></i>
		</button>
		<div class="table-responsive">
			<inno-core-datatable
				:enable-create-btn="false"
				:enable-edit-btn="false"
				:enable-delete-btn="false"
				:id-table="idTable"
				:columns-data="columns"
				:row-items="items"
				:custom-fields="customFields"
			>
			</inno-core-datatable>
		</div>
	</card-template>
</template>

<script>
export default {
	name: 'FaultLoglist',
	props:{
		currentFilter:{required:false}
	},
	created() {
		this.$bus.$on('FaultLogsByRecipe', (param) => {
			this.items=param;
		});
	},
	watch:{
		currentFilter:function(){
			this.currentFilterData = this.currentFilter;
			this.getItems();
		}
	},
	data() {
		return {
			idTable: 'faultlogTable',
			items: [],
			currentFilterData:null,
			customFields: [
			],
			columns: [
				{
					field: 'device_name',
					label: this.$receiver_locale.global.device_name
				},
				{
					field: 'shift_name',
					label: this.$receiver_locale.global.shift_name
				},

				{
					field: 'date_start',
					label: this.$receiver_locale.global.fault_log.date_start
				},

				{
					field: 'date_end',
					label: this.$receiver_locale.global.fault_log.date_end
				},

				{
					field: 'elapsed_time',
					label: this.$receiver_locale.global.fault_log.elapsed_time
				},
				{
					field: 'fault_type',
					label: this.$receiver_locale.global.fault_log.fault_type
				},
				{
					field: 'fault_code',
					label: this.$receiver_locale.global.fault_log.fault_code
				},
				{
					field: 'fault_var',
					label: this.$receiver_locale.global.fault_log.fault_var
				},
				{
					field: 'fault_descriptionES',
					label: this.$receiver_locale.global.fault_log.fault_descriptionES
				}

			]
		};
	},
	methods: {
		getItems() {
			axios.post('/api-admin/receiver/getFaultLogList',this.currentFilterData).then((response) => {
				const rows = response.data;
				this.items = rows;
			});
		},
		newItem() {},
		exportCsv(recipe_log_id) {
			axios.get('/api-admin/receiver/getFaultLogList/'+recipe_log_id).then((res) => {
				const blob = new Blob([this.ConvertToCSV(JSON.stringify(res.data,null,2))], { type: 'application/json;charset=utf-8' });
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = `FaultLogs_${this.formatDate(new Date())}.csv`;
				link.click();
				URL.revokeObjectURL(link.href);
			});
		},
		// JSON to CSV Converter
		ConvertToCSV(objArray) {
			var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
			var str = '';


			var line = '';
			for (var index in array[0]) {
				if (line != '') line += ',';

				line += index;
			}

			str += line + '\r\n';

			for (var i = 0; i < array.length; i++) {
				var line = '';
				for (var index in array[i]) {
					if (line != '') line += ',';

					line += array[i][index];
				}

				str += line + '\r\n';
			}

			return str;
		},
		formatDate(date) {
			return [
				this.padTo2Digits(date.getDate()),
				this.padTo2Digits(date.getMonth() + 1),
				date.getFullYear()
			].join('/');
		},
		padTo2Digits(num) {
			return num.toString().padStart(2, '0');
		}
	}
};
</script>
<style lang="scss" scoped>
	.VueTables__table{
		td{
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			font-size: 14px;
			height: 44px;
			line-height: 44px;
			text-align: center;

		}
		th {
			background-color:#004a98;
			color:#ffffff;
		}

	}
	.table-responsive:not(.VueTables .table-responsive){
		overflow: hidden;
		min-height: 50vh;
	}
</style>
