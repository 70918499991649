<template>
	<div>
		<li

			:href="`#item-installation-${installation.id}`"
			:class="!isOpen ? 'd-block pl-0 py-3 collapsed list-group-item collapse' : 'list-group-item pl-0 d-block  py-3 collapse'"
			data-toggle="collapse"
			role="button"
			:aria-expanded="isOpen ? 'true' : 'false' "
			:aria-controls="`#item-installation-${installation.id}`"
			@click="toggle()"
		>
			<div class="row align-items-center ml-1 m-0">
				<div class="col-2 ">
					<i v-if="installation.zones.length > 0 && !openId" class="fa fa-caret-right"></i>
					<i v-if="installation.zones.length > 0 && openId" class="fa fa-caret-down"></i>
				</div>
				<div class="col-10 p-0">
					<h6 class="m-0 font-weight-bold text-primary ">
						<small class="font-bold font-italic">#{{ installation.id }}</small>
						{{ installation.name }}
					</h6>
				</div>
			</div>
		</li>
		<div :id="`item-installation-${installation.id}`" :class="isOpen ? 'p-2  collapse show' : 'p-2  collapse' ">
			<zone-tree-component :zones="installation.zones"></zone-tree-component>
		</div>
	</div>
</template>
<script>

import ZoneTreeComponent from './ZoneTreeComponent.vue';
import {mapGetters} from 'vuex';

export default {
	name: 'InstallationItemAccordion',
	components: {ZoneTreeComponent},
	props: ['installation','zones'],
	computed:{
		...mapGetters('receivers', ['getDeviceTreeConfig']),
		isOpen(){
			if (this.installation.open){
				this.openId = this.installation.id;
			} else {
				this.openId = null;
			}
			return this.installation.open;
		}
	},
	data: function () {
		return {
			openId: null
		};
	},
	methods:{
		toggle(element){
			if (this.openId == null){
				this.openId = this.installation.id;
			} else {
				this.openId = null;
			}
		}
	}

};
</script>



<style scoped>

</style>
