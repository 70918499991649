<template>
	<div class="row h-100 m-0 p-0 w-100">
		<div class="row w-100">
			<div class="col-10">
				<label for="" class="border-bottom-1 border-bottom-primary"><i class="fa fa-warning mr-2"></i>Assets alarms <span class="badge badge-warning">{{ getDeviceSelectedAlarm.length }}</span></label>
			</div>
		</div>
		<div class="row h-100 overflow-auto w-100">
			<ul class="col-12 list-group">
				<li v-for="alert in getDeviceSelectedAlarm" class="list-group-item pl-0">
					<div class="px-3">
						<div class="row m-0 align-items-center">
							<div class="">
								<h4><i class="fa fa-warning mr-2 text-warning "></i><span class="badge badge-dark mr-1">{{ alert.item.name }}</span></h4>
							</div>
							<!--							<div class="col-6 p-0">-->
							<!--								<h4><span class="badge" :class=" alert.state == 'Activa'? 'badge-danger':'badge-success'">{{ alert.state }}</span></h4>-->
							<!--							</div>-->
						</div>
						<div class="row m-0 align-items-center">
							<span class="w-100"><b>{{ alert.item.device.name }}</b></span>
							<small>{{ alert.item.code }}</small>
						</div>
						<div class="row m-0 align-items-center">
							<div class="col-6 p-0">
								<small><b> </b>{{ alert.start_date }}</small>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>


import {mapGetters} from 'vuex';

export default {
	name: 'AssetAlerts',
	created(){

	},
	computed: {
		...mapGetters('receivers', ['getDeviceSelectedAlarm'])
	},

	data: function () {
		return {

		};
	},
	methods:{

	}

};
</script>



<style scoped>
.list-group-item:hover {
    background-color: #f8f9fa; /* Cambia el color de fondo en hover según tus preferencias */
}
</style>
