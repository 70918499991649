<template>
	<div class="row  m-0 p-0 w-100">
		<div class="row w-100">
			<div class="col-10">
				<label for="" class="border-bottom-1 border-bottom-primary"><i class="fa fa-info-circle mr-2"></i>Assets recipe logs</label>
			</div>
		</div>
		<div class="col-12 p-0 table-asset-recipe-log">
			<v-client-table
				:ref="idTable"
				:data="items"
				:columns="columns"
				:filter-by-column="false"
				:options="options"
			>
				<div slot="options" slot-scope="{row}" class="text-center">
					<button class="btn btn-xs" @click="goToRecipeLogs(row)">
						<i class="fa fa-pie-chart fa-2x fa-align-center" style="color:#182e5e"></i>
					</button>
				</div>
			</v-client-table>
		</div>
	</div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
	name: 'AssetRecipeLog',
	created() {
		this.setColumns();
		if (this.getDevicesDashboardSelected.length > 0){
			this.getItems();

		}
	},
	watch:{
		getDevicesDashboardSelected(){
			if (this.getDevicesDashboardSelected.length > 0){
				this.getItems();
			} else {
				this.items = [];
			}
		}
	},
	computed: {
		...mapGetters('receivers', ['getDevicesDashboardSelected','getDatePeriodFilter'])
	},
	data: function () {
		return {
			btnConfig:false,
			columns:[],
			columnsData: [
				{
					field:'options',
					label:this.$receiver_locale.global.options
				},
				{
					field: 'device_name',
					label: this.$receiver_locale.global.device_name
				},
				{
					field: 'shift_name',
					label: this.$receiver_locale.global.shift_name
				},

				{
					field: 'recipe_code',
					label: this.$receiver_locale.global.recipe_code
				},

				{
					field: 'recipe_name',
					label: this.$receiver_locale.global.recipe_name
				},

				{
					field: 'date_start',
					label: this.$receiver_locale.global.date_start
				},

				{
					field: 'date_end',
					label: this.$receiver_locale.global.date_end
				},

				{
					field: 'elapsed_time',
					label: this.$receiver_locale.global.elapsed_time
				}

			],
			idTable:'recipelogTable',
			items: [],
			options: {
				resizableColumns: true,
				perPage: 10,
				perPageValues: [5, 10, 50, 100],
				pagination: { chunk: 5 },
				datepickerOptions: {
					showDropdowns: true,
					autoUpdateInput: true
				},

				sortIcon: {
					base: 'fa float-right',
					is: 'fa-sort',
					up: 'fa-sort-up',
					down: 'fa-sort-down'
				},
				texts: {
					count: `${this.$t(
						'Showing {from} to {to} of {count} records|{count} records|One record'
					)}`,
					first: `${this.$t('First')}`,
					last: `${this.$t('Last')}`,
					filter: `${this.$t('Filter')}:`,
					filterPlaceholder: `${this.$t('Search query')}`,
					limit: `${this.$t('Records')}:`,
					page: `${this.$t('Page')}:`,
					noResults: `${this.$t('No matching records')}`,
					filterBy: `${this.$t('Filter by {column}')}`,
					loading: `${this.$t('Loading')}...`,
					defaultOption: `${this.$t('Select {column}')}`,
					columns: `${this.$t('Columns')}`
				},
				columnsDisplay: {}
			},
			customFields:[],
			enableCreateBtn: false,
			customFilters: []
		};
	},
	methods: {
		setColumns() {
			const headings = {};
			this.columnsData.map((item) => {
				this.columns.push(item.field);
				headings[item.field] = item.label;
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display;
				}
			});
			this.options.headings = headings;
		},
		getItems() {
			const queryParams = [];
			queryParams.push({
				device_name_filter: this.buildDevicesFilter()
			});
			queryParams.push({
				recipe_name_filter: this.buildRecipesFilter()
			});
			queryParams.push({
				date_start: this.getDatePeriodFilter
			});
			axios.post('/api-admin/receiver/getRecipeLogListFiltered',{
				queryParams: queryParams
			}).then((response) => {
				const rows = response.data;
				this.items = rows;
			});
		},
		buildDevicesFilter(){
			const devices_filter = this.getDevicesDashboardSelected.map(item=>{
				if (item.current_recipe){
					return {key:item.id,label:item.name};
				}
			});
			return devices_filter.filter(item => item !== undefined);
		},
		buildRecipesFilter(){
			const recipe_filter = this.getDevicesDashboardSelected.map(item=>{
				if (item.current_recipe){
					return {key:item.current_recipe.recipe_def_id,label:item.current_recipe.recipe_name,code:item.current_recipe.recipe_code};
				}
			});
			return recipe_filter.filter(item => item !== undefined);
		},
		goToRecipeLogs(recipe){
			const device = this.getDevicesDashboardSelected.find(item=>{
				return item.id == recipe.device_id;
			});
			this.$store.commit('receivers/setCurrentDevice',[{key:device.id,label:device.name}]);
			this.$store.commit('receivers/setCurrentRecipes',[{key:recipe.recipe_def_id,label:recipe.recipe_name,code:recipe.recipe_code}]);
			window.open('/receiver','_blank');
		}
	}
};
</script>

<style lang="scss">
.table-asset-recipe-log {
    zoom: 90%;
    .VueTables__search-field,.VueTables__limit-field{
        display: none;
    }
}
</style>
