<template>
    <div class="col-12 col-lg-4">
        <div class="col-12">
            <h4>Cambiar frecuencia de envío del dispositivo</h4>
            <div class="w-100 my-2 d-flex align-items-center">
                <input type="number"  v-model="period" @input="add" class="col-12 col-lg-4 form-control mr-2">
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "ChangeFrequencyDevice",
    props: ['device_id','frequency'],
    components: {},
    created() {
        if((this.frequency!=null)){
            this.period = this.frequency;
        }
    },
    data() {
        return {
            period:1,
            params: ``,
        }
    },
    methods: {


        add() {
            this.$emit('add', this.period)
        }
    }
}
</script>
