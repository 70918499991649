<template>
	<div v-if="devices">
		<div
			v-for="(device,key) in devices"
			:id="key"
			:key="key"
			class="mb-2"
		>
			<device-item-accordion v-if="device.children !== null && device.children.length > 0" :device="device">
				<device-tree-component :devices="device.children" />
			</device-item-accordion>

			<ul v-else class="list-group">
				<device-final :device="device" />
			</ul>
		</div>
	</div>
</template>
<script>

import DeviceItemAccordion from './DeviceItemAccordion.vue';
import DeviceFinal from './DeviceFinal.vue';


export default {
	name: 'DeviceTreeComponent',
	components: { DeviceFinal, DeviceItemAccordion},
	props: ['devices'],
	created() {
	},
	computed: {
	},
	data: function () {
		return {
		};
	},
	methods:{

	}

};
</script>



<style scoped>

</style>
