<template>
    <li class="list-group-item bg-gray-200">
        <customer-item  :customer="customer" :edit_fn="edit_fn"/>
    </li>
</template>
<script>
import CustomerItem from './CustomerItem'
export default {
    name: 'CustomerFinal',
    props: {
        customer: {required: true},
        edit_fn:{},
    },
    components: {
        CustomerItem
    },
    data() {
        return {

        }
    }
}
</script>
