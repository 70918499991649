<template>
    <div>
        <div id="Filter">
            <h4>{{ this.$zones_locale.zones_filter_title }}</h4>
            <form :action="search" method="POST">
                <div class="row">
                    <div class="col-sm-10 mb-2">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group ">
																		<label for="">{{this.$zones_locale.name}}</label>
                                    <input type="text" v-model="filter.name" :placeholder="this.$zones_locale.name" id="search" class="form-control">
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <zone-type-combo  @selected="setType" />
                            </div>
														<div class="col-sm-4">
																<installations-combo @add="setInstallation"/>
														</div>
                        </div>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <button style="margin-top: 32px;" @click="search" class="btn btn-sm btn-primary"><i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <hr>
        <ul class="list-group">
            <li class="list-group-item" :key="i" v-for="(zone,i) in zones">
                <zone-item  :refresh_search_fn="refreshData" :edit_fn="edit_fn" :zone="zone"></zone-item>
            </li>
        </ul>

    </div>
</template>
<script>
import ZoneItem from './ZoneItem'
import ZoneTypeCombo from '../zone_types/ZoneTypeCombo'
import InstallationsCombo from '/../Modules/Installations/Resources/assets/components/InstallationsCombo'
export default {
    name: 'SearchComponent',
    props: {
        edit_fn: {required:true, type:Function},
    },
    components: {
        ZoneItem,
        ZoneTypeCombo,
				InstallationsCombo
    },
    data() {
        return {
            filter: {
                name: null,
                type_id: null,
								installation_id: null,
            },
            zones: []
        }
    },
    methods: {
        setType(item){
            this.filter.type_id = item ? item.id : null
        },
        search(e) {
            if(e) e.preventDefault()
            const filter = this.filter
            if (
                (filter.name === null || filter.name === '')
                && filter.type_id === null
                && filter.installation_id === null

            ) {
                return this.$toast.error(this.$zones_locale.errors.empty_filter)
            }
            const url = `/api-admin/zones/search`
            axios.post(url, {filter: this.filter}).then(response => {
                if (response.data.length < 1) {
                    this.$toast.info(this.$zones_locale.errors.empty_results)
                }
                this.zones = response.data
            })
        },
				setInstallation(installation){
						this.filter.installation_id = installation ? installation.id : null
				},

        refreshData() {
            this.search()
        },

    }
}
</script>
