<template>
	<div class="row h-100 m-0 p-0">
		<div class="row w-100">
			<div class="col-10">
				<label for="" class="border-bottom-1 border-bottom-primary"><i class="fa fa-bars mr-2"></i>Assets
					details</label>
			</div>
		</div>
		<div v-if="getDevicesDashboardSelected.length > 0 " class="row h-100 overflow-auto">
			<div v-for="device in getDevicesDashboardSelected" class="row border mb-1 m-0">
				<div class="col-5 d-flex align-items-center">
					<img width="100%" src="/images/asset-image-default.jpg" alt="">
				</div>
				<div class="col-7 d-flex align-items-center">
					<div>
						<div class="row ">
							<b>{{ device.name }}</b>
						</div>
						<div class="row align-items-center">
							<div class="row align-items-center justify-content-center m-0 mr-1">
								<i class="fa fa-circle" style="font-size: 12px" :class="checkIsOnline(device) ? 'text-success' : 'text-danger'"></i>
							</div>
							<small>{{ device.lifebit }}</small>
						</div>

						<div class="row mt-2">
							<b class="w-100">Model number</b>
							<small>{{ device.code }}</small>
						</div>
						<div class="row mb-0">
							<b class="w-100">Installation</b>
							<small>{{ device.installation }}</small>
						</div>
						<div v-if="device.zone" class="row mb-0">
							<b class="w-100">Zone</b>
							<small>{{ device.zone }}</small>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="row w-100 h-100 overflow-auto">
			<b class="text-center m-auto w-100">No asset selected</b>
		</div>
	</div>
</template>
<script>


import {mapGetters} from 'vuex';

export default {
	name: 'AssetDetail',
	computed: {
		...mapGetters('receivers', ['getDevicesDashboardSelected','getAllDevices'])
	},
	data: function () {
		return {};
	},
	methods: {
		checkIsOnline(device){
			const currentDevice =  this.getAllDevices.find(item => {
				return item.id == device.id;
			});
			return currentDevice.isOnline;
		}
	}
};
</script>

<style scoped>

</style>
