<template>
	<div class="row mx-2 justify-content-center">
		<div class="row align-items-center justify-content-center">
			<label for="" class="mr-2 mb-0">Selecciona periodo:</label>
			<Datepicker
				v-model="filterDateRange"
				range
				circle
				show-clear-button
				lang="es"
				:placeholder="'Selecciona fechas'"
				:date-format="this.formatDate"
				text-format="short"
				@input="getData"
				@reset="restore"
			></Datepicker>
		</div>
	</div>
</template>
<script>


import Datepicker from 'vue-datepicker-ui';
import {mapGetters} from 'vuex';

export default {
	name: 'FilterPeriod',
	components: {Datepicker},
	created() {
		this.setDefaultFilterDate();
	},
	computed: {
	},
	data: function () {
		return {
			filterDateRange: [null, null],
			formatDate: {
				month: '2-digit',
				day: '2-digit',
				year: 'numeric'
			}
		};
	},
	methods:{
		getData() {
			this.$store.commit('receivers/setDashboardFilterPeriod',this.filterDateRange);

		},
		restore() {
			this.$emit('setFilter', { 'date_start': [] });
			this.$store.commit('receivers/setDashboardFilterPeriod',null);

		},
		setDefaultFilterDate(){
			const now = new Date();
			const nowSubWeek =  new Date().setDate(now.getDate() - 7);
			this.filterDateRange = [new Date(nowSubWeek),now];
			this.getData(false);
		}
	}

};
</script>



<style scoped>

</style>
