<template>

</template>
<script>
export default {
		name: 'MarkerMap',
		props: {
				edit_btn_url: {
						type: String,
						default: null
				},
				zone_id: {
						required: true
				},
				zone_name: {
						required: true
				},
				map: {
						required: true,
				},
				google: {
						required: true,
				},
				infoWindow: {
						default: null,
				},
				setCurrentMark_fn: {
						type: Function,
						// required: true
				},
				pushNewMark_fn: {
						type: Function,
						// required: true
				},
				editable: {
						type: Boolean,
						default: true,
				},
				enableInfoWindow: {
						type: Boolean,
						default: true,
				},
				marks: {
						type: Array,
				},
				emitMarkData_fn: {
						type: Function,
						required: true
				}
		},
		created() {
				if (this.google) {
						this.setMarks()
				}
		},
		watch: {
				current: function () {
						this.setCurrentMark_fn(this.current)
				},
				google: function () {
						this.setMarks()
				},
				marks: function(){
						this.setMarks()
				}
		},
		data() {
				return {
						current: null,
						labels: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
						labelIndex: 0,
				}
		},
		methods: {
				setMarks() {
						if (this.marks) {
								this.marks.forEach(mark => {
										if(mark.position.lat){
												const content = this.buildViewInfoWindow(mark)
												this.addMarker(mark.position, mark.title, content, mark.data)
										}
								})
						}
				},

				buildViewInfoWindow(mark) {
						const batteryView = this.buildViewBattery(mark)
						const signalsView = this.buildViewSignals(mark)
						const hourView = this.buildViewHour(mark)
						let edit_btn = ''
						if (this.edit_btn_url) {
								const url = this.edit_btn_url.replace(':id',mark.data.id)
								edit_btn = `
						    <br/>
						    <div class="mt-2">
						    	<a href="${url}" class="btn btn-warning btn-xs">${this.$t('Edit')}</a>
								</div>

							`
						}

						const header = `
                <div class="row text-dark">
                    <span class="col-8">
                    <b>${mark.title}</b>
                    ${edit_btn}
                    </span>
                    <div class="col-4 text-right">
                        ${hourView}
                        ${batteryView}
                    </div>

                </div>
                <hr>
            `
						const body = `
                <div class="row text-dark">
                    <span class="col-12 mb-1">${this.$t('installation')}: <b>${mark.data.installation_name}</b></span>
                    <span class="col-12 mb-1">${this.$t('zone')}: <b>${mark.data.zone_name}</b></span>
                    <span class="col-12 mb-1">${this.$t('signals')} ( ${this.$t('last_signal_update')}: <b>${mark.data.last_lifebit_parse}</b> ) </span>
                       ${signalsView}
                </div>
            `
						return `${header} ${body}`
				},
				buildViewSignals(mark) {
						let signals = mark.data.last_signal_values
						if (signals) {
								let listSignals = ''
								Object.keys(signals).forEach((index) => {
										if (index != 'battery_level')
												listSignals += `<li><span>${index}: <b>${signals[index]}</b></span></li>`
								})
								return `<ul>${listSignals}</ul>`
						}
						return ''

				},
				buildViewHour(mark) {
						if (mark.data.last_signal_values && mark.data.last_signal_values.hour != null && mark.data.last_signal_values.minute != null) {
								return '<span class="mr-1">' + mark.data.last_signal_values.hour + ':' + mark.data.last_signal_values.minute + '</span>'
						}
						return ''
				},
				buildViewBattery(mark) {
						let batteryIcon = 'fa-battery-empty'
						let batteryLevel = 0
						if (mark.data.last_signal_values && mark.data.last_signal_values.battery_level != null) {
								batteryLevel = mark.data.last_signal_values.battery_level ? mark.data.last_signal_values.battery_level : 0
								if (batteryLevel == 0) {
										batteryIcon = 'fa-battery-empty'
								} else if (batteryLevel > 0 && batteryLevel < 50) {
										batteryIcon = 'fa-battery-quarter'
								} else if (batteryLevel > 50 && batteryLevel < 9) {
										batteryIcon = 'fa-battery-three-quarters'
								} else if (batteryLevel > 95) {
										batteryIcon = 'fa-battery-full'
								}
						}
						return `<span><i class="mr-1 fa-solid ${batteryIcon}"></i>${batteryLevel}%</span>`
				},
				addMarker(location, title = null, content = null, mark_data = {}) {
						const label = null // this.labels[this.labelIndex++ % this.labels.length]

						const mark = new this.google.maps.Marker({
								position: location,
								label,
								map: this.map,
								draggable: this.editable,
								title,
						})
						mark.info = mark_data
						mark.status = 'active'
						mark.path = this.getPath(mark)
						mark.content = content
						mark.zone_id = this.zone_id
						this.current = mark
						this.pushNewMark_fn(mark)
						this.addListeners(mark)
				},

				addListeners(mark) {
						if (this.enableInfoWindow) {
								mark.addListener('click', (e) => {
										this.current = mark
										this.setInfoWindow(mark, mark.content)
										this.emitMarkData_fn(mark)
								})
						}


						mark.addListener('dragend', (e) => {
								mark.path = this.getPath(mark)
								this.$emit('markerChanged', mark)
						})
				},

				getPath(item) {
						const coord = {
								lat: item.position.lat(),
								lng: item.position.lng(),
						}
						return coord
				},

				setInfoWindow(mark, content) {
						if (this.infoWindow) {
								const html = content
								this.infoWindow.close()
								this.infoWindow.setContent(html)
								this.infoWindow.open(mark.getMap(), mark)
						}

				},

		},
}
</script>
