<template>
    <div >
        <div class='row mb-3'>
            <div class='col-4 '>
                <button @click='appendCondition' class='btn btn-success justify-end btn-xs'>
                    <i class='fa fa-plus'></i> {{ this.$t('alarms_add_condition') }}
                </button>
            </div>
        </div>
        <div class='row mt-4'>
            <div v-for='(condition,i) in conditionals' :key='i' class='col-12'>
                <ConfigAlarmEvaluates
                    :index='i'
                    :item='setted'
                    @remove='removeConditional'
                    @set='setItem'
                />
                <hr class='my-2'>
            </div>

        </div>
        <button @click='save' v-if='conditionals.length > 0' class='btn btn-primary btn-sm'>
            {{ this.$alarms_locale.buttons.add_alarm_config }}
        </button>
    </div>
</template>

<script>
import ConfigAlarmEvaluates from './ConfigAlarmEvaluates'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
    name: "ConfigAlarmForm",
    props: {
        setted: {required: false},
    },
    components:{
        ConfigAlarmEvaluates
    },
    data() {
        return {
            current_attribute: null,
            conditionals: [],
            evaluates_values:[]
        }
    },
    created() {
      this.current_attribute = this.setted
    },
    methods: {
        appendCondition() {
            if(this.current_attribute){
                const data = {
                    attribute: this.current_attribute,
                    operator: null,
                    value: null,
                    string: '',
                }
                this.conditionals.push(data)
            }
        },
        removeConditional(index) {
            const position = this.conditionals.findIndex((item, i) => i === index)
            this.conditionals.splice(position, 1)
        },
        setItem(data) {
            const item = this.conditionals[data.index]
            item.operator = data.operator
            item.value = data.value
            item.string = `($X ${data.operator.value} ${data.value})`

        },
        save() {
            let error = false
            let next = ''
            let evaluate = ''
            this.conditionals.map(item => {
                if (item.value === null || item.operator === null) {
                    this.$helpers.showError('Error condition with empty data')
                    error = true
                    return false
                }
                this.evaluates_values.push(item.value)
                evaluate = `${evaluate} ${next} ${item.string}`
                next = '&&'
            })

            if (!error) {
                const data = {evaluate:evaluate,evaluate_values:this.evaluates_values}
                axios.post(window.location + '/save',data).then(res=>{
                    this.$toast.success(this.$alarms_locale.config_save_succcessful)
                    this.$emit('save', res)

                })
                this.conditionals = []
            }
        },
    }
}
</script>

<style scoped>

</style>
