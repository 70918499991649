<template>
	<div class="row align-items-center w-100 m-0">
		<div class="col-2 p-0">
			<img width="100%" src="/images/asset-image-default.jpg" alt="">
		</div>

		<div class="col-1 ">
			<i v-if="device.children.length > 0 && !isOpen" class="fa fa-caret-right"></i>
			<i v-if="device.children.length > 0 && isOpen" class="fa fa-caret-down"></i>
		</div>
		<div class="col-7 p-0">
			<h6 class="m-0 font-weight-bold text-primary ">
				<small class="font-bold font-italic">#{{ device.id }}</small>
				{{ device.name }}
			</h6>
			<small>	{{ device.code }}</small>
			<div class="col-1 p-0 ">
				<span
					:class="`badge ${
						device.isOnline ? 'badge-success' : 'badge-danger'
					}`"
					:title="device.isOnline ? 'Conectado' : 'Desconectado'"
				>{{ device.isOnline ? 'Conectado' : 'Desconectado' }}</span>
			</div>
		</div>
		<div class="col-2 p-0">
			<div class="row  m-0">
				<div class="col-6  p-0">
					<input
						:id="'device-check-'+device.id"
						v-model="isChecked"
						type="checkbox"
						class="  md-checkbox"
						value="1"
						@click="preventCollapseOpen"
						@change="handleDeviceSelected"
					>
				</div>
				<div class="col-6  p-0">
					<h5>
						<span class="badge badge-warning h4">{{ device.alarms_count }}</span>
					</h5>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

import {
	resetDeviceAlarms,
	selectDeviceDashboard
} from '../../../../Modules/Receiver/Resources/assets/components/store/mutations';
import {mapGetters} from 'vuex';
import moment from 'moment/moment';
import {getDevicesAlarms} from '../../../../Modules/Receiver/Resources/assets/components/store/actions';
import {getDatePeriodFilter} from '../../../../Modules/Receiver/Resources/assets/components/store/getters';

export default {
	name: 'DeviceItem',
	props: ['device', 'isOpen'],
	computed: {
		...mapGetters('receivers', ['getDevicesDashboardSelected','getDeviceSelectedAlarm','getDatePeriodFilter'])
	},
	watch:{
		getDatePeriodFilter(){
			this.handleDeviceSelected();
		}
	},
	created() {
		// this.$store.commit('receivers/resetDeviceAlarms');
		if (this.getDevicesDashboardSelected.length == 0){
			this.$store.dispatch('receivers/getDevicesAlarms');
		}
		this.checkIfDeviceSelected();
	},
	data: function () {
		return {
			isChecked: false
		};
	},
	methods: {
		preventCollapseOpen(event) {
			event.stopPropagation(); // Evita que el evento se propague al colapso
		},
		handleDeviceSelected() {
			let currentDevicesSelected = this.getDevicesDashboardSelected;
			if (this.isChecked) {
				const existDevice = currentDevicesSelected.find(item => {
					return item.id == this.device.id;
				});
				if (!existDevice){
					currentDevicesSelected.push(this.device);
					this.$store.commit('receivers/selectDeviceDashboard', currentDevicesSelected);
				}

				//this.device.open = true;
				this.$store.dispatch('receivers/getDevicesAlarms');

			} else {
				currentDevicesSelected = currentDevicesSelected.filter(item => item.id !== this.device.id);
				//this.device.open = false;
				this.$store.commit('receivers/selectDeviceDashboard', currentDevicesSelected);
				this.$store.dispatch('receivers/getDevicesAlarms');
			}

		},
		checkIfDeviceSelected() {
			const deviceSelected = this.getDevicesDashboardSelected.find(item => item.id == this.device.id);
			if (deviceSelected) {
				this.isChecked = true;
			}
		},
		formatLastLifebit(lastLifebit) {
			return moment(lastLifebit).format('YYYY-MM-DD HH:mm:ss');
		}
	}

};
</script>


<style scoped>
.md-checkbox {
    width: 25px !important;
    height: 25px !important;
}

</style>
