<template>
	<div class="">
		<div class="row">
			<div class="col-lg-4 col-12 mb-3">
				<label for="">{{ $receiver_locale.global.datatable.device }}:</label>
				<device-name-filter :multiple="false" :refresh_fn="setDevice" @setFilter="setDevice" />
			</div>
			<div v-if="recipeData" class="col-lg-7 col-12 mb-3">
				<label for="">{{ $receiver_locale.global.datatable.recipe }}</label>
				<div class="row">
					<div class="col-lg-4 col-12">
						<b>{{ $receiver_locale.global.recipe_code }}:</b>
						<span>{{ recipeData.recipe_code }}</span>
					</div>
					<div class="col-lg-4 col-12">
						<b>{{ $receiver_locale.global.recipe_name }}:</b>
						<span>{{ recipeData.recipe_name }}</span>
					</div>
					<div class="col-lg-4 col-12">
						<b>{{ $receiver_locale.global.date_start }}:</b>
						<span>{{ recipeData.date_start }}</span>
					</div>
				</div>
			</div>
		</div>
		<div v-if="recipeData" id="currentProduction">
			<div class="row">
				<div class="col-lg-3 col-12">
					<last-minute-stats :current-recipe-data="recipeData" />
				</div>
				<div class="col-lg-3 col-12">
					<recipe-init-stats :current-recipe-data="recipeData" />
				</div>
				<div class="col-lg-6 col-12">
					<o-e-e-per-hour-chart :current-recipe-data="recipeData" />
				</div>
			</div>
			<div class="row">
				<div class="col-lg-4 col-12">
					<production-states :current-recipe-data="recipeData" />
				</div>
				<div class="col-lg-8 col-12">
					<div class="row">
						<div class="col-lg-6 col-12">
							<proceseed-parts :current-recipe-data="recipeData" />
						</div>
						<div class="col-lg-6 col-12">
							<control-quality :current-recipe-data="recipeData" />
						</div>
						<div class="col-12">
							<fault-recipe-init :current-recipe-data="recipeData" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="row withoutRecipe">
				<h4>
					{{ $receiver_locale.global.current_production.without_recipe }}
				</h4>
			</div>
		</div>
	</div>
</template>

<script>
import LastMinuteStats from './LastMinuteStats.vue';
import RecipeInitStats from './RecipeInitStats.vue';
import OEEPerHourChart from './OEEPerHourChart.vue';
import ProductionStates from './ProductionStates.vue';
import ProceseedParts from './ProceseedParts.vue';
import ControlQuality from './ControlQuality.vue';
import FaultRecipeInit from './FaultRecipeInit.vue';
import {mapGetters} from 'vuex';

export default {
	name: 'CurrentProduction',
	components: {
		FaultRecipeInit,
		ControlQuality,
		ProceseedParts,
		ProductionStates,
		OEEPerHourChart,
		RecipeInitStats,
		LastMinuteStats
	},
	mounted() {
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has('key') && searchParams.has('label')) {
			const key = searchParams.get('key');
			const label = searchParams.get('label');
			this.deviceId = key;
			this.getDeviceRecipe();
			// Emitting an event with updated filter data
			this.$bus.$emit('filter-updated', [
				{
					key,
					label
				}
			]);
		}
		this.refreshRecipeData();
	},
	computed: {
		...mapGetters('receivers', ['getCurrentDevices'])
	},
	watch: {
		getCurrentDevices(){
			this.refreshRecipeData();
		}
	},
	data() {
		return {
			deviceId: null,
			recipeId: null,
			recipeData: null
		};
	},
	methods: {
		refreshRecipeData(){
			if (this.getCurrentDevices.length > 0){
				const key = this.getCurrentDevices[0].key;
				this.deviceId = key;
				this.getDeviceRecipe();
			}
		},
		setDevice(device) {
			if (this.getCurrentDevices.length > 0) {
				this.deviceId = device.device_name_filter.key;
				this.getDeviceRecipe();
			}
		},
		getDeviceRecipe() {
			if (this.deviceId) {
				axios.get(`/api-admin/receiver/getRecipeDevice/${this.deviceId}`).then((res) => {
					this.recipeData = res ? res : null;
				});
			}
		}
	}
};
</script>
