Vue.component('UsersList', require('./users/UsersList.vue').default);
Vue.component('UserForm', require('./users/UserForm.vue').default);

Vue.component('RolesList', require('./roles/RolesList.vue').default);
Vue.component('RolesDetails', require('./roles/RoleItem.vue').default);

Vue.component(
	'PermissionsList',
	require('./permissions/PermissionsList.vue').default
);
Vue.component(
	'PermissionDetails',
	require('./permissions/PermissionItem.vue').default
);
Vue.component('RolesOverview', require('./RolesOverview.vue').default);
