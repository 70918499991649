<template>
	<div id="shortcuts" class="row mlmr">
		<div class="col-2 plpr mb-1">
			<button
				class="btn btn-xs btn-light border"
				:title="$receiver_locale.global.receivers"
				@click="erroresReceta(device)"
			>
				<i class="fas fa-fw fa-pie-chart"></i>
			</button>
		</div>
		<div class="col-2 plpr mb-1">
			<button
				class="btn btn-xs btn-light border"
				:title="$receiver_locale.global.apr"
				@click="produccionReceta(device)"
			>
				<i class="fas fa-fw fa-stopwatch"></i>
			</button>
		</div>
		<div class="col-2 plpr mb-1">
			<button
				class="btn btn-xs btn-light border"
				:title="$receiver_locale.global.preventive_maintenance.title"
				@click="preventiveMaintenance(device)"
			>
				<i class="fas fa-fw fa-hourglass-half"></i>
			</button>
		</div>
		<div class="col-2 plpr mb-1">
			<button
				class="btn btn-xs btn-light border"
				:title="$receiver_locale.global.predictive_production.title"
				@click="productionPredictive(device)"
			>
				<i class="fas fa-fw fa-chart-bar"></i>
			</button>
		</div>
		<div class="col-2 plpr mb-1">
			<button
				class="btn btn-xs btn-light border"
				:title="$receiver_locale.global.at"
				@click="analisisTurnos(device)"
			>
				<i class="fas fa-fw fa-warehouse"></i>
			</button>
		</div>

		<div class="col-2 plpr mb-1">
			<button
				class="btn btn-xs btn-light border"
				:title="$receiver_locale.global.al"
				@click="logAlarmas(device)"
			>
				<i class="fas fa-fw fa-warning"></i>
			</button>
		</div>
		<div class="col-md-12 col-xs-12 plpr mb-1">
			<button class="btn btn-xs btn-light border" @click="produccionActual(device)">
				<i class="fas fa-fw fa-pallet"></i>
				{{ $receiver_locale.global.current_production.title }}
				<div v-if="device.current_recipe" class="text-secondary">
					<div class="row">
						<div class="col-6 text-left">
							{{ $receiver_locale.global.recipe_name }}
						</div>
						<div class="col-6 text-right">
							<b>{{ device.current_recipe.recipe_name }}</b>
						</div>
					</div>
					<div class="row">
						<div class="col-6 text-left">
							{{ $receiver_locale.global.date_start }}
						</div>
						<div class="col-6 text-right">
							<b>{{ device.current_recipe.date_start }}</b>
						</div>
					</div>
					<div
						class="row"
						:class="
							device.current_recipe.in_OEE < 40
								? 'text-danger'
								: device.current_recipe.in_OEE < 75
									? 'text-warning'
									: ''
						"
					>
						<div class="col-6 text-left">
							{{ $receiver_locale.global.current_production.in_OEE }}
						</div>
						<div class="col-6 text-right">
							<b>
								{{ device.current_recipe.in_OEE }}
								%</b>
						</div>
					</div>
					<div class="row">
						<div class="col-6 text-left">
							{{ $receiver_locale.global.order_units }}
						</div>
						<div class="col-6 text-right">
							<b>{{ device.current_recipe.order_units }}
							</b>
						</div>
					</div>
					<div class="row">
						<div class="col-6 text-left">
							{{ $receiver_locale.global.pieces_produced }}
							(ok/nok)
						</div>
						<div class="col-6 text-right">
							<b :class="device.current_recipe.nok_units==0?'text-success':'text-primary'">{{ device.current_recipe.ok_units + device.current_recipe.nok_units }}
								<span v-if="device.current_recipe.nok_units>0" class="text-success">({{ device.current_recipe.ok_units }}</span><span v-if="device.current_recipe.nok_units>0" class="text-danger">/{{ device.current_recipe.nok_units }})</span>
							</b>
						</div>
					</div>
					<div class="row">
						<div class="col-6 text-left">
							{{ $receiver_locale.global.expected_time }}
						</div>
						<div class="col-6 text-right">
							<b>{{ device.current_recipe.expected_time_format }}
							</b>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="row">
						<div class="col-12 text-center">
							<span class="p-1 m-1 text-danger">
								<b>
									{{
										$receiver_locale.global.current_production
											.no_production_time
									}}
								</b>
							</span>
						</div>
					</div>
				</div>
			</button>
		</div>
	</div>
</template>
<script>
export default {
	name: 'DeviceShortcuts',
	props: ['device'],
	data() {
		return {
		};
	},
	methods: {
		erroresReceta(item) {
			const recipe = this.device.current_recipe;
			this.$store.commit('receivers/setCurrentDevice',[{key:item.id,label:item.name}]);
			this.$store.commit('receivers/setCurrentRecipes',[{key:recipe.recipe_def_id,label:recipe.recipe_name,code:recipe.recipe_code}]);
			window.open('/receiver','_blank');
		},
		produccionReceta(item) {
			const recipe = this.device.current_recipe;
			this.$store.commit('receivers/setCurrentDevice',[{key:item.id,label:item.name}]);
			this.$store.commit('receivers/setCurrentRecipes',[{key:recipe.recipe_def_id,label:recipe.recipe_name,code:recipe.recipe_code}]);
			window.open('/receiver/apr','_blank');
		},
		analisisTurnos(item) {
			const recipe = this.device.current_recipe;
			this.$store.commit('receivers/setCurrentDevice',[{key:item.id,label:item.name}]);
			this.$store.commit('receivers/setCurrentRecipes',{key:recipe.recipe_def_id,label:recipe.recipe_name,code:recipe.recipe_code});
			window.open('/receiver/at','_blank');
		},
		produccionActual(item) {
			this.$store.commit('receivers/setCurrentDevice',[{key:item.id,label:item.name}]);

			window.open('/receiver/current_production','_blank');
		},
		productionPredictive(item) {
			const recipe = this.device.current_recipe;
			this.$store.commit('receivers/setCurrentDevice',[{key:item.id,label:item.name}]);
			this.$store.commit('receivers/setCurrentRecipes',{key:recipe.recipe_def_id,label:recipe.recipe_name,code:recipe.recipe_code});
			window.open('/receiver/predictive','_blank');
		},
		logAlarmas(item) {
			const recipe = this.device.current_recipe;
			this.$store.commit('receivers/setCurrentDevice',[{key:item.id,label:item.name}]);
			this.$store.commit('receivers/setCurrentRecipes',{key:recipe.recipe_def_id,label:recipe.recipe_name,code:recipe.recipe_code});
			window.open('/receiver/alarms_log','_blank');
		},
		preventiveMaintenance(item) {
			const recipe = this.device.current_recipe;
			this.$store.commit('receivers/setCurrentDevice',[{key:item.id,label:item.name}]);
			this.$store.commit('receivers/setCurrentRecipes',[{key:recipe.recipe_def_id,label:recipe.recipe_name,code:recipe.recipe_code}]);
			window.open('/receiver/preventive','_blank');
		}
	}

};
</script>
<style scoped lang="scss">

#shortcuts button.btn {
	min-height: 100%;
	width: 100%;
}

#shortcuts .row {
	display: flex;
	justify-content: center;
	align-items: center;
}
#shortcuts small {
	font-variant: small-caps;
	font-weight: bold;
}

.plpr {
	padding-left: 1px;
	padding-right: 1px;
}

.mlmr {
	margin-left: 1px;
	margin-right: 1px;
}
</style>
