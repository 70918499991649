<template>
	<div>
		<!-- Tab Navigation -->
		<div class="col-sm-4 mb-2">
			<ul id="rolesTab" class="nav nav-pills" role="tablist">
				<li class="nav-item">
					<a
						id="rolesOverviewTab"
						class="nav-link nav-link-info"
						data-toggle="pill"
						href="#rolesOverview"
						role="tab"
						aria-controls="rolesOverview"
						aria-selected="true"
					>
						Roles Overview
					</a>
				</li>
				<li class="nav-item">
					<a
						id="permissionsOverviewTab"
						class="nav-link active nav-link-info"
						data-toggle="pill"
						href="#permissionsOverview"
						role="tab"
						aria-controls="permissionsOverview"
						aria-selected="false"
					>
						Permissions Overview
					</a>
				</li>
			</ul>
		</div>

		<!-- Tab Content -->
		<div id="rolesTabContent" class="tab-content">
			<!-- First Tab - Roles Overview -->
			<div id="rolesOverview" class="tab-pane fade" role="tabpanel">
				<h2>{{ $trans.roles.roles }}</h2>
				<div class="">
					<!-- Your first table here -->
					<table class="table table-bordered">
						<thead>
							<tr>
								<th>#</th>
								<th>{{ $trans.roles.name }}</th>
								<th>{{ $trans.roles.description }}</th>
								<th>{{ $trans.roles.users }}</th>
								<th>{{ $trans.roles.permissions }}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="role in roles" :key="role.id">
								<td>{{ role.id }}</td>
								<td>{{ role.name }}</td>
								<td>{{ role.description }}</td>
								<td>
									<!-- Display users -->
									<ul>
										<li v-for="user in role.users" :key="user.id">
											{{ user.name }} ({{ user.email }})
											<ul>
												<li v-if="user.role_permissions_not_assigned && user.role_permissions_not_assigned.length > 0">
													<strong>Not in Role:</strong>
													<ul>
														<li v-for="permission in user.role_permissions_not_assigned" :key="permission">
															{{ permission }}
														</li>
													</ul>
												</li>
												<li v-if="user.extra_permissions && user.extra_permissions.length > 0">
													<strong>Extra Permissions:</strong>
													<ul>
														<li v-for="permission in user.extra_permissions" :key="permission">
															{{ permission }}
														</li>
													</ul>
												</li>
											</ul>
										</li>
									</ul>
								</td>
								<td>
									<!-- Display permissions using groupedPermissions -->
									<table>
										<thead>
											<tr>
												<th v-for="modulePermissions in groupedPermissionsArray" :key="modulePermissions[0].group">
													{{ modulePermissions[0].group }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td v-for="modulePermissions in groupedPermissionsArray" :key="modulePermissions[0].group">
													<ol style="">
														<li v-for="permission in modulePermissions" :key="permission.id">
															<strong>{{ permission.name }}</strong>
															<br>
															{{ permission.description }}
														</li>
													</ol>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div> <!-- Closing tag for the first tab - Roles Overview -->

			<!-- Second Tab - Permissions Overview -->
			<div id="permissionsOverview" class="tab-pane fade active show" role="tabpanel">
				<div v-if="!loading">
					<h2>Permissions Overview</h2>
					<div class="table-responsive">
						<!-- Your second table here -->
						<table class="table table-bordered">
							<!-- Your new table structure for permissions -->
							<thead>
								<tr class="fixedHeight">
									<th>Module</th>
									<th>Permission Name</th>
									<th>Permission Description</th>
									<th
										v-for="role in roles"
										:key="role.id"
										class="rotated-header"
									>
										<div>
											<span>{{ role.name }}</span>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								<!-- Loop through permissions and roles to populate the table -->
								<tr v-for="permission in permissions" :key="permission.id">
									<td>{{ permission.group }}</td>
									<td>{{ permission.name }}</td>
									<td>{{ permission.description }}</td>
									<td v-for="role in roles" :key="role.id" class="text-center">
										<!-- Add X if the permission is active for the role -->
										{{ role.permissions.some(p => p.id === permission.id) ? 'X' : '' }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div v-else>
					Loading...
				</div>
			</div>
		</div> <!-- Closing tag for the tab content -->
	</div>
</template>

<script>
export default {
	name: 'RolesOverview',
	mounted() {
		this.loadData();
	},
	computed: {
		groupedPermissionsArray() {
			return Object.values(this.groupedPermissions);
		}
	},
	data() {
		return {
			roles: [],
			groupedPermissions: [],
			permissions: [], // Add the permissions property
			loading: true // Add loading state
		};
	},
	methods: {
		async loadData() {
			try {
				await this.fetchPermissions();
				await this.fetchRoles();
				await this.populateUserPermissions(); // Wait for populateUserPermissions to complete
				this.loading = false; // Set loading to false after data is fetched and processed
			} catch (error) {
				console.error('Error during component mounting:', error);
				this.loading = false; // Set loading to false in case of an error
			}
		},
		async fetchRoles() {
			try {
				const response = await axios.get('/api-admin/admin/roles');
				this.roles = response.data || [];
			} catch (error) {
				console.error('Error fetching roles:', error);
				throw error; // Propagate the error to the calling function
			}
		},
		async populateUserPermissions() {
			for (const role of this.roles) {
				for (const user of role.users) {
					try {
						const response = await axios.post(`/api-admin/admin/roles/${role.id}/users/${user.id}/permissions`);
						const { role_permissions, user_permissions } = response || [];
						user.role_permissions_not_assigned = role_permissions && role_permissions.length > 0
							? role_permissions.filter((permission) => !user_permissions.includes(permission))
							: [];
						user.extra_permissions = user_permissions && user_permissions.length > 0
							? user_permissions.filter((permission) => !role_permissions.includes(permission))
							: [];

						console.log('Not Assigned Permissions:', user.role_permissions_not_assigned);
						console.log('Extra Permissions:', user.extra_permissions);
					} catch (error) {
						console.error(`Error fetching permissions for user ${user.name} and role ${role.id}:`, error);
					}
				}
				console.log('Role:', role);
			}
		},
		async fetchPermissions() {
			try {
				const response = await axios.get('/api-admin/admin/permissions');
				const permissions = response || [];
				this.permissions = permissions;
				console.log('Permisos:', permissions);
				this.groupedPermissions = this.groupPermissionsByModule(permissions);
				console.log('groupedPermissions:', this.groupedPermissions);
			} catch (error) {
				console.error('Error fetching permissions:', error);
				throw error; // Propagate the error to the calling function
			}
		},
		groupPermissionsByModule(permissions) {
			console.log('Permisos:', permissions);
			let grouped = {};
			permissions.forEach((permission) => {
				const moduleName = permission.name.split('.')[0];
				if (!grouped[moduleName]) {
					grouped[moduleName] = [];
				}
				grouped[moduleName].push(permission);
			});
			console.log('Grouped:', grouped);
			return grouped;
		},
		syncUserPermissions(user, roleId) {
			console.log(`Syncing permissions for user ${user.name} and role ${roleId}`);
			// Add your logic to sync permissions here
		},
		goToUser(userId) {
			window.location = `/admin/users/${userId}`;
		}
	}
};
</script>

<style scoped>
/* Custom styles for rotated headers */
.rotated-header {
  vertical-align: bottom;
  text-align: center;
}

.rotated-header div {
  display: inline-block;
  transform: rotate(-90deg);
  white-space: nowrap;
}

.rotated-header span {
  border-bottom: 1px solid #dee2e6; /* Bootstrap table border color */
  padding: 5px; /* Adjust padding as needed */
  display: block;
  position:absolute;
  bottom:-10px;
}
.fixedHeight{
	height:200px;
}
</style>