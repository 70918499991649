<template>
	<div class="card shadow mb-4">
		<div class="card-body">
			<h6 v-if="user !== undefined" class="text-right">
				<i>#{{ user.id }} </i>
			</h6>
			<div class="row">
				<div class="col-xs-12 col-sm-6 mb-2">
					<div class="col-12 mb-2">
						<label class="required">{{ this.$t('Name') }}</label>
						<input v-model="formData.name" type="text" class="form-control">
					</div>
					<div class="col-12 mb-2">
						<label class="required">{{ this.$t('Email') }}</label>
						<input v-model="formData.email" type="email" class="form-control">
					</div>
				</div>

				<div class="col-xs-12 col-sm-6 mb-2">
					<div class="col-12 mb-2">
						<label class="required">{{ this.$t('Password') }}</label>
						<input v-model="formData.password" type="password" class="form-control">
					</div>
					<div class="col-12 mb-2">
						<label class="required">{{ this.$t('Password Confirmation') }}</label>
						<input v-model="formData.password_confirmation" type="password" class="form-control">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-6">
					<assign-filters
						:current_filters="formData.filters"
						:user_id="formData.id"
						@setUserFilters="setUserFilters"
					></assign-filters>
				</div>
			</div>
		</div>

		<div class="card-footer">
			<button class="btn btn-primary" @click="save">
				{{ this.$t('buttons.save') }}
			</button>
		</div>
	</div>
</template>
<script>
import AssignFilters from './filters/AssignFilters.vue';

export default {
	name: 'ProfileForm',
	components: {AssignFilters},
	props: {
		user: {required: true}
	},
	created() {
		this.setUser();
	},
	data() {
		return {
			formData: {
				id: null,
				name: null,
				email: null,
				password: null,
				password_confirmation: null,
				filters:[]

			}
		};
	},
	methods: {
		setUser() {
			this.formData.id = this.user.id;
			this.formData.name = this.user.name;
			this.formData.email = this.user.email;
			this.formData.filters = JSON.parse(this.user.default_filters);

		},
		save() {
			const parameters = this.formData;
			axios.post('/api-admin/profile', parameters).then(response => {
				this.$toast.success(this.$t('toast.create_success'));
			});
		},
		setUserFilters(filters){
			this.formData.filters = filters;
		}
	}
};
</script>
