<template>
    <div>
        <div class='row'>
            <div class='col-12'>
                <Gmap
                    :container_map_id='this.container_map_id'
                    :position-by="position_by"
                    :show-geo-pos-btn='false'
                    :show-zone-selector='false'
                    ref='mapZoneRef'
                    :editable='setEditable'
                    :mark_editable="false"
                    :zone_id='zone_id'
                    :zone_name='zone_name'
                    :zoom='15'
                    :zones='zones'
                    :calculateCurrentGeo='true'
                    :showGeoPosBtn="false"
                    :showZoneSelector="false"
                    @polygonsChanged='setPoly'
                >
                </Gmap>
            </div>
        </div>
    </div>

</template>
<script>
import Gmap from './../Gmap/Map'

export default {
    name: 'MapZone',
    components: {
        Gmap,
    },
    props: {
        show:{
            type: Boolean,
            default: false
        },
        zone_id: {
            type: Number
        },

        position_by: {
            type: String,
            default: 'polygons'
        }
    },
    data() {
        return {
            paths:[],
            zones: [],
            zone_name: '',
            container_map_id: 'edit_zone',
            calculate_current_geo: false,
        }
    },
    created() {
        this.getZoneData()
    },
    computed: {
        setEditable(){
            if(this.show){
                return false
            }
            return true
        }
    },
    methods: {
        setPoly() {
            this.paths = this.$refs.mapZoneRef.shapes
        },
        async getZoneData() {
            if(this.zone_id){
                await axios.get('/api-admin/zones/map/zone/' + this.zone_id).then(response => {
                    this.processDataZone(response.data)
                })
            }

        },
        processDataZone(data) {
            this.zones.push(data);
            this.zone_id = data.zone_id
            this.zone_name = data.zone_name
            this.container_map_id = data.zone_name
        },
        addMarkGeo(payload) {

                // const zone = {
                //     'zone_id': null,
                //     'zone_name': null,
                //     'checkpoints': [
                //         {
                //             title: '',
                //             data: {
                //                 id: this.device.id,
                //             },
                //             position: payload
                //         },
                //     ],
                //
                // }
                // this.zones.push(zone)

        },

				/**
				 * Get current center map
				 */
				getCenter(){
						return this.$refs.mapZoneRef.getCenter()
				}
    },
}
</script>
