<template>
    <div v-if="showIfValue" :class="[unit === 'moment' ? 'row' : '', '']">
        <div :class="[unit === 'moment' ? 'col-4 text-left' : '', '']">
            <h6>{{ label }}</h6>
        </div>
        <div :class="[unit === 'moment' ? 'col-8 text-right' : '', '']">
            <h5 class="text-secondary">{{ formattedValue }}
                <h6 class="text-secondary" v-if="unit !== 'moment'" style="display:inline-block">{{ unit }}</h6>
            </h5>
        </div>
    </div>
</template>

<script>
export default {
    props: ['label', 'value', 'unit'],
    computed: {
        showIfValue() {
            return (this.shownValue || this.shownValue === 0 || this.shownValue === null || this.shownValue === '')
        },
        shownValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('update:value', newValue);
            },
        },
        formattedValue: function () {
            if (!this.value) {
                return null
            }

            if (this.unit === 'moment') {
                let d = Math.floor(this.value.asDays())
                let h = Math.floor(this.value.asHours()) % 24
                let m = Math.floor(this.value.asMinutes()) % 60
                let s = Math.floor(this.value.asSeconds()) % 60

                const showValue = '' +
                    (d > 0 ? ((Math.floor(this.value.asDays())) + 'd : ') : '') +
                    (h > 0 ? ((Math.floor(this.value.asHours()) % 24) + 'h : ') : '') +
                    (m > 0 ? ((Math.floor(this.value.asMinutes()) % 60) + 'm : ') : '') +
                    (s > 0 ? ((Math.floor(this.value.asSeconds()) % 60) + 's') : '0s')
                return showValue
            }
            else {
                return this.value
            }
        }
    },
}
</script>
