<template>
	<div class="row">
		<div class="col-lg-6 col-12 p-3">
			<h5 class="text-primary">
				{{ this.$receiver_locale.global.predictive_production.total_pieces }}:
			</h5>
			<input
				v-model="totalUnits"
				type="text"
				class="form-control  w-100"
				readonly
				name="pieces_to_do"
			>
		</div>
		<div class="col-lg-6 col-12 p-3">
			<h5 class="text-primary">
				{{ this.$receiver_locale.global.predictive_production.pieces_ok }}:
			</h5>
			<input
				v-model="totalUnitsOK"
				type="text"
				class="form-control w-100"
				readonly
				name="pieces_to_do"
			>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PiecesOkNoOK',
	props: {
		total_units_ok: {default: null},
		total_units: {default: null}
	},
	computed:{
		totalUnits(){
			return this.total_units;
		},
		totalUnitsOK(){
			return this.total_units_ok;
		}
	}
};
</script>

<style scoped>

</style>
