<template>
	<div>
		<div class="modal-body">
			<div v-if="getParentName" class="alert alert-info mb-2">
				<i class="fa"></i>
				{{ `${this.$zones_locale.parent_zone_info} ${getParentName}` }}
			</div>

			<div class="row">
				<div class="col-12 col-sm-6 mb-2">
					<div class="form-floating">
						<input
							id="code"
							v-model="item.code"
							type="text"
							:readonly="this.show"
							class="form-control"
							:placeholder="this.$zones_locale.code"
						>
						<label for="code" class="required">{{ this.$zones_locale.code }}</label>
					</div>
				</div>
				<div class="col-12 col-sm-6 mb-2">
					<div class="form-floating">
						<input
							id="name"
							v-model="item.name"
							type="text"
							:readonly="this.show"
							class="form-control"
							:placeholder="this.$zones_locale.name"
						>
						<label for="name" class="required">{{ this.$zones_locale.name }}</label>
					</div>
				</div>
				<!--                <div class="col-12 col-sm-6 mb-2">-->
				<!--                    <zone-combo v-if="!this.show" :required='true' :current='parent_zone' @selected='setType'/>-->
				<!--                    <span class="w-100" v-if="this.show">{{ this.$zones_locale.new_main_zone}}: {{this.parent_zone}}</span>-->
				<!--                </div>-->
				<div class="col-12 col-sm-6 mb-2">
					<zone-type-combo
						v-if="!this.show"
						:required="true"
						:current="current_type"
						@selected="setType"
					/>
					<span v-if="this.show" class="w-100">{{ this.$zones_locale.zone_types }}:
						{{ this.current_type.name }}</span>
				</div>
				<div v-if="showInstallations" class="col-12 col-sm-6">
					<installations-combo
						v-if="!this.show"
						:readonly="this.show"
						:current="current_installation"
						:label="this.$zones_locale.installation"
						@add="setInstallation"
					/>
					<span v-else class="w-100">{{ this.$t('installations') }}: {{ this.current_installation.name }}</span>
				</div>
			</div>

			<map-zone
				ref="mapZoneComponent"
				:zone_id="item.id"
				:position_by="getTypePos"
				:show="this.show"
				@polygonsChanged="setPolygons"
			></map-zone>
		</div>
		<div v-if="!show" class="modal-footer">
			<button type="submit" class="btn btn-primary" @click="saveData">
				{{
					this.$t('buttons.save')
				}}
			</button>
		</div>
		<!--        <ModalConfirmFn/>-->
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import ZoneTypeCombo from '../zone_types/ZoneTypeCombo';
import InstallationsCombo from '/../Modules/Installations/Resources/assets/components/InstallationsCombo';
import MapZone from './../MapZone';

export default {
	name: 'ZoneForm',
	components: {
		ZoneTypeCombo,
		InstallationsCombo,
		MapZone
	},
	props: {
		current: { default: null },
		parent_zone: { default: null },
		show: { default: false, required: false },
		show_header: { default: false, required: false }
	},
	created() {
		this.resetItem();
		if (this.parent_zone) {
			this.item.parent_id = this.parent_zone.item.id;
			this.item.installation_id = this.parent_zone.item.installation_id;
		}

		if (this.current) {
			this.setCurrentItem();
		}

	},
	watch: {
		current: function (newItem) {
			if (!newItem) {
				this.resetItem();
			} else {
				this.setCurrentItem();
			}
		},
		current_type: function (data) {
			this.item.type_id = data ? data.id : null;
		},
		parent_zone: function (value) {
			if (value) {
				this.item.parent_id = this.parent_zone.item.id;
			} else {
				this.resetItem();
			}
		}
	},
	computed: {
		...mapGetters('zones', ['listZoneUrl']),
		showInstallations: function () {
			const parent_current = this.current ? this.current.parent_id : null;
			return !this.parent_zone && !parent_current;
		},

		getParentName: function () {
			if (this.parent_zone) return this.parent_zone.item.name;
			if (this.current && this.current.parent_zone) return this.current.parent_zone.name;
			return null;
		},
		getTypePos: function () {
			if (!this.item.polygons) {
				return 'latLng';
			}
			return 'polygons';
		}
	},
	data() {
		return {
			current_type: null,
			current_parent: null,
			current_installation: null,
			item: {}
		};
	},
	methods: {

		resetItem() {
			this.item = {
				name: null,
				code: null,
				type_id: null,
				installation_id: null,
				parent_id: null,
				lat: null,
				lng: null
			};
			this.current_type = null;
			this.current_parent = null;
			this.current_installation = null;
		},
		setCurrentItem() {
			this.item = { ...this.current };
			this.current_type = this.current.type;
			this.current_installation = this.current.installation;
		},

		setType(item) {
			this.item.type_id = item ? item.id : null;
		},

		setInstallation(item) {
			this.item.installation_id = item ? item.id : null;
		},

		setPolygons() {
			this.item.polygons = [];
			if (!this.$refs.mapZoneComponent.paths || this.$refs.mapZoneComponent.paths.length < 1) {
				this.$toast.error(this.$t('paths_required'));
				throw new Error(this.$t('paths_required'));
			}
			this.$refs.mapZoneComponent.paths.forEach((v) => {
				this.item.polygons.push(v.paths);
			});
		},

		/**
         * Set map coordinates from zone data
         */
		setCoordinates() {
			if (this.item.polygons.length > 0) {
				let bounds = new google.maps.LatLngBounds();
				this.item.polygons.forEach(polygon => {
					let polygonBounds = new google.maps.LatLngBounds();
					polygon.forEach(point => {
						polygonBounds.extend(point);
					});
					bounds.union(polygonBounds);
				});
				let center = bounds.getCenter();
				//we set the lat,lng on item on the center of all polygons
				this.item.lat = center.lat();
				this.item.lng = center.lng();
			}
		},

		saveData(e) {
			e.preventDefault();
			//we need to do first setPolygons because SetCoordinates need it
			this.setPolygons();
			this.setCoordinates();
			const data = { ...this.item };

			if (!this.show) {
				axios.post('/api-admin/zones', this.item).then(response => {
					this.$helpers.toastSuccess();
					setTimeout(() => {
						window.location.href = this.listZoneUrl;
					}, 1000);
				});
			}

		}


	}
};
</script>
