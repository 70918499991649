<template>
    <card-template>
        <template slot="titleCard">{{ this.$installations_locale.global.installations_list }}</template>
        <div class="table-responsive">
            <inno-core-datatable
                :enableCreateBtn="this.$helpers.hasPermission('installations.installation.create')"
                :enableEditBtn="this.$helpers.hasPermission('installations.installation.update')"
                :enableDeleteBtn="this.$helpers.hasPermission('installations.installation.delete')"
                :idTable="idTable"
                :columnsData="columns"
                :rowItems="items"
                :customFields="customFields"
                @edit="editItem"
                @delete="deleteItem"
                @new="newItem"
            />
        </div>
        <modal-confirm-delete @confirmed="removeItem"/>
    </card-template>
</template>
<script>
export default{
    name: 'InstallationsList',
    created() {
        this.getItems()
    },
    data() {
        return {
            idTable: 'installationsTable',
            items: [],
            customFields: [],
            columns: [
                {
                    field: 'id',
                    label: '#',
                    display: 'min_tabletP',
                },
                {
                    field: 'name',
                    label: this.$installations_locale.global.name,
                },
                {
                    field: 'customer_name',
                    label: this.$installations_locale.global.customer,
                },
                {
                    field: 'options',
                    label: this.$installations_locale.global.options
                }
            ]
        }
    },
    computed: {},
    methods: {
        getItems() {
            axios.get('/api-admin/installations').then(response => {
                const rows = response.data
                this.items = rows
            })
        },
        editItem(item) {
            window.location = `/installations/${item.id}`
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$installations_locale.global.modals.delete_installation} ${item.name} `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/installations/${item.id}`).then(response => {
                const rows = response.data
                this.items = rows
                this.$toast.success(this.$t('toast.delete_success'))
            })
        },
        newItem() {
            window.location = '/installations/create'
        }
    }

}
</script>
