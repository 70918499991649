export const getCurrentZone = (state) => {
	return state.current
}

export const listZoneUrl = (state, getters, rootState, rootGetters) => {
	return  state.list_url
}

export const newZoneUrl = (state, getters, rootState, rootGetters) => {
	return  state.create_url
}

export const editZoneUrl = (state, getters, rootState, rootGetters) => {
	const zone = state.current
	return zone ? state.edit_url.replace(':zone_id',zone.id) : null
}
export const showZoneUrl = (state, getters, rootState, rootGetters) => {
    const zone = state.current
    return zone ? state.show_url.replace(':zone_id',zone.id) : null
}

export const getTree = (state) => {
	return state.tree
}
