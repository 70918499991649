<template>
	<div :padding="'p-2'">
		<div class="">
			<alarms-log-datatable
				:btn-config="false"
				:enable-create-btn="false"
				:enable-edit-btn="false"
				:enable-delete-btn="false"
				:id-table="idTable"
				:columns-data="columns"
				:row-items="items"
				:custom-fields="customFields"
				:custom-filters="customFilters"
				@setFilter="setFilter"
			>
			</alarms-log-datatable>
		</div>
	</div>
</template>

<script>
import AlarmsLogDatatable from './AlarmsLogDatatable.vue';


export default {
	name: 'AlarmsLogList',
	components: {AlarmsLogDatatable},
	created() {
		// this.getItems()
	},

	data(){
		return {
			idTable: 'alarmsLogTable',
			items: [],
			customFields: [
			],
			customFilters: [
				'date','device_name'
			],
			columns: [
				{
					field: 'id',
					label: this.$receiver_locale.global.id
				},
				{
					field: 'type',
					label: this.$receiver_locale.global.alarms_log.type
				},
				{
					field: 'element_name',
					label: this.$receiver_locale.global.alarms_log.element_name
				},
				{
					field: 'device_name',
					label: this.$receiver_locale.global.alarms_log.device_name
				},
				{
					field: 'type',
					label: this.$receiver_locale.global.alarms_log.type
				},

				{
					field: 'elapsed_time',
					label: this.$receiver_locale.global.alarms_log.elapsed_time
				},

				{
					field: 'life_time',
					label: this.$receiver_locale.global.alarms_log.life_time
				},
				{
					field: 'date',
					label: this.$receiver_locale.global.alarms_log.date
				}


			]
		};
	},
	methods: {
		getItems() {
			axios.get('/api-admin/receiver/getAlarmsLogList').then((response) => {
				const rows = response;
				this.items = rows;
			});
		},
		editItem(item) {},
		deleteItem(item) {},
		removeItem(item) {},
		newItem() {},
		exportCsv(recipe_log_id) {
			axios.get('/api-admin/receiver/getEventLogList/'+recipe_log_id).then((res) => {
				const blob = new Blob([this.ConvertToCSV(JSON.stringify(res.data,null,2))], { type: 'application/json;charset=utf-8' });
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = `EventLogs_${this.formatDate(new Date())}.csv`;
				link.click();
				URL.revokeObjectURL(link.href);
			});
		},
		// JSON to CSV Converter
		ConvertToCSV(objArray) {
			var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
			var str = '';


			var line = '';
			for (var index in array[0]) {
				if (line != '') line += ',';

				line += index;
			}

			str += line + '\r\n';

			for (var i = 0; i < array.length; i++) {
				var line = '';
				for (var index in array[i]) {
					if (line != '') line += ',';

					line += array[i][index];
				}

				str += line + '\r\n';
			}

			return str;
		},
		formatDate(date) {
			return [
				this.padTo2Digits(date.getDate()),
				this.padTo2Digits(date.getMonth() + 1),
				date.getFullYear()
			].join('/');
		},
		padTo2Digits(num) {
			return num.toString().padStart(2, '0');
		},
		setFilter(filter){
			this.$emit('setFilter',filter);
		}
	}
};
</script>

<style scoped>

</style>
