<template>
    <card-template >
        <template slot="titleCard">{{
                this.$receiver_locale.global.current_production.last_minute
            }}
        </template>
        <div class="">
            <table class="w-100  table">
                <tbody>
                <tr v-for="key in dataKeys" v-if="lastMinuteData != null">
                    <td class="col-10">{{ $receiver_locale.global.current_production[key] }}</td>
                    <td class="col-2"><b>{{ lastMinuteData[key]?lastMinuteData[key]:'0' }}%</b></td>
                </tr>
                </tbody>
            </table>
        </div>
    </card-template>
</template>

<script>
export default {
    name: "LastMinuteStats",
    props: {
        currentRecipeData: {required: true}
    },
    data() {
        return {
            dataKeys: ['efficiency', 'availability', 'quality', 'in_OEE', 'ex_OEE'],
            recipe:null,
            lastMinuteData: null
        }
    },
    created() {
        if (this.currentRecipeData) {
            this.recipe = this.currentRecipeData
            this.getLastMinuteData()
        }
    },
    watch: {
        currentRecipeData() {
            if (this.currentRecipeData) {
                this.recipe = this.currentRecipeData
                this.getLastMinuteData()
            }
        }
    },
    methods: {
        getLastMinuteData() {
            const deviceId = this.recipe.device_id
            axios.post(`/api-admin/receiver/lastMinute`,{
                device_id:deviceId,
            }).then(res => {
                this.lastMinuteData = res
            })
        }
    }
}
</script>

<style scoped>

</style>
