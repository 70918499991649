<template>
	<div class="row h-100 m-0 p-0">
		<div class="row w-100 m-1">
			<div class="col-10">
				<label for="" class="border-bottom-1 border-bottom-primary"><i class="fa fa-location-dot mr-2"></i>Assets location</label>
			</div>
		</div>
		<div :id="container_map_id" :style="{height: map_height}" class="gmap-container">
		</div>
	</div>
</template>
<script>


import {mapGetters} from 'vuex';
import {Loader} from '@googlemaps/js-api-loader';
import {
	getAllDevices,
	getCurrentDevices,
	getDevicesTree
} from '../../../../Modules/Receiver/Resources/assets/components/store/getters';

export default {
	name: 'AssetMapLocation',
	mounted() {
		this.setMap();
	},
	computed: {
		...mapGetters('receivers',['getDevicesDashboardSelected','getAllDevices'])
	},
	watch:{
		getDevicesDashboardSelected(){
			this.setMarkers();
		}
	},
	data: function () {
		return {
			container_map_id:'asset_map_location',
			map_height: '300px',
			map: null,
			google: null,
			zoom: 14,
			center: {
				lat: 41.53881183339412,
				lng: 2.445926909799745
			},
			options: {
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: true,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				disableDefaultUi: true
			},
			markers:[]
		};
	},
	methods:{
		async setMap() {
			const loader = new Loader({
				apiKey: 'AIzaSyBJcQBziVj3_6swKwonhbDnYUaW29tMfOE',
				libraries: ['places']
			});
			const mapOptions = {
				center: this.center,
				zoom: this.zoom,
				...this.options
			};
			this.google = await loader.load();
			const map = new this.google.maps.Map(document.getElementById(this.container_map_id), mapOptions);
			this.map = map;
			this.setMarkers();
		},
		setMarkers(){
			this.resetMarkers();
			this.markers=[];
			if (this.getDevicesDashboardSelected.length > 0){
				this.getDevicesDashboardSelected.map(item=>{
					if (item.location[0]){
						const marker = new this.google.maps.Marker({
							position: {lat:item.location[0],lng:item.location[1]},
							label:item.name,
							map: this.map,
							draggable: false,
							title:item.name
						});
						this.markers.push(marker);
					}
				});
			} else {
				this.getAllDevices.map(item=>{
					if (item.location[0]){
						const marker = new this.google.maps.Marker({
							position: {lat:item.location[0],lng:item.location[1]},
							label:item.name,
							map: this.map,
							draggable: false,
							title:item.name,
							id:item.id
						});
						this.markers.push(marker);
						this.addListeners(marker);
					}
				});
			}
			this.recalculateCenter();
		},
		addListeners(mark) {
			mark.addListener('click', (e) => {
				const currentElement = document.getElementById('device-check-'+mark.id);
				$(currentElement).trigger('click');
				this.openNodeParents(currentElement);
			});
		},
		openNodeParents(element){
			const collapseDivs = [];
			let currentElement = element.parentElement;
			while (currentElement) {
				if (currentElement.classList.contains('collapse')) {
					collapseDivs.push(currentElement);
				}
				let sibling = currentElement.previousElementSibling;
				while (sibling) {
					if (sibling.classList.contains('collapse')) {
						collapseDivs.push(sibling);
					}
					sibling = sibling.previousElementSibling;
				}
				currentElement = currentElement.parentElement;
			}

			collapseDivs.forEach(collapseDiv => {
				if ($(collapseDiv).hasClass('collapsed')){
					$(collapseDiv).trigger('click');
				}
			});
		},
		resetMarkers() {
			for (let i = 0; i < this.markers.length; i++) {
				this.markers[i].setMap(null );
			}
		},
		recalculateCenter() {
			if (this.markers.length === 0) {
				return;
			}
			const bounds = new google.maps.LatLngBounds();
			this.markers.forEach(marker => {
				bounds.extend(marker.getPosition());
			});
			this.map.fitBounds(bounds);
		}

	}

};
</script>



<style scoped>

</style>
