export const getDeviceRecipes = (context) => {
	const devices = context.state.current_devices ? context.state.current_devices.map(item => {
		return item.key;
	}) : null;
	axios.post('/api-admin/receiver/devices/recipes', {devicesId: devices}).then(response => {
		context.commit('setDeviceRecipes', response.data ? response.data : []);
	});
};
export const getDevices = (context) => {
	axios
		.get('/api-admin/devices/')
		.then((response) => {
			context.commit('setDevices', response.data);
		});
};
export const getUserDevices = (context,userId) => {
	return axios
		.get('/api-admin/devices/user/' + userId)
		.then((response) => {
			return response.data;
		});
};
export const getDevicesTree = (context) => {
	const filterDatePeriod = context.state.dashboard_filter_period;
	axios
		.post('/api-admin/devices/list/tree', {date_period: filterDatePeriod})
		.then((response) => {
			context.commit('setDevicesTree', response);
		});
};
export const getAllDevices = (context) => {
	const filterDatePeriod = context.state.dashboard_filter_period;
	axios
		.post('/api-admin/devices/list', {date_period: filterDatePeriod})
		.then((response) => {
			context.commit('setAllDevices', response);
		});
};
export const getDevicesAlarms = (context) => {
	let devicesIds = [];
	if (context.state.devices_dashboard_selected.length > 0) {
		devicesIds = context.state.devices_dashboard_selected.map(item => {
			return item.id;
		});
	} else {
		devicesIds = context.state.devices_all.map(item => {
			return item.id;
		});
	}
	const filterDatePeriod = context.state.dashboard_filter_period;
	axios
		.post('/api-admin/devices/alarms', {devicesIds: devicesIds, date_period: filterDatePeriod})
		.then((response) => {
			context.commit('setSelectedDeviceAlarms', response.data);
		});
};
export const getDataDashboard = (context) => {
	const orderBy = context.state.innobox_list_order;
	const filters = context.state.innobox_list_filters;
	axios.post('/api-admin/receiver/getDataDevicesDashboard', {
		order_by: orderBy,
		filters: filters
	}, {hideLoader: true})
		.then((response) => {
			let items = response.data;
			if (orderBy && orderBy.key && orderBy.key.includes('oee')) {
				items.sort((a, b) => sortByOEE(a, b, orderBy));
			}
			if (orderBy && orderBy.key && orderBy.key.includes('expected_time')){
				items.sort((a, b) => sortByExpectedTime(a, b, orderBy));
			}
			context.commit('setInnoboxListData', items);
		});
};

function sortByOEE(a, b, orderBy) {
	const fieldA = a.current_recipe ? a.current_recipe.in_OEE : (orderBy.key == 'oee_asc' ? Number.POSITIVE_INFINITY : null);
	const fieldB = b.current_recipe ? b.current_recipe.in_OEE : (orderBy.key == 'oee_asc' ? Number.POSITIVE_INFINITY : null);
	if (orderBy.key == 'oee_asc') {
		return fieldA - fieldB;
	} else if (orderBy.key == 'oee_desc') {
		return fieldB - fieldA;
	} else {
		return 0;
	}
}
function sortByExpectedTime(a, b, orderBy) {
	const fieldA = a.current_recipe ? a.current_recipe.expected_time : (orderBy.key == 'expected_time_asc' ? Number.POSITIVE_INFINITY : null);
	const fieldB = b.current_recipe ? b.current_recipe.expected_time : (orderBy.key == 'expected_time_asc' ? Number.POSITIVE_INFINITY : null);
	if (orderBy.key == 'expected_time_asc') {
		return fieldA - fieldB;
	} else if (orderBy.key == 'expected_time_desc') {
		return fieldB - fieldA;
	} else {
		return 0;
	}
}
