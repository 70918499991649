<template>
	<div>
		<li
			:href="`#item-${device.id}`"
			:class="!isOpen ? 'd-block p-0  collapsed list-group-item collapse' : 'list-group-item p-0 d-block collapse'"
			data-toggle="collapse"
			role="button"
			:aria-expanded="isOpen ? 'true' : 'false' "
			:aria-controls="`#item-${device.id}`"
			@click="toggle()"
		>
			<div class="p-2">
				<device-item :device="device" :is-open="openId" />
			</div>
		</li>
		<div :id="`item-${device.id}`" :class="isOpen ? 'p-2  collapse show' : 'p-2  collapse' ">
			<div class=" ">
				<div class=" p-0">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import DeviceItem from './DeviceItem.vue';

export default {
	name: 'DeviceItemAccordion',
	components:{DeviceItem},
	props: ['device'],
	computed:{
		isOpen(){
			if (this.device.open){
				this.openId = this.device.id;
			} else {
				this.openId = null;
			}
			return this.device.open;
		}
	},

	data: function () {
		return {
			openId: null
		};
	},
	methods:{
		toggle(){
			if (this.openId == null){
				this.openId = this.device.id;
			} else {
				this.openId = null;
			}
		}
	}

};
</script>



<style scoped>
.list-group-item{
    border-top: 1px solid ligthgray;
    border-bottom: 1px solid ligthgray;
    border-right: none;
    border-left: none;
}
.list-group-item:hover {
    background-color: #f8f9fa; /* Cambia el color de fondo en hover según tus preferencias */

}
</style>
