<template>
		<div>
				<h4 v-if="show_title">{{this.$t('file_manager')}}</h4>

				<div v-if="show_input_file">
						<input-file
								:entity_id="entity_id"
								:entity_folder="entity_folder"
								:entity_namespace="entity_namespace"
								:installation_id="installation_id"
								:refresh_table_fn="refreshAttachments"
						/>

						<hr/>
				</div>

				<AttachmentsTable v-if="show_table" :items="attachments"/>

		</div>
</template>
<script>
import InputFile from './inputFile'
import AttachmentsTable from './AttachmentsTable'

export default {
		name: "FileManager",
		props: {
				show_title:{
					type: Boolean,
					default: true,
				},
				show_input_file: {
						type: Boolean,
						default: true
				},
				show_table: {
						type: Boolean,
						default: true
				},
				entity_namespace: {
						type: String,
						required: true
				},
				entity_folder: {
						type: String,
						required: true
				},
				entity_id: {
						type: Number,
						required: true
				},
				installation_id:{
						type: Number,
						required: false
				}
		},
		components: {
				InputFile,
				AttachmentsTable
		},
		created() {
				if(this.show_table) {
						this.getAttachments()
				}
		},
		data() {
				return {
						attachments: [],
				}
		},
		methods: {
				getAttachments() {
						axios.post('/api-admin/attachments/get-by-entity', {
								entity_namespace: this.entity_namespace,
								entity_id: this.entity_id,
						}).then(res => {
								this.attachments = res.data
						})
				},

				refreshAttachments(items) {
						this.attachments = items
				}
		}
}
</script>
