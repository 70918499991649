<template>
    <div>
        <Datepicker range circle show-clear-button lang="es" placeholder="Selecciona fecha"
                    v-model="filterDateRange" @input='getData' @reset='restore' :date-format="this.formatDate" text-format="short"
        ></Datepicker>

    </div>
</template>

<script>
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css';

export default {
    name: "AlarmFilter",
    props: {
        refresh_fn: {required: true},
        // task_id: {required: true},
    },
    mounted() {
    },
    components: {
        Datepicker: VueDatepickerUi,
    },
    data() {
        return {
            filterDateRange: [null, null],
            current: [null, null],
            formatDate:{
                day: '2-digit',
                month: '2-digit',
                year: 'numeric' }
        }
    },
    methods: {
        getData() {
            if (!this.filterDateRange.includes(null)) {
                this.filterDateRange[0]=new Date(this.filterDateRange[0]).toISOString().slice(0, 10);
                this.filterDateRange[1]=new Date(this.filterDateRange[1]).toISOString().slice(0, 10);
                this.$emit('setFilter', {'date_range': this.filterDateRange})
            }
        },
        restore() {
            this.$emit('setFilter', {'date_range': []})
        },

    },
}
</script>

<style lang="scss">
.calendar.range{
    z-index: 9999;
}
</style>
