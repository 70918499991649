<template>
	<div class="">
		<label>{{ this.$receiver_locale.global.order_by.title }}</label>
		<v-select
			v-model="selected"
			:options="items"
			label="label"
			code="key"
			@input="setOrder"
		></v-select>
	</div>
</template>
<script>

import vSelect from 'vue-select';
import {mapGetters} from 'vuex';
export default {
	name: 'DeviceOrderBy',
	components:{vSelect},
	created() {
		this.selected = this.getInnoboxListOrder;
	},
	computed:{
		...mapGetters('receivers',['getInnoboxListOrder'])
	},

	data(){
		return {
			selected:this.getInnoboxListOrder,
			items:[
				{
					key:'oee_asc',
					label:this.$receiver_locale.global.order_by.fields.oee_asc
				},
				{
					key:'oee_desc',
					label:this.$receiver_locale.global.order_by.fields.oee_desc
				},
				{
					key:'expected_time_asc',
					label:this.$receiver_locale.global.order_by.fields.expected_time_asc
				},
				{
					key:'expected_time_desc',
					label:this.$receiver_locale.global.order_by.fields.expected_time_desc
				}
			]
		};
	},

	methods:{
		setOrder(){
			this.$store.commit('receivers/setInnoboxListOrder',this.selected);
			this.$store.dispatch('receivers/getDataDashboard');
		}
	}
};
</script>
