export const getDeviceRecipes = (state) => {
	return state.device_recipes;
};
export const getDevices = (state) => {
	return state.devices;
};
export const getDevicesTree = (state) => {
	return state.devices_tree;
};
export const getAllDevices = (state) => {
	return state.devices_all;
};
export const getCurrentDevices = (state) => {
	return state.current_devices;
};
export const getCurrentRecipes = (state) => {
	return state.current_recipes;
};
export const getDevicesDashboardSelected = (state) => {
	return state.devices_dashboard_selected;
};
export const getDatePeriodFilter = (state) => {
	return state.dashboard_filter_period;
};
export const getDeviceSelectedAlarm = (state) => {
	return state.device_alarms;
};
export const getInnoboxListData = (state) =>{
	return state.innobox_list_data;
};
export const getInnoboxListOrder = (state) =>{
	return state.innobox_list_order;
};
export const getInnoboxListFilters = (state) =>{
	return state.innobox_list_filters;
};
export const getDefaultUserFilters = (state) =>{
	return state.default_user_filters;
};
export const getDefaultFiltersLoaded = (state) =>{
	return state.default_filters_loaded;
};
