<template>

	<div v-if='zones'>
		<div :id='key' :key='key' v-for='(zone,key) in zones'>

			<!-- PARENT ZONE -->
			<zone-accordion  v-if="zone.children !== null" :edit_fn='edit_fn'  :customer='zone'>
				<zone-component   :edit_fn='edit_fn' :zones="zone.children"/>
			</zone-accordion>

			<!-- WET ZONE -->
			<ul v-else class='list-group'>
				<customer-final   :edit_fn='edit_fn'  :customer='zone' />
			</ul>

		</div>
	</div>
</template>
<script>
import {mapGetters} from 'vuex'
import ZoneAccordion from './ZoneAccordion'
import CustomerFinal from './CustomerFinal'

export default {
	name: 'ZoneComponent',
	created(){

	},
		computed:{
		},
	props: {
		edit_fn: {},
			zones:{
				required: true
			}
	},
	components: {
		ZoneAccordion,
		CustomerFinal
	},
}
</script>
