<template>
	<card-template>
		<template slot="titleCard">{{
			this.$receiver_locale.global.receiver_list
		}}</template>
		<div class="table-responsive">
			<recipelog-datatable-apr
				:btnConfig="false"
				:enableCreateBtn="false"
				:enableEditBtn="true"
				:enableDeleteBtn="false"
				:idTable="idTable"
				:columnsData="columns"
				:rowItems="items"
				:customFields="customFields"
				@edit="editItem"
				:customFilters="customFilters"

								>
			</recipelog-datatable-apr>
		</div>
	</card-template>
</template>

<script>
export default {
	name: 'RecipeLoglist',
	created() {
		//this.getItems()
	},

	data() {
		return {
			idTable: 'recipelogTable',
			items: [],
			customFilters: [
                'device_name','recipe_name','date_start'
            ],
			customFields: [
			],
			columns: [
				{
					field:'options',
					label:this.$receiver_locale.global.options,
				},
				{
					field: 'device_name',
					label: this.$receiver_locale.global.device_name,
				},
				{
					field: 'in_OEE',
                    label: this.$receiver_locale.global.in_OEE,
				},

				{
					field: 'ex_OEE',
                    label: this.$receiver_locale.global.ex_OEE,
				},
				{
					field: 'fault_count',
                    label: this.$receiver_locale.global.fault_count,
				},

				{
					field: 'recipe_code',
                    label: this.$receiver_locale.global.recipe_code,
				},

				{
					field: 'recipe_name',
                    label: this.$receiver_locale.global.recipe_name,
				},

                {
					field: 'date_start',
					label: this.$receiver_locale.global.date_start,
				},

				{
					field: 'date_end',
                    label: this.$receiver_locale.global.date_end,
				},

				{
					field: 'elapsed_time',
                    label: this.$receiver_locale.global.elapsed_time,
				},

				{
					field: 'work_time',
                    label: this.$receiver_locale.global.work_time,
				},

				{
					field: 'setup_time',
                    label: this.$receiver_locale.global.setup_time,
				},

				{
					field: 'pause_time',
                    label: this.$receiver_locale.global.pause_time,
				},

				{
					field: 'fault_time',
                    label: this.$receiver_locale.global.fault_time,
				},

				{
					field: 'pl_stop_time',
                    label: this.$receiver_locale.global.pl_stop_time,
				},

				{
					field: 'starved_time',
                    label: this.$receiver_locale.global.starved_time,
				},

				{
					field: 'blocked_time',
                    label: this.$receiver_locale.global.blocked_time,
				},

				{
					field: 'no_production_time',
                    label: this.$receiver_locale.global.no_production_time,
				},

				{
					field: 'ok_units',
                    label: this.$receiver_locale.global.ok_units,
				},

				{
					field: 'nok_units',
                    label: this.$receiver_locale.global.nok_units,
				},

				{
					field: 'order_units',
                    label: this.$receiver_locale.global.order_units,
				},

				{
					field: 'fault_count',
                    label: this.$receiver_locale.global.fault_count,
				},

				{
					field: 'theoretical',
                    label: this.$receiver_locale.global.theoretical,
				},

				{
					field: 'availability',
                    label: this.$receiver_locale.global.availability,
				},

				{
					field: 'efficiency',
                    label: this.$receiver_locale.global.efficiency,
				},

				{
					field: 'quality',
                    label: this.$receiver_locale.global.quality,
				},
			],
		}
	},
	computed: {},
	methods: {
		getItems() {

			axios.post('/api-admin/receiver/getRecipeLogListFiltered').then((response) => {
				const rows = response.data
				this.items = rows
			})
		},
		editItem(item) {
			axios.get(`/api-admin/receiver/getEventLogList/${item.id}`).then((response) => {
				const rows = response.data
				this.$bus.$emit('EventLogsByRecipe', rows)
			})

			axios.get(`/api-admin/receiver/getEventLogData/${item.id}`).then((response) => {
				this.$bus.$emit('data_metrics', response.data_metrics)
				this.$bus.$emit('data_graph_stoptype', response.data_graph_stoptype)
			})

		},
	},
}
</script>
