<template>
	<card-template>
		<template slot="titleCard">
			{{ this.$customers_locale.global.customers_list }}
		</template>
		<div class="table-responsive">
			<inno-core-datatable
				:enable-create-btn="this.$helpers.hasPermission('customers.customer.create')"
				:enable-edit-btn="this.$helpers.hasPermission('customers.customer.update')"
				:enable-delete-btn="this.$helpers.hasPermission('customers.customer.delete')"
				:id-table="idTable"
				:columns-data="columns"
				:row-items="items"
				:custom-fields="customFields"
				:btn-config="false"
				@edit="editItem"
				@delete="deleteItem"
				@new="newItem"
			>
			</inno-core-datatable>
		</div>
		<modal-confirm-delete @confirmed="removeItem" />
	</card-template>
</template>
<script>
export default {
	name: 'CustomersList',
	created() {

		this.getItems();
	},
	computed: {},
	data() {
		return {
			idTable: 'customersTable',
			items: [],
			customFields: [
				// {field: 'name', content: 'pepito'}
			],
			columns: [
				{
					field: 'id',
					label: '#',
					display: 'min_tabletP'
				},
				{
					field: 'name',
					label: this.$customers_locale.global.name
				},
				{
					field: 'parent.name',
					label: this.$customers_locale.global.customer_parent
				},


				{
					field: 'options',
					label: this.$customers_locale.global.options
				}
			]
		};
	},
	methods: {
		getItems() {
			axios.get('/api-admin/customers').then(response => {
				const rows = response.data;
				this.items = rows;
			});
		},
		editItem(item) {
			window.location = `/customers/${item.id}`;
		},
		deleteItem(item) {
			this.$bus.$emit('fireModalConfirmDelete', {
				text: `${this.$customers_locale.global.modals.delete_customer} ${item.name} `,
				parameters: item
			});
		},
		removeItem(item) {
			axios.delete(`/api-admin/customers/${item.id}`).then(response => {
				const rows = response.data;
				this.items = rows;
				this.$toast.success(this.$t('toast.delete_success'));
			});
		},
		newItem() {
			window.location = '/customers/create';
		}
	}

};
</script>
