<template>
	<div v-if="zones">
		<div
			v-for="(zone,key) in zones"
			:id="key"
			:key="key"
			class="mb-2"
		>
			<zones-item-accordion v-if="zone.zones !== null && zone.zones.length > 0" :zone="zone">
				<zone-tree-component :zones="zone.zones" />
			</zones-item-accordion>

			<!-- WET ZONE -->
			<ul v-else class="list-group">
				<li
					:href="`#item-zone-${zone.id}`"
					:class="!zone.open ? 'd-block pl-0 py-3 collapsed list-group-item collapse' : 'list-group-item pl-0 d-block  py-3 collapse'"
					data-toggle="collapse"
					role="button"
					:aria-expanded="zone.open ? 'true' : 'false' "
					:aria-controls="`#item-zone-${zone.id}`"
					@click="toggle(zone)"
				>
					<zone-item :zone="zone" :is-open="openId"></zone-item>
				</li>
				<div :id="`item-zone-${zone.id}`" :class="zone.open ? 'p-2  collapse show' : 'p-2  collapse' ">
					<div class=" ">
						<div class=" p-0">
							<slot></slot>
						</div>
						<div>
							<device-tree-component :devices="zone.devices"></device-tree-component>
						</div>
					</div>
				</div>
			</ul>
		</div>
	</div>
</template>
<script>

import ZonesItemAccordion from './ZonesItemAccordion.vue';
import ZoneItem from './ZoneItem.vue';
import DeviceTreeComponent from './DeviceTreeComponent.vue';

export default {
	name: 'ZoneTreeComponent',
	components: {DeviceTreeComponent, ZoneItem, ZonesItemAccordion},
	props: ['zones'],
	created() {
	},
	computed:{
		isOpen(zone){
			if (zone.open){
				this.openId = zone.id;
			} else {
				this.openId = null;
			}
			return zone.open;
		}
	},
	data: function () {
		return {
			openId: null
		};
	},
	methods:{
		toggle(zone){

			if (this.openId == null){
				this.openId =zone.id;
			} else {
				this.openId = null;
			}
		}
	}

};
</script>



<style scoped>

</style>
