<template>
		<div>
				<label class="required">{{this.$zones_locale.name}}</label>
				<input type="text" v-model="formData.name" class="form-control">
				<hr>
				<div class="">
						<button @click="saveData" class="btn btn-success">{{this.$t('buttons.save')}}</button>
						<button @click="cancelAction" class="btn btn-danger">{{this.$t('buttons.cancel')}}</button>
				</div>
		</div>
</template>
<script>
export default{
		name:'ZoneTypeForm',
		props:{
				current:{
						required: false
				},
				close_modal_fn:{
						type: Function
				},
				refresh_data_fn:{
						type: Function
				}
		},
		created(){
			this.resetForm()
		},
		watch:{
			current: function(item){
					if(item){
							this.formData = {...item}
					}else{
							this.resetForm()
					}
			}
		},
		data(){
				return{
						formData:{}
				}
		},
		methods:{
				resetForm(){
						this.formData = {
								id: null,
								name: null
						}
				},

				cancelAction(){
						if(this.close_modal_fn){
								this.close_modal_fn();
						}
				},

				saveData() {
						axios.post('/api-admin/zones/types', this.formData).then(response => {
								if(this.refresh_data_fn){
										this.refresh_data_fn();
								}
								this.$emit('saved',response.data)
								this.cancelAction()
								this.resetForm()
								this.$helpers.toastSuccess()
						})
				}
		}
}
</script>