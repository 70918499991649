<template>
	<div
		:id="id"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		:aria-labelledby="`${id}_Modal`"
		aria-hidden="true"
	>
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 id="exampleModalLabel" class="modal-title">
						<span class="fa-stack fa-1x">
							<i class="fas fa-circle fa-stack-2x"></i>
							<i class="fas fa-info fa-stack-1x fa-inverse"></i>
						</span>
						{{ device.name }}
					</h5>
					<button
						class="close"
						type="button"
						data-dismiss="modal"
						aria-label="Close"
						@click="hideModal"
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<div class="modal-body">
					<div :id="`metricsTable${device.id}`">
						<table
							class="devices-grid-table-values list-unstyled table text-right text-secondary bg-light small table w-100"
						>
							<thead>
								<tr>
									<th></th>
									<th></th>
									<th>24h</th>
									<th>7d</th>
									<th>1m</th>
									<th>3m</th>
									<th>Total</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index2) in device.metrics" :key="index2">
									<td style="white-space: nowrap">
										{{ $receiver_locale.global.dash[item.type] }}
									</td>
									<td>
										<i
											v-if="item.type == 'recipes'"
											:title="$receiver_locale.global.current_production.title"
											style="cursor: pointer"
											class="ml-2 fas fa-fw fa-pallet"
											@click="
												redirectToPage(device, 'current_production', item.last_24h)
											"
										></i>
									</td>
									<td>
										<span
											class="cursor-pointer"
											@click="redirectToPage(device, item.type, 'last_24h')"
										>{{ item.last_24h }}</span>
									</td>
									<td>
										<span
											class="cursor-pointer"
											@click="redirectToPage(device, item.type, 'last_week')"
										>{{ item.last_week }}</span>
									</td>
									<td>
										<span
											class="cursor-pointer"
											@click="redirectToPage(device, item.type, 'last_month')"
										>{{ item.last_month }}</span>
									</td>
									<td>
										<span
											class="cursor-pointer"
											@click="redirectToPage(device, item.type, 'last_3_months')"
										>{{ item.last_3_months }}</span>
									</td>
									<td>{{ item.total }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'DeviceMetricsModal',
	props: ['id', 'device','show_modal'],
	watch:{
		show_modal: function(){
			if (this.show_modal) $(`#${this.id}`).modal('show');
			else $(`#${this.id}`).modal('hide');
		}
	},
	data() {
		return {};
	},
	methods: {
		hideModal() {
			$(`#${this.id}`).modal('hide');
		},
		redirectToPage(item, page, dateRange) {
			const dateFullRange = this.getRangeDates(dateRange);

			const pageUrls = {
				recipes: `/receiver/apr?key=${item.id}&label=${item.name}&date_start=${dateFullRange}`,
				alarms: `/receiver/alarms_log?key=${item.id}&label=${item.name}&date_start=${dateFullRange}`,
				faults: `/receiver?key=${item.id}&label=${item.name}&date_start=${dateFullRange}`,
				shifts: `/receiver/at?key=${item.id}&label=${item.name}&date_start=${dateFullRange}`,
				current_production: `/receiver/current_production?key=${item.id}&label=${item.name}`,
				elements: `/receiver/preventive?key=${item.id}&label=${item.name}&date_start=${dateFullRange}`
			};

			const url = pageUrls[page];

			if (url) {
				window.open(url, '_blank');
			}
		},
		getRangeDates(dateRange) {
			const now = new Date();
			let from = new Date();

			switch (dateRange) {
			case 'last_24h':
				from.setDate(now.getDate() - 1);
				break;
			case 'last_week':
				from.setDate(now.getDate() - 7);
				break;
			case 'last_month':
				from.setMonth(now.getMonth() - 1);
				break;
			case 'last_3_months':
				from.setMonth(now.getMonth() - 3);
				break;
			default:
				break;
			}
			const year = from.getFullYear();
			const month = from.getMonth() + 1; // los meses empiezan en 0, por lo que hay que sumar 1
			const day = from.getDate();
			const dateStr = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
			return dateStr;
		}
	}

};
</script>
<style scoped lang="scss">
.devices-grid-table-values {
	.cursor-pointer {
		cursor: pointer;
	}
	thead {
		th {
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}
	tbody {
		tr {
			td {
				padding: 0 3%;
			}
		}
	}
}

</style>

