<template>
	<card-template>
		<template slot="titleCard">
			{{ this.$devices_locale.nav }}
		</template>
		<div class="table-responsive">
			<inno-core-datatable
				:enable-create-btn="false"
				:enable-edit-btn="this.$helpers.hasPermission('devices.device.update')"
				:enable-delete-btn="this.$helpers.hasPermission('devices.device.delete')"
				:id-table="idTable"
				:columns-data="columns"
				:row-items="setItems"
				:custom-fields="customFields"
				:btn-config-licence="true"
				@edit="editItem"
				@delete="deleteItem"
				@new="newItem"
				@show="showItem"
			/>
		</div>
		<modal-confirm-delete @confirmed="removeItem" />
	</card-template>
</template>

<script>

export default {
	name: 'DeviceTable',
	props: {
		devices: {required: true}
	},
	computed: {
		setItems(){
			this.items = this.devices;
			return this.items;
		}
	},
	data() {
		return {
			idTable: 'devicesTable',
			items: [],
			customFields: [],
			columns: [
				{
					field: 'id',
					label: '#',
					display: 'min_tabletP'
				},
				{
					field: 'name',
					label: this.$devices_locale.columns.name
				},
				{
					field: 'code',
					label: this.$devices_locale.columns.code
				},
				{
					field: 'device_type_name',
					label: this.$devices_locale.columns.type
				},
				/* {
                    field: 'device_parent_name',
                    label: this.$devices_locale.columns.device
                },       */
				{
					field: 'zone',
					label: this.$devices_locale.columns.zone
				},
				{
					field: 'options',
					label: this.$devices_locale.columns.options
				}
			]
		};
	},
	methods: {

		editItem(item) {
			window.location = `/devices/${item.id}/edit`;
		},
		deleteItem(item) {
			this.$bus.$emit('fireModalConfirmDelete', {
				text: `${this.$devices_locale.modals.delete_device} ${item.name} `,
				parameters: item
			});
		},
		removeItem(item) {
			axios.delete(`/api-admin/devices/${item.id}`).then(response => {
				this.$toast.success(this.$t('toast.delete_success'));
				this.getItems();
			});
		},
		newItem() {
			window.location = '/devices/create';
		},
		showItem(item) {
			window.location = `/devicelicence/${item.id}/edit`;
		}
	}

};
</script>
