<template>
	<div class="row w-100 m-0  h-100">
		<div class="row w-100 justify-content-center align-items-center  m-0">
			<h5 for="" class="col-3 m-0">
				Listado de dispositivos
			</h5>
			<div class="col-9">
				<div class="row  justify-content-center">
					<filter-period></filter-period>
				</div>
			</div>
		</div>
		<div class="row w-100 m-0 mt-2">
			<div class="col-3  h-100 p-0 " style="border-right: 3px solid lightgray">
				<sidebar-device-tree></sidebar-device-tree>
			</div>
			<div class="col-9">
				<div class="row w-100 m-0">
					<div class="col-4  dashboard-block ">
						<div class=" py-1 bg  bg-white h-100">
							<asset-detail></asset-detail>
						</div>
					</div>
					<div class="col-4">
						<asset-production-states></asset-production-states>
					</div>

					<div class="col-4 dashboard-block p-0">
						<div class=" py-1 bg  bg-white h-100">
							<asset-alerts></asset-alerts>
						</div>
					</div>
				</div>
				<div class="row mt-5 m-0">
					<div class="col-4  dashboard-block p-0">
						<div class="  py-1 bg  bg-white h-100">
							<asset-map-location></asset-map-location>
						</div>
					</div>
					<div class="col-8">
						<div class="  py-1 bg  bg-white h-100">
							<asset-recipe-log></asset-recipe-log>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import SidebarDeviceTree from './SidebarDeviceTree.vue';
import FilterPeriod from './FilterPeriod.vue';
import AssetDetail from './AssetDetail.vue';
import AssetMapLocation from './AssetMapLocation.vue';
import AssetAlerts from './AssetAlerts.vue';
import AssetRecipeLog from './AssetRecipeLog.vue';
import AssetProductionStates from './AssetProductionStates.vue';

export default {
	name: 'Dashboard',
	components: {
		AssetProductionStates,
		AssetRecipeLog,
		AssetAlerts,
		AssetMapLocation,
		AssetDetail,
		FilterPeriod,
		SidebarDeviceTree
	},
	created() {
		this.getData();
	},
	computed: {},
	data() {
		return {};
	},
	methods: {
		getData() {
		}
	}
};
</script>
<style scope>
#content-wrapper {
    background: white !important;
}

.dashboard-block {
    height: 300px;
    max-height: 300px;

}
</style>
