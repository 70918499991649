<template>
    <card-template :padding="'p-1'" v-show="in_OEE!==null">
      <template slot="titleCard">{{ title }}</template>
      <div class="row text-center">
        <div class="col-6">
          <ValueBox :label="in_OEE_label" :value="in_OEE" :unit="'%'" />
        </div>
        <div class="col-6">
          <ValueBox :label="ex_OEE_label" :value="ex_OEE" :unit="'%'" />
        </div>
      </div>
      <div class="row text-center">
        <div class="col-6">
          <ValueBox :label="ok_units_label" :value="ok_units" />
        </div>
        <div class="col-6">
          <ValueBox :label="nok_units_label" :value="nok_units" />
        </div>
      </div>
      <div class="row text-center">
        <div class="col-6">
          <ValueBox :label="MTTR_label" :value="mttr" :unit="'min'" />
        </div>
        <div class="col-6">
          <ValueBox :label="MTBF_label" :value="mtbf" :unit="'min'" />
        </div>
      </div>
    </card-template>
  </template>
  
  <script>
  import ValueBox from '../ValueBox'
  export default {
    components: { ValueBox},
    props: {
      title: String,
      in_OEE_label: String,
      in_OEE: String,
      ex_OEE_label: String,
      ex_OEE: String,
      ok_units_label: String,
      ok_units: String,
      nok_units_label: String,
      nok_units: String,
      MTTR_label: String,
      mttr: String,
      MTBF_label: String,
      mtbf: String,
    },
  };
  </script>