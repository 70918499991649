<template>
	<card-template>
		<template slot="titleCard">
			{{
				this.$receiver_locale.global.current_production.processed_parts
			}}
		</template>
		<div class="">
			<apex-chart
				v-if="recipeData"
				ref="donut"
				type="donut"
				:options="chartOptions"
				:series="chartOptions.series"
			></apex-chart>
		</div>
		<table class="w-100  table">
			<tbody v-if="recipeData ">
				<tr v-for="(label,index) in chartOptions['labels']" :key="index">
					<td class="col-10">
						{{ label }}
					</td>
					<td class="col-2">
						<b>{{ chartOptions['series'][index] }}</b>
					</td>
				</tr>
			</tbody>
		</table>
	</card-template>
</template>

<script>
import ApexChart from 'vue-apexcharts';

export default {
	name: 'ProceseedParts',
	components: {ApexChart},
	props: {
		currentRecipeData: {required: true}
	},
	created() {
		this.initData();
	},
	watch: {
		currentRecipeData() {
			this.initData();
		}
	},
	data() {
		return {
			productionState: null,
			chartOptions: {
				series: [],
				times: [],
				noData: {
					text: 'No data',
					align: 'center',
					verticalAlign: 'middle'
				},
				dataLabels: {
					enabled: false
				},
				responsive: [{
					breakpoint: 480,
					options: {
						chart: {
							width: '100%'
						},
						legend: {
							position: 'bottom'
						}
					}
				}]
			},
			recipeData: null
		};
	},
	methods: {
		initData(){
			this.resetChartData();
			if (this.currentRecipeData) {
				this.recipeData = this.currentRecipeData;
				this.buildChartData();
			}
		},
		resetChartData(){
			this.recipeData = null;
			this.chartOptions['series'] = [];
			this.chartOptions['labels'] = [];
			this.chartOptions['times'] = [];
		},
		buildChartData() {
			const columnsData = ['ok_units', 'nok_units'];
			if (this.recipeData) {
				columnsData.forEach(item => {
					this.chartOptions['labels'].push(this.$receiver_locale.global.current_production[item]);
					this.chartOptions['series'].push(this.recipeData[item]);
					this.chartOptions['times'].push(this.recipeData[item]);
				});
			}
		}

	}
};
</script>

<style scoped>

</style>
