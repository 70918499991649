export const setDeviceRecipes = (state, payload) => {
	state.device_recipes = payload;
};
export const setDevices = (state,payload) => {
	state.devices = payload;
};
export const setDevicesTree = (state,payload) => {
	state.devices_tree = payload;
};
export const setAllDevices = (state,payload) => {
	state.devices_all = payload;
};
export const setCurrentDevice = (state,payload) => {
	state.current_devices = payload;
};
export const setCurrentRecipes = (state,payload) => {
	state.current_recipes = payload;
};

export const selectDeviceDashboard = (state,payload) => {
	state.devices_dashboard_selected = payload;
};
export const removeDeviceDashboard = (state,payload) => {
	state.devices_dashboard_selected = state.devices_dashboard_selected.filter(item => item !== payload);

};
export const setDashboardFilterPeriod = (state,payload) => {
	state.dashboard_filter_period = payload;

};
export const setSelectedDeviceAlarms = (state,alarms) => {
	state.device_alarms = alarms;

};
export const resetDeviceAlarms = (state) => {
	state.device_alarms = {};
};
export const setInnoboxListFilters = (state,filters)=>{
	state.innobox_list_filters = filters;
};
export const setInnoboxListOrder = (state,order)=>{
	state.innobox_list_order = order;
};
export const setInnoboxListData = (state,data)=>{
	state.innobox_list_data = data;
};
export const setDefaultUserFilters = (state,data)=>{
	state.default_user_filters = data;
};
export const setDefaultFiltersLoaded = (state,data) =>{
	 state.default_filters_loaded = data;
};
