<template>
	<div class="p-2" style="height: 100vh;overflow-y: auto">
		<installation-tree-component :installations="getDevicesTree"></installation-tree-component>
	</div>
</template>
<script>
import {mapGetters} from 'vuex';
import InstallationTreeComponent from './InstallationTreeComponent.vue';
export default {
	name: 'SidebarDeviceTree',
	components:{InstallationTreeComponent},

	created(){
		 this.refreshDevicesTree();
		this.handleCollapseDevices();
	},

	computed: {
		...mapGetters('receivers', ['getDevicesTree','getDevicesDashboardSelected','getDatePeriodFilter','getDeviceTreeConfig'])

	},
	watch:{
		getDatePeriodFilter(){
			this.refreshDevicesTree();
		}
	},
	methods: {
		async refreshDevicesTree(){
			this.$store.dispatch('receivers/getDevicesTree');
			this.$store.dispatch('receivers/getAllDevices');
		},
		handleCollapseDevices(){
			this.getDevicesDashboardSelected.map(item=>{
				setTimeout(()=>{
					const currentElement = document.getElementById('device-check-'+item.id);
					//$(currentElement).trigger('click');
					this.openNodeParents(currentElement);
				},1500);
			});
		},
		openNodeParents(element){
			const collapseDivs = [];
			let currentElement = element.parentElement;
			while (currentElement) {
				if (currentElement.classList.contains('collapse')) {
					collapseDivs.push(currentElement);
				}
				let sibling = currentElement.previousElementSibling;
				while (sibling) {
					if (sibling.classList.contains('collapse')) {
						collapseDivs.push(sibling);
					}
					sibling = sibling.previousElementSibling;
				}
				currentElement = currentElement.parentElement;
			}

			collapseDivs.forEach(collapseDiv => {
				$(collapseDiv).trigger('click');
			});
		}
	}
};
</script>



<style scoped>
</style>
