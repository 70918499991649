<template>
	<div>
		<label>{{ label }}</label>
		<v-select
			v-model="selected"
			autocomplete="off"
			:multiple="multiple"
			:options="items"
			label="name"
			code="id"
			@option:selected="add"
			@option:deselected="add"
		></v-select>
	</div>
</template>
<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';


export default {
	name: 'CustomersCombo',
	components: {
		vSelect
	},
	props: {
		'label': {type: String},
		'current': {},
		'multiple': {default: false},
		selectFirst: {default: false},
		customer_id: {default: null}
	},
	created() {
		this.setData();
		if (this.multiple) {
			this.selected = this.current ? [...this.current] : null;
		} else {
			this.selected = this.current ? {...this.current} : null;
		}
	},
	watch: {
		current: function () {
			if (this.multiple) {
				this.selected = this.current ? [...this.current] : null;
			} else {
				this.selected = this.current ? {...this.current} : null;
			}

		},
		selected: function () {
			if (this.selected === null) this.add();
		}
	},
	data() {
		return {
			selected: null,
			items: []
		};
	},
	methods: {
		setData() {
			axios.get('/api-admin/customers/combo').then(response => {
				this.items = response;
				if (this.customer_id){
					this.items = this.items.filter(item => {
						return item.id != this.customer_id;
					});
				}
				if (this.selectFirst) {
					this.selected = this.items[0];
					this.add();
				}
			});
		},
		add() {
			this.$emit('add', this.multiple ? [...this.selected] : {...this.selected});
		}
	}
};
</script>
