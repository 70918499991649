<template>
	<div class="text-center" style="height:360px">
		<apex-chart ref="donut" type="donut" height="360px" :options="chartOptions" :series="series"
			v-show="this.series.length > 0"></apex-chart>
	</div>
</template>

<script>
import ApexChart from 'vue-apexcharts'
export default {	
  name: 'DonutExample',
  components: {ApexChart},
  props:{
	items:{},
	labels:{}
  },
  watch: {
	items: function () {				
				this.series = []
				for(let i=0;i<this.items.length;i++){
					this.series[i] = parseInt(this.items[i])
				}				
				//this.series = this.items					
			},
	labels: function () {		
		//this.chartOptions.labels = []	
		for(let i=0;i<this.labels.length;i++){
			this.chartOptions.labels[i] = this.labels[i]
		}		
		//this.chartOptions.labels = this.labels	
						
	}
  },
  data: function() {
    return {
      chartOptions: {
        labels: [],	
		dataLabels:{
			enabled:true
		},
		chart:{
			height:'360px',
			toolbar: {
              show: true
            },
		},
		legend: {
			floating: false,
			position:'bottom'
			},
		tooltip: {
		enabled: true,
		},
		theme: {
			monochrome: {
				enabled: true,
				color: '#004a98',
				shadeTo: 'light',
				shadeIntensity: 0.65
			}
		},
		fill: {
			type:'solid',
				/*type: 'gradient',
				gradient: {
						shadeIntensity: 2,
						opacityFrom: 0.85,
						opacityTo: 1,
						stops: [0, 100]
				}*/
		},
      },
      series: [],
    }
  },
  methods: {    
  }
}
</script>
