<template>
    <div class="card shadow mb-4 w-100" v-if="this.device!=null&&!this.show">
        <div class="card-header py-3 bg-dark">
            <div class="w-100 d-flex ">
                <h3 for="descriptionDevice" class="w-100">{{
                    this.$devices_locale.columns.configuration
                    }}</h3>
                <div class="w-100 text-right">
                    <StopDevice :device_id="this.device.id"></StopDevice>
                </div>
            </div>
        </div>
        <div :class="`card-body p-4`">
            <div class="w-100 configuration-device-controller">
                <div class="w-100 d-flex">
                    <change-output-device ref="changeOutputDevice" @outputChanged="outputChanged"
                                          :outputs_config="this.device_outputs"></change-output-device>
                    <change-date-hour-device @dateChanged="dateChanged" @updateDate="updateDate"
                                             :device_date="this.device_date"
                                             :device_signal_time="this.device_signal_time"></change-date-hour-device>
                    <change-frequency-device @add="setFrequency" :frequency="frequency"
                    ></change-frequency-device>
                </div>
                <div class="w-100">
                    <hr>
                    <div class="col-4 mb-3">
                        <h4> Cambiar agenda de las salidas del dispositivo</h4>
                    </div>
                    <change-diary-device :output="'code_table_output_1'" @add="setDiary"
                                         @setIrrigationTable="setIrrigationTable"
                                         :diary_config="diary_config"></change-diary-device>
                    <hr>
                    <change-diary-device :output="'code_table_output_2'" @add="setDiary"
                                         @setIrrigationTable="setIrrigationTable"
                                         :diary_config="diary_config"></change-diary-device>
                    <hr>
                    <change-diary-device :output="'code_table_output_3'" @add="setDiary"
                                         @setIrrigationTable="setIrrigationTable"
                                         :diary_config="diary_config"></change-diary-device>
                    <hr>
                    <change-diary-device :output="'code_table_output_4'" @add="setDiary"
                                         @setIrrigationTable="setIrrigationTable"
                                         :diary_config="diary_config"></change-diary-device>
                </div>
            </div>
        </div>
    </div>
    </div>


</template>

<script>
import ChangeDiaryDevice from "./ChangeDiaryDevice";
import ChangeFrequencyDevice from "./ChangeFrequencyDevice";
import ChangeDateHourDevice from "./ChangeDateHourDevice";
import ChangeOutputDevice from "./ChangeOutputDevice";
import StopDevice from "./StopDevice";

export default {
    name: "DeviceConfiguration",
    components: {ChangeDateHourDevice, ChangeOutputDevice, ChangeFrequencyDevice, ChangeDiaryDevice, StopDevice},
    data() {
        return {
            current_outputs: '',
            current_frequency: '',
            current_diary: {},
            irrigation_table: {},
            current_device_date: '',
            change_date: '',
        }
    },
    props: ['show','device', 'device_date', 'device_outputs', 'frequency', 'diary_config', 'device_signal_time'],
    created() {
    },
    computed: {},
    methods: {
        dateChanged(date) {
            this.current_device_date = date;
        },
        outputChanged(outputs) {
            this.current_outputs = outputs;
        },
        updateDate(changeDate) {
            this.change_date = changeDate;
        },
        setFrequency(frequency) {
            this.current_frequency = frequency
            this.$emit('frequencyChanged', frequency)
        },
        setDiary(diary,output) {
            this.current_diary[output]= diary
            this.$emit('diaryChanged', this.current_diary)
        },
        setIrrigationTable(irrigationTable,output) {
            this.irrigation_table[output]= irrigationTable
            this.$emit('setIrrigationTable', this.irrigation_table,output)
        }
    }
}
</script>

<style scoped>

</style>

