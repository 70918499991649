<template>
    <card-template :padding="'p-1'" v-show="values!==null && values.length > 0">
      <template slot="titleCard">{{ title }}</template>
      <DoughnutApex :items="values" :labels="labels" :label="label" />
      <ValueBox :label="work_time_label" :value="work_time" :unit="unit" />
      <ValueBox :label="fault_time_label" :value="fault_time" :unit="unit" />
      <ValueBox :label="pause_time_label" :value="pause_time" :unit="unit" />
      <ValueBox :label="setup_time_label" :value="setup_time" :unit="unit" />
      <ValueBox :label="starved_time_label" :value="starved_time" :unit="unit" />
      <ValueBox :label="blocked_time_label" :value="blocked_time" :unit="unit" />
      <ValueBox :label="no_production_time_label" :value="no_production_time" :unit="unit" />
    </card-template>
  </template>
  
  <script>
  import ValueBox from '../ValueBox'
  import DoughnutApex from '../charts/DoughnutApex'
  export default {
    components: { DoughnutApex, ValueBox },
    props: {
      title: String,
      values: Array,
      labels: Array,
      label: String,
      work_time_label: String,
      work_time: Object,
      fault_time_label: String,
      fault_time: Object,
      pause_time_label: String,
      pause_time: Object,
      setup_time_label: String,
      setup_time: Object,
      starved_time_label: String,
      starved_time: Object,
      blocked_time_label: String,
      blocked_time: Object,
      no_production_time_label: String,
      no_production_time: Object,
      unit: String,
    },
  };
  </script>
  