<template>
	<div class="row">
		<div class="col-lg-6 col-12 d-flex">
			<h5 class="col-6">
				{{ this.$receiver_locale.global.predictive_production.simulation }} / {{ this.$receiver_locale.global.predictive_production.real_time }}
			</h5>
			<vue-toggles
				v-model="real_simulation"
				class=""
				value="real_simulation"
				@click="setRealSimulation"
			></vue-toggles>
		</div>
		<div class="col-lg-6 col-12 d-flex">
			<h5 class="col-6">
				{{ this.$receiver_locale.global.predictive_production.pieces_ok_nok }}
			</h5>
			<vue-toggles
				v-model="piezas_ok_nok"
				class=""
				value="piezas_ok_nok"
				@click="setPiezasOk"
			></vue-toggles>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PredictiveOptions',
	data(){
		return {
			real_simulation: false,
			piezas_ok_nok:false
		};
	},
	methods:{
		setRealSimulation(){
			this.real_simulation = !this.real_simulation;
			this.$emit('setRealSimulation',this.real_simulation);
		},
		setPiezasOk(){
			this.piezas_ok_nok = !this.piezas_ok_nok;
			this.$emit('setPiezasOk',this.piezas_ok_nok);
		}
	}
};
</script>

<style scoped>

</style>
