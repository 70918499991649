<template>
	<card-template :class="{ 'bg-danger-light': isExpired }">
		<template slot="titleCard">
			{{
				this.$subscriptions_locale.subTitle
			}}
		</template>

		<div v-if="formData.id" class="row">
			<div class="col-12 col-sm-6">
				<h4>{{ this.$subscriptions_locale.subscriptions.data }}</h4>
				<ul class="list-group">
					<li class="list-group-item d-flex justify-content-between align-items-center">
						{{ this.$subscriptions_locale.columns.customerName }}
						<b class="text-secondary">{{
							formData.customer.name
						}}</b>
					</li>
					<li class="list-group-item d-flex justify-content-between align-items-center">
						{{ this.$subscriptions_locale.columns.type }}
						<b class="text-secondary">{{ formData.type.name }}</b>
					</li>
					<li class="list-group-item d-flex justify-content-between align-items-center">
						{{ this.$subscriptions_locale.columns.quantity }}
						<b class="text-secondary">{{ formData.quantity }}</b>
					</li>
					<li class="list-group-item">
						<div class="progress font-weight-bold" style="height: 30px;">
							<div
								class="progress-bar progress-bar-striped bg-primary"
								role="progressbar"
								:style="{ width: (activeLicences / formData.quantity) * 100 + '%' }"
								aria-valuenow="25"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								{{ activeLicences }}
							</div>
							<div
								class="progress-bar progress-bar-striped bg-warning"
								role="progressbar"
								:style="{ width: (activatedLicences / formData.quantity) * 100 + '%' }"
								aria-valuenow="25"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								{{ activatedLicences }}
							</div>
							<div
								class="progress-bar progress-bar-striped bg-success"
								role="progressbar"
								:style="{ width: (freeLicences / formData.quantity) * 100 + '%' }"
								aria-valuenow="25"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								{{ freeLicences }}
							</div>
						</div>


						<ul class="list-group my-2">
							<li class="list-group-item d-flex justify-content-between align-items-center bg-primary-light">
								<div>
									<i class="fa fa-lock mr-1 text-warning"></i>
									<i class="fa fa-star mr-1 text-primary"></i>

									{{ this.$subscriptions_locale.subscriptions.activeLicences }}
								</div>
								<b class="text-primary">{{ activeLicences }}</b>
							</li>
							<li v-if="activeLicences > 0" class="list-group-item bg-primary-light">
								<span

									class="progress-bar progress-bar-striped bg-primary"
									:style="{ width: (activeLicences / formData.quantity) * 100 + '%' }"
								>{{ activeLicences }}</span>
							</li>
							<li class="list-group-item d-flex justify-content-between align-items-center bg-warning-light">
								<div>
									<i class="fa fa-lock mr-1 text-warning"></i>
									{{ this.$subscriptions_locale.subscriptions.activatedLicences }}
								</div>
								<b class="text-warning">{{ activatedLicences }}</b>
							</li>
							<li v-if="activatedLicences > 0" class="list-group-item bg-warning-light">
								<span

									class="progress-bar progress-bar-striped bg-warning"
									:style="{ width: (activatedLicences / formData.quantity) * 100 + '%' }"
								>{{ activatedLicences }}</span>
							</li>
							<li class="list-group-item d-flex justify-content-between align-items-center bg-success-light">
								<div>
									<i class="fa fa-unlock mr-1 text-success"></i>
									{{ this.$subscriptions_locale.subscriptions.freeLicences }}
								</div>
								<b class="text-success">{{ freeLicences }}</b>
							</li>
							<li v-if="freeLicences > 0" class="list-group-item bg-success-light">
								<span

									class="progress-bar progress-bar-striped bg-success"
									:style="{ width: (freeLicences / formData.quantity) * 100 + '%' }"
								>{{ freeLicences }}</span>
							</li>
						</ul>
					</li>
					<li class="list-group-item d-flex justify-content-between align-items-center">
						{{ this.$subscriptions_locale.columns.amount }}
						<b class="text-secondary">{{ formData.amount }}</b>
					</li>
					<li class="list-group-item d-flex justify-content-between align-items-center">
						{{ this.$subscriptions_locale.columns.start_date }}
						<b class="text-secondary">{{ formData.start_date }}</b>
					</li>
					<li
						v-if="formData.end_date"
						class="list-group-item d-flex justify-content-between align-items-center"
					>
						{{ this.$subscriptions_locale.columns.end_date }}
						<b class="text-secondary">{{ formData.end_date }}</b>
					</li>
					<li
						v-if="formData.expired_date"
						class="list-group-item text-danger d-flex justify-content-between align-items-center"
					>
						{{ this.$subscriptions_locale.columns.expired_date }}:
						<div>
							<b>{{ formData.expired_date }}</b>
							<span
								v-if="isExpired"
								class="badge badge-danger badge-lg"
							>{{ this.$subscriptions_locale.expired }}</span>
						</div>
					</li>
					<li class="list-group-item d-flex justify-content-between align-items-center">
						{{ this.$subscriptions_locale.buttons.actions }}
						<div v-if="!isExpired">
							<button class="btn btn-danger" @click="markAsExpired">
								{{ this.$subscriptions_locale.subscriptions.mark_as_expired }}
							</button>
						</div>
						<div v-else>
							<button class="btn btn-warning" @click="markAsActive">
								{{ this.$subscriptions_locale.subscriptions.mark_as_active }}
							</button>
						</div>
					</li>
					<li class="list-group-item d-flex justify-content-between align-items-center">
						{{ this.$subscriptions_locale.buttons.increment }}
						<input
							v-model.number="incrementNumber"
							type="number"
							placeholder="Enter number to increment"
							class="form-control p-2 mx-2"
						/>
						<button
							class="btn btn-primary"
							:disabled="incrementNumber < 1"
							@click="incrementQuantity"
						>
							{{ this.$subscriptions_locale.buttons.save }}
						</button>
					</li>
				</ul>
			</div>
			<div class="col-12 col-sm-6">
				<h4>{{ this.$subscriptions_locale.subscriptions.licences_list }}</h4>
				<template v-for="item, index in formData.subscription_licences">
					<div class="col-12 list-group">
						<span
							v-if="item.devicelicence_id == null"
							class="list-group-item d-flex justify-content-between align-items-center bg-success-light"
						>
							<i class="fa fa-unlock mr-2 text-success"></i>
							{{ $subscriptions_locale.subscriptions.free }} [ {{ index+ 1 }} /
							{{ formData.quantity }}]
						</span>
						<span
							v-if="item.devicelicence_id != null && item.applied_date == null"
							class="list-group-item d-flex justify-content-between align-items-center"
							:class="{
								'bg-primary-light': item.devicelicence.device,
								'bg-danger-light': !item.devicelicence.device }"
						>
							<span v-if="!item.devicelicence.device" class="text-danger">
								<i class="fa fa-lock mr-2 text-warning"></i>
								<b>{{ $subscriptions_locale.subscriptions.device_not_found }}</b>
								({{ item.devicelicence.asset_id }})
								[ {{ index+ 1 }} / {{ formData.quantity }}]</span>
							<span v-else>


								<i class="fa fa-lock mr-2 text-warning"></i>
								{{ $subscriptions_locale.subscriptions.assigned }}
								<a
									class="white bg-dark pl-1 pr-1"
									:href="'/devicelicence/' + item.devicelicence.device.id + '/edit'"
								>
									{{ item.devicelicence.device.name }}</a>
								[ {{ index+ 1 }} / {{ formData.quantity }}]
							</span>
						</span>
						<span
							v-if="item.devicelicence_id != null && item.applied_date != null"
							class="list-group-item d-flex justify-content-between align-items-center"
							:class="{
								'bg-primary-light': item.devicelicence.device,
								'bg-danger-light': !item.devicelicence.device }"
						>
							<span v-if="!item.devicelicence.device" class="text-danger">
								<i class="fa fa-lock mr-2 text-warning"></i>
								<i class="fa fa-star mr-1 text-primary"></i>
								{{ $subscriptions_locale.subscriptions.activated }} ({{ item.applied_date }})
								<b>{{ $subscriptions_locale.subscriptions.device_not_found }}</b>
								({{ item.devicelicence.asset_id }})
								[
								{{ index+ 1 }} / {{ formData.quantity }}]</span>
							<span v-else>
								<i class="fa fa-lock mr-2 text-warning"></i>
								<i class="fa fa-star mr-1 text-secondary"></i>
								{{ $subscriptions_locale.subscriptions.activated }}
								({{ item.applied_date }})
								<a
									class="white bg-dark pl-1 pr-1"
									:href="'/devicelicence/' + item.devicelicence.device.id + '/edit'"
								>
									{{ item.devicelicence.device.name }}</a>
								[ {{ index+ 1 }} / {{ formData.quantity }}]
							</span>
						</span>
					</div>
				</template>
			</div>
		</div>
		<div v-if="!formData.id" class="row">
			<div class="col-12 col-md-3">
				<customers-combo
					:current="formData.customer"
					:multiple="false"
					:label="
						this.$subscriptions_locale.columns.customerName
					"
					@add="syncCustomer"
				></customers-combo>
			</div>
			<div class="col-12 col-md-3">
				<subscription-type-combo
					:current="formData.subscriptionType"
					:required="'required'"
					:label="
						this.$subscriptions_locale.columns.type
					"
					@add="setSubscriptionType"
				></subscription-type-combo>
			</div>
			<div class="col-12 col-md-3">
				<div class="mb-3">
					<label for="quantity" class="required">{{
						this.$subscriptions_locale.columns.quantity
					}}</label>
					<input
						id="subscription_quantity"
						v-model="formData.quantity"
						type="number"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div class="mb-3">
					<label for="amount" class="required">{{
						this.$subscriptions_locale.columns.amount
					}}</label>
					<input
						id="subscription_amount"
						v-model="formData.amount"
						type="number"
						class="form-control"
					/>
				</div>
			</div>
		</div>
		<div v-if="!formData.id" class="row">
			<div class="col-12 col-md-3">
				<div class="mb-3">
					<label for="start_date" class="required">{{
						this.$subscriptions_locale.columns.start_date
					}}</label>
					<input
						id="subscription_start_date"
						v-model="formData.start_date"
						type="date"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-12 col-md-3">
				<div class="mb-3">
					<label for="end_date">{{
						this.$subscriptions_locale.columns.end_date
					}}</label>
					<input
						id="subscription_end_date"
						v-model="formData.end_date"
						type="date"
						class="form-control"
					/>
				</div>
			</div>
		</div>
		<button v-if="!formData.id" class="btn btn-primary" @click="save">
			{{ this.$subscriptions_locale.buttons.save }}
		</button>
	</card-template>
</template>

<script>
import SubscriptionTypeCombo from './SubscriptionTypeCombo';
import CustomersCombo from '/../Modules/Customers/Resources/assets/components/CustomerCombo';
import moment from 'moment';

export default {
	name: 'SubscriptionForm',
	components: {
		SubscriptionTypeCombo,
		CustomersCombo
	},
	props: {
		subscription: { required: false }
	},
	created() {
		if (this.subscription) {
			this.formData._method = 'put';
			this.editPath = '/' + this.subscription.id;
			this.getItem();
		}
	},
	computed: {
		isExpired() {
			return this.formData.expired_date !== null;
		}
	},
	data() {
		return {
			device: {
				code: ''
			},
			editPath: '',
			subscriptionType: null,
			customer: null,
			formData: {
				id: null,
				customer: null,
				amount: '',
				start_date: '',
				end_date: '',
				expired_date: '',
				quantity: '',
				subscription_type: '',
				customer_id: '',
				type: null,
				_method: 'post'
			},
			activeLicences: 0,
			activatedLicences: 0,
			freeLicences: 0,
			incrementNumber: 0
		};
	},
	methods: {
		getItem() {
			this.formData = this.subscription;
			this.formData.id = this.subscription.id;
			this.formData.customer = this.subscription.customer
				? this.subscription.customer
				: null;
			this.formData.amount = this.subscription.amount;
			this.formData.start_date = moment(
				this.subscription.start_date
			).format('YYYY-MM-DD');
			this.formData.end_date = this.subscription.end_date
				? moment(this.subscription.end_date).format('YYYY-MM-DD')
				: null;
			this.formData.expired_date = this.subscription.expired_date
				? moment(this.subscription.expired_date).format('YYYY-MM-DD')
				: null;

			this.formData.updated_at = this.subscription.updated_at
				? moment(this.subscription.updated_at).format('YYYY-MM-DD')
				: null;

			this.formData.created_at = this.subscription.created_at
				? moment(this.subscription.created_at).format('YYYY-MM-DD')
				: null;

			this.formData.subscription_type = this.subscription
				.type
				? this.subscription.type.id
				: null;
			this.formData.quantity = this.subscription.quantity;
			this.subscriptionType = this.subscription.type
				? this.subscription.type.id
				: null;
			this.calculateLicences();
		},
		setSubscriptionType: function (subscriptionType) {
			this.subscriptionType = subscriptionType;
			this.formData.subscription_type = subscriptionType.id;
		},
		syncCustomer(customer) {
			this.formData.customer = customer ? customer : null;
			this.formData.customer_id = customer.id ? customer.id : null;
		},
		save: function () {
			let formData = new FormData();
			Object.keys(this.formData).map((field) => {
				formData.append(
					field,
					this.formData[field] === null ? '' : this.formData[field]
				);
			});
			axios
				.post(`/api-admin/subscriptions${this.editPath}`, formData, {
					headers: { 'content-type': 'multipart/form-data' }
				})
				.then((response) => {
					this.$helpers.showLoading();
					this.$helpers.toastSuccess();
					setTimeout(() => {
						window.location.reload();
					}, 1000);
				});
		},
		markAsExpired() {
			this.formData.end_date = moment().format('YYYY-MM-DD');
			this.formData.expired_date = moment().format('YYYY-MM-DD');
			this.save();
		},
		markAsActive() {
			this.formData.start_date = moment().format('YYYY-MM-DD');
			this.formData.end_date = null;
			this.formData.expired_date = null;
			this.save();
		},
		calculateLicences() {
			// Assuming you have a way to get the list of subscription_licences
			this.activeLicences = this.formData.subscription_licences.filter(
				item => item.devicelicence_id != null && item.applied_date != null
			).length;
			this.activatedLicences = this.formData.subscription_licences.filter(
				item => item.devicelicence_id != null && item.applied_date == null
			).length;
			this.freeLicences = this.formData.subscription_licences.filter(
				item => item.devicelicence_id == null
			).length;
		},
		incrementQuantity() {
			if (this.incrementNumber > 0) {
				this.formData.quantity += this.incrementNumber;
				this.save();
			}
		}
	}
};
</script>

<style scoped>

</style>
