import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import ZoneStore from '/../Modules/DevicesBundle/Resources/assets/components/zones/store';
import DeviceStore from '/../Modules/DevicesBundle/Resources/assets/components/devices/store';
import CustomersStore from '/../Modules/Customers/Resources/assets/components/store';
import ReceiverStore from '/../Modules/Receiver/Resources/assets/components/store';
import InstallationStore from  '/../Modules/Installations/Resources/assets/components/store';

Vue.use(Vuex);


export default new Vuex.Store({
	modules:{
		 zones: ZoneStore,
		 devices: DeviceStore,
		 customers: CustomersStore,
		 receivers: ReceiverStore,
		 installations: InstallationStore
	},
	plugins: [
		createPersistedState({
			storage: window.sessionStorage
		})
	]
});
