<template>
	<div>
		<div class="row">
			<div class=" btn-filter-export-mobile mb-3">
				<button class="btn btn-warning btn-filters-mobile mr-2" @click="showDashboardFilters()">
					<i class="fa fa-filter fa-lg"></i>
				</button>
			</div>
			<div class="col-12 filters-section">
				<device-filters>
				</device-filters>
			</div>
		</div>
		<div class="card-device-grid row">
			<div
				v-for="(device, index) in devices"
				:key="index"
				class="card-device-mod col-xs-12 col-sm-6 col-lg-4 col-xl-3 mb-3"
			>
				<div class="card h-100">
					<div class="card-header p-1">
						<device-header
							:device="device"
						/>
					</div>
					<div class="card-body p-1 bg-gray-100 ">
						<device-shortcuts :device="device" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DeviceHeader from './DeviceHeader.vue';
import DeviceFilters from './DeviceFilters.vue';
import DeviceShortcuts from './DeviceShortcuts.vue';
import moment from 'moment';
export default {
	name: 'DeviceGrid',
	components:{
		DeviceHeader,DeviceShortcuts,DeviceFilters
	},
	props: {
		devices: {required: true},
		countdown: {required:true},
		last_load_time: {required:true}
	},

	methods: {
		formatLastLifebit(lastLifebit) {
			return moment(lastLifebit).format('YYYY-MM-DD HH:mm:ss');
		},
		showDashboardFilters(){
			const el = document.querySelector('.filters-section');
			if (el.style.display === 'none' || window.getComputedStyle(el).display === 'none') {

				$(el).show('slow');
			} else {
				$(el).hide('slow');
			}
		}
	}
};
</script>
<style scoped lang="scss">
.btn-filters-mobile{
  display:none;
}

@media only screen and (max-width: 767px) {
  .btn-filters-mobile{
    display:block;
  }
  .filters-section{
    display:none;
  }
}

@media only screen and (max-width: 480px) {
  .btn-filters-mobile{
    display:block;
  }
  .filters-section{
    display:none;
  }
}
</style>
