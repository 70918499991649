Vue.prototype.$receiver_locale = window.trans_receiver;
require('../components');

window.showFilters = function (){
	const el = document.querySelector('.VueTables__search');
	if (el.style.display === 'none' || window.getComputedStyle(el).display === 'none') {

		$(el).show('slow');
	} else {
		$(el).hide('slow');
	}

};
