<template>
	<div class="w-100">
		<div id="deviceToolbar" class="mb-2">
			<div class="row">
				<div class="col-sm-4 mb-2">
					<ul id="deviceViews" class="nav nav-pills" role="tablist">
						<li class="nav-item">
							<a
								id="device-table-tab"
								class="nav-link active nav-link-info"
								data-toggle="pill"
								href="#deviceTable"
								role="tab"
								aria-controls="deviceTable"
								aria-selected="true"
							>
								<i class="fa fa-list"></i>
							</a>
						</li>
						<li class="nav-item">
							<a
								id="device-grid-tab"
								class="nav-link nav-link-info"
								data-toggle="pill"
								href="#deviceGrid"
								role="tab"
								aria-controls="deviceGrid"
								aria-selected="false"
							>
								<i class="fa fa-th-large"></i>
							</a>
						</li>
					</ul>
				</div>
				<div v-show="this.$helpers.hasPermission('devices.device.create')" class="col-sm-8 text-right mb-2">
					<a :href="'/devices/create'" class="btn btn-primary btn-sm"><i class="fa fa-plus"></i>
						{{ this.$t('buttons.new') }}
					</a>
				</div>
			</div>
		</div>

		<div id="pills-tabContent" class="tab-content">
			<div
				id="deviceTable"
				class="tab-pane show fade active"
				role="tabpanel"
				:show_header="false"
				padding="p-0"
			>
				<DeviceTable :devices="items"></DeviceTable>
			</div>
			<card-template
				id="deviceGrid"
				class="tab-pane fade"
				role="tabpanel"
				:show_header="false"
			>
				<DeviceGrid :devices="items"></DeviceGrid>
			</card-template>
		</div>
	</div>
</template>

<script>
import DeviceTable from './device-views/DeviceTable';
import DeviceGrid from './device-views/DeviceGrid';

export default {
	name: 'DeviceList',
	components: {DeviceTable, DeviceGrid},
	created() {
		this.getItems();
	},
	data() {
		return {
			items: []
		};
	},
	methods: {
		getItems() {
			this.axiosCallGetItems();
			setInterval(() => {
				this.axiosCallGetItems();
			},3000);
		},
		axiosCallGetItems() {
			axios.get('/api-admin/devices',{hideLoader:true}).then(response => {
				const rows = response.data;
				this.items = rows;
			});
		}
	}
};
</script>

<style scoped>

</style>
