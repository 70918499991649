<template>
    <card-template>
        <template slot="titleCard">{{
                this.$receiver_locale.global.current_production.production_state
            }}
        </template>
        <div class="">
            <apex-chart v-if="Object.keys(this.faultRecipeData).length > 0" ref="bar" type="bar" height="250" :options="chartOptions"
                        :series="chartOptions.series"></apex-chart>
            <div v-else>
                <span>{{ this.$receiver_locale.global.current_production.fault_not_found }}</span>
            </div>
        </div>

    </card-template>
</template>

<script>
import ApexChart from 'vue-apexcharts'

export default {
    name: "FaultRecipeInit",
    props: {
        currentRecipeData: {required: true},
    },
    components: {ApexChart},
    data() {
        return {
            faultRecipeData: [],
            chartOptions: {
                noData: {
                    text: "No data",
                    align: "center",
                    verticalAlign: "middle",
                },
                series: [],
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [],
                }
            },
            deviceId: null,
            recipeId: null,
            recipeData: [],
        }
    },
    created() {
        this.initData()
    },
    watch: {
        currentRecipeData() {
            this.initData()
        }
    },
    methods: {
        initData(){
            this.resetChartData()
            if (this.currentRecipeData) {
                this.recipeData = this.currentRecipeData
                this.recipeId = this.currentRecipeData.id
                this.deviceId = this.currentRecipeData.device_id
                this.getFaultsRecipe()
            }
        },
        resetChartData(){
            this.recipeData = []
            this.chartOptions.series = []
            this.chartOptions.xaxis.categories = []
        },
        async getFaultsRecipe() {
            await axios.post(`/api-admin/receiver/getFaultsRecipe/${this.deviceId}`, {
                recipe_def_id: this.recipeId,
                date_start:this.recipeData.date_start
            }).then(res => {
                this.faultRecipeData = res
                if( Object.keys(this.faultRecipeData).length > 0){
                    this.setDataBar()
                }else{
                    this.resetChartData()
                }

            })
        },
        setDataBar() {
            let data = []
            if(this.faultRecipeData != null && this.faultRecipeData != undefined){
                Object.keys(this.faultRecipeData).forEach((key)=>{
                    this.chartOptions.xaxis.categories.push(key)
                    data.push(this.faultRecipeData[key])
                })
                this.chartOptions.series.push({data:data})
            }

        }
    }
}
</script>

<style scoped>

</style>
