export default {
	devices:[],
	current_devices: [],
	current_recipes:[],
	device_recipes:[],
	devices_tree:[],
	devices_all:[],
	devices_dashboard_selected:[],
	dashboard_filter_period:null,
	device_alarms: {},
	innobox_list_filters:{},
	innobox_list_order:{},
	innobox_list_data:{},
	default_user_filters:{},
	default_filters_loaded:false

};
