export const getCurrentCustomer = (state) => {
	return state.current
}

export const listCustomerUrl = (state, getters, rootState, rootGetters) => {
	return  state.list_url
}

export const newCustomerUrl = (state, getters, rootState, rootGetters) => {
	return  state.create_url
}

export const editCustomerUrl = (state, getters, rootState, rootGetters) => {
	const customer = state.current
	return customer ? state.edit_url.replace(':customer_id',customer.id) : null
}
export const showCustomerUrl = (state, getters, rootState, rootGetters) => {
    const customer = state.current
    return customer ? state.show_url.replace(':customer_id',customer.id) : null
}

export const getTree = (state) => {
	return state.tree
}
