<template>
		<div>
				<v-client-table ref="AttachmentsTable" :data="documents" :columns="columns" :options="options">
						<template v-slot:options="prop">
								<button @click="downloadFile(prop.row)" class="btn btn-xs btn-secondary ma-2"><i class="fa fa-download"></i></button>
								<button @click="deleteFile(prop.row)" class="btn btn-xs btn-danger ma-2"><i class="fa fa-trash"></i></button>
						</template>
				</v-client-table>
				<modal-confirm-delete-fn />
		</div>
</template>
<script>
export default {
		name: 'HistoryTable',
		props: {
				items: {
						required: true,
						type: Array
				},
		},
		created() {
				this.setColumns()
				this.documents = [...this.items]
		},
		watch:{
			items: function(){
					this.documents = [...this.items]
			}
		},
		computed: {
				columnsConfig: function () {
						return [
								{
										field: 'filename',
										label: this.$t('filename'),
										// display: 'min_tabletP',
								},
								{
										field: 'mime_type',
										label: this.$t('mime_type'),
										// display: 'min_tabletP',
								},
								{
										field: 'installation',
										label: this.$t('Installation'),
										// display: 'min_tabletP',
								},
								{
										field: 'created_at',
										label: this.$t('created_at'),
										// display: 'min_tabletP',
								},
								{
										field: 'updated_at',
										label: this.$t('updated_at'),
										// display: 'min_tabletP',
								},
								{
										field: 'options',
										label: this.$t('Options'),
										// display: 'min_tabletP',
								}
						]

				}
		},
		data() {
				return {
						columns: [],
						documents: [],
						options: {
								uniqueKey: 'filename',
								resizableColumns: true,
								perPage: 10,
								perPageValues: [5, 10, 50, 100],
								pagination: {chunk: 5},
								orderBy: {
										column: 'created_at',
										ascending: false
								},
								datepickerOptions: {
										showDropdowns: true,
										autoUpdateInput: true,
								},
								sortIcon: {
										base: 'fa float-right',
										is: 'fa-sort',
										up: 'fa-sort-up',
										down: 'fa-sort-down'
								},
								texts: {
										count: `${this.$t("Showing {from} to {to} of {count} records|{count} records|One record")}`,
										first: `${this.$t("First")}`,
										last: `${this.$t("Last")}`,
										filter: `${this.$t("Filter")}:`,
										filterPlaceholder: `${this.$t("Search query")}`,
										limit: `${this.$t("Records")}:`,
										page: `${this.$t("Page")}:`,
										noResults: `${this.$t("No matching records")}`,
										filterBy: `${this.$t("Filter by {column}")}`,
										loading: `${this.$t("Loading")}...`,
										defaultOption: `${this.$t("Select {column}")}`,
										columns: `${this.$t("Columns")}`,
								}
						}
				}
		},
		methods: {
				setColumns() {
						const headings = {}
						this.columnsConfig.map(item => {
								this.columns.push(item.field)
								headings[item.field] = item.label
								if (item.display) {
										this.options.columnsDisplay[item.field] = item.display
								}
						})
						this.options.headings = headings

				},

				downloadFile(item){
						location.href = `/attachments/download/${item.hash}`
				},

				deleteFile(item){
						this.$bus.$emit('fireModalConfirmDeleteFn', {
								text: `${this.$t('modal_delete_attachment')} ${item.filename} `,
								parameters: item,
								fn: this.removeFile
						})
				},

				removeFile(item){
						axios.delete(`/api-admin/attachments/${item.hash}`).then(res => {
								this.documents = this.items.filter(el => el.id !== item.id)
								this.$helpers.toastSuccess();
						})
				}

		}
}
</script>