<template>
	<card-template>
		<template slot="titleCard">
			{{
				this.$receiver_locale.global.current_production.production_state
			}}
		</template>
		<div class="">
			<apex-chart
				v-if="recipeData"
				ref="pie"
				type="pie"
				:options="chartOptions"
				:series="chartOptions.series"
			></apex-chart>
		</div>
		<table class="w-100  table">
			<tbody v-if="chartOptions['labels'] != null">
				<tr v-for="(label,index) in chartOptions[`labels`]" :key="index">
					<td class="col-8">
						{{ label }}
					</td>
					<td class="col-4">
						<b>{{ chartOptions['times'][index] }}</b>
					</td>
				</tr>
			</tbody>
		</table>
	</card-template>
</template>

<script>
import ApexChart from 'vue-apexcharts';
import moment from 'moment';

export default {
	name: 'ProductionStates',
	components: {ApexChart},
	props: {
		currentRecipeData: {required: true}
	},
	created() {
		this.initData();
	},
	watch: {
		currentRecipeData() {
			this.initData();
		}
	},

	data() {
		return {
			productionState: null,
			chartOptions: {
				chart: {
					width: '100%',
					type: 'pie'
				},
				noData: {
					text: 'No data',
					align: 'center',
					verticalAlign: 'middle'
				},
				dataLabels: {
					enabled: false
				},
				responsive: [{
					breakpoint: 480,
					options: {
						chart: {
							width: '100%'
						},
						legend: {
							position: 'bottom'
						}
					}
				}]
			},
			deviceId: null,
			recipeId: null,
			recipeData: null
		};
	},
	methods: {
		resetChartData() {
			this.recipeData = [];
			this.chartOptions['series'] = [];
			this.chartOptions['labels'] = [];
			this.chartOptions['times'] = [];
		},
		initData() {
			this.resetChartData();
			if (this.currentRecipeData) {
				this.recipeData = this.currentRecipeData;
				this.recipeId = this.currentRecipeData.id;
				this.deviceId = this.currentRecipeData.device_id;
				this.getProductionState();
			}
		},
		async getProductionState() {
			const columnsData = ['work_time', 'fault_time', 'pause_time', 'setup_time', 'starved_time', 'blocked_time', 'pl_stop_time', 'no_production_time'];
			columnsData.forEach(item => {
				this.chartOptions['labels'].push(this.$receiver_locale.global.current_production[item]);
				const time = this.buildTimes(this.recipeData[item]);
				this.chartOptions['series'].push(this.recipeData[item]);
				this.chartOptions['times'].push(time);
			});
		},
		buildTimes(time) {
			const duration = moment.duration(time, 'seconds');
			const minutes = duration.minutes();
			const seconds = duration.seconds();
			return `${minutes}m : ${seconds < 10 ? '0' : ''}${seconds}s`;
		}
	}
};
</script>

<style scoped>

</style>
