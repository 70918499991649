<template>
	<div>
		<label :class="required?'required':''">{{ label }}</label>
		<v-select
			v-model="selected"
			autocomplete="off"
			:multiple="multiple"
			:options="items"
			label="name"
			code="id"
			@input="add"
			@option:deselected="add(null)"
		>
		</v-select>
	</div>
</template>
<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
	name: 'InstallationsCombo',
	components: {
		vSelect
	},
	props: {
		'current': {required: false},
		'multiple': {default: false},
		label: {default: ''},
		required: {default: false}
	},
	created() {
		this.setData();
		if (this.current) {
			this.selected = this.multiple ? [...this.current] : {...this.current};
		}
	},
	watch: {
		current: function () {
			if (this.current) {
				this.selected = this.multiple ? [...this.current] : {...this.current};
			}
		},
		selected: function () {
			if (this.selected === null) this.add();
		}
	},
	data() {
		return {
			selected: null,
			items: []
		};
	},
	methods: {
		setData() {
			axios.get('/api-admin/installations/combo').then(response => {
				this.items = response;
			});
		},
		add() {
			this.$emit('add', this.selected);
		}
	}
};
</script>
