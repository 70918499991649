<template>
    <div>
        <a :href="`#item-${customer.item.id}`" :class="!isOpen ? 'd-block card-header py-3 collapsed' : 'd-block card-header py-3'" data-toggle="collapse"
           role="button" :aria-expanded="isOpen ? 'true' : 'false' " :aria-controls="`#item-${customer.item.id}`">
            <customer-item   :edit_fn="edit_fn" :customer="customer"  />
        </a>

        <div :class="isOpen ? 'p-2 bg-gray-600 collapse show' : 'p-2 bg-gray-600 collapse' "  :id="`item-${customer.item.id}`">
            <div class="card shadow mb-4">
                <div class="card-body p-0">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomerItem from './CustomerItem'
export default {
    name: 'CustomerAccordion',
    props: {
        customer: {required: true},
        edit_fn: {},
    },
	created(){
	 const current_id = parseInt(window.location.href.split('#').pop())
		this.openId = current_id
	},
    components: {
        CustomerItem
    },
    data() {
        return {
			openId: null
		}
    },
	computed:{
		isOpen(){
			return this.openId === this.customer.item.id
		}
	},
    methods: {
        deleteItem(item) {
            console.log('item')
        },
        editItem(item) {
            console.log('item')
        },

    }
}
</script>
