<template>
    <card-template>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-1">
                        <div class="mb-3">
                            <label for="name" >{{ this.$devices_locale.global.columns.id }}</label>
                            <input type="text" disabled v-model="formData.id"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="name" class="required">{{ this.$devices_locale.global.columns.name }}</label>
                            <input type="text" v-model="formData.name"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="name">{{ this.$devices_locale.global.columns.code }}</label>
                            <input type="text" v-model="formData.code"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <device-type-combo :current="deviceType" @add="setDeviceType" :required="'required'"
                                           :label="this.$devices_locale.global.columns.type"></device-type-combo>
                    </div>

                    <div class="col-12 col-md-4">
                        <installations-combo :current="installation" @add="setInstallation" :required="'required'"
                                           :label="this.$devices_locale.global.columns.installation"></installations-combo>
                    </div>

                    <div class="col-12 col-md-4 ">
                        <device-combo :current="parent_device" @add="setDevice"
                                      :label="this.$devices_locale.global.columns.device"></device-combo>
                    </div>
                    <div class="col-12 col-md-4 " v-if="this.device!=null">
                        <label for="name">{{ this.$devices_locale.global.columns.last_lifebit }}</label>
                        <input type="text" readonly="readonly" v-model="this.device.last_lifebit" id="signal_code"
                               class="form-control">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mb-3 ">
                        <label for="descriptionDevice" class="">{{
                                this.$devices_locale.global.columns.description
                            }}</label>
                        <textarea id="descriptionDevice" class="form-control"
                                  v-model="formData.description"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <button @click="save" class="btn btn-primary">{{ this.$devices_locale.global.buttons.save }}</button>
    </card-template>
</template>

<script>
import DeviceTypeCombo from './DeviceTypeCombo'
import InstallationsCombo from '../../../../Installations/Resources/assets/components/InstallationsCombo'

export default {
    name: "DeviceForm",
    components: {
        DeviceTypeCombo, InstallationsCombo

    },
    props: {
        device: {required: false},
    },
    created() {
        if (this.device) {
            this.formData._method = 'put'
            this.editPath = '/' + this.device.id
            this.getItem()
        }
    },

    data() {
        return {
            editPath: '',
            installation : null,
            deviceType: null,
            parent_device: null,
            formData: {
                id: null,
                name: '',
                code: '',
                description: '',
                device: '',
                unit: '',
                device_type: '',
                installation_id:'',
                is_controller: 0,
                _method: 'post'
            },
        }
    },
    methods: {

        save() {
            let formData = new FormData()
            Object.keys(this.formData).map(field => {
                formData.append(field, this.formData[field] === null ? '' : this.formData[field])
            })
            axios.post(`/api-admin/devices${this.editPath}`, formData, {headers: {'content-type': 'multipart/form-data'}}).then(response => {
                this.$helpers.showLoading()
                this.$helpers.toastSuccess()
                setTimeout(() => {
                    window.location = `/devices/${response.id}/edit`
                }, 1000)
            })
        },
        getItem() {
            this.formData.name = this.device.name
            this.formData.code = this.device.code
            this.formData.id = this.device.id
            this.formData.description = this.device.description
            this.formData.device_type = this.device.device_type_id
            this.formData.device = this.device.device_id
            this.parent_device = this.device.device
            this.deviceType = this.device.device_type
            this.formData.installation_id = this.device.installation_id   
            this.installation = this.device.installation         
        },
        setInstallation(installation){
            this.formData.installation_id = installation.id
            this.installation = installation
        },
        setDevice(device) {
            this.formData.device = device.id
        },
        setDeviceType(deviceType) {
            this.deviceType = deviceType;
            this.formData.device_type = deviceType.id
            this.checkIfDeviceController(deviceType)
        },
        checkIfDeviceController(deviceType) {
            this.formData.is_controller = 0
            if (deviceType.code == 'controller') {
                this.formData.is_controller = 1
            }
        },
    }
}
</script>

<style scoped>

</style>
