<template>
    <card-template>
        <template slot="titleCard">{{ this.$alarms_locale.alarm_detail }}</template>
        <div class="row">
            <div class="col-xs-12 col-sm-6 mb-2">

                <div class="col-12 border mb-2">
                    <div class="col-12 mb-2">
                        <span class="">{{ this.$alarms_locale.table_columns.device }}: </span>
                        <b class="">{{this.alarm.device}}</b>
                    </div>
                    <div class="col-12 mb-2">
                        <span class="">{{ this.$alarms_locale.table_columns.installation }}: </span>
                        <b class="">{{this.alarm.installation}}</b>
                    </div>
                    <div class="col-12 mb-2">
                        <span class="">{{ this.$alarms_locale.table_columns.zone }}:</span>
                        <b class="">{{this.alarm.zone}}</b>
                    </div>
                </div>
                <div class="col-12 border mb-2">
                    <div class="col-12 mb-2">
                        <span class="">{{ this.$alarms_locale.table_columns.item_code }}:</span>
                        <b class="">{{this.alarm.item_code}}</b>
                    </div>
                    <div class="col-12 mb-2">
                        <span class="">{{ this.$alarms_locale.table_columns.item_value }}:</span>
                        <b class="">{{this.alarm.item_value}}</b>
                    </div>
                    <div class="col-12 mb-2">
                        <span class="">{{ this.$alarms_locale.table_columns.fired_alarm_config }}:</span>
                        <b class="">{{this.alarm.fired_alarm_config}}</b>
                    </div>
                </div>
                <div class="col-12 border mb-2">
                    <div class="col-12 mb-2">
                        <span class="">{{ this.$alarms_locale.table_columns.updated_date }}: </span>
                        <b class="">{{this.alarm.updated_at}}</b>
                    </div>
                    <div class="col-12 mb-2">
                        <span class="">{{ this.$alarms_locale.table_columns.start_date }}: </span>
                        <b class="">{{this.alarm.start_date}}</b>
                    </div>
                    <div class="col-12 mb-2">
                        <span class="">{{ this.$alarms_locale.table_columns.end_date }}: </span>
                        <b class="">{{this.alarm.end_date}}</b>
                    </div>
                    <div class="col-12 mb-2">
                        <span class="">{{ this.$alarms_locale.table_columns.elapsed_time }}: </span>
                        <b class="">{{this.alarm.elapsed_time}}</b>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 mb-2 ">
                <div class="col-12  mb-2 d-flex align-items-center">
                    <span class="mr-1">{{ this.$alarms_locale.table_columns.state }}:</span>
                    <div class="w-50">
                        <v-select v-model="current_state" @input='setState' :options="parseAlarmStates(alarm_states)"
                                 :settings="{width:'60%' }"/>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <label for="commentsTask" class="">{{
                            this.$alarms_locale.table_columns.comments
                        }}</label>
                    <textarea id="commentsAlarm" class="form-control"
                              v-model="formData.comments"></textarea>
                </div>
            </div>
        </div>
        <div class="col-12">
            <button @click="save" class="btn btn-primary">{{ this.$alarms_locale.buttons.update }}</button>
            <button @click="cancel" class="btn btn-dark">{{ this.$alarms_locale.buttons.cancel }}</button>
        </div>
        <hr>

    </card-template>
</template>
<script>
import VSelect from 'vue-select';

export default {
    name: 'AlarmForm',
    props: ['alarm', 'alarm_states'],
    components: {
        VSelect
    },
    created() {
    },
    data() {
        return {
            formData: {
                id: this.alarm?this.alarm.id:null,
                alarm_state_id: this.alarm?this.alarm.alarm_state_id:null,
                comments: this.alarm?this.alarm.comments:null
            },
            current_state:this.alarm.state,
            customFields: [
            ],
            idTable: 'readingsTable',
            customFilters: [
                'date_range'
            ],
        }
    },
    computed: {},
    methods: {
        parseAlarmStates(states) {
            let result = [];
            states.forEach((v) => {
                result.push({value: v.id, label: this.$alarms_locale.states_trans[v.name]})
            })
            return result
        },
        setState(state) {
            this.current_state=state
            this.formData.alarm_state_id=state.value
        },
        save() {
            let formData = new FormData()
            Object.keys(this.formData).map(field => {
                formData.append(field, this.formData[field] === null ? '' : this.formData[field])
            })
            axios.post('/api-admin/alarms', formData, {headers: {'content-type': 'multipart/form-data'}})
                .then(response => {
                    this.$helpers.showLoading()
                    this.$helpers.toastSuccess()
                    setTimeout(() => {
                        window.location = `/alarms/${this.alarm.id}/edit`
                    }, 1000)
                })
        },
        cancel: ()=>{
            window.location=`/alarms/`
        },
        editItem(item) {
            window.location = `/readings/${item.id}`
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$readings_locale.modals.delete_task} ${item.name} `,
                parameters: item
            })
        },
    }

}
</script>

