<template>
    <div id="ZonesModule">
        <div id="zoneToolbar" class="alert alert-dark mb-2">
            <div class="row">
                <div class="col-sm-4 mb-2">
                    <ul class="nav nav-pills" id="zoneViews" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active nav-link-info" id="zone-tree-tab" data-toggle="pill"
                               href="#zoneTree" role="tab" aria-controls="zoneTree" aria-selected="true">
                                <i class="fa fa-folder-tree"></i>
                            </a>

                        </li>

                        <li class="nav-item">
                            <a class="nav-link nav-link-info" id="zone-search-tab" data-toggle="pill" href="#zoneSearch"
                               role="tab" aria-controls="zoneSearch" aria-selected="false">
                                <i class="fa fa-search"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-8 text-right mb-2">
                    <a v-if="this.$helpers.hasPermission('zones.zone.create')" :href="newZoneUrl" class="btn btn-success btn-sm"><i class="fa fa-plus"></i>
                        {{ this.$zones_locale.new_main_zone }}
                    </a>
                </div>
            </div>
        </div>

        <div class="tab-content" id="pills-tabContent">
            <card-template class="tab-pane show fade active" role="tabpanel" id="zoneTree" :show_header="false"
                           padding="p-0">
                <div class="card">
                    <div class="card-body">
                        <installations-combo @add="filterData"/>
                    </div>
                </div>
                <zone-component :edit_fn="editZone" :zones="getTree"/>
            </card-template>
            <card-template class="tab-pane fade" role="tabpanel" id="zoneSearch" :show_header="false">
                <zone-search-component :edit_fn="editZone"></zone-search-component>
            </card-template>
        </div>

        <modal-confirm-delete-fn/>
    </div>
</template>
<script>
import ZoneComponent from './ZoneComponent'
import InstallationsCombo from '/../Modules/Installations/Resources/assets/components/InstallationsCombo'
import ZoneSearchComponent from './ZoneSearchComponent'
import {mapGetters} from 'vuex'

export default {
    name: 'ZoneModule',
    props: {},
    components: {
        ZoneComponent,
        InstallationsCombo,
        ZoneSearchComponent,
    },
    computed: {
        ...mapGetters('zones', ['newZoneUrl', 'getTree']),
    },
    created() {
        this.$store.dispatch('zones/getTree')

    },
    data() {
        return {
            parent_zone: null,
            current: null,
            modal_id: 'ZoneModalForm',
            // zones: {}
        }
    },
    methods: {
        filterData(installation) {
            console.log(installation)
            const parameters = installation ? this.$helpers.setUrlParameters({installation_id: installation.id}) : null
            this.$store.dispatch('zones/getTree', parameters)
        },

        hasChildren(zone) {
            return zone.filter((item, key) => key !== 'zone')
        },

        editZone(item) {
            this.current = item
            $(`#${this.modal_id}`).modal('show')
        },


    }
}
</script>
