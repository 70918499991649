<template>
	<div>
		<v-client-table
			:ref="idTable"
			:data="items"
			:columns="columns"
			:options="options"
		>
			<div slot="afterFilter" style="margin-left:10px;align-items: center;display: flex;margin-bottom: 0;">
				<label
					v-if="this.customFilters.includes('date_range')"
					for=""
				>{{ this.$alarms_locale.table_columns.alarm_date }}:</label>
				<alarms-filter
					v-if="this.customFilters.includes('date_range')"
					:current="currentFilter"
					class="my-2"
					:refresh_fn="refreshData"
					@setFilter="setFilter"
				></alarms-filter>
			</div>
			<div slot="afterFilter" style="align-items: center;display: flex;margin-bottom: 0;">
				<label
					v-if="this.customFilters.includes('installation')"
					for=""
				>{{ this.$t('Installation') }}:</label>
				<installations-combo
					v-if="this.customFilters.includes('installation')"
					:show_label="false"
					class="my-2"
					style="min-width:200px"
					@add="setInstallation"
				/>
			</div>
			<template v-for="(item) in customFields">
				<div :slot="item.field" v-html="item.content">
					{{ item.content }}
				</div>
			</template>
			<div slot="afterFilter" style="margin-left:10px;align-items: center;display: flex;margin-bottom: 0;">
				<button class="btn btn-excel" style="background-color: #1D6F42;color:white;" @click="exportCsv()">
					<i class="fa fa-file-excel"></i>
				</button>
			</div>
			<div slot="state" slot-scope="{row}" class="text-center">
				<span class="p-2 w-100 text-center" :class="(row.alarm_state_id==1?'badge badge-danger':'badge badge-light')">{{ row.state }}</span>
			</div>
			<div slot="options" slot-scope="{row}" class=" text-center">
				<button v-show="enableEditBtn" class="btn btn-xs btn-warning" @click="editItem(row)">
					<i class="fa fa-pencil"></i>
				</button>
				<button class=" btn btn-xs btn-primary px-2" @click="showDetail(row)">
					<i :class="'text-white fa-info-circle fa'"></i>
				</button>
			</div>
		</v-client-table>
	</div>
</template>

<script>
import AlarmsFilter from './AlarmsFilter';
import InstallationsCombo from '/../Modules/Installations/Resources/assets/components/InstallationsCombo';
import 'animate.css';

export default {
	name: 'AlarmsTable',
	components: {
		AlarmsFilter,
		InstallationsCombo
	},
	props: {
		btnConfig: {default: false},
		customFilters: {required: false},
		columnsData: {required: true},
		idTable: {required: true},
		rowItems: {required: true},
		customFields: {required: false},
		enableCreateBtn: {default: true},
		enableEditBtn: {default: true}
	},
	created() {
		this.setColumns();
		this.items = [...this.rowItems];
	},
	mounted() {

	},
	computed: {},

	watch: {
		rowItems: function (newItems) {
			this.items = [...newItems];
		}
	},
	data() {
		return {
			items: [],
			columns: [],
			currentFilter: [],
			options: {
				rowClassCallback: row => {
					return row.disabled ? 'disabled-item' : null;
				},

				resizableColumns: true,
				perPage: 10,
				perPageValues: [5, 10, 50, 100],
				pagination: {chunk: 5},
				datepickerOptions: {
					showDropdowns: true,
					autoUpdateInput: true
				},
				sortIcon: {
					base: 'fa float-right',
					is: 'fa-sort',
					up: 'fa-sort-up',
					down: 'fa-sort-down'
				},
				texts: {
					count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
					first: `${this.$t('First')}`,
					last: `${this.$t('Last')}`,
					filter: `${this.$t('Filter')}:`,
					filterPlaceholder: `${this.$t('Search query')}`,
					limit: `${this.$t('Records')}:`,
					page: `${this.$t('Page')}:`,
					noResults: `${this.$t('No matching records')}`,
					filterBy: `${this.$t('Filter by {column}')}`,
					loading: `${this.$t('Loading')}...`,
					defaultOption: `${this.$t('Select {column}')}`,
					columns: `${this.$t('Columns')}`
				},
				columnsDisplay: {}
			},
			selectChangeState: ''
		};
	},
	methods: {
		exportCsv() {
			let data = this.$refs.alarmsTable.data;
			axios.post('/api-admin/alarms/exportCsv', data).then((res) => {
				const blob = new Blob([res], {type: 'text/csv'});
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = `alarms_${this.formatDate(new Date())}.csv`;
				link.click();
				URL.revokeObjectURL(link.href);
			});
		},
		formatDate(date) {
			return [
				this.padTo2Digits(date.getDate()),
				this.padTo2Digits(date.getMonth() + 1),
				date.getFullYear()
			].join('/');
		},
		padTo2Digits(num) {
			return num.toString().padStart(2, '0');
		},
		setColumns() {
			const headings = {};
			this.columnsData.map(item => {
				this.columns.push(item.field);
				headings[item.field] = item.label;
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display;
				}
			});
			this.options.headings = headings;
		},
		refreshData(items) {
			this.items = items;
		},

		editItem(item) {
			this.$emit('edit', item);
		},
		deleteItem(item) {
			this.$emit('delete', item);
		},
		newItem() {
			this.$emit('new');
		},
		config(item) {
			this.$emit('config', item);
		},

		setFilter(filter) {
			let filterKey = Object.keys(filter)[0];
			this.currentFilter[filterKey] = filter[filterKey];
			this.filterResults();
		},

		setInstallation(installation){
			this.currentFilter.installation = installation ? installation.id : null;
			this.filterResults();
		},

		filterResults() {
			let filtersKey = Object.keys(this.currentFilter);
			let queryParams = '?';
			filtersKey.forEach((value, key) => {
				queryParams += `${value}=${this.currentFilter[value]}&`;
			});
			axios.get(`/api-admin/alarms/getAlarms/${queryParams}`).then(response => {
				const rows = response.data;
				this.items = rows;
				this.refreshData(rows);
			});
		},
		restore(item) {
			axios.get('/api-admin/alarms/getAlarms/restore').then(response => {
				this.filterResults();
			});
		},
		showDetail(data) {
			let text = `
                <div class="w-100 text-left">
                    <small>${this.$alarms_locale.table_columns.installation}: ${data.installation}</small>
                </div>
                <div class="w-100 text-left">
                    <small>${this.$alarms_locale.table_columns.zone}: ${data.zone ? data.zone : '-'}</small>
                </div>
                <div class="w-100 text-left">
                    <small>${this.$alarms_locale.table_columns.start_date}: ${data.start_date}</small>
                    <small>${this.$alarms_locale.table_columns.end_date}: ${data.end_date}</small>
                </div>

                <table class="mt-1 table-alarm-detail table table-bordered table-striped" >
                    <tbody class="text-left">
                        <tr>
                            <td>${this.$alarms_locale.table_columns.item_name}</td>
                            <td>${data.item_name}</td>
                        </tr>
                        <tr>
                            <td>${this.$alarms_locale.table_columns.item_code}</td>
                            <td>${data.item_code}</td>
                        </tr>
                         <tr>
                            <td>${this.$alarms_locale.table_columns.item_value}</td>
                            <td>${data.item_value}</td>
                        </tr>
                        <tr>
                            <td>${this.$alarms_locale.table_columns.fired_alarm_config}</td>
                            <td>${data.fired_alarm_config}</td>
                        </tr>
                        <tr>
                            <td>${this.$alarms_locale.table_columns.state}</td>
                            <td>
                               ${data.state}
                            </td>
                        </tr>
                    </tbody>
                </table>
            `;
			this.$swal.fire({
				title: this.$alarms_locale.alarm_detail,
				html: text,
				showClass: {
					popup: 'animate__animated animate__fadeInDown'
				},
				hideClass: {
					popup: 'animate__animated animate__fadeOutUp'
				},
				allowOutsideClick: false,
				customClass: 'modal-detail-alarm',
				// icon: 'info',
				showCancelButton: true,
				showConfirmButton: false,
				cancelButtonText: this.$alarms_locale.buttons.close
			});
		}
	}
};
</script>

<style lang='scss'>

tr.VueTables__row.disabled-item {
		background-color: #ffe5e5 !important;
}

.VueTables {
		label {
				margin-right: 10px;
		}
}

.VueTables__wrapper {
		max-height: 500px;
		overflow-y: scroll;
}

.VueTables__search-field {
		display: flex;
}

.VueTables__limit-field {
		display: flex;
}

.VueTables__columns-dropdown-wrapper {
		margin-right: 10px;
}

.VueTables__row {
		td {
				padding: 3px 5px;
				vertical-align: middle;
		}
}

.VueTables__columns-dropdown {
		input {
				margin-right: 3px;
		}
}

.VueTables__limit-field {
		select {
				margin: 5% 0;
		}

}

.table-reading-detail {
		font-size: 13px;

		tr {
				text-align: left;
		}

		td {
				text-align: center;
				padding: 2px 0;
				color: black;
				vertical-align: middle;
		}
}

.modal-detail-reading {
		color: black;
		font-size: 13px;

}
</style>
