

export const getTree = (context,parameters) => {
	const url = parameters ? `/api-admin/customers/tree${parameters}` : '/api-admin/customers/tree';
	axios.get(url).then(res => {
		context.commit('setTree',res);
	});
};
export const getUserCustomers = (context,userId) => {
	return axios.get('/api-admin/customers/user/'+userId).then(response => {
		return response;
	});
};
