<template>
		<div>
				<ul class='nav nav-pills mb-3' id='pills-tab' role='tablist'>
						<li class='nav-item'>
								<a class='nav-link active' id='pills-ac-tab' data-toggle='pill' href='#tab-history' role='tab' aria-controls='pills-his' aria-selected='true'>
										{{ this.$t('History') }}
								</a>
						</li>

				</ul>
				<div class='tab-content' id='pills-tabContent'>
						<card-template :show_header="false" class="tab-pane show fade active" id="tab-history" role="tabpanel" ria-labelledby='pills-his'>
								<div class="row">
										<History class="col-12" :installation_id="installation_id" />
								</div>

						</card-template>
				</div>


		</div>
</template>
<script>
import History from './AnalyticsHistory'
export default {
		name: 'Analytics',
		props:{
			installation_id:{required: true}
		},
		components:{
				History
		}
}
</script>