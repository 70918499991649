<template>
	<div>
		<v-select
			v-model="selected"
			autocomplete="off"
			:multiple="multiple"
			:options="convertOptionsSelect(getDevices)"
			label="label"
			code="key"
			class="style-chooser"
			style="min-width: 200px"
			@input="add"
		></v-select>
	</div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import {mapGetters} from 'vuex';
export default {
	name: 'DeviceFilter',
	components: {
		vSelect
	},
	props: {
		refresh_fn: { required: true },
		'multiple':{default:true}
	},
	created(){
		this.setData();
		this.current = this.getCurrentDevices;

		this.$bus.$on('filter-updated', (value) => {
			if (this.multiple) this.current = value;
			else this.current = value[0];
		});
	},
	computed: {
		...mapGetters('receivers', ['getDevices','setDeviceRecipes','getCurrentDevices'])
	},
	watch: {
		current: function () {
			if (this.multiple){
				this.selected = this.current ? [...this.current] : null;
			} else {
				this.selected = this.current ? {...this.current[0]} : null;
			}
		}
	},
	data() {
		return {
			options: [],
			selected:null,
			current:null
		};
	},
	methods: {
		setData(){
			this.getDeviceNames();
		},
		convertOptionsSelect(data) {
			let result = [];
			data.forEach((key, value) => {
				result.push({ 'key': data[value]['id'], 'label': data[value]['name'] });
			});
			return result;
		},
		getDeviceNames() {
			this.$store.dispatch('receivers/getDevices');
		},
		add() {
			if (!this.multiple) this.selected = this.selected? [this.selected] : [];
			this.$store.commit('receivers/setCurrentDevice',this.selected);
			this.$store.commit('receivers/setCurrentRecipes',[]);
			this.$store.dispatch('receivers/getDeviceRecipes');
		}
	}
};
</script>

<style scoped>
.style-chooser .vs__dropdown-toggle {
}
</style>
