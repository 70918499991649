// config alarms components
Vue.component('config-alarm-component', require('./ConfigAlarms/ConfigAlarmComponent.vue').default)
Vue.component('config-alarm-list', require('./ConfigAlarms/ConfigAlarmList.vue').default)

// general alarms components
Vue.component('alarms-component', require('./Alarms/AlarmComponent.vue').default)
Vue.component('alarms-filter', require('./Alarms/AlarmsFilter.vue').default)
Vue.component('alarms-table', require('./Alarms/AlarmsTable.vue').default)
Vue.component('alarms-list', require('./Alarms/AlarmsList.vue').default)
Vue.component('alarm-form', require('./Alarms/AlarmForm.vue').default)
Vue.component('alarm-history-list', require('./Alarms/AlarmHistoryList.vue').default)

// alarm types components
Vue.component('alarm-types-list', require('./AlarmTypes/AlarmTypesList.vue').default)
