<template>
	<div>
		<v-client-table
			:ref="idTable"
			:data="items"
			:columns="columns"
			:options="options"
		>
			<template v-for="(item) in customFields">
				<div :slot="item.field" v-html v-html="item.content">
					{{ item.content }}
				</div>
			</template>
			<div slot="afterFilter" class="after__filter">
				<div>
					<!--                    <button @click="filterResults()" class="btn btn-sm btn-warning">-->
					<!--                        <i class="fa fa-filter fa-lg"></i>-->
					<!--                        {{ this.$receiver_locale.global.buttons.filter_simple }}-->
					<!--                    </button>-->
					<button class="btn btn-excel  ml-2 float float-right" style="background-color: #182e5e;color:white;" @click="exportCsv()">
						<i class="fa fa-file-excel  "></i>
					</button>
				</div>
			</div>
		</v-client-table>
	</div>
</template>
<script>
import 'animate.css';

export default {
	name: 'ElementsLogDatatable',
	props: {
		btnConfig: {default: false},
		customFilters: {required: false},
		columnsData: {required: true},
		idTable: {required: true},
		rowItems: {required: true},
		customFields: {required: false},
		enableCreateBtn: {default: true}
	},
	created() {
		this.setColumns();
		this.items = [...this.rowItems];
	},
	mounted() {
	},
	watch:{
		rowItems(){
			this.items = [...this.rowItems];

		}
	},
	data() {
		return {
			items: [],
			columns: [],
			currentFilter: [],
			options: {
				rowClassCallback: row => {
					return row.disabled ? 'disabled-item' : null;
				},
				resizableColumns: true,
				perPage: 10,
				perPageValues: [5, 10, 50, 100],
				pagination: {chunk: 5},
				datepickerOptions: {
					showDropdowns: true,
					autoUpdateInput: true
				},
				sortIcon: {
					base: 'fa float-right',
					is: 'fa-sort',
					up: 'fa-sort-up',
					down: 'fa-sort-down'
				},
				texts: {
					count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
					first: `${this.$t('First')}`,
					last: `${this.$t('Last')}`,
					filter: `${this.$t('Filter')}:`,
					filterPlaceholder: `${this.$t('Search query')}`,
					limit: `${this.$t('Records')}:`,
					page: `${this.$t('Page')}:`,
					noResults: `${this.$t('No matching records')}`,
					filterBy: `${this.$t('Filter by {column}')}`,
					loading: `${this.$t('Loading')}...`,
					defaultOption: `${this.$t('Select {column}')}`,
					columns: `${this.$t('Columns')}`
				},
				columnsDisplay: {}
			},
			selectChangeState: ''
		};
	},
	methods: {
		setColumns() {
			const headings = {};
			this.columnsData.map(item => {
				this.columns.push(item.field);
				headings[item.field] = item.label;
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display;
				}
			});
			this.options.headings = headings;
		},
		exportCsv() {
			let data = this.$refs.elementsLogTable.data.map(item=> { return item.id; });
			axios.post('/api-admin/receiver/exportCsvRegisters', data).then((res) => {
				const blob = new Blob([res], {type: 'text/csv'});
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = `preventive_maintenance_registers_${this.formatDate(new Date())}.csv`;
				link.click();
				URL.revokeObjectURL(link.href);
			});
		},
		formatDate(date) {
			return [
				this.padTo2Digits(date.getDate()),
				this.padTo2Digits(date.getMonth() + 1),
				date.getFullYear()
			].join('/');
		},
		padTo2Digits(num) {
			return num.toString().padStart(2, '0');
		}
	}
};
</script>

<style lang='scss' scoped>

tr.VueTables__row.disabled-item {
    background-color: #ffe5e5 !important;
}

.VueTables {
    label {
        margin-right: 10px;
    }
}

.VueTables__wrapper {
    max-height: 500px;
    overflow-y: scroll;
}

.VueTables__search-field {
    display: none !important;
}

.VueTables__limit-field {
    display: flex;
}

.VueTables__columns-dropdown-wrapper {
    margin-right: 10px;
}

.VueTables__row {
    td {
        padding: 3px 5px;
        vertical-align: middle;
    }
}

.VueTables__columns-dropdown {
    input {
        margin-right: 3px;
    }
}

.VueTables__limit-field {
    select {
        margin: 5% 0;
    }

}

.table-reading-detail {
    font-size: 13px;

    tr {
        text-align: left;
    }

    td {
        text-align: center;
        padding: 2px 0;
        color: black;
        vertical-align: middle;
    }
}

.modal-detail-reading {
    color: black;
    font-size: 13px;

}
</style>
