<template>
    <div>
        <div class='row'>
            <div class='col-12'>
                <Gmap
                    :container_map_id='this.container_map_id'
                    :position-by="position_by"
                    :show-geo-pos-btn='false'
                    :show-zone-selector='show === false'
                    ref='mapDeviceRef'
                    :editable='false'
                    :searchable='show === false'
                    :mark_editable="setEditable"
                    :zone_id='zone_id'
                    :zone_name='zone_name'
                    :zoom='15'
                    :lat="zone.lat"
                    :lng="zone.lng"
                    :zones='zones'
                    :calculateCurrentGeo='false'
                    :showGeoPosBtn="false"
                    :showZoneSelector="false"
                    @markerChanged='positionMarkerChanged'
                    :enableInfoWindow="true"
                >
                </Gmap>
            </div>
        </div>
    </div>

</template>
<script>
import Gmap from './../Gmap/Map'

export default {
    name: 'MapDevice',
    components: {
        Gmap,
    },
    props: {
        device_id: {
            type: Number
        },
        show: {},
        position_by: {
            type: String,
            default: 'checkpoints'
        },
        zone:{
                default: null
        }
    },
		watch:{
				zone: function(){
                    this.zone_id=this.zone.id                    
					this.getZoneData()
				}
		},
    data() {
        return {
            device: {
                lat: null,
                lng: null
            },
            //paths:[],
            zones: [],
            zone_id: null,
            zone_name: '',
            container_map_id: 'edit_device',
            calculate_current_geo: false,
        }
    },
    created() {
				if(this.device_id){
						this.getDeviceData()
				}
    },
		mounted(){
				if(!this.device_id) {
						this.addMarkGeo()
				}
		},
    computed: {
        setEditable(){
            if(this.show){
                return false
            }
            return true
        }
    },
    methods: {
        positionMarkerChanged(markerPosition) {
            this.$emit('markerChanged', markerPosition)
        },
        async getDeviceData() {
            await axios.get('/api-admin/devices/map/device/' + this.device_id).then(response => {
                this.device = response.data
                this.processDataDevice(response.data)
            })
        },
        async getZoneData() {
            if(this.zone_id){
                await axios.get('/api-admin/zones/map/zone/' + this.zone_id).then(response => {
                    this.processDataDevice(response.data)
                })
            }

        },
        processDataDevice(data) {     
                    
            this.zone_id = data.zone_id
            this.zone_name = data.zone_name
            this.container_map_id = data.zone_name   
            if (data.checkpoints[0]&&data.checkpoints[0].position&&(data.checkpoints[0].position.lat == null || data.checkpoints[0].position.lng == null)) {
                this.calculate_current_geo = false
                this.device.lat = data.checkpoints[0].position.lat
                this.device.lng = data.checkpoints[0].position.lng
            }
            this.addMarkGeo()
        },
        //add mark device in gmap
        addMarkGeo() {
						this.$refs.mapDeviceRef.resetMapObjects()
                        //console.log('MapDevice.vue - addMarkGeo()')
						if(this.zone && this.setEditable ){
								const zone = {
                                        'zone':this.zone,
										'zone_id': this.zone.id,
										'zone_name': this.zone.name,
                                        'paths': this.zone.paths,
										'checkpoints': [
												{
														title: this.device.name,
														data: {
																// id: this.device.id,
																// type: this.device.device_type?this.device.device_type.name: ' ',
																// last_signal_values: this.device.last_signal_values?JSON.parse(this.device.last_signal_values):[],
																// zone_name: this.device.zone_name,
																// installation_name: this.device.installation_name,
																// last_lifebit_parse: this.device.last_lifebit_parse
														},
														position: {lat:this.zone.lat, lng:this.zone.lng}
												},
										]
								}
								this.zones.push(zone)
						}
        }
    },
}
</script>
