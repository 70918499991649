<template>
	<card-template :padding="'p-1'">
		<template slot="titleCard">
			{{
				this.$receiver_locale.global.at
			}}
		</template>
		<div class="table-responsive">
			<shift-log-datatable
				:btn-config="false"
				:enable-create-btn="false"
				:enable-edit-btn="false"
				:enable-delete-btn="false"
				:id-table="idTable"
				:columns-data="columns"
				:row-items="items"
				:custom-fields="customFields"
				:custom-filters="customFilters"
			>
			</shift-log-datatable>
		</div>
	</card-template>
</template>
<script>
export default {
	name: 'ShiftLoglist',
	data() {
		return {
			idTable: 'shiftlogTable',
			items: [],
			customFilters: [
				'device_name', 'date_start'
			],
			customFields: [
			],
			columns: [
				{
					field: 'options',
					label: this.$receiver_locale.global.options
				},
				{
					field: 'device_name',
					label: this.$receiver_locale.global.device_name
				},
				{
					field: 'shift_name',
					label: this.$receiver_locale.global.shift_name
				},

				{
					field: 'work_time',
					label: this.$receiver_locale.global.work_time
				},

				{
					field: 'setup_time',
					label: this.$receiver_locale.global.setup_time
				},

				{
					field: 'pause_time',
					label: this.$receiver_locale.global.pause_time
				},

				{
					field: 'fault_time',
					label: this.$receiver_locale.global.fault_time
				},

				{
					field: 'pl_stop_time',
					label: this.$receiver_locale.global.pl_stop_time
				},

				{
					field: 'starved_time',
					label: this.$receiver_locale.global.starved_time
				},

				{
					field: 'blocked_time',
					label: this.$receiver_locale.global.blocked_time
				},

				{
					field: 'no_production_time',
					label: this.$receiver_locale.global.no_production_time
				},

				{
					field: 'ok_units',
					label: this.$receiver_locale.global.ok_units
				},

				{
					field: 'nok_units',
					label: this.$receiver_locale.global.nok_units
				},

				{
					field: 'order_units',
					label: this.$receiver_locale.global.order_units
				},

				{
					field: 'fault_count',
					label: this.$receiver_locale.global.fault_count
				},

				{
					field: 'quality',
					label: this.$receiver_locale.global.quality
				},

				{
					field: 'in_OEE',
					label: this.$receiver_locale.global.in_OEE
				},

				{
					field: 'ex_OEE',
					label: this.$receiver_locale.global.ex_OEE
				},

				{
					field: 'MTTR',
					label: this.$receiver_locale.global.MTTR
				},

				{
					field: 'MTBF',
					label: this.$receiver_locale.global.MTBF
				},

				{
					field: 'counter3',
					label: this.$receiver_locale.global.counter3
				},

				{
					field: 'counter4',
					label: this.$receiver_locale.global.counter4
				},

				{
					field: 'counter5',
					label: this.$receiver_locale.global.counter5
				},

				{
					field: 'counter6',
					label: this.$receiver_locale.global.counter6
				},

				{
					field: 'counter7',
					label: this.$receiver_locale.global.counter7
				},

				{
					field: 'counter8',
					label: this.$receiver_locale.global.counter8
				},

				{
					field: 'exported',
					label: this.$receiver_locale.global.exported
				},

				{
					field: 'inserted_at',
					label: this.$receiver_locale.global.inserted_at
				},

				{
					field: 'updated_at',
					label: this.$receiver_locale.global.updated_at
				}


			]
		};
	}
};
</script>
