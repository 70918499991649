<template>
	<div id="innobox-filter-toolbar" class="row m-0 mb-3 align-items-end">
		<div class="col-lg-2 col-12 d-block">
			<label>{{ this.$receiver_locale.global.filters.name }}</label>
			<input
				type="text"
				:value="filters.devices"
				class="form-control form-control-sm"
				@input="setFilterDevice($event,'devices')"
				@keyup="search($event)"
			>
		</div>
		<div class="col-lg-2 col-12 d-block mt-2 mt-lg-0">
			<customers-combo
				:current="filters.customers"
				:label="this.$receiver_locale.global.filters.customer"
				@add="setFilters($event,'customers')"
			></customers-combo>
		</div>
		<div class="col-lg-2 col-12 d-block mt-2 mt-lg-0">
			<installation-combo
				:required="false"
				:current="filters.installations"
				:label="this.$receiver_locale.global.filters.installation"
				@add="setFilters($event,'installations')"
			></installation-combo>
		</div>
		<div class="col-lg-2 col-12 d-block mt-2 mt-lg-0">
			<zone-combo
				:required="false"
				:current="filters.sections"
				:label="this.$receiver_locale.global.filters.zone"
				@add="setFilters($event,'sections')"
			></zone-combo>
		</div>
		<div class="col-lg-1 col-12 d-block mt-2 mt-lg-0">
			<device-type-combo
				:required="false"
				:current="filters.deviceTypes"
				:label="this.$receiver_locale.global.filters.type"
				@add="setFilters($event,'deviceTypes')"
			></device-type-combo>
		</div>
		<div class="col-lg-1 col-12 d-block mt-2 mt-lg-0">
			<button class="btn btn-warning mr-2 w-100" @click="getDataFiltered()">
				<i class="fa fa-search fa-lg"></i>
			</button>
		</div>
		<div class="col-lg-2 col-12 d-block">
			<device-order-by></device-order-by>
		</div>
	</div>
</template>
<script>

import CustomersCombo from '/../Modules/Customers/Resources/assets/components/CustomerCombo.vue';
import InstallationCombo from '/../Modules/Installations/Resources/assets/components/InstallationsCombo.vue';
import ZoneCombo from '/../Modules/DevicesBundle/Resources/assets/components/zones/ZoneCombo.vue';
import DeviceTypeCombo from '/../Modules/DevicesBundle/Resources/assets/components/devices/DeviceTypeCombo.vue';
import DeviceOrderBy from './DeviceOrderBy.vue';
import {mapGetters} from 'vuex';
export default {
	name: 'DeviceFilters',
	components:{DeviceOrderBy, CustomersCombo,InstallationCombo,ZoneCombo,DeviceTypeCombo},
	created() {
		if (this.getDefaultUserFilters&&this.getDefaultFiltersLoaded){
			Object.keys(this.getDefaultUserFilters).map(async filter=>{
				if (filter!='devices'){
					await this.setFilters(this.getDefaultUserFilters[filter],filter);
				} else {
					await this.setFilters(this.getDefaultUserFilters[filter]['name'],filter);
				}
			});
			setTimeout(()=>{
				this.getDataFiltered();
				this.$store.commit('receivers/setDefaultFiltersLoaded',true);
			},500);
		} else {
			this.filters = this.getInnoboxListFilters;
		}
	},
	computed:{
		...mapGetters('receivers',['getInnoboxListFilters','getDefaultUserFilters','getDefaultFiltersLoaded'])
	},
	watch:{
		getDefaultUserFilters(){
			this.filters = this.getDefaultUserFilters;
		}
	},
	data(){
		return {
			filters:{
				devices:null,
				customers:null,
				installations:null,
				sections:null,
				deviceTypes:null
			}
		};
	},

	methods:{
		setFilters(filter,key){
			this.filters[key] = filter;
			this.$store.commit('receivers/setInnoboxListFilters',this.filters);
		},
		setFilterDevice(e,key){
			this.filters[key] = e.target.value;
			this.$store.commit('receivers/setInnoboxListFilters',this.filters);
		},
		getDataFiltered(){
			this.$store.dispatch('receivers/getDataDashboard');
		},
		search(event){
			if (event.keyCode === 13) {
				this.$store.dispatch('receivers/getDataDashboard');
			}
		},
		parseFilterDevice(){
			if (this.filters.devices){
				return this.filters.devices.name;
			}
		}
	}
};
</script>
<!-- not scoped for set css style to different components-->

<style lang="scss">
    #innobox-filter-toolbar{
        .v-select{
        }
        .vs__selected{
            font-size: 14px !important;
        }
        .vs__dropdown-toggle{
            white-space: nowrap;
            max-height:35px;

        }
        .vs__selected-options{
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
</style>
