<template>
	<div>
		<v-select
			v-model="selected"
			autocomplete="off"
			:multiple="multiple"
			style="min-width: 200px;max-width: 600px"
			:options="convertOptionsSelect(getDeviceRecipes)"
			label="label"
			code="key"
			class="style-chooser-recipe"
			@option:selected="add"
			@option:deselected="add"
			@input="add"
		></v-select>
	</div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import {mapGetters} from 'vuex';

export default {
	name: 'RecipeNameFilter',
	components: {
		vSelect
	},
	props: {
		refresh_fn: { required: true },
		multiple:{default:true}
	},

	created(){
		this.setData();
		this.current = this.getCurrentRecipes;

	},
	computed: {
		...mapGetters('receivers', ['getDeviceRecipes','getCurrentRecipes'])
	},
	watch: {
		current: function () {
			if (this.multiple){
				this.selected = this.current ? [...this.current] : null;
			} else {
				this.selected = this.current ? {...this.current} : null;
			}
		},
		selected: function(){
			if (this.selected === null) this.add();
		},
		getCurrentRecipes(){
			if (this.getCurrentRecipes && this.getCurrentRecipes.length == 0){
				this.selected = null;
			}
		}
	},
	data() {
		return {
			options: [],
			selected:null,
			current:null
		};
	},
	methods: {
		setData(){
			this.getRecipeNames();
		},
		convertOptionsSelect(data) {
			let result = [];

			data.forEach((key, value) => {

				result.push({
					'key': data[value]['id'],
					'label': data[value]['recipe_name'],
					'code': data[value]['recipe_code']
				});
			});
			return result;
		},
		getRecipeNames() {
			this.options = [...this.convertOptionsSelect(this.getDeviceRecipes)];
		},
		add() {
			this.$store.commit('receivers/setCurrentRecipes',this.selected);
		}
	}
};
</script>
