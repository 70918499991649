<template>
	<div class="table-receiver-section">
		<v-client-table
			:ref="idTable"
			:data="items"
			:columns="columns"
			:options="options"
			@row-click="rowClick"
		>
			<div slot="beforeSearch" class=" btn-filter-export-mobile mb-3">
				<button class="btn btn-warning btn-filters-mobile mr-2" @click="showFilters()">
					<i class="fa fa-filter fa-lg"></i>
				</button>
				<button class="btn btn-excel btn-excel-mobile " @click="exportCsv()">
					<i class="fa fa-file-excel fa-lg"></i>
				</button>
			</div>
			<div slot="afterFilter" class="after__filter d-block m-lg-1 m-0 col-12 col-lg-2 p-0">
				<label v-if="this.customFilters.includes('device_name')" for="">{{
					this.$receiver_locale.global.datatable.device
				}}:</label>
				<device-name-filter
					v-if="this.customFilters.includes('device_name')"
					:current="currentFilter"
					:refresh_fn="refreshData"
					:multiple="false"
					@setFilter="setFilter"
				></device-name-filter>
			</div>
			<div slot="afterFilter" class="after__filter d-block m-lg-1 m-0 col-12 col-lg-2 p-0">
				<label v-if="this.customFilters.includes('recipe_name')" for="">{{
					this.$receiver_locale.global.datatable.recipe
				}}:</label>
				<recipe-name-filter
					v-if="this.customFilters.includes('recipe_name')"
					:current="currentFilter"
					:refresh_fn="refreshData"
					@setFilter="setFilter"
				></recipe-name-filter>
			</div>
			<div slot="afterFilter" class="after__filter d-block m-lg-1 m-0 col-12 col-lg-auto p-0">
				<recipe-start-date-filter
					v-if="this.customFilters.includes('date_start')"
					:current="currentFilter"
					:refresh_fn="refreshData"
					@setFilter="setFilter"
				></recipe-start-date-filter>
			</div>
			<div slot="afterFilter" class="after__filter d-block m-lg-1 mt-2  m-0 col-12 col-lg-auto p-0">
				<button class="btn btn-warning btn-search mr-2 ml-lg-5 w-lg-auto w-sm-100" @click="filterResults()">
					<i class="fa fa-search fa-lg"></i>
					{{ this.$receiver_locale.global.search }}
				</button>
				<button
					class="btn btn-excel btn-excel-desktop"
					@click="exportCsv()"
				>
					<i class="fa fa-file-excel fa-lg"></i>
					{{ this.$receiver_locale.global.export }}
				</button>
			</div>
			<div slot="beforeTable">
				<div class="row">
					<div id="div_duration" class="col-lg-4 col-12">
						<card-template>
							<template slot="titleCard">
								{{ this.$receiver_locale.global.dash.duration }}
							</template>

							<BarChartApex
								ref="tiempo_all"
								:items="values1"
								:labels="labels1"
								:label="label1"
							/>
						</card-template>
					</div>
					<div id="div_times" class="col-lg-4 col-12">
						<card-template>
							<template slot="titleCard">
								{{ this.$receiver_locale.global.dash.times }}
							</template>

							<BarChartApex
								ref="numero_all"
								:items="values2"
								:labels="labels2"
								:label="label2"
							/>
						</card-template>
					</div>
					<div id="div_stop_type" class="col-lg-4 col-12">
						<card-template>
							<template slot="titleCard">
								{{ this.$receiver_locale.global.dash.stop_type }}
							</template>
							<DoughnutApex
								ref="stop_type_all"
								:items="values_stop_type"
								:labels="labels_stop_type"
								:label="label_stop_type"
							/>
						</card-template>
					</div>
				</div>
			</div>

			<div slot="options" slot-scope="{row}" class="text-center">
				<button class="btn btn-xs" @click="editUser(row)">
					<i class="fa fa-pie-chart fa-2x fa-align-center" style="color:#182e5e"></i>
				</button>
				<button v-if="btnConfig" class="btn btn-xs btn-primary" @click="config(row)">
					<i class="fa fa-cog"></i>
				</button>
			</div>

			<div slot="child_row" slot-scope="props">
				<div class="row">
					<div class="col-3 text-left">
						<ul>
							<li v-for="(item, key, index) in props.row" v-if="index < 12">
								{{ key }} :<b class="text-secondary">{{ item }}</b>
							</li>
						</ul>
					</div>
					<div class="col-3 text-left">
						<ul>
							<li v-for="(item, key, index) in props.row" v-if="index >= 12 && index < 24">
								{{ key }} :<b class="text-secondary">{{ item }}</b>
							</li>
						</ul>
					</div>
					<div class="col-3 text-left">
						<ul>
							<li v-for="(item, key, index) in props.row" v-if="index >= 24 && index < 36">
								{{ key }} :<b class="text-secondary">{{ item }}</b>
							</li>
						</ul>
					</div>
					<div class="col-3 text-left">
						<ul>
							<li v-for="(item, key, index) in props.row" v-if="index >= 36">
								{{ key }} :<b class="text-secondary">{{ item }}</b>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<template v-for="item in customFields">
				<div :slot="item.field">
					{{ item.content }}
				</div>
			</template>
		</v-client-table>
	</div>
</template>
<script>

import BarChartApex from '../charts/BarChartApex';

import DoughnutApex from '../charts/DoughnutApex';
import {mapGetters} from 'vuex';

export default {
	name: 'RecipeLogDatatable',
	components: {BarChartApex, DoughnutApex},
	props: {
		btnConfig: {default: false},
		columnsData: {required: true},
		idTable: {required: true},
		rowItems: {required: true},
		customFields: {required: false},
		enableCreateBtn: {default: true},
		customFilters: {required: false}
	},
	created() {
		this.setColumns();
		this.items = [...this.rowItems];
	},
	mounted() {
		this.currentFilter['device_name_filter'] = [];
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has('key') && searchParams.has('label')) {
			const key = searchParams.get('key');
			const label = searchParams.get('label');
			const date = searchParams.get('date_start');
			this.currentFilter['device_name_filter'].push({key: key, label: label});
			const now = new Date();
			this.currentFilter['date_start'] = [date, now];
			this.$bus.$emit('filter-updated', [
				{
					key,
					label,
					date
				}
			]);

		}
		this.filterResults();

	},
	computed: {
		...mapGetters('receivers', ['getCurrentDevices', 'getCurrentRecipes'])
	},
	watch: {
		rowItems: function (newItems) {
			this.items = [...newItems];
		}

	},
	data() {
		return {
			items: [],
			columns: [],
			currentFilter: [],
			prevQueryParams: null,
			values1: null,
			values2: null,
			labels1: null,
			labels2: null,
			label1: null,
			label2: null,
			values_stop_type: null,
			labels_stop_type: null,
			label_stop_type: null,

			options: {
				resizableColumns: true,
				perPage: 10,
				perPageValues: [],
				pagination: {chunk: 5},
				datepickerOptions: {
					showDropdowns: true,
					autoUpdateInput: true
				},
				sortIcon: {
					base: 'fa float-right',
					is: 'fa-sort',
					up: 'fa-sort-up',
					down: 'fa-sort-down'
				},
				texts: {
					count: `${this.$t(
						'Showing {from} to {to} of {count} records|{count} records|One record'
					)}`,
					first: `${this.$t('First')}`,
					last: `${this.$t('Last')}`,
					filter: `${this.$t('Filter')}:`,
					filterPlaceholder: `${this.$t('Search query')}`,
					limit: `${this.$t('Records')}:`,
					page: `${this.$t('Page')}:`,
					noResults: `${this.$t('No matching records')}`,
					filterBy: `${this.$t('Filter by {column}')}`,
					loading: `${this.$t('Loading')}...`,
					defaultOption: `${this.$t('Select {column}')}`,
					columns: `${this.$t('Columns')}`
				},
				columnsDisplay: {}
			}
		};
	},
	methods: {
		setColumns() {
			const headings = {};
			this.columnsData.map((item) => {
				this.columns.push(item.field);
				headings[item.field] = item.label;
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display;
				}
			});
			this.options.headings = headings;
		},
		getData() {
			this.$refs.usersTable.setLoadingState(true);
			axios.get('/api-admin/admin/users').then((response) => {
				const rows = response.data.data;

				this.items = rows;
				this.$refs.usersTable.setLoadingState(false);
			});
		},

		editUser(item) {
			this.$emit('edit', item);
		},
		deleteUser(item) {
			this.$emit('delete', item);
		},
		newItem() {
			this.$emit('new');
		},
		config(item) {
			this.$emit('config', item);
		},
		rowClick: function (item) {
			this.$emit('rowclick', item);
		},
		setFilter(filter) {
			let filterKey = Object.keys(filter)[0];
			this.currentFilter[filterKey] = filter[filterKey];
		},
		filterResults() {
			const queryParams = [];
			queryParams.push({
				device_name_filter: this.getCurrentDevices
			});
			queryParams.push({
				recipe_name_filter: this.getCurrentRecipes
			});

			queryParams.push({
				date_start: this.currentFilter['date_start']
					? this.currentFilter['date_start']
					: ''
			});

			// Only continue if the queryParams have changed
			if (JSON.stringify(queryParams) === JSON.stringify(this.prevQueryParams)) {
				return;
			}
			// Store the updated queryParams as previous ones
			this.prevQueryParams = queryParams;
			axios
				.post('/api-admin/receiver/getRecipeLogListFiltered', {
					queryParams: queryParams
				})
				.then((response) => {
					const rows = response.data;
					this.items = rows;
					this.refreshData(rows);


				})
				.catch(function (error) {
					alert(error);
				});
		},
		refreshData(items) {
			this.items = items;
			let recipe_log_id_list = [];
			for (let i = 0; i < items.length; i++) {
				recipe_log_id_list.push(items[i].id);
			}
			axios.post('/api-admin/receiver/getFaultLogDataAll', {
				recipe_log_id_list: recipe_log_id_list
			}).then((res) => {

				this.drawBarChart_duration(res.data_graph_duration);
				this.drawBarChart_times(res.data_graph_times);
				this.drawDoughnut(res.data_graph_stoptype);

			});
		},
		exportCsv() {
			let filtersKey = Object.keys(this.currentFilter);
			let queryParams = [];

			queryParams.push({
				device_name_filter: this.currentFilter['device_name_filter']
					? this.currentFilter['device_name_filter']
					: ''
			});

			queryParams.push({
				recipe_name_filter: this.currentFilter['recipe_name_filter']
					? this.currentFilter['recipe_name_filter']
					: ''
			});

			queryParams.push({
				date_start: this.currentFilter['date_start']
					? this.currentFilter['date_start']
					: ''
			});
			axios.post('/api-admin/receiver/getRecipeLogListFiltered', {queryParams: queryParams}).then((res) => {

				const blob = new Blob([this.ConvertToCSV(JSON.stringify(res.data, null, 2))], {type: 'application/json;charset=utf-8'});
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = `RecipeLogs_${this.formatDate(new Date())}.csv`;
				link.click();
				URL.revokeObjectURL(link.href);
			});
		},
		// JSON to CSV Converter
		ConvertToCSV(objArray) {
			var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
			var str = '';


			var line = '';
			for (var index in array[0]) {
				if (line != '') line += ',';

				line += index;
			}

			str += line + '\r\n';

			for (var i = 0; i < array.length; i++) {
				var line = '';
				for (var index in array[i]) {
					if (line != '') line += ',';

					line += array[i][index];
				}

				str += line + '\r\n';
			}

			return str;
		},
		formatDate(date) {
			return [
				this.padTo2Digits(date.getDate()),
				this.padTo2Digits(date.getMonth() + 1),
				date.getFullYear()
			].join('/');
		},
		padTo2Digits(num) {
			return num.toString().padStart(2, '0');
		},
		showJSONdata(param, id) {
			//$(id).text(JSON.stringify(param, null, 2))
			this.showWidgets = true;
		},
		drawBarChart_duration(param) {
			this.labels1 = [];
			param.forEach((element) => {
				this.labels1.push(element.fault_descriptionES);
			});

			this.values1 = [];
			param.forEach((element) => {
				this.values1.push(Number(element.timeToMin).toFixed(2));
			});
			this.label1 = this.$receiver_locale.global.dash.duration;
			window.dispatchEvent(new Event('resize'));
		},
		drawBarChart_times(param) {
			this.labels2 = [];
			param.forEach((element) => {
				this.labels2.push(element.fault_descriptionES);
			});
			this.values2 = [];
			param.forEach((element) => {
				this.values2.push(element.num);
			});
			this.label2 = this.$receiver_locale.global.dash.times;
			window.dispatchEvent(new Event('resize'));
		},
		drawDoughnut(param) {
			this.labels_stop_type = [];
			param.forEach((element) => {
				this.labels_stop_type.push(element.parada);
			});
			this.values_stop_type = [];
			param.forEach((element) => {
				this.values_stop_type.push(element.time);
			});
			this.label_stop_type = this.$receiver_locale.global.dash.stop_type;
			window.dispatchEvent(new Event('resize'));
		},
		showFilters() {
			window.showFilters();
		}
	}
};
</script>
<style lang="scss" scoped>
.btn-excel-mobile,.btn-excel-desktop {
    background-color: #182e5e;
    color: white;
}
</style>
