<template>
    <div>
        <div class="row my-3">
            <div class="col">
                <div class="d-flex">
                    <div class="w-50">
                        <input class="form-control " type="text" v-model="element_selected" :placeholder="this.$receiver_locale.global.elements.element_name">
                    </div>
                    <div class="w-50">
                        <button class="btn btn-primary">{{ this.$receiver_locale.global.elements.enable }} /  {{ this.$receiver_locale.global.elements.disable }}</button>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="d-flex">
                    <div class="w-50">
                        <input class="form-control " type="text" v-model="elapsed_time_selected" :placeholder="this.$receiver_locale.global.elements.elapsed_time">
                    </div>
                    <div class="w-50">
                        <button class="btn btn-primary">{{ this.$receiver_locale.global.elements.reset }} </button>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="d-flex">
                    <div class="w-50">
                        <input class="form-control " type="text" v-model="life_time_selected" :placeholder="this.$receiver_locale.global.elements.life_time">
                    </div>
                    <div class="w-50">
                        <button class="btn btn-primary">{{ this.$receiver_locale.global.elements.update }} </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ElementsInteractions",
    data(){
        return {
            element_selected:null,
            elapsed_time_selected:null,
            life_time_selected:null,
        }
    },
    methods:{
        add(){
        }
    }
}
</script>

<style scoped>

</style>
