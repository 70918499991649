<template>
	<div v-if="installations">
		<div
			v-for="(installationObject,key) in installations"
			:id="key"
			:key="key"
			class="mb-2"
		>
			<installation-item-accordion :installation="installationObject.installation" :zones="installationObject.installation"></installation-item-accordion>
		</div>
	</div>
</template>
<script>

import InstallationItemAccordion from './InstallationItemAccordion.vue';

export default {
	name: 'InstallationTreeComponent',
	components: {InstallationItemAccordion},
	props: ['installations'],
	created() {
	},
	computed: {
	},
	data: function () {
		return {
		};
	},
	methods:{

	}

};
</script>



<style scoped>

</style>
