<template>
    <h6 class="m-0 font-weight-bold text-primary mb-3">
		<small class="font-bold font-italic">#{{zone.item.id}}</small>
<!--        <small class="btn btn-xs mr-1 btn-dark">{{zone.item.code}}</small> - -->
		{{zone.item.name}}

        <button v-if="this.$helpers.hasPermission('zones.zone.delete')" @click="deleteItem(zone.item)" class="ml-1 btn btn-danger btn-xs float-right">
            <i class="fa fa-trash"></i></button>
        <button v-if="this.$helpers.hasPermission('zones.zone.update')" @click="editZone(zone.item)" class="ml-1 btn btn-warning btn-xs float-right">
            <i class="fa fa-pencil"></i></button>
        <button v-if="this.$helpers.hasPermission('zones.zone.show')" @click="showZone(zone.item)" class="ml-1 btn btn-primary btn-xs float-right">
            <i class="fa fa-eye"></i></button>
        <button v-if="!zone.item.wet_zone&&this.$helpers.hasPermission('zones.zone.create')" @click="newZone" class="ml-1 btn btn-success btn-xs float-right">
            <i class="fa fa-plus"></i></button>


        <br>
				<small class="mt-2 d-block text-gray-600">
						<b>{{this.$zones_locale.installation}}:</b> <span class="btn btn-xs btn-info mr-3">{{zone.item.installation.name}}</span>
						<b>{{this.$zones_locale.zone_types}}:</b> <span class="btn btn-xs btn-info mr-3">{{zone.item.type.name}}</span>
				</small>
				<small class="d-block mt-2 text-gray-600">
						<b>{{this.$zones_locale.path}}:</b> <i v-for="(path) in zone.path.split('/')" class="btn btn-xs btn-secondary mr-1">{{ path }}</i>
				</small>

    </h6>
</template>
<script>
import {mapGetters} from 'vuex'

export default{
    name: 'ZoneItem',
    props:{
        zone:{required:true},
        edit_fn: {required: false, type: Function},
				refresh_search_fn: {type: Function}
    },
	computed:{
		...mapGetters('zones',['newZoneUrl','editZoneUrl','showZoneUrl']),
	},
    methods:{
        deleteItem(item){
            this.$bus.$emit('fireModalConfirmDeleteFn', {
                text: `${this.$zones_locale.modals.delete_zone} ${item.name} `,
                parameters: item,
                fn: this.removeItem
            })
        },
        removeItem(item){
            const url = `/api-admin/zones/${item.id}`
            axios.delete(url).then(response=>{
                this.$toast.success(this.$t('toast.delete_success'))
								if(this.refresh_search_fn){
										this.refresh_search_fn()
								}else{
										this.$store.commit('zones/setTree',response)
								}
            })
        },
		newZone(e){
			e.preventDefault()
			window.location.href = this.newZoneUrl + '?parent=' + this.zone.item.id
		},
        showZone(item){
            this.$store.commit('zones/setCurrent',item);
            window.location.href = this.showZoneUrl
        },
		editZone(item){
			this.$store.commit('zones/setCurrent',item);
			window.location.href = this.editZoneUrl
		},
		editCheckpoints(zone){
			this.$store.commit('zones/setCurrent',zone);
			window.location.href = this.listCheckpointUrl
		}
    }
}
</script>
