<template>
	<card-template>
		<template slot="titleCard">
			{{
				this.$receiver_locale.global.api_log.apilog_list
			}}
		</template>
		<div class="table-responsive">
			<inno-core-datatable
				:enable-create-btn="false"
				:enable-edit-btn="this.$helpers.hasPermission('receiver.update')"
				:enable-delete-btn="
					this.$helpers.hasPermission('receiver.delete')
				"
				:id-table="idTable"
				:columns-data="columns"
				:row-items="items"
				:custom-fields="customFields"
				@edit="editItem"
				@delete="deleteItem"
				@new="newItem"
			>
			</inno-core-datatable>
		</div>
		<modal-confirm-delete @confirmed="removeItem" />
	</card-template>
</template>

<script>
export default {
	name: 'ApiLoglist',
	created() {
		console.log('creamos datos apilog');
		this.getItems();
	},
	computed: {},
	data() {
		return {
			idTable: 'apilogTable',
			items: [],
			customFields: [
				// {field: 'name', content: 'pepito'}
			],
			columns: [
				{
					field: 'id',
					label: '#',
					display: 'min_tabletP'
				},
				{
					field: 'date_created',
					label: this.$receiver_locale.global.api_log.date_created
				},

				{
					field: 'method',
					label: this.$receiver_locale.global.api_log.method
				},

				{
					field: 'url',
					label: this.$receiver_locale.global.api_log.url
				},

				{
					field: 'baseUrl',
					label: this.$receiver_locale.global.api_log.baseUrl
				},

				{
					field: 'originalUrl',
					label: this.$receiver_locale.global.api_log.originalUrl
				},

				{
					field: 'body',
					label: this.$receiver_locale.global.api_log.body
				},

				{
					field: 'query',
					label: this.$receiver_locale.global.api_log.query
				}

			]
		};
	},
	methods: {
		getItems() {
			console.log('axios faultlog');
			axios.get('/api-admin/receiver/getApiLogList').then((response) => {
				const rows = response.data;
				this.items = rows;
				console.log('axios apilog end');
			});
		},
		editItem(item) {},
		deleteItem(item) {},
		removeItem(item) {},
		newItem() {}
	}
};
</script>
