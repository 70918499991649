<template>
    <h6 class="m-0 font-weight-bold text-secondary mb-3">
		<small class="font-bold font-italic">#{{customer.item.id}}</small>
<!--        <small class="btn btn-xs mr-1 btn-dark">{{customer.item.code}}</small> - -->
		{{customer.item.name}}
        
        

        <button v-if="this.$helpers.hasPermission('zones.zone.delete')" @click="deleteItem(customer.item)" class="ml-1 btn btn-danger btn-xs float-right">
            <i class="fa fa-trash"></i></button>
        <button v-if="this.$helpers.hasPermission('zones.zone.update')" @click="editCustomer(customer.item)" class="ml-1 btn btn-warning btn-xs float-right">
            <i class="fa fa-pencil"></i></button>
        <!--button v-if="this.$helpers.hasPermission('zones.zone.show')" @click="showCustomer(customer.item)" class="ml-1 btn btn-primary btn-xs float-right">
            <i class="fa fa-eye"></i></button-->

        <br/>
        <i v-for="(path) in customer.path.split('/')" class="btn btn-xs btn-light mr-1">{{ path }}</i>
        <br/>
        <i v-for="(installation) in customer.item.installations" class="btn btn-xs btn-primary mr-1">{{ installation.name }}</i>

    </h6>
</template>
<script>
import {mapGetters} from 'vuex'

export default{
    name: 'CustomerItem',
    props:{
        customer:{required:true},
        edit_fn: {required: false, type: Function},
				refresh_search_fn: {type: Function}
    },
	computed:{
		...mapGetters('customers',['newCustomerUrl','editCustomerUrl','showCustomerUrl']),
	},
    methods:{
        deleteItem(item){
            this.$bus.$emit('fireModalConfirmDeleteFn', {
                text: `${this.$zones_locale.modals.delete_zone} ${item.name} `,
                parameters: item,
                fn: this.removeItem
            })
        },
        removeItem(item){
            const url = `/api-admin/customers/${item.id}`
            axios.delete(url).then(response=>{
                this.$toast.success(this.$t('toast.delete_success'))
								if(this.refresh_search_fn){
										this.refresh_search_fn()
								}else{
										this.$store.commit('customers/setTree',response)
								}
            })
        },
		newCustomer(e){
			e.preventDefault()
			window.location.href = this.newCustomerUrl + '?parent=' + this.customer.item.id
		},
        showCustomer(item){
            this.$store.commit('customers/setCurrent',item);
            window.location.href = this.showCustomerUrl
        },
		editCustomer(item){
			this.$store.commit('customers/setCurrent',item);
			window.location.href = this.editCustomerUrl
		}
    }
}
</script>
