<template>
    <div class="w-100 p-3">
        <h5 class="text-primary">{{ this.$receiver_locale.global.predictive_production.select_pieces_todo }}:</h5>
        <input type="text" class="form-control w-100" name="pieces_to_do" v-model="piecesToDo" @input="setPieces">
    </div>
</template>

<script>
export default {
    name: "SelectPiecesToDo",
    props: {
        pieces_to_production: {default: null}
    },
    data() {
        return {
            piecesToDo: null
        }
    },
    watch:{
        piecesToDo(){
            this.$emit('setPieces', this.piecesToDo)
        }

    },
    methods: {
        setPieces() {
            // this.$emit('setPieces', this.piecesToDo)
        },
        resetPieces(){
            this.piecesToDo = 0
        }
    }
}
</script>

<style scoped>

</style>
