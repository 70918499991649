<template>
	<card-template v-if="items">
		<template slot="titleCard">{{
			this.$receiver_locale.global.event_log.event_list
		}} </template>
		<button class="btn btn-excel float float-right" style="background-color: #182e5e;color:white;" @click="exportCsv(items[0].recipe_log_id)">
			<i class="fa fa-file-excel mr-1"></i>Exportar CSV
		</button>  
		<div class="table-responsive">
			<inno-core-datatable
				:enableCreateBtn="false"
				:enableEditBtn="false"
				:enableDeleteBtn="false"
				:idTable="idTable"
				:columnsData="columns"
				:rowItems="items"
				:customFields="customFields"
				@edit="editItem"
				@delete="deleteItem"
				@new="newItem"
				>
			</inno-core-datatable>
		</div>
		<modal-confirm-delete @confirmed="removeItem" />
	</card-template>
</template>

<script>
export default {
	name: 'EventLoglist',
	created() {
				
		this.$bus.$on('EventLogsByRecipe', (param) => {
			console.log('creamos datos eventlog para un recipe_id')
			console.log(param)
			this.items=param
		})
	},
	data() {
		return {
			idTable: 'eventlogTable',
			items: [],
			customFields: [
				// {field: 'name', content: 'pepito'}
			],
			columns: [
				{
					field: 'device_name',
					label: this.$receiver_locale.global.device_name,
				},
				{
					field: 'shift_name',
                    label: this.$receiver_locale.global.shift_name,
				},
				{
					field: 'event',
                    label: this.$receiver_locale.global.event_log.event,
				},
                {
					field: 'date_start',
					label: this.$receiver_locale.global.event_log.date_start,
				},

				{
					field: 'date_end',
                    label: this.$receiver_locale.global.event_log.date_end,
				},

				{
					field: 'elapsed_time',
                    label: this.$receiver_locale.global.event_log.elapsed_time,
				},	
			
				
			],
		}
	},
	computed: {},
	methods: {
		getItems() {			
			axios.get('/api-admin/receiver/getEventLogList').then((response) => {
				const rows = response.data
				this.items = rows			
			})
		},
		editItem(item) {},
		deleteItem(item) {},
		removeItem(item) {},
		newItem() {},
		exportCsv(recipe_log_id) {           
            axios.get('/api-admin/receiver/getEventLogList/'+recipe_log_id).then((res) => {                
                
                const blob = new Blob([this.ConvertToCSV(JSON.stringify(res.data,null,2))], { type: 'application/json;charset=utf-8' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = `EventLogs_${this.formatDate(new Date())}.csv`
                link.click()
                URL.revokeObjectURL(link.href)
            })
        },
         // JSON to CSV Converter
        ConvertToCSV(objArray) {
            var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
            var str = '';

            
            var line = '';
            for (var index in array[0]) {                    
                if (line != '') line += ','

                line += index;
            }

            str += line + '\r\n';

            for (var i = 0; i < array.length; i++) {
                var line = '';
                for (var index in array[i]) {
                    if (line != '') line += ','

                    line += array[i][index];
                }

                str += line + '\r\n';
            }

            return str;
        },
        formatDate(date) {
            return [
                this.padTo2Digits(date.getDate()),
                this.padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('/');
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
	},
}
</script>
<style lang="scss">
	.VueTables__table{
		td{
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;			
			font-size: 14px;
			height: 44px;
			line-height: 44px;
			text-align: center;
	
		}
		th {
			background-color:#004a98;
			color:#ffffff;
		}
		//td:hover{
		//    max-width: 300px;
		//    font-size: 14px;
		//    white-space: normal;
		//    overflow: visible;
		//}
	
	}
	.table-responsive:not(.VueTables .table-responsive){
		overflow: hidden;
		min-height: 50vh;
	}
</style>