<template>
	<div>
		<chart-apex
			v-show="series[0].data && (series[0].data.length > 0)"
			id="HistoryChart"
			ref="myApex"
			height="350px"
			chart_type="bar"
			width="100%"
			xaxis_type="category"
			:series="series"
			:categories="categories"
		/>
		<span v-show="series[0].data && (series[0].data.length == 0)">{{ this.$t('No matching records') }}</span>
	</div>
</template>

<script>

import ChartApex from '/../resources/js/components/Charts/ChartApex';

export default {
	name: 'BarChartApex',
	components: {
		ChartApex
	},
	props: {
		items: {},
		labels: {},
		label:''
	},
	created() {
		this.series[0].data=[];
		this.series[0].data = this.items;
	},
	watch: {
		items: function () {

			this.series[0].data=[];
			this.series[0].data = this.items;
		},
		labels: function () {
			this.categories.splice(0,this.categories.length);
			for (let i=0;i< this.labels.length;i++){
				this.categories[i] = this.labels[i];
			}
		},
		label:function(){
			this.series[0].name = this.label;
		}
	},
	data() {
		return {
			categories:[],
			series: [{
				name: 'series-1',
				data: []
			}]

		};
	}
};
</script>
