<template>
	<div id="sld" class="table-receiver-section">
		<v-client-table

			v-show="items"
			:ref="idTable"
			:data="items"
			:columns="columns"
			:options="options"
			@row-click="rowClick"
		>
			<div slot="beforeSearch" class=" btn-filter-export-mobile mb-3">
				<button class="btn btn-warning btn-filters-mobile mr-2" @click="showFilters()">
					<i class="fa fa-filter fa-lg"></i>
				</button>
			</div>
			<div slot="afterFilter" class="after__filter d-block m-lg-1 m-0 col-12 col-lg-2 p-0">
				<label v-if="includesDeviceName" for="">{{
					this.$receiver_locale.global.datatable.device
				}}:</label>
				<device-name-filter
					v-if="includesDeviceName"
					:current="currentFilter"
					:refresh_fn="refreshData"
					:multiple="false"
					@setFilter="setFilter"
				></device-name-filter>
			</div>
			<div slot="afterFilter" class="after__filter d-block m-lg-1 m-0 col-12 col-lg-auto p-0">
				<recipe-start-date-filter
					v-if="includesDateStart"
					:current="currentFilter"
					:refresh_fn="refreshData"
					@setFilter="setFilter"
				></recipe-start-date-filter>
			</div>
			<div slot="afterFilter" class="after__filter d-block m-lg-1 mt-2  m-0 col-12 col-lg-auto p-0">
				<button class="btn btn-warning btn-search mr-2 ml-lg-5 w-lg-auto w-sm-100" @click="filterResults()">
					<i class="fa fa-search fa-lg"></i>
					{{ this.$receiver_locale.global.search }}
				</button>
			</div>

			<div slot="beforeTable">
				<div class="row">
					<div id="shift_1" class="col-lg-4 col-12 ">
						<ShiftCard
							:title="this.$receiver_locale.global.dash.shift_1"
							:in_OEE="in_OEE_1"
							:ex_OEE="ex_OEE_1"
							:ok_units="ok_units_1"
							:nok_units="nok_units_1"
							:mttr="mttr_1"
							:mtbf="mtbf_1"
							:in_OEE_label="this.$receiver_locale.global.in_oee"
							:ex_OEE_label="this.$receiver_locale.global.ex_oee"
							:ok_units_label="this.$receiver_locale.global.ok_units"
							:nok_units_label="this.$receiver_locale.global.nok_units"
							:MTTR_label="this.$receiver_locale.global.MTTR"
							:MTBF_label="this.$receiver_locale.global.MTBF"
						/>
						<ProductionStateCard
							:title="this.$receiver_locale.global.dash.production_states"
							:values="state_1_values"
							:labels="state_1_labels"
							:label="state_1_label"
							:work_time="work_time_moment_1"
							:fault_time="fault_time_moment_1"
							:pause_time="pause_time_moment_1"
							:setup_time="setup_time_moment_1"
							:starved_time="starved_time_moment_1"
							:blocked_time="blocked_time_moment_1"
							:no_production_time="no_production_time_moment_1"
							:unit="unit_moment"
							:work_time_label="this.$receiver_locale.global.dash.work_time"
							:fault_time_label="this.$receiver_locale.global.dash.fault_time"
							:pause_time_label="this.$receiver_locale.global.dash.pause_time"
							:setup_time_label="this.$receiver_locale.global.dash.setup_time + ' (' + setup_count_1 + ')'"
							:starved_time_label="this.$receiver_locale.global.dash.starved_time"
							:blocked_time_label="this.$receiver_locale.global.dash.blocked_time"
							:no_production_time_label="this.$receiver_locale.global.dash.no_production_time"
						/>
						<DurationCard
							:title="this.$receiver_locale.global.dash.duration"
							:values="duration_1_values"
							:labels="duration_1_labels"
							:label="duration_1_label"
						/>
						<DurationCard
							:title="this.$receiver_locale.global.dash.times"
							:values="times_1_values"
							:labels="times_1_labels"
							:label="times_1_label"
						/>
					</div>
					<div id="shift_2" class="col-lg-4 col-12  ">
						<ShiftCard
							:title="this.$receiver_locale.global.dash.shift_2"
							:in_OEE="in_OEE_2"
							:ex_OEE="ex_OEE_2"
							:ok_units="ok_units_2"
							:nok_units="nok_units_2"
							:mttr="mttr_2"
							:mtbf="mtbf_2"
							:in_OEE_label="this.$receiver_locale.global.in_oee"
							:ex_OEE_label="this.$receiver_locale.global.ex_oee"
							:ok_units_label="this.$receiver_locale.global.ok_units"
							:nok_units_label="this.$receiver_locale.global.nok_units"
							:MTTR_label="this.$receiver_locale.global.MTTR"
							:MTBF_label="this.$receiver_locale.global.MTBF"
						/>
						<ProductionStateCard
							:title="this.$receiver_locale.global.dash.production_states"
							:values="state_2_values"
							:labels="state_2_labels"
							:label="state_2_label"
							:work_time="work_time_moment_2"
							:fault_time="fault_time_moment_2"
							:pause_time="pause_time_moment_2"
							:setup_time="setup_time_moment_2"
							:starved_time="starved_time_moment_2"
							:blocked_time="blocked_time_moment_2"
							:no_production_time="no_production_time_moment_2"
							:unit="unit_moment"
							:work_time_label="this.$receiver_locale.global.dash.work_time"
							:fault_time_label="this.$receiver_locale.global.dash.fault_time"
							:pause_time_label="this.$receiver_locale.global.dash.pause_time"
							:setup_time_label="this.$receiver_locale.global.dash.setup_time + ' (' + setup_count_2 + ')'"
							:starved_time_label="this.$receiver_locale.global.dash.starved_time"
							:blocked_time_label="this.$receiver_locale.global.dash.blocked_time"
							:no_production_time_label="this.$receiver_locale.global.dash.no_production_time"
						/>
						<DurationCard
							:title="this.$receiver_locale.global.dash.duration"
							:values="duration_2_values"
							:labels="duration_2_labels"
							:label="duration_2_label"
						/>
						<DurationCard
							:title="this.$receiver_locale.global.dash.times"
							:values="times_2_values"
							:labels="times_2_labels"
							:label="times_2_label"
						/>
					</div>
					<div id="shift_3" class="col-lg-4 col-12  ">
						<ShiftCard
							:title="this.$receiver_locale.global.dash.shift_3"
							:in_OEE="in_OEE_3"
							:ex_OEE="ex_OEE_3"
							:ok_units="ok_units_3"
							:nok_units="nok_units_3"
							:mttr="mttr_3"
							:mtbf="mtbf_3"
							:in_OEE_label="this.$receiver_locale.global.in_oee"
							:ex_OEE_label="this.$receiver_locale.global.ex_oee"
							:ok_units_label="this.$receiver_locale.global.ok_units"
							:nok_units_label="this.$receiver_locale.global.nok_units"
							:MTTR_label="this.$receiver_locale.global.MTTR"
							:MTBF_label="this.$receiver_locale.global.MTBF"
						/>
						<ProductionStateCard
							:title="this.$receiver_locale.global.dash.production_states"
							:values="state_3_values"
							:labels="state_3_labels"
							:label="state_3_label"
							:work_time="work_time_moment_3"
							:fault_time="fault_time_moment_3"
							:pause_time="pause_time_moment_3"
							:setup_time="setup_time_moment_3"
							:starved_time="starved_time_moment_3"
							:blocked_time="blocked_time_moment_3"
							:no_production_time="no_production_time_moment_3"
							:unit="unit_moment"
							:work_time_label="this.$receiver_locale.global.dash.work_time"
							:fault_time_label="this.$receiver_locale.global.dash.fault_time"
							:pause_time_label="this.$receiver_locale.global.dash.pause_time"
							:setup_time_label="this.$receiver_locale.global.dash.setup_time + ' (' + setup_count_3 + ')'"
							:starved_time_label="this.$receiver_locale.global.dash.starved_time"
							:blocked_time_label="this.$receiver_locale.global.dash.blocked_time"
							:no_production_time_label="this.$receiver_locale.global.dash.no_production_time"
						/>
						<DurationCard
							:title="this.$receiver_locale.global.dash.duration"
							:values="duration_3_values"
							:labels="duration_3_labels"
							:label="duration_3_label"
						/>
						<DurationCard
							:title="this.$receiver_locale.global.dash.times"
							:values="times_3_values"
							:labels="times_3_labels"
							:label="times_3_label"
						/>
					</div>
				</div>
			</div>
		</v-client-table>
	</div>
</template>
<script>
import BarChartApex from '../charts/BarChartApex';
import DoughnutApex from '../charts/DoughnutApex';
import ValueBox from '../ValueBox';
import moment from 'moment';


import ShiftCard from './ShiftCard.vue';
import DurationCard from './DurationCard.vue';
import ProductionStateCard from './StateCard.vue';
import {mapGetters} from 'vuex';

export default {
	name: 'ShiftLogDatatable',
	components: {
		BarChartApex, DoughnutApex, ValueBox, ShiftCard,
		DurationCard,
		ProductionStateCard
	},
	props: {
		btnConfig: { default: false },
		columnsData: { required: true },
		idTable: { required: true },
		rowItems: { required: true },
		customFields: { required: false },
		enableCreateBtn: { default: true },
		customFilters: { required: false }
	},
	mounted() {
		this.currentFilter['device_name_filter'] = [];
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has('key') && searchParams.has('label')) {
			const key = searchParams.get('key');
			const label = searchParams.get('label');
			const date = searchParams.get('date_start');
			this.currentFilter['device_name_filter'].push({key: key, label: label});
			const now = new Date();
			this.currentFilter['date_start'] = [date,now];
			this.$bus.$emit('filter-updated', [
				{
					key,
					label,
					date
				}
			]);
			this.filterResults();

		}
	},
	created() {
		this.setColumns();
		this.items = [...this.rowItems];

		this.filterResults();
	},
	computed: {
		includesDateStart() {
			return this.customFilters.includes('date_start');
		},
		includesDeviceName() {
			return this.customFilters.includes('device_name');
		},
		...mapGetters('receivers', ['getCurrentDevices'])

	},
	watch: {
		rowItems: function (newItems) {
			this.items = [...newItems];
		}
	},
	data() {
		return {
			items: [],
			columns: [],
			currentFilter: [],


			in_OEE_1: null, in_OEE_2: null, in_OEE_3: null,
			ex_OEE_1: null, ex_OEE_2: null, ex_OEE_3: null,

			ok_units_1: null, ok_units_2: null, ok_units_3: null,
			nok_units_1: null, nok_units_2: null, nok_units_3: null,

			mttr_1: null, mttr_2: null, mttr_3: null,
			mtbf_1: null, mtbf_2: null, mtbf_3: null,

			work_time_moment_1: null, work_time_moment_2: null, work_time_moment_3: null,
			fault_time_moment_1: null, fault_time_moment_2: null, fault_time_moment_3: null,
			pause_time_moment_1: null, pause_time_moment_2: null, pause_time_moment_3: null,
			setup_time_moment_1: null, setup_time_moment_2: null, setup_time_moment_3: null,
			setup_count_1: null, setup_count_2: null, setup_count_3: null,
			starved_time_moment_1: null, starved_time_moment_2: null, starved_time_moment_3: null,
			blocked_time_moment_1: null, blocked_time_moment_2: null, blocked_time_moment_3: null,
			no_production_time_moment_1: null, no_production_time_moment_2: null, no_production_time_moment_3: null,
			unit_moment: 'moment',

			state_1_values: null, state_2_values: null, state_3_values: null,
			state_1_labels: null, state_2_labels: null, state_3_labels: null,
			state_1_label: null, state_2_label: null, state_3_label: null,

			duration_1_values: null, duration_2_values: null, duration_3_values: null,
			duration_1_labels: null, duration_2_labels: null, duration_3_labels: null,
			duration_1_label: null, duration_2_label: null, duration_3_label: null,

			times_1_values: null, times_2_values: null, times_3_values: null,
			times_1_labels: null, times_2_labels: null, times_3_labels: null,
			times_1_label: null, times_2_label: null, times_3_label: null,

			options: {
				resizableColumns: true,
				perPage: 10,
				perPageValues: [],
				pagination: { chunk: 5 },
				datepickerOptions: {
					showDropdowns: true,
					autoUpdateInput: true
				},
				sortIcon: {
					base: 'fa float-right',
					is: 'fa-sort',
					up: 'fa-sort-up',
					down: 'fa-sort-down'
				},
				texts: {
					count: `${this.$t(
						'Showing {from} to {to} of {count} records|{count} records|One record'
					)}`,
					first: `${this.$t('First')}`,
					last: `${this.$t('Last')}`,
					filter: `${this.$t('Filter')}:`,
					filterPlaceholder: `${this.$t('Search query')}`,
					limit: `${this.$t('Records')}:`,
					page: `${this.$t('Page')}:`,
					noResults: `${this.$t('No matching records')}`,
					filterBy: `${this.$t('Filter by {column}')}`,
					loading: `${this.$t('Loading')}...`,
					defaultOption: `${this.$t('Select {column}')}`,
					columns: `${this.$t('Columns')}`
				},
				columnsDisplay: {}
			}
		};
	},
	methods: {
		setColumns() {
			const headings = {};
			this.columnsData.map((item) => {
				this.columns.push(item.field);
				headings[item.field] = item.label;
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display;
				}
			});
			this.options.headings = headings;
		},

		editUser(item) {
			this.$emit('edit', item);
		},
		deleteUser(item) {
			this.$emit('delete', item);
		},
		newItem() {
			this.$emit('new');
		},
		config(item) {
			this.$emit('config', item);
		},
		rowClick: function (item) {
			this.$emit('rowclick', item);
		},
		setFilter(filter) {
			let filterKey = Object.keys(filter)[0];
			this.currentFilter[filterKey] = filter[filterKey];
			//this.filterResults()
		},
		filterResults() {
			let queryParams = [];
			queryParams.push({
				device_name_filter: this.getCurrentDevices
			});
			queryParams.push({
				date_start: this.currentFilter['date_start']
					? this.currentFilter['date_start']
					: ''
			});


			axios
				.post('/api-admin/receiver/getShiftLogListFiltered', {
					queryParams: queryParams
				})
				.then((response) => {
					const rows = response.data;
					this.items = rows;
					if (rows.length) this.refreshData(rows);
					else this.resetData();
				})
				.catch(function (error) {
					alert(error);
				});
		},
		resetData() {
			this.items = []; // reset items array to empty
			this.shiftCodes = [1, 2, 3]; // reset shiftCodes array to empty
			// Reset all the parameters for each shiftCode back to null
			for (let i = 0; i < this.shiftCodes.length; i++) {
				const shiftCode = this.shiftCodes[i];
				this[`in_OEE_${shiftCode}`] = null;
				this[`ex_OEE_${shiftCode}`] = null;
				this[`ok_units_${shiftCode}`] = null;
				this[`nok_units_${shiftCode}`] = null;
				this[`mttr_${shiftCode}`] = null;
				this[`mtbf_${shiftCode}`] = null;
				this[`work_time_moment_${shiftCode}`] = null;
				this[`fault_time_moment_${shiftCode}`] = null;
				this[`pause_time_moment_${shiftCode}`] = null;
				this[`setup_time_moment_${shiftCode}`] = null;
				this[`starved_time_moment_${shiftCode}`] = null;
				this[`blocked_time_moment_${shiftCode}`] = null;
				this[`no_production_time_moment_${shiftCode}`] = null;
				this[`setup_count_${shiftCode}`] = null;
			}
			// Reset all the charts and graphs for each shiftCode
			for (let i = 0; i < this.shiftCodes.length; i++) {
				const shiftCode = this.shiftCodes[i];
				this[`state_${shiftCode}_drawDoughnut`]([]);
				this[`duration_${shiftCode}_drawBarChart`]([]);
				this[`times_${shiftCode}_drawBarChart`]([]);
			}
		}
		,
		refreshData(items) {

			this.items = items;
			let device_id_list = [];
			for (let i = 0; i < items.length; i++) {
				let deviceId = items[i].device_id;
				if (!device_id_list.includes(deviceId)) { // Only push if not already in array
					device_id_list.push(deviceId);
				}
			}

			axios.post('/api-admin/receiver/getShiftLogDataAll', {
				device_id_list: device_id_list,
				date_start: this.currentFilter['date_start']
					? this.currentFilter['date_start']
					: ''
			}).then((res) => {
				// Call the function for each shift_code and assign return value to corresponding variables
				this.shiftCodes = [1, 2, 3];
				for (let i = 0; i < this.shiftCodes.length; i++) {
					const shiftCode = this.shiftCodes[i];

					const params = this.getParametersForShiftCode(res, shiftCode);

					const {
						in_OEE,
						ex_OEE,
						ok_units,
						nok_units,
						MTTR,
						MTBF,
						work_time_moment,
						fault_time_moment,
						pause_time_moment,
						setup_time_moment,
						starved_time_moment,
						blocked_time_moment,
						no_production_time_moment
					} = params;

					this[`in_OEE_${shiftCode}`] = in_OEE || null;
					this[`ex_OEE_${shiftCode}`] = ex_OEE || null;
					this[`ok_units_${shiftCode}`] = ok_units || null;
					this[`nok_units_${shiftCode}`] = nok_units || null;
					this[`mttr_${shiftCode}`] = MTTR || null;
					this[`mtbf_${shiftCode}`] = MTBF || null;
					this[`work_time_moment_${shiftCode}`] = work_time_moment || null;
					this[`fault_time_moment_${shiftCode}`] = fault_time_moment || null;
					this[`pause_time_moment_${shiftCode}`] = pause_time_moment || null;
					this[`setup_time_moment_${shiftCode}`] = setup_time_moment || null;
					this[`starved_time_moment_${shiftCode}`] = starved_time_moment || null;
					this[`blocked_time_moment_${shiftCode}`] = blocked_time_moment || null;
					this[`no_production_time_moment_${shiftCode}`] = no_production_time_moment || null;


					this[`state_${shiftCode}_drawDoughnut`](res.data_graph_states.filter(item => item.shift_code === shiftCode));
					this[`duration_${shiftCode}_drawBarChart`](res.data_graph_times.filter(item => item.shift_code === shiftCode));
					this[`times_${shiftCode}_drawBarChart`](res.data_graph_stoptype.filter(item => item.shift_code === shiftCode));

					const state = res.data_graph_states.find(item => item.shift_code === shiftCode);
					this[`setup_count_${shiftCode}`] = state?.setup_count ?? null;

				}
			});
		},
		getParametersForShiftCode(res, shift_code) {
			const params = res.data_literals.find(item => item.shift_code === shift_code);
			if (!params) {
				return {};
			}
			// Use Object destructuring to assign values from each parameter inside params
			const {
				in_OEE,
				ex_OEE,
				ok_units,
				nok_units,
				MTTR,
				MTBF,
				work_time,
				fault_time,
				pause_time,
				setup_time,
				starved_time,
				blocked_time,
				no_production_time
			} = params;

			return {
				in_OEE,
				ex_OEE,
				ok_units,
				nok_units,
				MTTR,
				MTBF,
				work_time_moment: moment.duration(work_time * 1000*60),
				fault_time_moment: moment.duration(fault_time * 1000*60),
				pause_time_moment: moment.duration(pause_time * 1000*60),
				setup_time_moment: moment.duration(setup_time * 1000*60),
				starved_time_moment: moment.duration(starved_time * 1000*60),
				blocked_time_moment: moment.duration(blocked_time * 1000*60),
				no_production_time_moment: moment.duration(no_production_time * 1000*60)
			};
		}
		,

		showJSONdata(param, id) {
			//$(id).text(JSON.stringify(param, null, 2))
			this.showWidgets = true;
		},
		state_1_drawDoughnut(param) {

			this.state_1_labels = [];
			this.state_1_values = [];

			param.forEach((element) => {
				const { shift_code, num, ...rest } = element;

				Object.keys(rest).forEach((key) => {
					const label = this.$receiver_locale.global.dash[key] || key;
					this.state_1_labels.push(label + (key === 'setup_time' ? ' (' + rest['setup_count'] + ')' : ''));
					this.state_1_values.push(rest[key]);
				});
			});
			this.state_1_label = this.$receiver_locale.global.dash.stop_type;
			window.dispatchEvent(new Event('resize'));
		},
		state_2_drawDoughnut(param) {
			this.state_2_labels = [];
			this.state_2_values = [];

			param.forEach((element) => {
				const { shift_code, num, ...rest } = element;

				Object.keys(rest).forEach((key) => {
					const label = this.$receiver_locale.global.dash[key] || key;
					this.state_2_labels.push(label + (key == 'setup_time' ? ' (' + rest['setup_count'] + ')' : ''));
					this.state_2_values.push(rest[key]);
				});
			});
			this.state_2_label = this.$receiver_locale.global.dash.stop_type;
			window.dispatchEvent(new Event('resize'));
		},
		state_3_drawDoughnut(param) {
			this.state_3_labels = [];
			this.state_3_values = [];

			param.forEach((element) => {
				const { shift_code, num, ...rest } = element;

				Object.keys(rest).forEach((key) => {
					const label = this.$receiver_locale.global.dash[key] || key;
					this.state_3_labels.push(label + (key == 'setup_time' ? ' (' + rest['setup_count'] + ')' : ''));
					this.state_3_values.push(rest[key]);
				});
			});
			this.state_3_label = this.$receiver_locale.global.dash.stop_type;
			window.dispatchEvent(new Event('resize'));
		},
		duration_1_drawBarChart(param) {
			this.duration_1_labels = [];
			param.forEach((element) => {
				this.duration_1_labels.push(element.fault);
			});

			this.duration_1_values = [];
			param.forEach((element) => {
				this.duration_1_values.push(Number(element.time).toFixed(2));
			});
			this.duration_1_label = this.$receiver_locale.global.dash.duration;
			window.dispatchEvent(new Event('resize'));
		},
		duration_2_drawBarChart(param) {
			this.duration_2_labels = [];
			param.forEach((element) => {
				this.duration_2_labels.push(element.fault);
			});

			this.duration_2_values = [];
			param.forEach((element) => {
				this.duration_2_values.push(Number(element.time).toFixed(2));
			});
			this.duration_2_label = this.$receiver_locale.global.dash.duration;
			window.dispatchEvent(new Event('resize'));
		},
		duration_3_drawBarChart(param) {
			this.duration_3_labels = [];
			param.forEach((element) => {
				this.duration_3_labels.push(element.fault);
			});

			this.duration_3_values = [];
			param.forEach((element) => {
				this.duration_3_values.push(Number(element.time).toFixed(2));
			});
			this.duration_3_label = this.$receiver_locale.global.dash.duration;
			window.dispatchEvent(new Event('resize'));
		},
		times_1_drawBarChart(param) {
			this.times_1_labels = [];
			param.forEach((element) => {
				this.times_1_labels.push(element.fault);
			});

			this.times_1_values = [];
			param.forEach((element) => {
				this.times_1_values.push(Number(element.counter));
			});
			this.times_1_label = this.$receiver_locale.global.dash.times;
			window.dispatchEvent(new Event('resize'));
		},
		times_2_drawBarChart(param) {
			this.times_2_labels = [];
			param.forEach((element) => {
				this.times_2_labels.push(element.fault);
			});

			this.times_2_values = [];
			param.forEach((element) => {
				this.times_2_values.push(Number(element.counter));
			});
			this.times_2_label = this.$receiver_locale.global.dash.times;
			window.dispatchEvent(new Event('resize'));
		},
		times_3_drawBarChart(param) {
			this.times_3_labels = [];
			param.forEach((element) => {
				this.times_3_labels.push(element.fault);
			});

			this.times_3_values = [];
			param.forEach((element) => {
				this.times_3_values.push(Number(element.counter));
			});
			this.times_3_label = this.$receiver_locale.global.dash.times;
			window.dispatchEvent(new Event('resize'));
		},
		showFilters(){
			window.showFilters();
		}

	}
};
</script>

