<template>
	<card-template>
		<template slot="titleCard">
			{{
				this.$receiver_locale.global.current_production.recipe_init_stats
			}}
		</template>
		<div v-if="lastRecipeData != null" class="">
			<table class="w-100  table">
				<tbody>
					<tr v-for="(key,index) in dataKeys" :key="index">
						<td class="col-10">
							{{ $receiver_locale.global.current_production[key] }}
						</td>
						<td class="col-2">
							<b>{{ lastRecipeData[key]?lastRecipeData[key]:'0' }}%</b>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</card-template>
</template>

<script>
export default {
	name: 'RecipeInitStats',
	props: {
		currentRecipeData: {required: true}
	},
	created() {
		if (this.currentRecipeData){
			this.lastRecipeData = this.currentRecipeData;
		}
	},
	watch: {
		currentRecipeData() {
			this.lastRecipeData = [];
			if (this.currentRecipeData){
				this.lastRecipeData = this.currentRecipeData;
			}
		}
	},
	data() {
		return {
			dataKeys: ['efficiency', 'availability', 'quality', 'in_OEE', 'ex_OEE'],
			lastRecipeData: null
		};
	}
};
</script>

