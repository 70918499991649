<template>
	<div class="text-center" style="max-height:360px">
		<apex-chart v-show="series[0].data.length > 0" ref="timeline" type="rangeBar" height="350px" :options="chartOptions"
			:series="series"></apex-chart>
		<span v-show="series[0].data.length == 0" class="">{{ this.$t('No matching records') }}</span>
	</div>
</template>

<script>
import ApexChart from 'vue-apexcharts'
import moment from 'moment'
export default {
	name: 'TimeLine',
	components: { ApexChart },
	props: {
		items: {},
		labels: {}
	},
	watch: {
		items: function () {
			
			this.series[0].data = []
			this.items.forEach(item => {
				this.series[0].data.push({
					x: item.x,
					y: [
						new Date(item.y[0]).getTime(),
						new Date(item.y[1]).getTime()
					]
				})
			});			
		},		
	},
	data: function () {
		return {
			chartOptions: {
				labels: [],
				chart: {
					height: '350px',
					toolbar: {
						show: true
					},
					type: 'rangeBar'
				},
				plotOptions: {
					bar: {
						horizontal: true,
						barHeight: '100%',
						rangeBarGroupRows: true,
						dataLabels: {
							hideOverflowingLabels: true
						}
					}
				},
				dataLabels: {
					enabled: true,
					formatter: function (val) {
						
						let a = moment(val[0])
						let b = moment(val[1]);
						let diff = b.diff(a);
						return moment.utc(diff).format("HH:mm:ss");
					}

				},
				legend: {
					position: 'top',
					horizontalAlign: 'left'
				},
				tooltip: {
					enabled: true,
					x: {
						format: 'dd-mm-yyyy HH:mm:ss'
					},
					
					custom: function ({ y1, y2 }) {

						let a = moment(y1)
						let b = moment(y2)
						let diff = b.diff(a);
						return ('<h5 style="padding:5px">Start Date: <b style="color:#5063c3">' +
							a.format('DD-MM-YYYY HH:mm:ss') +
							'</b></h5>' +
							'<h5 style="padding:5px"> End Date: <b style="color:#5063c3">' +
							b.format('DD-MM-YYYY HH:mm:ss') +
							'</b></h5>' +
							'<h5 style="padding:5px"> Duration : <b style="color:#5063c3">' +
							moment.utc(diff).format("HH:mm:ss") +
							'</b></h5>')
					}
				},
				theme: {
					monochrome: {
						enabled: true,
						color: '#004a98',
						shadeTo: 'light',
						shadeIntensity: 0.65
					}
				},
				fill: {
					type: 'solid',
					/*type: 'gradient',
					gradient: {
							shadeIntensity: 2,
							opacityFrom: 0.85,
							opacityTo: 1,
							stops: [0, 100]
					}*/
				},
				xaxis: {
					type: 'datetime',
					labels: {
						datetimeUTC: false
					}
				},

			},
			series: [
				{
					name: 'Eventos',
					data: []
				}
			],
		}
	},
	methods: {
	}
}
</script>
