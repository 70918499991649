<template>

</template>
<script>
import MarkerMap from './MarkerMap'

export default {
    name: 'DrawManager',
    components: {
        MarkerMap,
    },
    props: {
        zone_id: {
            required: true,
        },
        map: {
            required: true,
        },
        google: {
            required: true,
        },
        shapes: {
            type: Array,
            default: [],
        },
        addShape_fn: {
            type: Function,
            required: true,
        },
        changeColorShapes_fn: {
            type: Function,
            required: true,
        },
        setCurrentMark_fn: {
            type: Function,
            // required: true
        },
        pushNewMark_fn: {
            type: Function,
            // required: true
        },
        emitMarkData_fn: {
            type: Function,
            required: true,
        },

    },

    watch: {
        map: function() {
            this.setDrawler()
        },
        current_shape: function() {
            this.setCurrentColor()
            this.$emit('currentShape', this.current_shape)
        },
    },
    data() {
        return {
            drawler: null,
            current_shape: null,
            options: {
                fillColor: 'rgba(116,116,116,0.5)',
                fillOpacity: 1,
                strokeWeight: 3,
                strokeColor: 'blue',
                clickable: true,
                editable: true,
                zIndex: 1,
                draggable: true,
            },
        }
    },
    methods: {
        setDrawler() {
            // add drawing menu
            const drawingManager = new this.google.maps.drawing.DrawingManager({
                // drawingMode: google.maps.drawing.OverlayType.POLYGON,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [
                        google.maps.drawing.OverlayType.POLYGON,
                        // google.maps.drawing.OverlayType.MARKER,
                    ],
                },
                polygonOptions: this.options,
            })
            this.drawler = drawingManager
            drawingManager.setMap(this.map)
            this.addListeners()
        },

        setCurrentColor() {
            this.changeColorShapes_fn(this.options.fillColor)
            this.current_shape.set('fillColor', 'rgba(196, 247, 195,.5)')
        },

        addListeners() {
            this.google.maps.event.addListener(this.drawler, 'overlaycomplete', (e) => {
                const shape = e.overlay
                const paths = this.getPaths(shape)
                shape.paths = paths
                shape.status = 'active'
                this.current_shape = shape
                this.addShape_fn(shape)
                this.onChangeListener(shape)
                this.onClickListener(shape)
            })
        },
        onClickListener(item) {
            item.addListener('click', (e) => {
                // this.$refs.MarkerDrawComponent.addMarker(e.latLng)
                this.$emit('click', item)
                this.changeColorShapes_fn(this.options.fillColor)
                item.set('fillColor','rgba(196, 247, 195,.5)')
                this.$emit('currentShape', item)
            })
        },
        onChangeListener(item) {
            this.google.maps.event.addListener(item, 'mouseup', (e) => {
                const paths = this.getPaths(item)
                item.paths = paths
                this.current_shape = item
            })
        },

        getPaths(item) {
            const paths = item.getPaths()
            const new_position = []
            paths.forEach(function(pathObject) {
                pathObject.forEach(function(position) {
                    const coord = {lat: position.lat(), lng: position.lng()}
                    new_position.push(coord)
                })
            })
            return new_position
        },
    },
}
</script>
