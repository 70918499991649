<template>
	<div>
		<apex-chart :height="height" :options="chart_options" :series="series" />
	</div>
</template>
<script>
import ApexChart from 'vue-apexcharts'

export default {
	name: 'ChartApex',
	props: {
		id: {
			required: true
		},
		height: {},
		chart_type: {
			type: String,
			default: 'area' //area, line, column, pie
		},
		categories: {
			type: Array,
			required: true
		},
		xaxis_type: {
			type: String,
			default: 'category' //category, datetime, numeric
		},
		series: {
			type: Array,
			required: true
		},
		show_guide: {
			type: Boolean,
			default: false
		},
		guide_value: {
			type: Number,
		},
		guide_label: {
			type: String,
			default: ''
		}
	},
	watch: {
		series: function () {
			window.dispatchEvent(new Event('resize'))
		}
	},
	components: {
		ApexChart
	},
	created() {
		if (this.show_guide) {
			this.chart_options.annotations = {
				yaxis: [
					{
						y: this.guide_value,
						borderColor: '#e3003d',
						label: {
							borderColor: '#e3003d',
							style: {
								color: '#fff',
								background: '#e3003d'
							},
							text: this.guide_label
						}
					}
				]
			}
		}
	},
	data() {
		return {
			chart_options: {
				chart: {
					id: this.id,
					type: this.chart_type,
					height: '350px',
					zoom: {
						autoScaleYaxis: true
					}
				},
				dataLabels: {
					position: 'bottom'
				},
				markers: {
					size: 0,
					style: 'hollow',
				},
				xaxis: {
					categories: this.categories,
					type: this.xaxis_type,
					tickAmount: 6,
					labels: {
						show: true,
						rotate: -25,
						rotateAlways: false,
						style: {
							fontSize: '10px'
						}
					}
				},
				fill: {
					type: 'solid',
				},
				theme: {
					monochrome: {
						enabled: true,
						color: '#004a98',
						shadeTo: 'light',
						shadeIntensity: 0.65
					}
				}
			}
		}
	},
}
</script>