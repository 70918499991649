<template>
    <div class="row" v-show="showWidgets">
        <div class="col-12 col-md-12" id="div_recipe_selected">
            <card-template>
                <template slot="titleCard">{{ this.$receiver_locale.global.dash.recipe_selected }}</template>
                <div class="row text-center">
                    <div class="col-6">
                        <ValueBox :label="label_recipe_code" :value="recipe_code"/>
                    </div>
                    <div class="col-6">
                        <ValueBox :label="label_name" :value="name"/>
                    </div>
                    <div class="col-4">
                        <ValueBox :label="label_inicio" :value="inicio"/>
                    </div>
                    <div class="col-4">
                        <ValueBox :label="label_end" :value="end"/>
                    </div>
                    <div class="col-4">
                        <ValueBox :label="label_elapsed" :value="elapsed"/>
                    </div>
                </div>
            </card-template>
        </div>
        <div class="col-12 col-md-12" id="div_timeline">
            <card-template>
                <template slot="titleCard">{{ this.$receiver_locale.global.dash.chronogram }}</template>
                <TimelineApex ref="chronogram" :items="values_chronogram" :labels="labels_chronogram"
                              :label="label_chronogram"/>
            </card-template>
        </div>
        <div class="col-12 col-md-6" id="div_production_states">
            <card-template>
                <template slot="titleCard">{{ this.$receiver_locale.global.dash.production_states }}</template>
                <div>
                    <DoughnutApex ref="production_state" :items="values_production_state"
                                  :labels="labels_production_state"
                                  :label="label_production_state"/>
                    <ValueBox :label="this.$receiver_locale.global.dash.work_time" :value="work_time_moment"
                              :unit="unit_moment"/>
                    <ValueBox :label="this.$receiver_locale.global.dash.fault_time" :value="fault_time_moment"
                              :unit="unit_moment"/>
                    <ValueBox :label="this.$receiver_locale.global.dash.pause_time" :value="pause_time_moment"
                              :unit="unit_moment"/>
                    <ValueBox :label="this.$receiver_locale.global.dash.setup_time" :value="setup_time_moment"
                              :unit="unit_moment"/>
                    <ValueBox :label="this.$receiver_locale.global.dash.starved_time" :value="starved_time_moment"
                              :unit="unit_moment"/>
                    <ValueBox :label="this.$receiver_locale.global.dash.blocked_time" :value="blocked_time_moment"
                              :unit="unit_moment"/>
                    <ValueBox :label="this.$receiver_locale.global.dash.no_production_time"
                              :value="no_production_time_moment" :unit="unit_moment"/>

                </div>
            </card-template>
        </div>
        <div class="col-12 col-md-6" id="div_metrics">

            <card-template>
                <template slot="titleCard">{{ this.$receiver_locale.global.dash.oee }}</template>
                <div class="row text-center">
                    <div class="col-6">

                        <ValueBox :label="label_in_oee" :value="in_OEE" :unit="unit_in_oee"/>
                    </div>
                    <div class="col-6">
                        <ValueBox :label="label_ex_oee" :value="ex_OEE" :unit="unit_ex_oee"/>
                    </div>

                </div>
            </card-template>
            <card-template>
                <template slot="titleCard">{{ this.$receiver_locale.global.dash.counters }}</template>
                <div class="row text-center">
                    <div class="col-3">

                        <ValueBox :label="label_ok_units" :value="ok_units"/>
                    </div>
                    <div class="col-3">
                        <ValueBox :label="label_nok_units" :value="nok_units"/>
                    </div>
                    <div class="col-3">
                        <ValueBox :label="label_order_units" :value="order_units"/>
                    </div>
                    <div class="col-3">
                        <ValueBox :label="label_quality" :value="quality"/>
                    </div>

                </div>
            </card-template>
            <card-template>
                <template slot="titleCard">{{ this.$receiver_locale.global.dash.quality_control }}</template>
                <div class="row text-center">
                    <div class="col-6">
                        <ValueBox :label="label_counter3" :value="counter3"/>
                    </div>
                    <div class="col-6">
                        <ValueBox :label="label_counter4" :value="counter4"/>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-6">
                        <ValueBox :label="label_counter5" :value="counter5"/>
                    </div>
                    <div class="col-6">
                        <ValueBox :label="label_counter6" :value="counter6"/>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-6">
                        <ValueBox :label="label_counter7" :value="counter7"/>
                    </div>
                    <div class="col-6">
                        <ValueBox :label="label_counter8" :value="counter8"/>
                    </div>
                </div>
            </card-template>
        </div>
        <div class="col-12" id="data_recipe_selected"></div>
        <div class="col-12" id="data_metrics"></div>
        <div class="col-12" id="data_graph_productionstate"></div>
        <div class="col-12">
            <eventlog-list></eventlog-list>
        </div>
    </div>
</template>
<script>

import BarChartApex from '../charts/BarChartApex'
import moment from 'moment'

import DoughnutApex from '../charts/DoughnutApex'
import TimelineApex from '../charts/TimelineApex'
import ValueBox from '../ValueBox'

export default {
    name: 'recipelog-dash',
    components: {BarChartApex, DoughnutApex, ValueBox, TimelineApex},
    data() {
        return {
            showWidgets: false,
            values_chronogram: null,
            values2: null,
            labels_chronogram: null,
            labels2: null,
            label_chronogram: null,
            label2: null,
            values_production_state: null,
            labels_production_state: null,
            label_production_state: null,
            label_in_oee: this.$receiver_locale.global.dash.in_OEE,
            unit_in_oee: "%",
            in_OEE: null,
            label_ex_oee: this.$receiver_locale.global.dash.ex_OEE,
            unit_ex_oee: "%",
            ex_OEE: null,
            label_name: this.$receiver_locale.global.dash.recipe_name,
            name: null,
            label_recipe_code: this.$receiver_locale.global.dash.recipe_code,
            recipe_code: null,
            id: null,
            label_inicio: this.$receiver_locale.global.dash.recipe_start_date,
            label_end: this.$receiver_locale.global.dash.recipe_end_date,
            label_elapsed: this.$receiver_locale.global.dash.recipe_elapsed,
            inicio: null,
            end: null,
            elapsed: null,
            label_ok_units: this.$receiver_locale.global.ok_units,
            ok_units: null,
            label_nok_units: this.$receiver_locale.global.nok_units,
            nok_units: null,
            label_order_units: this.$receiver_locale.global.order_units,
            order_units: null,
            label_quality: this.$receiver_locale.global.quality,
            quality: null,
            label_counter3: this.$receiver_locale.global.counter3,
            label_counter4: this.$receiver_locale.global.counter4,
            label_counter5: this.$receiver_locale.global.counter5,
            label_counter6: this.$receiver_locale.global.counter6,
            label_counter7: this.$receiver_locale.global.counter7,
            label_counter8: this.$receiver_locale.global.counter8,
            counter3: null,
            counter4: null,
            counter5: null,
            counter6: null,
            counter7: null,
            counter8: null,
            work_time_moment: null,
            fault_time_moment: null,
            pause_time_moment: null,
            setup_time_moment: null,
            starved_time_moment: null,
            blocked_time_moment: null,
            no_production_time_moment: null,
            unit_moment: 'moment'
        }
    },
   
    created() {

        this.$bus.$on('data_metrics', (param) => {
            this.showJSONdata(param, '#data_metrics')
            this.drawRecipeMetrics(param)
            this.showJSONdata(param, '#data_recipe_selected')
            this.drawRecipeSelected(param)
            this.showJSONdata(param, '#data_production_state')
            this.drawDoughnut(param)
            this.getCounterLabels(param.device_id)
        })
        this.$bus.$on('data_graph_productionstate', (param) => {
            this.showJSONdata(param, '#data_production_state')
            this.drawDoughnut(param)
        })

        this.$bus.$on('EventLogsByRecipe', (param) => {
            this.showJSONdata(param, '#data_production_state')
            this.drawTimelineChart(param)
        })
    },
    methods: {
        async getCounterLabels(deviceId) {
            await axios.post('/api-admin/receiver/getCounterByDevice/' + deviceId).then(res => {
                this.label_counter3 = res['counter3']? res['counter3']: this.$receiver_locale.global.counter3
                this.label_counter4 = res['counter4']? res['counter4']:this.$receiver_locale.global.counter4
                this.label_counter5 = res['counter5']? res['counter5']:this.$receiver_locale.global.counter5
                this.label_counter6 = res['counter6']? res['counter6']:this.$receiver_locale.global.counter6
                this.label_counter7 = res['counter7']? res['counter7']:this.$receiver_locale.global.counter7
                this.label_counter8 = res['counter8']? res['counter8']: this.$receiver_locale.global.counter8
            })
        },
        showJSONdata(param, id) {
            this.showWidgets = true
        },
        drawTimelineChart(param) {
            this.labels_chronogram = []
            param.forEach((element) => {
                this.labels_chronogram.push(element.event)
            })

            this.values_chronogram = []
            param.forEach((element) => {
                this.values_chronogram.push({
                    "x": element.event,
                    "y": [new Date(element.date_start).toISOString(), new Date(element.date_end).toISOString()]
                })
            })
            this.label_chronogram = this.$receiver_locale.global.dash.duration
            window.dispatchEvent(new Event('resize'))
        },
        drawDoughnut(param) {
            this.labels_production_state = []

            this.labels_production_state.push(this.$receiver_locale.global.dash.work_time)
            this.labels_production_state.push(this.$receiver_locale.global.dash.fault_time)
            this.labels_production_state.push(this.$receiver_locale.global.dash.pause_time)
            this.labels_production_state.push(this.$receiver_locale.global.dash.setup_time)
            this.labels_production_state.push(this.$receiver_locale.global.dash.starved_time)
            this.labels_production_state.push(this.$receiver_locale.global.dash.blocked_time)
            this.labels_production_state.push(this.$receiver_locale.global.dash.no_production_time)

            this.values_production_state = []

            this.values_production_state.push(Number(param.work_time))
            this.values_production_state.push(Number(param.fault_time))
            this.values_production_state.push(Number(param.pause_time))
            this.values_production_state.push(Number(param.setup_time))
            this.values_production_state.push(Number(param.starved_time))
            this.values_production_state.push(Number(param.blocked_time))
            this.values_production_state.push(Number(param.no_production_time))


            this.work_time_moment = moment.duration(param.work_time * 1000)
            this.fault_time_moment = moment.duration(param.fault_time * 1000)
            this.pause_time_moment = moment.duration(param.pause_time * 1000)
            this.setup_time_moment = moment.duration(param.setup_time * 1000)
            this.starved_time_moment = moment.duration(param.starved_time * 1000)
            this.blocked_time_moment = moment.duration(param.blocked_time * 1000)
            this.no_production_time_moment = moment.duration(param.no_production_time * 1000)


            this.label_production_state = this.$receiver_locale.global.dash.production_state
            window.dispatchEvent(new Event('resize'))
        },
        drawRecipeMetrics(param) {
            this.in_OEE = param.in_OEE
            this.ex_OEE = param.ex_OEE
        },
        drawRecipeSelected(param) {
            this.name = param.recipe_name
            this.recipe_code = param.recipe_code
            this.inicio = moment(param.date_start).format("DD-MM-YY hh:mm")
            this.end = param.date_end ? moment(param.date_end).format("DD-MM-YY hh:mm") : null
            this.elapsed = param.elapsed_time
            this.ok_units = param.ok_units
            this.nok_units = param.nok_units
            this.order_units = param.order_units
            this.quality = param.quality
            this.counter3 = param.counter3
            this.counter4 = param.counter4
            this.counter5 = param.counter5
            this.counter6 = param.counter6
            this.counter7 = param.counter7
            this.counter8 = param.counter8
            window.dispatchEvent(new Event('resize'))
        },
    },
}
</script>
