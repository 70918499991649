<template>
	<div class="table-receiver-section">
		<v-client-table
			ref="alarmsLogTable"
			:data="items"
			:columns="columns"
			:options="options"
		>
			<template v-for="(item) in customFields">
				<div :slot="item.field" v-html v-html="item.content">
					{{ item.content }}
				</div>
			</template>

			<div slot="beforeSearch" class=" btn-filter-export-mobile mb-3">
				<button class="btn btn-warning btn-filters-mobile mr-2" @click="showFilters()">
					<i class="fa fa-filter fa-lg"></i>
				</button>
				<button class="btn btn-excel btn-excel-mobile " style="background-color: #182e5e;color:white;" @click="exportCsv()">
					<i class="fa fa-file-excel fa-lg"></i>
				</button>
			</div>
			<div slot="afterFilter" class="after__filter d-block m-lg-1 m-0 col-12 col-lg-2 p-0">
				<label v-if="this.customFilters.includes('device_name')" for="">{{
					this.$receiver_locale.global.datatable.device
				}}:</label>
				<device-name-filter
					v-if="this.customFilters.includes('device_name')"
					:current="currentFilter"
					:refresh_fn="refreshData"
					:multiple="false"
					@setFilter="setFilter"
				></device-name-filter>
			</div>

			<div slot="afterFilter" class="after__filter d-block m-lg-1 m-0 col-12 col-lg-auto p-0">
				<recipe-start-date-filter
					v-if="includesDateStart"
					ref="recipeDateFilter"
					:current_date_range="customFilters['date_start']"
					:refresh_fn="refreshData"
					@setFilter="setFilter"
				></recipe-start-date-filter>
			</div>
			<div slot="afterFilter" class="after__filter d-block m-lg-1 mt-2  m-0 col-12 col-lg-auto p-0">
				<button class="btn btn-warning btn-search mr-2 ml-lg-5 w-lg-auto w-sm-100" @click="filterResults()">
					<i class="fa fa-search fa-lg"></i>
					{{ this.$receiver_locale.global.search }}
				</button>
				<button class="btn btn-excel btn-excel-desktop" style="background-color: #182e5e;color:white;" @click="exportCsv()">
					<i class="fa fa-file-excel fa-lg"></i>
					{{ this.$receiver_locale.global.export }}
				</button>
			</div>
		</v-client-table>
	</div>
</template>
<script>
import 'animate.css';
import {mapGetters} from 'vuex';

export default {
	name: 'AlarmsLogDatatable',
	components: {
	},
	props: {
		btnConfig: {default: false},
		customFilters: {required: false},
		columnsData: {required: true},
		idTable: {required: true},
		rowItems: {required: true},
		customFields: {required: false},
		enableCreateBtn: {default: true}
	},
	created() {
		this.setColumns();
		this.items = [...this.rowItems];

	},
	mounted() {
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has('key') && searchParams.has('label')) {
			const key = searchParams.get('key');
			const label = searchParams.get('label');
			const date = searchParams.get('date_start');

			this.filterResults();
			// Emitting an event with updated filter data
			this.$bus.$emit('filter-updated', [
				{
					key,
					label,
					date
				}
			]);
			//this.setDevice(data)
		}

		//this.filterResults()
	},
	computed: {
		includesDateStart() {
			return this.customFilters.includes('date');
		},
		...mapGetters('receivers', ['getCurrentDevices'])

	},

	data() {
		return {
			items: [],
			columns: [],
			currentFilter: [],
			options: {
				rowClassCallback: row => {
					return row.disabled ? 'disabled-item' : null;
				},

				resizableColumns: true,
				perPage: 10,
				perPageValues: [5, 10, 50, 100],
				pagination: {chunk: 5},
				datepickerOptions: {
					showDropdowns: true,
					autoUpdateInput: true
				},
				sortIcon: {
					base: 'fa float-right',
					is: 'fa-sort',
					up: 'fa-sort-up',
					down: 'fa-sort-down'
				},
				texts: {
					count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
					first: `${this.$t('First')}`,
					last: `${this.$t('Last')}`,
					filter: `${this.$t('Filter')}:`,
					filterPlaceholder: `${this.$t('Search query')}`,
					limit: `${this.$t('Records')}:`,
					page: `${this.$t('Page')}:`,
					noResults: `${this.$t('No matching records')}`,
					filterBy: `${this.$t('Filter by {column}')}`,
					loading: `${this.$t('Loading')}...`,
					defaultOption: `${this.$t('Select {column}')}`,
					columns: `${this.$t('Columns')}`
				},
				columnsDisplay: {}
			},
			selectChangeState: ''
		};
	},
	methods: {
		exportCsv(){
			let data = this.$refs.alarmsLogTable.allFilteredData;
			const blob = new Blob([this.ConvertToCSV(data)], { type: 'application/json;charset=utf-8' });
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = `AlarmLogs_${this.formatDate(new Date())}.csv`;
			link.click();
			URL.revokeObjectURL(link.href);
		},
		exportcsv2(){
			let data =this.$refs.alarmsTable.allFilteredData;
			axios.post('/api-admin/alarms/exportCsv',data).then((res)=>{
				const blob = new Blob([res], { type: 'text/csv' });
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = `alarms_${this.formatDate(new Date()) }.csv`;
				link.click();
				URL.revokeObjectURL(link.href);
			});
		},
		ConvertToCSV(objArray) {
			var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
			var str = '';


			var line = '';
			for (var index in array[0]) {
				if (line != '') line += ',';

				line += index;
			}

			str += line + '\r\n';

			for (var i = 0; i < array.length; i++) {
				var line = '';
				for (var index in array[i]) {
					if (line != '') line += ',';

					line += array[i][index];
				}

				str += line + '\r\n';
			}

			return str;
		},
		formatDate(date) {
			return [
				this.padTo2Digits(date.getDarecipeDateFilterte()),
				this.padTo2Digits(date.getMonth() + 1),
				date.getFullYear()
			].join('/');
		},
		padTo2Digits(num) {
			return num.toString().padStart(2, '0');
		},
		setColumns() {
			const headings = {};
			this.columnsData.map(item => {
				this.columns.push(item.field);
				headings[item.field] = item.label;
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display;
				}
			});
			this.options.headings = headings;
		},
		refreshData(items) {
			this.items = items;
		},

		editItem(item) {
			this.$emit('edit', item);
		},
		deleteItem(item) {
			this.$emit('delete', item);
		},
		newItem() {
			this.$emit('new');
		},
		config(item) {
			this.$emit('config', item);
		},
		setFilter(filter) {
			let filterKey = Object.keys(filter)[0];
			this.currentFilter[filterKey] = filter[filterKey];
			this.filterResults();
		},
		filterResults() {
			let queryParams = [];
			queryParams.push({
				date: this.currentFilter['date_start']
					? this.currentFilter['date_start']
					: ''
			});
			queryParams.push({
				device_name: this.getCurrentDevices
			});
			if (this.getCurrentDevices.length > 0){
				this.$emit('setFilter',queryParams);
				axios.post('/api-admin/receiver/getAlarmsLogList',queryParams).then(response => {
					const rows = response.data;
					this.items = rows;
				});
			}

		},
		restore(item) {
			axios.get('/api-admin/receiver/getAlarmsLogList').then(response => {
				this.filterResults();
			});
		},
		showFilters(){
			window.showFilters();
		}
	}
};
</script>
