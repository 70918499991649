<template>
	<li class="list-group-item p-0 d-block  py-3">
		<device-item :device="device" />
	</li>
</template>
<script>

import DeviceItem from './DeviceItem.vue';

export default {
	name: 'DeviceFinal',
	components: {DeviceItem},
	props: ['device'],
	computed: {
	},
	data: function () {
		return {
		};
	},
	methods:{

	}

};
</script>



<style scoped>
.list-group-item{
    border-top: 1px solid ligthgray;
    border-bottom: 1px solid ligthgray;
    border-right: none;
    border-left: none;
    border-radius: initial !important;
}
.list-group-item:hover {
    background-color: #f8f9fa; /* Cambia el color de fondo en hover según tus preferencias */
}
</style>
