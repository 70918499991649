<template>
    <div class="mb-3">
        <label :class="required">{{ label }}</label>
        <v-select @input="add" v-model="selected"  :options="items" label="name" code="id"></v-select>
    </div>
</template>
<script>
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"

export default {
    name: 'DeviceTypeCombo',
    props: {
        'label': {type: String},
        'current': {type: Object},
        'multiple': {default: false},
        'required': {default: ''},
    },
    components: {
        vSelect,
    },
    created() {
        this.setData()
        this.selected = this.current ? {...this.current} : null
    },
    watch: {
        current: function () {
            this.selected = {...this.current}
        },
    },
    data() {
        return {
            items: [],
            selected: {},
        }
    },
    methods: {
        add() {
            this.$emit('add', {...this.selected})
        },
        setData() {
            axios.get('/api-admin/devices/types').then(response => {
                this.items = response.data
            })
        },
    }
}
</script>
