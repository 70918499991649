<template>
		<div>
				<div class="row">
						<div class="col-12 col-sm-4 mb-2">
								<!--device-combo-signals :installation_id="installation_id" @add="setSignal"/ -->
						</div>

						<div class="col-12 col-sm-4 mb-2">
								<label for="">{{ this.$t('SelectPeriod') }}</label>
								<vue-datepicker-ui
										range
										circle
										show-clear-button
										lang="es"
										:placeholder="this.$t('SelectPeriod')"
										@input='setPeriod'
										:date-format="this.formatDate"
										text-format="short"
								/>
						</div>
						<div class="col-12 col-sm-4 mb-2">
								<label for="">{{ this.$t('SelectInterval') }}</label>
								<v-select :options="avg_type_options" v-model="avg_type" @input="setAvgType"/>
						</div>
				</div>

				<hr>
				<chart-apex
						height="350px"
						v-show="series[0].data.length > 0"
						id="HistoryChart"
						chart_type="area"
						xaxis_type="datetime"
						:series="series"
				/>
		</div>
</template>
<script>
//import DeviceComboSignals from '/../Modules/DevicesBundle/Resources/assets/components/devices/DeviceComboSignals'
import ChartApex from '/../resources/js/components/Charts/ChartApex'
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css'

export default {
		name: 'AnalyticsHistory',
		props: {
				installation_id: {required: true},
		},
		components: {
				//DeviceComboSignals,
				ChartApex,
				vSelect,
				VueDatepickerUi
		},
		computed: {
				avg_type_options: function () {
						return [
								{
										value: 'hour',
										label: this.$t('byHour')
								},
								{
										value: 'day',
										label: this.$t('byDay')
								},
								{
										value: 'minute',
										label: this.$t('byMinute')
								}
						]
				}
		},
		created() {
		},
		data() {
				return {
						filter: {
								signal_id: null,
								avg_type_id: 'hour',
								period: [],
						},
						avg_type: {
								value: 'hour',
								label: this.$t('byHour')
						},
						formatDate: {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric'
						},

						series: [{
								name: 'series-1',
								data: []
						}]

				}
		},
		methods: {
				setSignal(payload) {
						this.filter.signal_id = payload
						this.getData()
				},
				setAvgType() {
						this.filter.avg_type_id = this.avg_type ? this.avg_type.value : null
						this.getData()
				},

				setPeriod(payload){
						if (!payload.includes(null)) {
								this.filter.period = [
										new Date(payload[0]).toString().slice(0, 10),
										new Date(payload[1]).toString().slice(0, 10)
								]
						}else{
								this.filter.period = []
						}
						this.getData()
				},

				getData() {
						if (this.filter.avg_type_id && this.filter.signal_id && this.filter.period.length > 0) {
								const query = this.$helpers.setUrlParameters(this.filter)
								axios.get(`/api-admin/signals/history` + query).then(res => {
										this.series = [res.series]
								})
						}
				},
		}
}
</script>