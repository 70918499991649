<template>
	<card-template>
		<template slot="titleCard">
			{{ this.$alarms_locale.alarms_list }}
		</template>
		<div class="table-responsive">
			<alarms-table
				:enable-create-btn="false"
				:enable-edit-btn="this.$helpers.hasPermission('alarms.update')"
				:id-table="idTable"
				:columns-data="columns"
				:custom-filters="customFilters"
				:row-items="items"
				:custom-fields="customFields"
				:btn-config="false"
				@edit="editItem"
				@delete="deleteItem"
				@new="newItem"
			>
			</alarms-table>
		</div>
		<modal-confirm-delete @confirmed="removeItem" />
	</card-template>
</template>
<script>

export default {
	name: 'AlarmsList',
	created() {
		this.getAlarms();
	},
	computed: {},
	data() {
		return {
			idTable: 'alarmsTable',
			items: [],
			customFilters: [
				'date_range','installation'
			],
			customFields: [

			],
			columns: [
				{
					'field': 'id',
					'label': '#',
					'display': 'min_tabletP'
				},
				{
					'field': 'installation',
					'label': this.$t('installation')
				},
				{
					'field': 'item_code',
					'label': this.$alarms_locale.table_columns.code
				}, {
					'field': 'item_value',
					'label': this.$alarms_locale.table_columns.item_value
				},
				{
					'field': 'type',
					'label': this.$alarms_locale.table_columns.type
				},
				{
					'field': 'start_date',
					'label': this.$alarms_locale.table_columns.start_date
				},
				{
					'field': 'end_date',
					'label': this.$alarms_locale.table_columns.end_date
				},
				{
					'field': 'elapsed_time',
					'label': this.$alarms_locale.table_columns.elapsed_time
				},{
					'field': 'state',
					'label': this.$alarms_locale.table_columns.state
				},
				{
					'field': 'options',
					'label': this.$alarms_locale.table_columns.options
				}
			]
		};
	},
	methods: {
		getAlarms() {
			axios.get('/api-admin/alarms/getAlarms').then(response => {
				const rows = response.data;
				this.items = rows;
			});
		},
		editItem(item) {
			window.open(`/alarms/${item.id}/edit`,'_blank');
		},
		deleteItem(item) {
			this.$bus.$emit('fireModalConfirmDelete', {
				text: `${this.$readings_locale.modals.delete_task} ${item.name} `,
				parameters: item
			});
		},
		removeItem(item) {
			axios.delete(`/api-admin/alarms/${item.id}`).then(response => {
				const rows = response.data;
				this.items = rows;
				this.$toast.success(this.$t('toast.delete_success'));
			});
		},
		newItem() {
			window.location = '/alarms/create';
		},
		setItem(item) {
			window.location = `/alarms/${item.id}/config`;
		}
	}

};
</script>

