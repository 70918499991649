<template>
	<div class="row  m-0 p-0 ">
		<div class="row w-100">
			<div class="col-10">
				<label for="" class="border-bottom-1 border-bottom-primary"><i class="fa fa-chart-pie mr-2"></i>Asset production state</label>
			</div>
		</div>
		<div class="h-100 w-100 text-center ">
			<apex-chart
				v-if="showChart"
				ref="pie"
				type="pie"
				width="380"
				:options="
					chartOptions"
				:series="chartOptions.series"
			></apex-chart>
		</div>
	</div>
</template>
<script>


import {mapGetters} from 'vuex';
import ApexChart from 'vue-apexcharts';
import moment from 'moment/moment';

export default {
	name: 'AssetProductionStates',
	components: {ApexChart},
	created() {
		this.initData();
	},
	watch:{
		getDevicesDashboardSelected(){
			this.initData();
		}
	},
	computed: {
		...mapGetters('receivers', ['getDevicesDashboardSelected'])
	},

	data: function () {
		return {
			showChart:false,
			currentRecipeData:null,
			productionState: null,
			recipeDevice:null,
			chartOptions: {
				chart: {
					width: 380,
					type: 'pie'
				},
				noData: {
					text: 'No data',
					align: 'center',
					verticalAlign: 'middle'
				},
				legend: {
					position: 'bottom'
				},
				dataLabels: {
					enabled: false
				},
				responsive: [{
					breakpoint: 480,
					options: {
						chart: {
							width: 380
						},
						legend: {
							position: 'bottom'
						}
					}
				}]
			},
			deviceId: null,
			recipeId: null,
			recipeData: null
		};
	},
	methods: {
		resetChartData(){
			this.showChart = false;
			this.recipeData = [];
			this.chartOptions['series'] = [];
			this.chartOptions['labels'] = [];
			this.chartOptions['times'] = [];
		},
		initData(){
			this.resetChartData();
			if (this.getDevicesDashboardSelected.length > 0){
				let recipeDevicesData = [];
				const columnsData = ['work_time','fault_time','pause_time','setup_time','starved_time','blocked_time','pl_stop_time','no_production_time'];
				this.getDevicesDashboardSelected.map((item)=>{
					if (item.current_recipe){
						Object.keys(item.current_recipe).map((value) =>{
							if (columnsData.includes(value)){
								if (recipeDevicesData[value]){
									recipeDevicesData[value] += item.current_recipe[value];
								} else {
									recipeDevicesData[value] = item.current_recipe[value];
								}
							}
						});
					}
				});
				this.currentRecipeData=recipeDevicesData;
				console.log(this.currentRecipeData);
				this.recipeData = this.currentRecipeData;
				this.recipeId = this.currentRecipeData.id;
				this.deviceId = this.currentRecipeData.device_id;
				this.getProductionState();
				this.showChart = true;
			} else {
				this.currentRecipeData = null;
				this.showChart = false;
			}
		},
		async getProductionState() {
			const columnsData = ['work_time','fault_time','pause_time','setup_time','starved_time','blocked_time','pl_stop_time','no_production_time'];
			columnsData.forEach(item => {
				const time = this.buildTimes(this.recipeData[item]);
				this.chartOptions['series'].push(this.recipeData[item]);
				this.chartOptions['times'].push(time);
				this.chartOptions['labels'].push(  this.$receiver_locale.global.current_production[item]);

			});
		},
		buildTimes(time){
			const duration = moment.duration(time, 'seconds');
			const minutes = duration.minutes();
			const seconds = duration.seconds();
			return `${minutes}m : ${seconds < 10 ? '0' : ''}${seconds}s`;
		}
	}
};
</script>

<style scoped>

</style>
