<template>
	<div class="">
		<label :class="required">{{ label }}</label>
		<v-select
			v-model="selected"
			:options="items"
			label="name"
			code="id"
			@input="add"
		></v-select>
	</div>
</template>
<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
	name: 'ZoneCombo',
	components: {
		vSelect
	},
	props: {
		'label': {type: String},
		'current': {type: Object},
		'multiple': {default: false},
		'required': {default: ''}
	},
	created() {
		this.setData();
		this.selected = this.current ? {...this.current} : null;
	},
	watch: {
		current: function () {
			this.selected = {...this.current};
		}
	},
	data() {
		return {
			items: [],
			selected: {}
		};
	},
	methods: {
		add() {
			this.$emit('add', {...this.selected});
		},
		setData() {
			axios.get('/api-admin/zones').then(response => {
				this.items = response;
			});
		}
	}
};
</script>
