<template>
    <card-template>
        <template slot="titleCard">{{ this.$devices_locale.global.nav }}</template>
        <div class="table-responsive">
            <inno-core-datatable
                :enableCreateBtn="this.$helpers.hasPermission('devices.device.create')"
                :enableEditBtn="this.$helpers.hasPermission('devices.device.update')"
                :enableDeleteBtn="this.$helpers.hasPermission('devices.device.delete')"
                :idTable="idTable"
                :columnsData="columns"
                :rowItems="items"
                :customFields="customFields"
                @edit="editItem"
                @delete="deleteItem"
                @new="newItem"
            />
        </div>
        <modal-confirm-delete @confirmed="removeItem"/>
    </card-template>
</template>

<script>

export default{
    name: 'DeviceList',
    created() {
        this.getItems()
    },
    data() {
        return {
            idTable: 'devicesTable',
            items: [],
            customFields: [],
            columns: [
                {
                    field: 'id',
                    label: '#',
                    display: 'min_tabletP',
                },
                {
                    field: 'name',
                    label: this.$devices_locale.global.columns.name,
                },
                {
                    field: 'code',
                    label: this.$devices_locale.global.columns.code,
                },
                {
                    field: 'device_type_name',
                    label: this.$devices_locale.global.columns.type
                },
                {
                    field: 'device_parent_name',
                    label: this.$devices_locale.global.columns.device
                },
                {
                    field: 'installation.name',
                    label: this.$devices_locale.global.columns.installation
                },
                {
                    field: 'options',
                    label: this.$devices_locale.global.columns.options
                }
            ]
        }
    },
    computed: {},
    methods: {
        getItems() {
            axios.get('/api-admin/devices').then(response => {
                const rows = response.data
                this.items = rows
            })
        },
        editItem(item) {
            window.location = `/devices/${item.id}/edit`
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$devices_locale.global.modals.delete_device} ${item.name} `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/devices/${item.id}`).then(response => {
                this.$toast.success(this.$t('toast.delete_success'))
                this.getItems()
            })
        },
        newItem() {
            window.location = '/devices/create'
        }
    }

}
</script>
