<template>
	<card-template>
		<template slot="titleCard">{{
			this.$receiver_locale.global.receiver_list
		}}</template>
		<div class="table-responsive">
			<recipe-log-datatable
				:btnConfig="false"
				:enableCreateBtn="false"
				:enableEditBtn="true"
				:enableDeleteBtn="false"
				:idTable="idTable"
				:columnsData="columns"
				:rowItems="items"
				:customFields="customFields"
				@edit="editItem"
				:customFilters="customFilters"
								>
			</recipe-log-datatable>
		</div>
	</card-template>
</template>

<script>
export default {
	name: 'RecipeLoglist',
	created() {
		//this.getItems()
	},
	data() {
		return {
			idTable: 'recipelogTable',
			items: [],
			customFilters: [
                'device_name','recipe_name','date_start'
            ],
			customFields: [

			],
			columns: [
				{
					field:'options',
					label:this.$receiver_locale.global.options,
				},
				{
					field: 'device_name',
					label: this.$receiver_locale.global.device_name,
				},
				{
					field: 'shift_name',
                    label: this.$receiver_locale.global.shift_name,
				},

				{
					field: 'recipe_code',
                    label: this.$receiver_locale.global.recipe_code,
				},

				{
					field: 'recipe_name',
                    label: this.$receiver_locale.global.recipe_name,
				},

                {
					field: 'date_start',
					label: this.$receiver_locale.global.date_start,
				},

				{
					field: 'date_end',
                    label: this.$receiver_locale.global.date_end,
				},

				{
					field: 'elapsed_time',
                    label: this.$receiver_locale.global.elapsed_time,
				},

			],
		}
	},
	computed: {},
	methods: {
		getItems() {

			axios.post('/api-admin/receiver/getRecipeLogListFiltered').then((response) => {
				const rows = response.data
				this.items = rows
			})
		},
		editItem(item) {
			axios.get(`/api-admin/receiver/getFaultLogList/${item.id}`).then((response) => {
				const rows = response.data
				this.$bus.$emit('FaultLogsByRecipe', rows)
			})

			axios.get(`/api-admin/receiver/getFaultLogData/${item.id}`).then((response) => {

				this.$bus.$emit('data_graph_duration', response.data_graph_duration)
				this.$bus.$emit('data_graph_times', response.data_graph_times)
				this.$bus.$emit('data_metrics', response.data_metrics)
				this.$bus.$emit('data_graph_stoptype', response.data_graph_stoptype)
			})

		},
	},
}
</script>
