<template>
    <card-template>
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="mb-3">
                    <label for="name" class="required">{{ this.$subscriptions_locale.columns.name }}</label>
                    <input  type="text" v-model="formData.name" id="subscription_name"
                           class="form-control">
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="mb-3">
                    <label for="name">{{ this.$subscriptions_locale.columns.code }}</label>
                    <input type="text"  v-model="formData.code" id="subscription_code"
                           class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 mb-3 ">
                <label for="descriptionTask" class="">{{
                        this.$subscriptions_locale.columns.description
                    }}</label>
                <textarea id="descriptionTask" class="form-control"
                          v-model="formData.description"></textarea>
            </div>
        </div>
        <hr>
        <button @click="save" class="btn btn-primary">{{ this.$subscriptions_locale.buttons.save }}</button>
    </card-template>
</template>

<script>
export default {
    name: "SubscriptionTypeForm",
    props: {
        subscription_type: {required: false},
    },
    created() {
        if (this.subscription_type) {
            this.formData._method = 'put'
            this.editPath = '/' + this.subscription_type.id
            this.getItem()
        }
    },
    data() {
        return {
            editPath: '',
            formData: {
                id: null,
                name: '',
                code: '',
                description: '',
                _method: 'post'
            }
        }
    },
    methods: {
        getItem() {
            this.formData.name = this.subscription_type.name
            this.formData.code = this.subscription_type.code
            this.formData.id = this.subscription_type.id
            this.formData.description = this.subscription_type.description
        },
        save: function () {
            let formData = new FormData()
            Object.keys(this.formData).map(field => {
                formData.append(field, this.formData[field] === null ? '' : this.formData[field])
            })
            axios.post(`/api-admin/subscriptions/types${this.editPath}`, formData, {headers: {'content-type': 'multipart/form-data'}}).then(response => {
                this.$helpers.showLoading()
                this.$helpers.toastSuccess()
                setTimeout(() => {
                    window.location = `/subscriptions/types/${response.id}/edit`
                }, 1000)
            })
        }
    }
}
</script>

<style scoped>

</style>
