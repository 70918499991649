import { render, staticRenderFns } from "./FaultRecipeInit.vue?vue&type=template&id=576806cf&scoped=true&"
import script from "./FaultRecipeInit.vue?vue&type=script&lang=js&"
export * from "./FaultRecipeInit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "576806cf",
  null
  
)

export default component.exports