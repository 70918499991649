<template>
    <div class="col-12">
        <div class="row ">
            <div class="col-4">
                <h5>Salida: {{output}}</h5>
            </div>
            <div class="col-8 text-right p-0">
                <button type="button" @click="addNewDiaryConfig" class="rounded btn btn-dark btn-sm"><i
                    class="fa fa-plus-circle mr-1"></i>Añadir nuevo horario
                </button>
            </div>
        </div>
        <div class="row my-2  ">
            <div class="col-3 ">
                <label for="userScheduleDays">Selecciona los días</label>
                <v-select
                    id="userScheduleDays"
                    v-model="user_schedule.week_days"
                    name="diaryConfig[][week_days]"
                    :options="days"
                    @input="parseDaysSelected"
                    :multiple="true"
                    label="title"
                    width="100"
                    class="w-100"
                ></v-select>
<!--                <button @click="sendNewDiary()" type="button" class="btn mt-2 btn-primary">Enviar nueva agenda</button>-->

            </div>
            <div class="col-9 ">
                <div class="row m-0">
                    <div v-for="(config_diary, index) in diaryConfig" class="col-6">
                        <div class="row m-1 border border-gray rounded p-2 border-2">
                            <div class="row justify-content-center m-0">
                                <div class="col-12 p-0 text-center">
                                    <label for="userScheduleHour" class="col-6">Hora de inicio</label>
                                    <vue-timepicker @input="add" v-model="user_schedule.start_time[index]" class="col-6"
                                                    name="diaryConfig[][start_time]" x></vue-timepicker>

                                </div>
                                <div class="col-12 p-0 text-center">
                                    <label for="userScheduleHour" class="col-6">Hora de parada</label>
                                    <vue-timepicker @input="add" v-model="user_schedule.stop_time[index]" class="col-6"
                                                    name="diaryConfig[][stop_time]" x></vue-timepicker>
                                </div>
                            </div>
                            <div class="row w-100 my-1 ">
                                <div class="col-12 text-center">
                                    <button type="button" @click="removeConfigDiary(index)"
                                            class="m-auto  rounded btn btn-danger btn-sm"><i
                                        class="fa fa-trash mr-1"></i>Eliminar horario
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import vSelect from "vue-select";
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    name: "ChangeDiaryDevice",
    props: ['device_id','diary_config','output'],
    components: {
        vSelect, VueTimepicker
    },
    created() {
        if(this.diary_config!=null&&this.diary_config!='null'){
            this.setDiaryConfig()
        }
    },
    data() {
        return {
            params: ``,
            date: '',
            irrigation_table:[],
            diaryConfig: [
                {
                    start_time: '',
                    stop_time: '',
                    week_days:''
                }
            ],
            days: [
                {title: "Monday", id: "Monday"},
                {title: "Tuesday", id: "Tuesday"},
                {title: "Wednesday", id: "Wednesday"},
                {title: "Thursday", id: "Thursday"},
                {title: "Friday", id: "Friday"},
                {title: "Saturday", id: "Saturday"},
                {title: "Sunday", id: "Sunday"},
            ],
            user_schedule: {
                start_time: [{HH: '00', mm: '00'}],
                stop_time: [{HH: '00', mm: '00'}],
                week_days: ''
            },
        }
    },
    methods: {
        setDiaryConfig() {
            this.diaryConfig = [];
            let currentDiaryConfig= JSON.parse(this.diary_config)[this.output]
            this.user_schedule.week_days = currentDiaryConfig.week_days
            this.user_schedule.start_time = currentDiaryConfig['start_time']
            this.user_schedule.stop_time = currentDiaryConfig['stop_time']
            this.user_schedule.start_time.map((item,key) =>{
                this.diaryConfig.push({
                    start_time:this.user_schedule.start_time[key],
                    stop_time:this.user_schedule.stop_time[key],
                })
            })
            this.irrigation_table = this.getDataFromUserDateTime(this.user_schedule);
        },
        parseDaysSelected() {
            let result = this.user_schedule.week_days.map(val => {
                return val.id != null ? val.id : val
            })
            this.user_schedule.week_days = result
            this.add()
        },
        addNewDiaryConfig: function () {
            this.user_schedule.start_time.push({HH: '00', mm: '00'})
            this.user_schedule.stop_time.push({HH: '00', mm: '00'})
            this.diaryConfig.push(Vue.util.extend({}, this.user_schedule))
        },
        removeConfigDiary(index) {
            this.diaryConfig.splice(index, 1)
            this.user_schedule.start_time.splice(index, 1)
            this.user_schedule.stop_time.splice(index, 1)
        },
        async sendNewDiary() {
            let irrigation_table = this.getDataFromUserDateTime(this.user_schedule);
            this.postTTS_DecodedPayload(irrigation_table)
        },
        /* Get schedule from user date time */
        getDataFromUserDateTime(user_schedule) {
            var irrigation_table_array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            var j = 0;
            var i = 0;

            for (var k = 0; k < user_schedule.start_time.length; k++) {
                /* Convert time operations from object to minutes */
                let timeStart, timeStop;
                timeStart = parseInt(user_schedule.start_time[k].HH) * 60 + parseInt(user_schedule.start_time[k].mm);
                timeStop = parseInt(user_schedule.stop_time[k].HH) * 60 + parseInt(user_schedule.stop_time[k].mm);

                timeStart /= 15;
                timeStop /= 15;

                /* Calculate values for table operation */
                j = Math.trunc(timeStart / 8);
                i = timeStart % 8;
                while ((j <= Math.trunc(timeStop / 8)) && (j < 12)) {
                    while (((i < 8) && (j < Math.trunc(timeStop / 8))) || ((i < timeStop % 8) && (j === Math.trunc(timeStop / 8)))) {
                        irrigation_table_array[j] |= (1 << i);
                        i++;
                    }
                    i = 0;
                    j++;
                }
            }
            for (k = 0; k < user_schedule.week_days.length; k++) {
                if (user_schedule.week_days[k] === "Monday") {
                    irrigation_table_array[12] |= 1;
                } else if (user_schedule.week_days[k] === "Tuesday") {
                    irrigation_table_array[12] |= 2;
                } else if (user_schedule.week_days[k] === "Wednesday") {
                    irrigation_table_array[12] |= 4;
                } else if (user_schedule.week_days[k] === "Thursday") {
                    irrigation_table_array[12] |= 8;
                } else if (user_schedule.week_days[k] === "Friday") {
                    irrigation_table_array[12] |= 16;
                } else if (user_schedule.week_days[k] === "Saturday") {
                    irrigation_table_array[12] |= 32;
                } else if (user_schedule.week_days[k] === "Sunday") {
                    irrigation_table_array[12] |= 64;
                }
            }

            return irrigation_table_array;
        },
        async postTTS_DecodedPayload(irrigation_table) {
            var endpoint = "";
            endpoint = this.urlTTS;
            await axios.post(
                endpoint,
                {
                    "irrigation_table": irrigation_table
                }
            ).then(res => {
                toast.success('Device diary updated')
            });
        },
        add() {
            this.irrigation_table = this.getDataFromUserDateTime(this.user_schedule);
            this.$emit('add', this.user_schedule,this.output)
            this.$emit('setIrrigationTable', this.irrigation_table,this.output)
        }
    }
}
</script>
