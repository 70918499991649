<template>
    <button @click="stopDevice()" class="mx-2 btn btn-danger "><i class="fa fa-stop"></i> {{this.$devices_locale.buttons.stop}}</button>
</template>

<script>
export default {
    name: "StopDevice",
    props:["device_id"],
    methods: {
        stopDevice() {
            this.$swal.fire({
                title: this.$devices_locale.buttons.stop,
                html: this.$devices_locale.devices.text.stop_device,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('buttons.submit'),
                cancelButtonText: this.$t('buttons.cancel')
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/api-admin/devices/'+this.device_id+'/stop').then((res)=>{
                        this.$toast.success(this.$devices_locale.devices.text.stop_success)
                    })
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
