<template>
    <div :id='container_polygon_id'>
    </div>
</template>
<script>

export default {
    name: 'PolygonMap',
    components: {},
    props: {
        container_polygon_id: {
            required: true,
            type: String,
        },
        polygon_data: {
            type: Object,
            required: true,
        },
        map: {
            required: true,
        },
        google: {
            required: true,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        draggable: {
            type: Boolean,
            default: true,
        },
        addShape_fn: {
            type: Function,
            required: true,
        },
        changeColorShapes_fn: {
            type: Function,
            required: true,
        },
        infoWindow: {
            required: false,
        },

    },
    created() {
        this.position = this.polygon_data.paths
				if(this.google){
						this.initPolygon()
				}
    },

    watch: {
        google: function() {
            this.initPolygon()
        },
				// polygon_data: function(){
				// 		console.log('reload position polygon')
				// }
    },
    data() {
        return {
            current_paths: {},
            position: [],
            options: {
                editable: this.editable,
                draggable: this.draggable,
                strokeWeight: 2,
                fillColor: 'rgba(116,116,116,0.5)',
                fillOpacity: 1,
            },
        }
    },
    methods: {
        initPolygon() {
            const poly = new this.google.maps.Polygon({
                paths: this.position,
                ...this.options,
            })

            poly.setMap(this.map)
            const paths = this.getPaths(poly)
            poly.status = 'active'
            poly.paths = paths
            poly.info = {...this.polygon_data}
            this.addShape_fn(poly)
            this.setListeners(poly)
        },

        setCheckpoints() {
            const checkpoints = this.polygon_data.checkpoints
            if (checkpoints) {
                checkpoints.forEach((checkpoint) => {

                })
            }
        },
        setListeners(poly) {
            this.changeListener(poly)
            this.onClickListener(poly)
        },

        changeListener(poly) {
            poly.addListener('mouseup', (e) => {
                const paths = (this.getPaths(poly))
                poly.paths = paths
            })
        },

        onClickListener(poly) {
            poly.addListener('click', (e) => {
                this.$emit('click', poly)
                this.changeColorShapes_fn(this.options.fillColor)
                poly.set('fillColor', 'rgba(196, 247, 195,.5)')

                this.$emit('currentShape', poly)
                //this.setInfoWindow(e, poly)

                if (this.editable) {
                    // this.$refs.MarkerPolyComponent.addMarker(e.latLng)
                }
            })
        },

        getPaths(item) {
            const paths = item.getPaths()
            const new_position = []
            paths.getArray().forEach(function(pathObject) {
                pathObject.forEach(function(position) {
                    const coord = {lat: position.lat(), lng: position.lng()}
                    new_position.push(coord)
                })
            })
            return new_position
        },

        setInfoWindow(e, poly) {
            const title = `${this.polygon_data.zone_name}<br>
                            <small>${this.polygon_data.zone_name}</small>`
            if (this.infoWindow && title) {
                this.infoWindow.close()
                this.infoWindow.setContent(title)
                this.infoWindow.setPosition(e.latLng)
                this.infoWindow.open(this.map)
            }
        },
    },
}
</script>
