<template>
	<div :padding="'p-2'">
		<div class="">
			<elements-datatable
				:btn-config="false"
				:enable-create-btn="false"
				:enable-edit-btn="false"
				:enable-delete-btn="false"
				:id-table="idTable"
				:columns-data="columns"
				:row-items="items"
				:custom-fields="customFields"
				:custom-filters="customFilters"
				@setFilter="setFilter"
			>
			</elements-datatable>
		</div>
	</div>
</template>

<script>

import ElementsDatatable from './ElementsDatatable.vue';

export default {
	name: 'ElementsList',
	components: {ElementsDatatable},
	created() {
	},
	data(){
		return {
			idTable: 'elementsTable',
			items: [],
			customFields: [
			],
			customFilters: [
				'date','device_name'
			],
			columns: [

				{
					field: 'element_name',
					label: this.$receiver_locale.global.elements.element_name
				},
				{
					field: 'element_var',
					label: this.$receiver_locale.global.elements.element_var
				},
				{
					field: 'device.name',
					label: this.$receiver_locale.global.elements.device
				},
				{
					field: 'time_type',
					label: this.$receiver_locale.global.elements.time_type
				},
				{
					field: 'active',
					label: this.$receiver_locale.global.elements.active
				},
				{
					field: 'elapsed_time',
					label: this.$receiver_locale.global.elements.elapsed_time
				},
				{
					field: 'remaining_time',
					label: this.$receiver_locale.global.elements.remaining
				},

				{
					field: 'life_time',
					label: this.$receiver_locale.global.elements.life_time
				},
				{
					field: 'task_parsed',
					label: this.$receiver_locale.global.elements.task
				}


			]
		};
	},
	methods: {
		setFilter(queryParams){
			this.$emit('setFilter',queryParams);
		}
	}
};
</script>
