<template>
    <div class="mb-3 col-lg-4 col-12  border-right">
        <div class="col-12 ">
            <h4>Cambiar salidas del dispositivo</h4>
            <div class="col-12 m-0 pl-2">
                <table class="w-100">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Mode</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(output,index) in setOutputConfig">
                        <td><span class="mr-1">{{ output.name }}</span></td>
                        <td>
                            <VueToggles :width="50" class="" :value="output.mode" :v-model="output.mode"
                                        @click="toggleMode(index)"></VueToggles>
                        </td>
                        <td>
                            <VueToggles :width="50" class="" :value="output.status"
                                       v-model="output.status" @click="toggleStatus(index)"></VueToggles>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import VueToggles from 'vue-toggles/dist/vue-toggles.ssr';
import 'vue-toggles/dist/vue-toggles.ssr.css';

export default {
    name: "ChangeOutputDevice",
    components: {
        VueToggles
    },
    created() {
    },
    props: ['outputs_config'],
    data() {
        return {
            outputs:
                [
                    {
                        name:'code_table_output_1',
                        mode: false,
                        status:false
                    },
                    {
                        name:'code_table_output_2',
                        mode: false,
                        status: false
                    },
                    {
                        name:'code_table_output_3',
                        mode: false,
                        status: false
                    },
                    {
                        name:'code_table_output_4',
                        mode: false,
                        status: false
                    }
                ],
        }
    },
    computed: {
      setOutputConfig(){
            if(this.outputs_config){
                this.outputs = JSON.parse(this.outputs_config)
            }
            return this.outputs
        }
    },
    methods: {
        toggleMode(index) {
            this.outputs[index].mode = !this.outputs[index].mode
        },
        toggleStatus(index) {
            this.outputs[index].status = !this.outputs[index].status
        }
    }
}
</script>

<style scoped>

</style>
