<template>
    <div>
        <!--pre>
        {{getTree}}
    </pre-->
    <card-template class="tab-pane show fade active" role="tabpanel" id="customersTree" :show_header="false"
    padding="p-0">
    <customers-tree-component :edit_fn="editZone" :zones="getTree"/>
    </card-template>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import CustomersTreeComponent from './CustomersTreeComponent'

export default {
    name: 'CustomersTreeModule',
    props: {},
    components: {CustomersTreeComponent},
    computed: {
        ...mapGetters('customers', ['getTree']),
    },
    created() {
        this.$store.dispatch('customers/getTree')
    },
    data() {
        return {
            parent_zone: null,
            current: null,
            modal_id: 'ZoneModalForm',            
        }
    },
    methods: {
        editZone(item) {
            this.current = item
            $(`#${this.modal_id}`).modal('show')
        }
    }
}
</script>
