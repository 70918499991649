<template>
	<card-template>
		<template slot="titleCard">
			{{
				this.$receiver_locale.global.current_production.oee_per_hour
			}}
		</template>
		<div class="">
			<apex-chart
				v-if="recipeData&&hourData"
				ref="lineal"
				height="250"
				type="line"
				:options="chartOptions"
				:series="chartOptions.series"
			></apex-chart>
			<div v-else>
				<span>{{ this.$receiver_locale.global.current_production.hour_not_received }}</span>
			</div>
		</div>
	</card-template>
</template>

<script>
import ApexChart from 'vue-apexcharts';
export default {
	name: 'OEEPerHourChart',
	components: {ApexChart},
	props: {
		currentRecipeData: {required: true}
	},
	created() {
		this.initData();
	},
	watch: {
		currentRecipeData() {
			this.initData();
		}
	},
	data() {
		return {
			chartOptions: {
				series: [{
					name: 'OEE Machine',
					data: this.hourData?this.hourData['in_oee']:[]
				},
				{
					name: 'OEE Global',
					data:  this.hourData?this.hourData['ex_oee']:[]
				}
				],
				noData: {
					text: 'No data',
					align: 'center',
					verticalAlign: 'middle'
				},
				chart: {
					height: 200,
					width: '100%',
					type: 'line',
					zoom: {
						enabled: false
					}
				},
				dataLabels: {
					enabled: false
				},

				legend: {
					tooltipHoverFormatter: function(val, opts) {
						return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '';
					}
				},
				markers: {
					size: 0,
					hover: {
						sizeOffset: 6
					}
				},
				xaxis: {
					categories:  []
				},
				grid: {
					borderColor: '#f1f1f1'
				}
			},
			deviceId: null,
			recipeId: null,
			recipeData: null,
			hourData: null
		};
	},
	methods:{
		resetChartData(){
			this.recipeData = null;
			this.hourData = null;
			this.chartOptions['labels'] = [];
			this.chartOptions['series'] = [];
			this.chartOptions.xaxis.categories = [];
		},
		initData(){
			this.resetChartData();
			if (this.currentRecipeData) {
				this.recipeData = this.currentRecipeData;
				this.recipeId = this.currentRecipeData.id;
				this.deviceId = this.currentRecipeData.device_id;
				this.getOeePerHour();
			}
		},
		getOeePerHour(){
			const recipeDateStart = this.recipeData.date_start;
			const recipeDefId = this.recipeData.recipe_def_id;
			axios.post('/api-admin/receiver/lastOeeHour',{
				recipe_def_id:recipeDefId,
				device_id:this.deviceId,
				date_start:recipeDateStart
			}).then(res => {
				this.hourData = res;
				if (Object.keys(this.hourData).length > 0){
					this.chartOptions.xaxis.categories = this.hourData.date_start.series;
					this.chartOptions.series = [{
						name: 'OEE Machine',
						data: this.hourData?this.hourData['in_oee']:[]
					},
					{
						name: 'OEE Global',
						data:  this.hourData?this.hourData['ex_oee']:[]
					}
					];
				} else {
					this.resetChartData();
				}


			});
		},
		updateChart() {
			const max = 90;
			const min = 20;
			const newData = this.series.map(() => {
				return Math.floor(Math.random() * (max - min + 1)) + min;
			});
			this.series = newData;
		}
	}
};
</script>

<style scoped>

</style>
